
import Location from './Location'

import { isObject } from '@/services/validation';
// import { capitalize } from '@/helpers/string'

import AmsterdamRealisation from '@/models/amsterdam/Realisation'
import PNCRealisation from '@/models/park-n-charge/Realisation'
import GORRealisation from '@/models/go-ral/Realisation'
import OPCRealisation from '@/models/opcharge/Realisation'
import RVBRealisation from '@/models/rvb/Realisation'

const leadingZero = (num) => `0${num}`.slice(-2);

// let currentYear = (new Date()).getFullYear()
const formatTime = (date) =>
  [date.getHours(), date.getMinutes()] // , date.getSeconds()
  .map(leadingZero)
  .join(':')


/******************************************************************************
 * Constructors
 */
const RealisationByTenent = {
  'amsterdam': AmsterdamRealisation,
  'park-n-charge': PNCRealisation,
  'go-ral': GORRealisation,
  'opcharge': OPCRealisation,
  'rvb': RVBRealisation
}

const Realisation = RealisationByTenent[process.env.VUE_APP_TENANT] || function() {}

/******************************************************************************
 * Shared methods
 */
Realisation.prototype.getRef = function() {
  return this.ref
}
Realisation.prototype.getModelName = function() {
  return 'Realisation'
}
Realisation.prototype.setTags = function({ Tags }) {
  this.Tags = Tags
}
Realisation.prototype.hasTags = function() {
  return !! (Array.isArray(this.Tags) && this.Tags.length !== 0)
}
Realisation.prototype.getTags = function() {
  return Array.isArray(this.Tags) ? this.Tags : []
}
Realisation.prototype.getCaseRef = function() {
  return this.case_ref
}
Realisation.prototype.getLocation = function() {
  return this.values.Location
}
Realisation.prototype.hasDocOfType = function({ doctype }) {
  return Array.isArray(this.Docs[doctype]) && this.Docs[doctype].length !== 0
}
Realisation.prototype.getNextDocTypeId = function({ doctype }) {
  let ids = (this.Docs[doctype] || []).map(doc => {
    return (isObject(doc)) ? doc.id : doc
  })
  return ids.length ? Math.max(...ids) + 1 : 1
}
Realisation.prototype.nextLocationVersion = function() {
  // console.log(this.history)
  return Math.max(...this.history.map(location => location.version)) + 1
}

/******************************************************************************
 * Data formatting at contruction that is shared between all tenants
 */

if (! Realisation.prototype.generateSearchData) {
  Realisation.prototype.generateSearchData = () => {
    return {}
  }
}
if (! Realisation.prototype.generateAddressData) {
  Realisation.prototype.generateAddressData = () => {
    return ''
  }
}
if (! Realisation.prototype.generateMunicipalityData) {
  Realisation.prototype.generateMunicipalityData = () => {
    return ''
  }
}

if (! Realisation.prototype.getFlowUuid) {
  Realisation.prototype.getFlowUuid = function(){
    return this.flow && this.flow.uuid ? this.flow.uuid : false
  }
}

if (! Realisation.prototype.statusByFlow) {
  Realisation.prototype.statusByFlow = function({ flow }) {
    let steps = this.$store.getters['tenant/getWorkflowSpecByName']({ 
      name: 'realisation'
    })?.steps || []

    // most recently started step that has not been completed
    let uuid = (flow.state || [])
      .filter(step => !step.completed_at)
      .sort((a, b) => a.started_at - b.started_at)
      .map(step => step.uuid)
      .shift()

    // There was no uncompleted step. Perhaps the process was completed.
    if (! uuid) {

      // Something could have gone horribly wrong with the flow state
      if (flow.state.length === 0) {
        return {
          step: 1,
          label: 'Onbekend'
        }
      }

      // Was the final step completed? 
      let final = steps.find(step => step.final === true) 
      if (final && (flow.state || []).find(step => step.uuid === final.uuid)) {
        return {
          step: final.step || final.short,
          uuid: final.uuid,
          version: final.version,
          label: 'Afgerond'
        }
      } 

      // If not, return the details of the step that was completed last
      uuid = (flow.state || [])
        .filter(step => step.completed_at)
        .sort((a, b) => {
          // Note the assumption that the later step in the flow follows after the former
          return  b.completed_at === a.completed_at ? -1 : b.completed_at - a.completed_at
        })[0].uuid || null
    }

    let step = steps.find(step => step.uuid === uuid)
    if (! step) {

      // Perhaps we got a case of mixed flows?
      return {
        step: 1,
        label: 'Onbekend'
      }
    }

    return {
      step: step.step || step.short,
      uuid: step.uuid,
      version: step.version,
      label: step.label,
    }
  }
}

if (! Realisation.prototype.getCurrentFlowStatus) {
  Realisation.prototype.getCurrentFlowStatus = function(){
    return this.statusByFlow({ 
      flow: this.flow
    })
  }
}

if (! Realisation.prototype.orderAvailableFlowSteps) {
  Realisation.prototype.orderAvailableFlowSteps = function() {
    return this.flow.state.filter(state => state.started_at).sort((a, b) => a.started_at - b.started_at)
  }
}
if (! Realisation.prototype.nextAvailableFlowStep) {
  Realisation.prototype.nextAvailableFlowStep = function({ currentUuid }) {
    let order = this.orderAvailableFlowSteps()
    // console.log("order", order)
    let index = order.findIndex(state => state.uuid === currentUuid)
    return order[index + 1] ? order[index + 1] : order[0]
  }
}
if (! Realisation.prototype.previousAvailableFlowStep) {
  Realisation.prototype.previousAvailableFlowStep = function({ currentUuid }) {
    let order = this.orderAvailableFlowSteps()
    let index = order.findIndex(state => state.uuid === currentUuid)
    return order[index - 1] ? order[index - 1] : order[0]
  }
}

if (! Realisation.prototype.commonStructure) {
  Realisation.prototype.commonStructure = function({ ref, data }) {

    let model = {}

    // console.log("new Location", ref, data)

    let updated_at = 'Onbekend'
    let updated_at_short = ''
    if (data.updated_at) {
      
      let recordDate = new Date(data.updated_at)
      updated_at = `${recordDate.toLocaleDateString("nl-NL")} ${formatTime(recordDate)}`

      // old data
      updated_at_short = {
        date: recordDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' }).replace('. ', ' \''),
        time: formatTime(recordDate)
      }
      // if (currentYear > recordDate.getFullYear()) {
      //   updated_at_short = recordDate.toLocaleDateString("nl-NL", { day: 'numeric', month: 'short', year: 'numeric' })
      // } else {
        
      // }
    }

    // The first Location is the current Location
    let CurrentLocation = data.Locations && data.Locations.length !== 0 ? data.Locations[0] : {}


    try {
      let status = data.status ? data.status : null
  
      // Fallback... 
      if (status === null) {
        let steps = this.$store.getters['tenant/getWorkflowSpecByName']({ 
          name: 'realisation'
        })?.steps || []

        status = {
          step: steps[0].short || steps[0].step,
          uuid: steps[0].uuid,
          version: steps[0].version,
          label: steps[0].label,
          completed: false,
          cancelled: false,
          onhold: false,
          vkb: false
        }
      }

      // Go by process flow
      if (data.flow) {
        // console.log("flow available... !", data.flow, ref)

        status = Object.assign(
          status, 
          this.statusByFlow({ flow: data.flow })
        )
        // console.log(status)
      }

      let statusMetaDefaults = {
        onhold: {
          reason: '',
          label: '',
          comment: ''
        },
        cancelled: {
          reason: '',
          label: '',
          comment: ''
        },
        onholdReason:'',
        onholdLabel: '',
        onholdComment: '',
        cancelledReason: '',
        cancelledLabel: '',
        cancelledComment: '',
      }
      let statusMeta = statusMetaDefaults

      if (data.statusMeta) {
        
        // detect v2 structure
        // if (data.statusMeta.onhold) {
        //   ['onhold', 'cancelled'].forEach(status => {
        //     if (! data.statusMeta[status]) return

        //     ['reason', 'comment', 'label'].forEach(prop => {
        //       let metaProp = `${status}${capitalize(prop)}`
        //       statusMeta[metaProp] = data.statusMeta[status][prop] || ''
        //     })
        //   })
        // } else {
          
        // }
        statusMeta = Object.assign(statusMetaDefaults, data.statusMeta)
      }
      

      if (CurrentLocation) {
        CurrentLocation.Municipality = data.MunicipalityCode || data.Municipality || null
      }


      model = {
        ref: ref,
        uuid: data.uuid,

        raw: JSON.parse(JSON.stringify(data)),

        case_ref: data.case ? data.case.full: '',

        status,
        statusMeta,

        /**
         * Step 1 only for now
         */
        values: {      
          Location: CurrentLocation ? new Location({ data: CurrentLocation }) : new Location({ data: {} })
        },
        
        CurrentLocation,

        history: data.Locations ? data.Locations.map(data => new Location({ data })) : [],

        Docs: data.Docs ? {
          ParkingSpots: data.Docs.ParkingSpots || [],
          Underground: data.Docs.Underground || [],
          Broker: data.Docs.Broker || [],
          GridOperator: data.Docs.GridOperator || [],
          CPOAdditional: data.Docs.CPOAdditional || [],
          TrafficDecision: data.Docs.TrafficDecision || [],
          DeliveryDocumentation: data.Docs.DeliveryDocumentation || [],
          SideView: data.Docs.SideView || [],
          TopView: data.Docs.TopView || [],
        } : {
          ParkingSpots: [],
          Underground: [],
          Broker: [],
          GridOperator: [],
          CPOAdditional: [],
          TrafficDecision: [],
          DeliveryDocumentation: [],
          SideView: [],
          TopView: []
        },

        /**
         * Connected requests
         */
        requestUuids: data.requestUuids || [],
        relationalChanges: {
          removed: [],
          added: []
        },

        /**
         * Tags
         */
        Tags: data.Tags || [],

        /**
         * TODO: Remarks etc.
         */
        Events: data.Events || [],

        comments: data.comments || [],

        /**
         * The process flow information
         */
        flow: data.flow || null,

        updated_at,
        updated_at_short
      }


      model.Municipality = this.generateMunicipalityData({ model, data })
      model.address = this.generateAddressData({ model, data })
      model.search = this.generateSearchData({ model, data })

    } catch(e) {
      // console.log(e)
      console.log("Failed to construct data model")
    }

    return model
  }
}


export default Realisation;