<template>
  <div class="Address">
    <FieldSet
      class="mb-2" 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="coordlayout"
      :disabled="disabled"
      :path="path"
      @blur="handleBlur"
      @input="handleInput"
    />
    <strong>Publicatie adres</strong>
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @blur="handleBlur"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required, helpers } from 'vuelidate/lib/validators'
import { isNumeric, unicodeString, isLatitude, isLongitude, isDutchPostalcode } from '@/services/validation'

import { formatPostalCode } from '@/helpers/string'

export default {
  name: 'Address',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Location'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: {
        "StreetName": {
            "type": "string",
            "title": "Straatnaam"
        },
        "HouseNumber": {
            "type": "number",
            "title": "Huisnummer"
        },
        "HouseNumberSuffix": {
            "type": "string",
            "title": "Toevoeging"
        },
        "PostalCode": {
            "type": "string",
            "title": "Postcode"
        },
        "CityName": {
            "type": "string",
            "title": "Plaatsnaam"
        },
        "Latitude": {
            "type": "float",
            "title": "Breedtegraad"
        },
        "Longitude": {
            "type": "float",
            "title": "Lengtegraad"
        }
      },
      layout: [{
        "columns": '2fr 1fr 1fr',
        "fields": [ 'StreetName', 'HouseNumber', 'HouseNumberSuffix' ]
      }, {
        "columns": '1fr 1fr',
        "fields": [ 'PostalCode', 'CityName' ]
      }],
      coordlayout: [{
        "columns": '1fr 1fr',
        "fields": ['Latitude', 'Longitude']
      }],
      validation: {
        "StreetName": {
          required,
          unicodeString
        },
        "HouseNumber": {
          required, 
          isNumeric
        }, 
        "HouseNumberSuffix": {
          unicodeString: (value) => !helpers.req(value) || unicodeString(value)
        },
        "PostalCode": {
          required,
          isDutchPostalcode
        },
        "CityName": {
          required,
          unicodeString
        },
        "Latitude": {
          required,
          isLatitude
        },
        "Longitude": {
          required,
          isLongitude
        }
      },
      internalData: {}
    }
  },
  watch: {
    values(old, values) {
      Object.keys(old.Location).forEach(key => {
        if (old.Location[key] !== values.Location[key]) {
          delete this.internalData[`Location.${key}`]
        }
      })
    }
  },
  methods: {
    handleInput({ name, value }) {
      this.internalData[name] = value
    },
    handleBlur({ name }) {
      if (['Location.Latitude', 'Location.Longitude'].includes(name)) {
        this.emitCoordinateUpdate()
      } 
      else if ([
        'Location.StreetName', 'Location.HouseNumber', 'Location.HouseNumberSuffix', 
        'Location.PostalCode', 'Location.CityName'
      ].includes(name)) {
        this.emitAddressUpdate()
      }
    },
    /**
     * Communicate the current coordinates (changed or not) after user interaction with the coordinate fields
     */
    emitCoordinateUpdate() {
      let lng = parseFloat(this.internalData['Location.Longitude'] || this.values.Location['Longitude'])
      let lat = parseFloat(this.internalData['Location.Latitude'] || this.values.Location['Latitude'])

      // console.log("blur", lng, lat)

      if (lng && lat) {
        this.$emit('coordinates', { lng, lat })
      }
    },
    /**
     * Communicate the current coordinates (changed or not) after user interaction with the address fields
     */
    emitAddressUpdate() {
      const suffix = Object.prototype.hasOwnProperty.call(this.internalData, 'Location.HouseNumberSuffix') 
        ? this.internalData['Location.HouseNumberSuffix'] 
        : (this.values.Location['HouseNumberSuffix'] || '')

      this.$emit('address', {
        StreetName: this.internalData['Location.StreetName'] || this.values.Location['StreetName'],
        HouseNumber: this.internalData['Location.HouseNumber'] || this.values.Location['HouseNumber'],
        HouseNumberSuffix: suffix,
        PostalCode: formatPostalCode(this.internalData['Location.PostalCode'] || this.values.Location['PostalCode']),
        CityName: this.internalData['Location.CityName'] || this.values.Location['CityName']
      })
    }
  }
}
</script>