<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">
      
      <RealisationHeader 
        :record="record" 
        :feedback="feedback" 
        :feedbackMessage="feedbackMessage" 
        :step="currentStep" 
        :recordStep="step">
        <FlowInteraction
          position="top"
          :first="currentStep === 1"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationHeader>

      <RealisationSidebar 
        :uuid="uuid" 
        :disabled="disabled || locked" />

      <div v-if="! reloading" class="DetailGrid__map">

        <!-- TODO: invoegen instructie aanpassingen -->

        <h3>3A - Schouw verslag</h3>
        <b-alert variant="lightgray" :show="true">
          Correcties aan de bestaande situatie, zoals het aantal bestaande laadpunten of de aansluitwaarde, kunnen worden ingevoerd in stap 1.
        </b-alert>
        <Assessment 
          label=""
          :values="internalData"
          :disabled="locked || disabled || correction"    
          @blur="handleBlur"
          @input="handleInput" />

        <b-alert variant="danger" :show="correction">
          Wegens de impact op het proces kunnen de conclusies van het schouw verslag niet via een simpele <strong>correctie</strong> aangepast worden. De conclusies kunnen wel aangepast worden middels een <strong>wijziging</strong>, omdat hierbij de impact van de aanpassing op het proces volledig wordt geevalueerd.
        </b-alert>

        <h2 class="mt-5" v-if="showStep({ step: '3B' }) || showStep({ step: '3E' })">
          Netverzwaring
        </h2>

        <template v-if="showStep({ step: '3B' })">
          <h3 class="mt-4">3B - Offerte voor netverzwaring</h3>
          <RequestQuotationForGridReinforcement 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3C' })">
          <h3 class="mt-4">3C - Toestemming voor netverzwaring</h3>
          <Permissions_for_Power_Adjustments 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3D' })">
          <h3 class="mt-4">3D - Uitvoering van netverzwaring</h3>
          <Done_Adjustments_to_Building_Power 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3E' })">
          <h3 class="mt-4">3E - Opties voor de energie aansluiting</h3>
          <Assess_Power_Possibilities_II 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <h2 class="mt-5" v-if="showStep({ step: '3F' }) || showStep({ step: '3H' }) || showStep({ step: '3I' })">
          Hoofdverdeelkast
        </h2>

        <template v-if="showStep({ step: '3F' })">
          <h3 class="mt-4">3F - Offerte voor grote aanpassing hoofdverdeelkast / vervanging</h3>
          <Request_Quotation_for_Fuse_Box_Replacement 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3G' })">
          <h3 class="mt-4">3G - Toestemming voor grote aanpassing/vervanging hoofdverdeelkast</h3>
          <Permissions_for_Fusebox_Adjustments 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3H' })">
          <h3 class="mt-4">3H - Uitvoering van (grote) aanpassing/vervanging hoofdverdeelkast</h3>
          <Done_Adjustments_to_Building_Fuse_Box 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <template v-if="showStep({ step: '3I' })">
          <h3 class="mt-4">3I - Opties voor de hoofdverdeelkast</h3>
          <Assess_Fuse_Box_Possibilities_II 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>

        <h2 class="mt-5" v-if="showStep({ step: '3J' })">
          Verminder het aantal te plaatsen laadpalen
        </h2>

        <template v-if="showStep({ step: '3J' })">
          <h3 class="mt-4">3J - Aanpassing op de laadpaal aantallen</h3>
          <ParkingAndChargePoints 
            label=""
            :values="internalData"
            :disabled="locked || disabled"
            @blur="handleBlur"
            @input="handleInput" />
        </template>
      </div>
      
      <RealisationFooter 
        :feedback="feedback" 
        :feedbackMessage="feedbackMessage">
        
        <FlowInteraction
          position="bottom"
          :first="currentStep === 1"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationFooter>
      
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>


import RealisationHeader from '@/components/realisation/RealisationHeader.vue'
import RealisationFooter from '@/components/realisation/RealisationFooter.vue'
import RealisationSidebar from '@/components/realisation/RealisationSidebar.vue'

import Form from '@/components/form/Form.vue'

import Comments from '@/components/Comments.vue'

import BaseRealisationStep from '@/views/BaseRealisationStep.vue'

import FlowInteraction from '@/components/flow/FlowInteraction.vue'

// RVB
import Assessment from '@/components/definitions/rvb/step_3/Assessment.vue'
import RequestQuotationForGridReinforcement from '@/components/definitions/rvb/step_3/Request_Quotation_for_Grid_Reinforcement.vue'
import Permissions_for_Power_Adjustments from '@/components/definitions/rvb/step_3/Permissions_for_Power_Adjustments.vue'
import Done_Adjustments_to_Building_Power from '@/components/definitions/rvb/step_3/Done_Adjustments_to_Building_Power.vue'
import Assess_Power_Possibilities_II from '@/components/definitions/rvb/step_3/Assess_Power_Possibilities_II.vue'
import Request_Quotation_for_Fuse_Box_Replacement from '@/components/definitions/rvb/step_3/Request_Quotation_for_Fuse_Box_Replacement.vue'
import Permissions_for_Fusebox_Adjustments from '@/components/definitions/rvb/step_3/Permissions_for_Fusebox_Adjustments.vue'
import Done_Adjustments_to_Building_Fuse_Box from '@/components/definitions/rvb/step_3/Done_Adjustments_to_Building_Fuse_Box.vue'
import Assess_Fuse_Box_Possibilities_II from '@/components/definitions/rvb/step_3/Assess_Fuse_Box_Possibilities_II.vue'
import ParkingAndChargePoints from '@/components/definitions/rvb/ParkingAndChargePoints.vue'

import Vue from 'vue'

export default {
  name: "RealisationDetail_3",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form,
    Comments,

    FlowInteraction,

    // RVB
    // 3A
    Assessment,
    // 3B
    RequestQuotationForGridReinforcement,
    // 3C
    Permissions_for_Power_Adjustments,
    // 3D
    Done_Adjustments_to_Building_Power,
    // 3E
    Assess_Power_Possibilities_II,
    // 3F
    Request_Quotation_for_Fuse_Box_Replacement,
    // 3G
    Permissions_for_Fusebox_Adjustments,
    // 3H
    Done_Adjustments_to_Building_Fuse_Box,
    // 3I
    Assess_Fuse_Box_Possibilities_II,
    // 3J
    ParkingAndChargePoints,

  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: '3',
      internalData: {}
    }
  },
  computed: {

    showStep() {
      return ({ step }) => {
        let data = this.internalData
        // console.log("access switch - ", data)

        switch(step) {

          /**
           * Power Grid
           */

          // sales offer & permission for grid upgrade when one is needed
          case '3B': 
            return (data?.BuildingOptions?.PowerOption === 'GridUpgrade')
          case '3C':
            return (data?.BuildingOptions?.PowerOption === 'GridUpgrade')

          // If a Go is given, allow users to set a date for the work
          case '3D':
            return (
              data?.BuildingOptions?.PowerOption === 'GridUpgrade'
              && data?.BuildingOptions?.PowerPermission?.PowerAdjustmentDecision === 'Go'
            )

          // If no permission is given, the user has to make alternative plans.
          case '3E': 
            return (
              data?.BuildingOptions?.PowerOption === 'GridUpgrade'
              && data?.BuildingOptions?.PowerPermission?.PowerAdjustmentDecision === 'NoGo'
            )

          /**
           * Fusebox 
           */ 

          // sales offer & permission for a major fusebox adjustment when one is needed
          case '3F': 
            return (data?.BuildingOptions?.FuseBoxOption === 'ReplacementNeeded')
          case '3G': 
            return (data?.BuildingOptions?.FuseBoxOption === 'ReplacementNeeded')

          // If a minor adjustment is needed, or if a go has been given on a major one
          case '3H':
            return (
              (
                data?.BuildingOptions?.FuseBoxOption === 'ReplacementNeeded' 
                && data?.BuildingOptions?.FuseBoxPermission?.FuseBoxAdjustmentDecision === 'Go'
              )
              || data?.BuildingOptions?.FuseBoxOption === 'MinorChangeNeeded'
            )

          // If a major adjustment is necessary, but didn't get a go
          case '3I': 
            return (
              data?.BuildingOptions?.FuseBoxOption === 'ReplacementNeeded' 
              && data?.BuildingOptions?.FuseBoxPermission?.FuseBoxAdjustmentDecision === 'NoGo'
            )

          /**
           * Various conditions following which the plans for chargingpoints have to be adjusted
           */
          case '3J': 
            return (
              // 3A 
              data?.BuildingOptions?.PowerOption === 'ReduceChargePoints'
              // 3E
              || (
                data?.BuildingOptions?.PowerOption === 'GridUpgrade'
                && data?.BuildingOptions?.PowerPermission?.PowerAdjustmentDecision === 'NoGo'
                && data?.BuildingOptions?.PowerPermission?.PowerOption === 'ReduceChargePoints'
              )
            )
        }

        return false
      }
    }
  },
  watch: {
    values() {
      this.internalData = this.values  
    }
  },
  created() {
    this.internalData = this.values
  },
  methods: {
    handleInput({ name, value }) {
      this.reloading = true

      // console.log("input", name, value)
      if (name === 'BuildingOptions.PowerOption') {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        Vue.set(this.internalData.BuildingOptions, "PowerOption", value)
      }
      if (name === 'BuildingOptions.PowerPermission.PowerAdjustmentDecision') {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        this.internalData.BuildingOptions.PowerPermission = this.internalData.BuildingOptions.PowerPermission || {}
        Vue.set(this.internalData.BuildingOptions.PowerPermission, "PowerAdjustmentDecision", value)
      }
      if (name === 'BuildingOptions.PowerPermission.PowerOption') {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        this.internalData.BuildingOptions.PowerPermission = this.internalData.BuildingOptions.PowerPermission || {}
        Vue.set(this.internalData.BuildingOptions.PowerPermission, "PowerOption", value)
      }
      

      if (name === "BuildingOptions.FuseBoxOption") {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        Vue.set(this.internalData.BuildingOptions, "FuseBoxOption", value)
      }
      if (name === 'BuildingOptions.FuseBoxPermission.FuseBoxAdjustmentDecision') {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        this.internalData.BuildingOptions.FuseBoxPermission = this.internalData.BuildingOptions.FuseBoxPermission || {}
        Vue.set(this.internalData.BuildingOptions.FuseBoxPermission, "FuseBoxAdjustmentDecision", value)
      }
      if (name === "BuildingOptions.FuseBoxPermission.FuseBoxOption") {
        this.internalData.BuildingOptions = this.internalData.BuildingOptions || {}
        this.internalData.BuildingOptions.FuseBoxPermission = this.internalData.BuildingOptions.FuseBoxPermission || {}
        Vue.set(this.internalData.BuildingOptions.FuseBoxPermission, "FuseBoxOption", value)
      }

      this.reloading = false
    },
    handleBlur({ name }) {
      console.log("blur", name)
    }
  }
};
</script>
