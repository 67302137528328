<template>
  <div class="DetailGrid__sidebar">
    <b-tabs class="RealisationDetail__aside">
      
      <RealisationSidebarMainTab :uuid="uuid" :disabled="disabled">
        <slot />
      </RealisationSidebarMainTab>
      <RealisationSidebarRVBTab :record="record" />
      <RealisationSidebarTagsTab :record="record" />
      <RealisationSideBarEventsTab :record="record" />
      <RealisationSidebarDebugTab :record="record" />
      <RealisationSidebarMailTab :record="record" />

    </b-tabs>
  </div>
</template>

<script>
import RealisationSidebarRVBTab from '@/components/realisation/RealisationSidebarRVBTab.vue'
import RealisationSidebarMainTab from '@/components/realisation/RealisationSidebarMainTab.vue'
import RealisationSidebarTagsTab from '@/components/realisation/RealisationSidebarTagsTab.vue'
import RealisationSidebarMailTab from '@/components/realisation/RealisationSidebarMailTab.vue'
import RealisationSideBarEventsTab from '@/components/realisation/RealisationSidebarEventsTab.vue'
import RealisationSidebarDebugTab from '@/components/realisation/RelisationSidebarDebugTab.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationSidebar',
  components: {
    RealisationSidebarMainTab,
    RealisationSidebarRVBTab,
    RealisationSidebarTagsTab,
    RealisationSidebarMailTab,
    RealisationSideBarEventsTab,
    RealisationSidebarDebugTab
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    record() {
      return this.recordByUuid({
        uuid: this.uuid
      }) || {}
    }
  }
}
</script>
