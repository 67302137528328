<template>
  <b-tab v-if="showMainTab">
    <template #title>
      <b-icon class="mr-2" icon="info-circle-fill"></b-icon>
      Kenmerken
    </template>
    <p class="mt-4">
      De belangrijkste kenmerken van het realisatieproces
    </p>

    <table v-if="record && record.ref" class="table table-striped table-sm">
      <tbody>
        <tr v-if="case_ref">
          <th scope="row">Proceskenmerk</th>
          <td>{{ case_ref }}</td>
        </tr>
        <tr v-if="locationRef">
          <th scope="row">Locatiekenmerk</th>
          <td>{{ locationRef }}</td>
        </tr>
        <tr v-if="address">
          <th scope="row">Adres</th>
          <td>
            {{ address }}
            <br />
            <a target="_blank" :href='`https://www.google.com/maps/search/?api=1&amp;query=${encodeURIComponent(address)}`'>
              Open adres in Google Maps <b-icon icon="box-arrow-up-right"></b-icon>
            </a>
          </td>
        </tr>
        <tr v-if="StatusLabel">
          <th scope="row">Status</th>
          <td>{{ StatusLabel }}</td>
        </tr>
        <tr v-if="ChargePointOperator">
          <th scope="row">CPO</th>
          <td>{{ ChargePointOperator }}</td>
        </tr>
        <!-- park-n-charge specific...  -->
        <tr v-if="FinUnit">
          <th scope="row">Financiële Unit</th>
          <td>{{ FinUnit }}</td>
        </tr>
        <tr v-if="showTags">
          <th scope="row">Tags</th>
          <td>
            <span v-if="currentTags.length === 0">Er zijn geen tags</span>
            <b-badge 
              v-for="(tag,index) in currentTags" 
              :key="`tag_${index}`"
              class="mr-1" 
              variant="secondary">
              {{ labelByUuid({ uuid: tag }) }}
            </b-badge>
          </td>
        </tr>
      </tbody>
    </table>

    <AutomatedViewsBtn class="mb-3" :uuid="uuid" :disabled="disabled" />

    <h5 v-if="(location && location.Docs ? location.Docs.ParkingSpots : []).length !== 0">
      Handmatig startdocument
    </h5>
    <FileUpload 
        class=""
        label=""
        doctype="ParkingSpots"
        :uuid="uuid"
        :files="location && location.Docs ? location.Docs.ParkingSpots : null"
        :minFileId="0"
        :reference="record.ref || null"
        :casefull="record.case_ref || null"
        :locked="true"
        :disabled="true" />
    <template v-if="(location && location.Docs ? location.Docs.ParkingSpots : []).length === 0">
      <DocDownload :uuid="uuid" />
    </template>

    <div class="Views">
      <div v-if="(Docs.TopView || []).length">
        <h5 class="mt-3">
          Boven aanzicht
        </h5>
        <FileUpload 
          class="mt-3"
          label=""
          doctype="TopView"  
          :uuid="uuid"
          :files="(Docs.TopView || [])"
          :minFileId="0"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="true"
          :disabled="true" 
          :preview="true"
          :wide="true" />
      </div>
      <div v-if="(Docs.SideView || []).length">
        <h5 class="mt-3" >
          Zij aanzicht
        </h5>
        <FileUpload 
          class="mt-3"
          label=""
          doctype="SideView"  
          :uuid="uuid"
          :files="Docs.SideView || []"
          :minFileId="0"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="true"
          :disabled="true"
          :preview="true"
          :wide="true" />
      </div>
    </div>

    <slot />

    <RelatedRequests :record="record" class="mb-4" />

    <RealisationStatusPanel class="mt-4 mb-4" :uuid="uuid" />

    <RealisationVKBStatus class="mt-4 mb-4" :uuid="uuid" />

  </b-tab>
</template>

<script>

import FileUpload from '@/components/FileUpload'
import DocDownload from '@/components/realisation/DocDownload.vue'
import AutomatedViewsBtn from '@/components/realisation/AutomatedViewsBtn.vue'
import RealisationStatusPanel from '@/components/realisation/RealisationStatusPanel.vue'
import RealisationVKBStatus from '@/components/realisation/RealisationVKBStatus.vue'
import RelatedRequests from '@/components/realisation/RelatedRequests.vue'

import { mapGetters } from 'vuex'

import { formatPostalCode } from '@/helpers/string'

export default {
  name: 'RealisationSidebarMainTab',
  components: {
    FileUpload,
    RealisationStatusPanel,
    RealisationVKBStatus,
    DocDownload,
    AutomatedViewsBtn,
    RelatedRequests,
  },
  props: {
    uuid: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('tenant', [
      'isCurrentTenant',
      'getEnabledTags',
      'getLabelsByTagUuid'
    ]),
    ...mapGetters('user', [
      'hasRole' 
    ]),
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    showMainTab() {
      return process.env.VUE_APP_TENANT !== 'rvb'
    },
    record() {
      return this.recordByUuid({
        uuid: this.uuid
      }) || {}
    },
    case_ref() {
      return this.record ? this.record.case_ref : false
    },
    location() {
      try {
        return this.record.values.Location
      } catch(e) {
        return {
          Location: {}
        }
      }
    }, 
    locationRef() {
      try {
        return `${this.case_ref}-${this.location.version}`
      } catch(e) {
        return ''
      }
    },
    Docs() {
      try {
        return this.location.Docs || {}
      } catch(e) {
        return {}
      }
    },
    StatusLabel() {
      try {
        return this.record.status.label
      } catch (e) {
        return ''
      }
    },
    ChargePointOperator() {
      try {
        return this.record.values.Location.ChargePointOperator
      } catch(e) {
        return false
      }
    },
    address() {
      try {
        let location = this.location.Location

        if (this.isCurrentTenant({ tenant: 'go-ral'})) {
          location = this.getChargingPointByUUID({
            uuid: location.chargingpointUuid
          })
          location = location.data.address
          let number = (`${location.number || ''} ${location.suffix || ''}`).trim()
          return (`${location.street || ''} ${number}, ${formatPostalCode(location.postalcode || '')} ${location.city || ''}`).trim().trim(',')
        } else {
          let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
          return (`${location.StreetName || ''} ${number}, ${formatPostalCode(location.PostalCode || '')} ${location.CityName || ''}`).trim().trim(',')
        }
      } catch(e) {
        return false
      }
    },


    /**************************************************************************
     * TAGS
     */
    currentTags() {
      return this.record.getTags()
    },

    showTags() {
      return this.getEnabledTags.length || ( this.record && this.record.hasTags())
    },


    /**************************************************************************
     * FIN UNIT - park-n-charge specific
     */
    FinUnit() {
      try {
        return this.hasRole({ role: 'cpo' }) && this.isCurrentTenant({ tenant: 'park-n-charge' }) 
          ? this.record.values.Location.FinUnit 
          : false
      } catch(e) {
        return false
      }
    },
  },
  methods: {
    labelByUuid({ uuid }) {
      return this.getLabelsByTagUuid[uuid] || 'Onbekende tag'
    },
  }
}
</script>


<style lang="scss">
.Views {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  > div {
    width: 48%;
  }
}
</style>
