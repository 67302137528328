

export default {
  tenantName: 'GO Ral',

  /**
   * Feature Access checks
   */ 
  access: {
    'requestlist': ['cpo'],
    'requestlocations': ['cpo', 'municipality'],
    'realisationlist': ['cpo', 'municipality'],
    'realisationbtn': ['cpo'],
    'realisationcomment': ['cpo', 'municipality'],
    'canConfirmTrafficDecisions': ['municipality'],
    'canChangeCommentStatus': ['cpo', 'municipality'],
    'canChangeStatus': ['cpo', 'municipality'],
    'automatedViews': ['cpo'],
    'generateStartDoc': ['cpo', 'municipality'],
    'admin': ['admin'],
    'reporting': ['admin'],
    'eventlog': []
  },

  
  /** 
   * Judgement Reasons
   */ 
  reasonOptions: [
    { value: 1, text: 'Eigen parkeergelegenheid', short: 'Eigen parkeerplaats', custom: false },
    { value: 2, text: 'Laadpaal in de buurt', short: 'Bestaande laadpaal', custom: true, placeholder: 'Adres aanwezige laadpaal' },
    { value: 3, text: 'Geen geldige bewijsstukken', short: 'Bewijsstukken', custom: false },
    // { value: 4, text: 'Er is al een laadpunt in behandeling in uw buurt', short: 'Geplande laadpaal', custom: true, placeholder: 'Adres geplande laadpaal' },
    { value: 5, text: 'Anders, namelijk ...', short: 'Anders', custom: true, placeholder: 'Schrijf hier je toelichting naar de aanvrager waarom je hem afwijst. De aanhef, afwijzing en de afsluiting worden al toegevoegd.' }
  ],


  /**
   * Monitoring config
   */ 
  monitoring: {
    enabled: false,
    limit: 0.5
  },


  /**
   * search
   */ 
  search: {
    requests: {
      keys: ['summary.name', 'summary.address', 'case_ref', 'ref', 'uuid', 'search.postalcode', 'search.city'],
      placeholder: 'Zoek op referentie, naam, adres of plaats'
    },
    realisations: {
      keys: ['case_ref', 'search.Municipality', 'search.City', 'address', 'status.label', 'values.Location.PlannedRealisation.Contractor', 'values.Location.GridOperator', 'ref', 'uuid'],
      placeholder: 'Zoek op referentie, adres of stap'
    }
  },


  /** 
   * onHold & cancel reasons
   */ 
  onHoldReasons: [{
    text: 'Bezwaar VKB binnengekomen',
    value: 'vkb'
  }, {
    text: 'Overige',
    value: 'other'
  }],

  cancelReasons: [{
    text: 'Bezwaar VKB toegewezen',
    value: 'vkb'
  },{
    text: 'Overige',
    value: 'other'
  }],
  

  /**
   * Municipality Options
   */
  municipalityOptions: [
    { value: "0197", text: "Aalten", disabled: false },
    { value: "0141", text: "Almelo", disabled: false },
    { value: "0200", text: "Apeldoorn", disabled: false },
    { value: "0202", text: "Arnhem", disabled: false },
    { value: "0203", text: "Barneveld", disabled: false },
    { value: "1945", text: "Berg en Dal", disabled: false },
    { value: "0209", text: "Beuningen", disabled: false },
    { value: "0147", text: "Borne", disabled: false },
    { value: "1876", text: "Bronckhorst", disabled: false },
    { value: "0213", text: "Brummen", disabled: false },
    { value: "0214", text: "Buren", disabled: false },
    { value: "0148", text: "Dalfsen", disabled: false },
    { value: "0150", text: "Deventer", disabled: false },
    { value: "1774", text: "Dinkelland", disabled: false },
    { value: "0221", text: "Doesburg", disabled: false },
    { value: "0222", text: "Doetinchem", disabled: false },
    { value: "0225", text: "Druten", disabled: false },
    { value: "0226", text: "Duiven", disabled: false },
    { value: "0228", text: "Ede", disabled: false },
    { value: "0230", text: "Elburg", disabled: false },
    { value: "0153", text: "Enschede", disabled: false },
    { value: "0232", text: "Epe", disabled: false },
    { value: "0233", text: "Ermelo", disabled: false },
    { value: "0158", text: "Haaksbergen", disabled: false },
    { value: "0160", text: "Hardenberg", disabled: false },
    { value: "0244", text: "Hattem", disabled: false },
    { value: "0246", text: "Heerde", disabled: false },
    { value: "0163", text: "Hellendoorn", disabled: false },
    { value: "0164", text: "Hengelo", disabled: false },
    { value: "1735", text: "Hof van Twente", disabled: false },
    { value: "0166", text: "Kampen", disabled: false },
    { value: "1705", text: "Lingewaard", disabled: false },
    { value: "0262", text: "Lochem", disabled: false },
    { value: "0168", text: "Losser", disabled: false },
    { value: "0263", text: "Maasdriel", disabled: false },
    { value: "1955", text: "Montferland", disabled: false },
    { value: "1740", text: "Neder-betuwe", disabled: false },
    { value: "0267", text: "Nijkerk", disabled: false },
    { value: "0268", text: "Nijmegen", disabled: false },
    { value: "0302", text: "Nunspeet", disabled: false },
    { value: "0269", text: "Oldebroek", disabled: false },
    { value: "0173", text: "Oldenzaal", disabled: false },
    { value: "1773", text: "Olst-Wijhe", disabled: false },
    { value: "0175", text: "Ommen", disabled: false },
    { value: "1586", text: "Oost Gelre", disabled: false },
    { value: "1734", text: "Overbetuwe", disabled: false },
    { value: "0177", text: "Raalte", disabled: false },
    { value: "0275", text: "Rheden", disabled: false },
    { value: "1742", text: "Rijssen-Holten", disabled: false },
    { value: "0277", text: "Rozendaal", disabled: false },
    { value: "0180", text: "Staphorst", disabled: false },
    { value: "1708", text: "Steenwijkerland", disabled: false },
    { value: "0281", text: "Tiel", disabled: false },
    { value: "0183", text: "Tubbergen", disabled: false },
    { value: "1700", text: "Twenterand", disabled: false },
    { value: "0345", text: "Veenendaal", disabled: false },
    { value: "0285", text: "Voorst", disabled: false },
    { value: "0289", text: "Wageningen", disabled: false },
    { value: "1960", text: "West Betuwe", disabled: false },
    { value: "0668", text: "West Maas en Waal", disabled: false },
    { value: "0293", text: "Westervoort", disabled: false },
    { value: "0189", text: "Wierden", disabled: false },
    { value: "0296", text: "Wijchen", disabled: false },
    { value: "0294", text: "Winterswijk", disabled: false },
    { value: "0299", text: "Zevenaar", disabled: false },
    { value: "0301", text: "Zutphen", disabled: false },
    { value: "1896", text: "Zwartewaterland", disabled: false },
    { value: "0193", text: "Zwolle", disabled: false } 
  ],


  /** 
   * Steps 
   */
  steps: [
    {
      step: 1,
      uuid: '63a4d7f6-f3d1-4030-9637-66386d2f876b',
      component: require('@/views/park-n-charge/RealisationStep1'),
      version: 1,
      label: 'Locatievoorstel',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 2,
      uuid: '7fc0ed9a-f715-472e-9f7a-67152f3eecaa',
      component: require('@/views/park-n-charge/RealisationStep2'),
      version: 1,
      label: 'Gemeente beoordeling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 3,
      uuid: 'd9e54a80-1d1d-4d61-a58d-df4d6a719fcb',
      component: require('@/views/park-n-charge/RealisationStep3'),
      version: 1,
      label: 'Verwerking beoordeling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 4,
      uuid: 'b3fc2e59-25ee-4792-a1b3-27e69b277ee0',
      component: require('@/views/park-n-charge/RealisationStep4'),
      version: 1,
      label: 'Verkeersbesluit',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 5,
      uuid: '252575b0-3ab6-4de3-82af-4cdd83ec6705',
      component: require('@/views/park-n-charge/RealisationStep5'),
      version: 1,
      label: 'Aanvragen netaansluiting',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 6,
      uuid: '5f4ade07-5a0a-471c-bed8-3d6b2316c0b8',
      component: require('@/views/park-n-charge/RealisationStep6'),
      version: 1,
      label: 'Plan realisatie',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 7,
      uuid: '1c146862-f847-4b63-b21c-15593b61bdeb',
      component: require('@/views/park-n-charge/RealisationStep7'),
      version: 1,
      label: 'Realisatie en inbedrijfstelling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 8,
      uuid: '0c985c06-9a98-445f-93da-047a76d2117f',
      component: require('@/views/park-n-charge/RealisationStep8'),
      version: 1,
      label: 'SAT',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    }
  ]
}


