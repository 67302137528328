<template>
  <div class="BuildingAdjustments">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />

  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'BuildingAdjustments',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Uitvoering van netverzwaring'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingAdjustments'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PowerUpgradeRequestDate": {
            // "title": "Datum dat netverzwaring is aangevraagd",
            "title": "Datum van aanvraag",
            "type": "string",
            "format": "date"
        },
        "PowerUpgradeExpectedFinishDate": {
            // "title": "Verwachte datum dat netverzwaring klaar is",
            "title": "Verwachte opleveringsdatum",
            "type": "string",
            "format": "date"
        },
        "PowerUpgradeFinishDate": {
            // "title": "Daadwerkelijke datum dat netverzwaring klaar was",
            "title": "Daadwerkelijke opleveringsdatum",
            "type": "string",
            "format": "date"
        }
      },
      layout: [{
        "columns": "1fr 1fr 1fr",
        "fields": [ 'PowerUpgradeRequestDate', 'PowerUpgradeExpectedFinishDate', 'PowerUpgradeFinishDate' ]
      }]
    }
  }
}
</script>