<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>

      <RealisationSidebar :uuid="uuid" />
      
      <template v-if="! reloading">
        <MapLocationViewer 
          :record="record"
          :values="values" 
          class="DetailGrid__map" />

        <div class="DetailGrid__col1">
          <BrokerApproval :values="values" :disabled="locked || disabled" />
          <FileUpload 
            class="mt-3"
            label="Documenten Stadsdeel"
            doctype="Broker"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.Broker || null) : null"
            :minFileId="minBrokerDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            @files="handleFileChanges" />
        </div>
        <div class="DetailGrid__col2">
          <GridOperatorApproval :values="values" :disabled="locked || disabled" />
          <FileUpload 
            class="mt-3"
            label="Situatieschets LS net"
            doctype="GridOperator"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.GridOperator || null) : null"
            :minFileId="minGridOperatorDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            @files="handleFileChanges" />
        </div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form> 
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'
import MapLocationViewer from '@/components/map/MapLocationViewer'
import GridOperatorApproval from '@/components/definitions/GridOperatorApproval'
import BrokerApproval from '@/components/definitions/BrokerApproval'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'


export default {
  name: "RealisationDetail_2",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    MapLocationViewer, 
    Form, FileUpload, Comments,
    GridOperatorApproval, BrokerApproval
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 2,

      Docs: {
        Broker: [],
        GridOperator: []
      }
    };
  },
  computed: {
    // Docs
    minBrokerDocsId() {
      try {
        return this.record.values.Docs.Broker.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },
    minGridOperatorDocsId() {
      try {
        return this.record.values.Docs.GridOperator.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    }
  },
  methods: {

    prepData({ data }) {

      // Add document numbers
      data.Docs = {
        Broker: (this.Docs.Broker || []).filter(file => file.progress === 100).map(file => file.id),
        GridOperator: (this.Docs.GridOperator || []).filter(file => file.progress === 100).map(file => file.id)
      }

      return data
    },

    prepStatus() { // { data }
      let status = {
        uuid: this.currentStepData.uuid
      }
      
      // Going to the next step?
      if (this.goingToNextStep && ! this.locked) {

        let step = this.currentStep + 1

        if (this.Skip) {
          step = 5
        }

        status = {
          uuid: this.getStepDataByNumber({ number: step })?.uuid
        }
      }
      return status
    },
    prepIntention(intention) {
      if (
        this.goingToNextStep 
        && ! this.locked
        && this.Skip
      ) {
        let step = this.currentStep + 2
        intention.target = this.getStepDataByNumber({ number: step })?.uuid
      }
      
      return intention
    },

    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (
              ! ['yes', 'blanco', 'negative-advice'].includes(data.BrokerApproval.IsBrokerAccepted) ||
              ! ['yes'].includes(data.GridOperatorApproval.IsGridOperatorAccepted)
          ) {
            this.disabled = false
            this.feedback = 'danger'
            this.feedbackMessage = 'Het proces kan niet verder zonder akkoord. Ga terug naar stap 1 om het locatievoorstel te wijzigen.'
            return false
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Ten minste 1 besluit ontbreekt.'
          return false
        }
      }

      return true
    }
  }
}
</script>

