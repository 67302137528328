<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">
      
      <RealisationHeader 
        :record="record" 
        :feedback="feedback" 
        :feedbackMessage="feedbackMessage" 
        :step="currentStep" 
        :recordStep="step">
        <FlowInteraction
          position="top"
          :first="currentStep === 1"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationHeader>

      <RealisationSidebar 
        :uuid="uuid" 
        :disabled="disabled || locked" />

      <div class="DetailGrid__map" >
        <VisitBox 
          :values="values"
          :disabled="locked || disabled" />

      </div>
      
      <RealisationFooter 
        :feedback="feedback" 
        :feedbackMessage="feedbackMessage">
        
        <FlowInteraction
          position="bottom"
          :first="currentStep === 1"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationFooter>
      
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>


import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

import FlowInteraction from '@/components/flow/FlowInteraction.vue'

// RVB
import VisitBox from '@/components/definitions/rvb/VisitBox.vue'

export default {
  name: "RealisationDetail_2",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form,
    Comments,

    FlowInteraction,

    // RVB
    VisitBox
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: '2'
    }
  }
};
</script>
