<template>
  <div class="PowerPermission">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
    />

  </div>
</template>

<script>

import { helpers } from 'vuelidate/lib/validators' // required, 
import { isUrl } from '@/services/validation'


import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'PowerPermission',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Toestemming voor netverzwaring'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions.PowerPermission'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PowerPermissionAskedDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de Go/No Go voor netverzwaring is aangevraagd"
            "title": "Datum van Go/No Go aanvraag"
        },
        "PowerPermissionReceivedDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de uitkomst van de Go/No Go beslissing voor een netverzwaring is ontvangen"
            "title": "Datum van beslissing"
        },
        "GridOperatorQuotationLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar de netverzwaring offerte"
            "title": "Link naar offerte",
            "readonly": true
        },
        "Amount": {
            "type": "string",
            // "title": "Kosten van de netverzwaring"
            "title": "Kosten in €",
            "readonly": true
        },
        "PowerAdjustmentDecision": {
            "type": "string",
            // "title": "Uitkomst Go/No Go beslissing netverzwaring",
            "title": "Beslissing",
            "enumNames": [
                "Go",
                "No Go"
            ],
            "enum": [
                "Go",
                "NoGo"
            ]
        },
        "PowerAdjustmentDecisionLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar Go/No Go document voor netverzwaring"
            "title": "Link naar het document over de beslissing"
        }
      },
      layout: [{
        "columns": "1fr 1fr",
        "fields": [ 'PowerPermissionAskedDate', 'PowerPermissionReceivedDate' ]
      },{
        "columns": "1fr 1fr",
        "fields": [ 'Amount', 'GridOperatorQuotationLink' ]
      },{
        "columns": "1fr 1fr",
        "fields": [ 'PowerAdjustmentDecision', 'PowerAdjustmentDecisionLink' ]
      }],
      validation: {
        // "PowerAdjustmentDecision": {
        //   required
        // },
        PowerAdjustmentDecisionLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        },
        GridOperatorQuotationLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  },
  watch: {
    completed() {
      if (this.completed) {
        this.lockEvaluationOptions()
      }
    }
  },
  created() {
    if (this.completed) {
      this.lockEvaluationOptions()
    }
  },
  methods: {
    lockEvaluationOptions() {
      this.fields.PowerAdjustmentDecision.disabled = true
    },

    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name
      })
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name
      })
    }
  }
}
</script>