<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <template v-if="! reloading">
        <div class="DetailGrid__col1">
          <DateOfCommissioning :values="values" :disabled="locked || disabled" />
          <DateOfEnergyConnection :values="values" :disabled="locked || disabled" />
        </div>
        <div class="DetailGrid__col2"></div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import DateOfCommissioning from '@/components/definitions/DateOfCommissioning'
import DateOfEnergyConnection from '@/components/definitions/DateOfEnergyConnection'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

export default {
  name: 'RealisationStep7',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, Comments,
    DateOfCommissioning, DateOfEnergyConnection
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "7"
    }
  },
  created() {
    // console.log("step 7")
    // console.log(this.record)
    // console.log(this.values)
  },
  methods: {
    prepData({ data }) {

      // turn falsy values into empty string
      if (! data.DateOfCommissioning.DateOfCommissioning) {
        data.DateOfCommissioning.DateOfCommissioning = ''
      }
      if (! data.DateOfCommissioning.DateOfEnergyConnection) {
        data.DateOfCommissioning.DateOfEnergyConnection = ''
      }

      return data
    },
    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (
            data.DateOfCommissioning.DateOfCommissioning === ''
          ) {
            throw new Error('Het proces kan niet verder zonder een datum van inbedrijfstelling.')
          }
          if (
            data.DateOfCommissioning.DateOfEnergyConnection === ''
          ) {
            throw new Error('Het proces kan niet verder zonder een datum van aanmelding bij de energie leverancier.')
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = e.message
          return false
        }
      }

      return true
    }
  },
}
</script>