<template>
  <div class="MapModal__tab">
    <div v-if="!hasChargingPoint && !averageData">
      <p>
        Selecteer een gerealiseerde laadpaal om de gebruiksdata in te zien.
        Als je de gemiddelde bezettingsgraad over meerdere laadpalen wil zien, kun je met CTRL + klik meerdere laadpalen selecteren.
        Je kunt ook in één keer alle laadpalen in de buurt van de aanvraag selecteren met de knop ‘Nabije selecteren’.
      </p>

      <b-button
        variant="outline-primary"
        size="sm"
        @click="selectNearby"
      >
        Nabije selecteren
      </b-button>
    </div>

    <div v-if="averageData">
      <strong>Gemiddelde gebruiksdata</strong> <br />

      <div class="MapModal__segment">
        <ChartOccupancyRate :data="averageData" title="Bezettingsgraad" :options="{ limit }" />
      </div>

      <div class="MapModal__segment">
        <p class="mt-1 mb-0 d-flex justify-content-end">
          <b-button
            variant="outline-danger"
            size="sm"
            @click="clearSelected"
          >
            Deselecteren
          </b-button>
        </p>

        <ul class="mt-3">
          <li class="d-flex justify-content-between">

            <span> Referentie </span>
            <span> EVSE </span>
            <span> Operator </span>

          </li>
          <li
            v-for="location in selectedLocationsWithData"
            :key="location.uuid"
            class="d-flex justify-content-between"
          >

            <span>{{ location.chargerNumber }} </span>
            <span>{{ location.evse }} </span>
            <span>{{ location.operator }} </span>

          </li>
        </ul>
      </div>
    </div>

    <div v-if="hasChargingPoint && ! averageData">
      <strong>Adres</strong>
      <div v-if="address" class="MapModal__segment">
        {{ address }} <br v-if="distance" />
        <span v-if="distance">
          Afstand hemelsbreed vanaf aanvraaglocatie: {{ distance }} meter
        </span>
      </div>
      <table class="MapModal__segment mt-3">
        <tr>
          <td class="pr-3">Referentie</td>
          <td>{{ chargerNumber }}</td>
        </tr>
        <tr>
          <td>EVSE</td>
          <td>{{ evse }}</td>
        </tr>
        <tr>
          <td>CPO</td>
          <td>{{ operator }}</td>
        </tr>
      </table>
    </div>

    <div v-if="hasChargingPoint  && ! averageData" class="mt-3">
      <strong>Gebruiksdata</strong>

      <div v-if="loading" class="mt-1 mb-3">
        Bezig met laden van data
      </div>
      <div v-else-if="! hasLocationData" class="mt-1 mb-3">
        Er is (nog) geen data beschikbaar over deze locatie
      </div>
      <div v-else-if="connectionPointOptions">
        <b-form-select
          class="mt-1 mb-3"
          v-model="selectedPointToShow"
          :options="connectionPointOptions"
        />
        <div class="MapModal__segment">
          <ChartOccupancyRate :data="data" title="Bezettingsgraad" :options="{ limit }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ChartOccupancyRate from '@/components/monitoring/ChartOccupancyRate.vue'

import { mapGetters, mapActions, mapMutations } from 'vuex'

const LAADPAAL_SELECTED = "laadpaal_is_selected"

import { turf } from '@/services/turf'
import {numberWithDots, round2Decimals} from '@/helpers/number'

import { monitoringConfig } from '@/config'

export default {
  name: 'ChargerTab',
  components: {
    ChartOccupancyRate
  },
  props: {
    requestCoordinates: {
      type: Object
    }
  },
  data() {
    return {
      /**
       * Whether we're busy loading data from the API
       */
      loading: false,

      /**
       * The monitoring data of the selected charging location
       */
      data: null,
      
      /**
       * The connection points of the selected charging location
       */
      connectionPoints: [],

      /**
       * The selected connection point to show
       */
      selectedPointToShow: LAADPAAL_SELECTED,
      
      /**
       * Usage above this limit shows up red
       *  Usage between 85% and 100% of limit shows orange
       */
      limit: monitoringConfig.limit
    }
  },
  computed: {
    ...mapGetters('monitoring', {
      locationData: 'getSelectedLocationData',
      selectedChargerUUID: 'getSelectedChargerUUID',
      selectedLocationsWithData: 'getSelectedLocationsWithData',
    }),
    ...mapGetters('chargingpoints', [
      'chargingpoints',
      'getChargingPointByUUID'
    ]),
    hasChargingPoint() {
      return !! this.chargingpoint
    },
    hasLocationData() {
      return !! this.data
    },

    chargingpoint() {
      return this.getChargingPointByUUID({ uuid: this.selectedChargerUUID }) || false
    },
    
    /**
     * Get the full formatted address
     */
    address() {
      if (! this.hasChargingPoint) return false
      return this.chargingpoint?.data.address.formatted_address || false
    },

    chargerNumber() {
      return `${this.chargingpoint?.data.code}-${this.chargingpoint?.data.properties.id}`
    },
    evse() {
      return this.chargingpoint?.data.properties.evse
    },
    operator() {
      return this.chargingpoint?.data.properties.operator
    },
    coordinates() {
      return this.chargingpoint.data.coordinates
    },

    connectionPointOptions() {
      if (! this.locationData) return false

      const points = this.locationData.connectionPoints

      return points.reduce(( list, option ) => 
        list.concat([ { value: option.charge_point_id, text: option.charge_point_id } ]), [
        { value: LAADPAAL_SELECTED, text: `Laadpaal (${points.length} laadpunt${points.length > 1 ? "en" : ""})` },
      ])
    },
    distance() {
      if (! this.requestCoordinates) return null
      const from = [this.requestCoordinates.lng, this.requestCoordinates.lat]
      const to = this.coordinates

      return numberWithDots( Math.ceil(turf.distance(from, to, { unit: 'kilometers' }) * 1000) )
    },


    nearbyChargingpoints() {
      if (! this.requestCoordinates || ! this.chargingpoints) return null 

      const from = [this.requestCoordinates.lng, this.requestCoordinates.lat]
      const limit = 200 / 1000
      return this.chargingpoints
        .filter(point => turf.distance(from, point.data.coordinates, { unit: 'kilometers' }) < limit)
        .map(chargingpoint => {
          this.loadData({ chargingpoint }) // async load data. Don't wait for it
          return chargingpoint
        })
        .map(point => point.data)
    },
    hasList() {
      return this.nearbyChargingpoints !== null
    },

    averageData() {
      const observed = ['occupancy_rate'] // , 'session_count', 'unique_users', 'volume'

      const totals = this.selectedLocationsWithData.reduce((averageData, locationData) => {
        if (! locationData) {
          return averageData
        }

        Object.entries(locationData.aggregated).forEach(([month, data]) => {
          if (! averageData[month]) {
            averageData[month] = {
              anonymized: data.anonymized,
              label: data.label,
              noData: data.noData,
            }
          }

          Object.entries(data).forEach(([key, value]) => {
            if (observed.includes(key)) {
              if (! averageData[month][key]) {
                averageData[month][key] = 0
              }

              averageData[month][key] += value
            }
          })
        })

        return averageData
      }, {})

      return this.calculateAverage(totals, this.selectedLocationsWithData.length)
    },
  },
  watch: {
    selectedPointToShow() {
      this.loadData({ chargingpoint: this.chargingpoint, activate: true })
    },
    chargingpoint() {
      this.selectedPointToShow = LAADPAAL_SELECTED
      this.loadData({ chargingpoint: this.chargingpoint, activate: true })
    }
  },
  created() {
    this.loadData({ chargingpoint: this.chargingpoint, activate: true })
  },
  beforeDestroy() {
    this.clearSelected()
  },
  methods: {
    ...mapActions('monitoring', [
      'fetchConnectionPointsByChargingpoint',
      'addOrRemoveSelectedCharger',
    ]),
    ...mapMutations('monitoring', [
      'setSelectedChargerUUID',
      'clearSelected'
    ]),
    selectNearby() {
      this.nearbyChargingpoints.forEach(chargingpoint => this.addOrRemoveSelectedCharger({uuid: chargingpoint.uuid}))
    },
    async loadData({ chargingpoint, activate }) {
      // Without a chargingpoint reference we can't load data
      if (! chargingpoint) return 

      // check if data is already loaded
      if (this.locationData) {
        if (activate) {
          this.setData()
        }
        return
      }

      if (activate) {
        this.loading = true
      }

      await this.fetchConnectionPointsByChargingpoint({ chargingpoint })

      if (activate) {
        this.setData()
        this.loading = false
      }
    },
    setData() {
      if (this.selectedPointToShow === LAADPAAL_SELECTED) {
        this.data = this.locationData?.aggregated
      } else {
        const index = this.locationData.connectionPoints
          .findIndex(connectionPoint => connectionPoint.charge_point_id === this.selectedPointToShow)
        this.data = (index === -1) 
          ? this.locationData?.aggregated
          : this.locationData.connectionPoints[index].data
      }
    },
    calculateAverage(obj, count) {
      if (count === 0) {
        return
      }

      Object.keys(obj).forEach(key => {
        if (! obj[key]["occupancy_rate"]) {
          return
        }

        // divide occupancy_rate data by number of connection points for average
        obj[key]["occupancy_rate"] = round2Decimals(obj[key]["occupancy_rate"] / count)
      })

      return obj
    }
  }
  
}
</script>

<style lang="scss">
.MapModal__tab {
  &__select-all {
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }
}
.MapModal__segment {
  button.btn.btn-outline-danger.btn-sm {
    font-size: 0.75rem;
  }

  ul {
    list-style-type: none;
    padding: 0;

    li {
      &:first-child {
        border-bottom: 1px solid rgb(174, 174, 174);
        padding-bottom: 5px;
        margin-bottom: 5px;
      }
    }
  }

  hr {
    border-color: rgb(174, 174, 174);
    margin: 0.75rem auto 0.75rem 0;
  }
}
</style>