/**
 * Import Dependency
 */
import Realisation from '@/models/Realisation'

import Vue from 'vue'
 

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  /**
   * Locks the UI while loading / refreshing / sending data
   */
  loading: false,
  lastRefresh: '',

  /**
   * The search term in the list view
   */
   searchterm: '',
   currentPage: 1,


  /**
   * List of realisation processes
   */
   records: [],
 
   /**
    * Keep track of the last viewed realisation (in this session)
    */
   lastOpenedRecord: null,
}

const getters = {
  loading: state => state.loading,
  lastRefresh: state => state.lastRefresh,

  searchterm: state => state.searchterm,
  currentPage: state => state.currentPage,

  lastOpenedRecord: state => state.lastOpenedRecord,

  /**
   * List
   */
  records: state => state.records.sort((a, b) => a.counter > b.counter ? -1: 1),
  hasRecords: state => state.records.length !== 0,
  recordsByStatus: (state, getters) => ({ status }) => getters.records.filter(record => record.status === status),
  recordsByActiveStatus: (state, getters) => getters.recordsByStatus({ status: state.activeStatusId }),

  /**
   * Detail
   */
  hasRecord: state => ({ uuid }) => {
    return Array.isArray(state.records) && state.records.filter(record => record.uuid === uuid).length > 0
  },
  recordByUuid: state => ({ uuid }) => {
    let records = state.records.filter(record => record.uuid === uuid)
    return records.length ? records[0] : null
  },
}
const actions = {
  loadRecords: async ({ state, commit }, { token }) => {
    if (state.loading) return

    commit('setLoadingState', { loading: true })

    const api = await fetch('/api/realisationlist', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (! api.ok) {
      commit('setLoadingState', { loading: false })  
    } else {
      const response = await api.json()
      
      const records = response.data.records.map(record => {
        return new Realisation({ data: record.data, ref: record.ref['@ref'].id })
      })

      commit('setRecords', {
        records
      })
      commit('setLoadingState', { loading: false })
    }
  },
}
const mutations = {
  setLoadingState(state, { loading }) {
    state.loading = loading
  },
  setLastOpenedRecord(state, { uuid }) {
    state.lastOpenedRecord = uuid
  },
  setRecords(state, { records }) {
    state.records = records
    const date = new Date(Date.now())
    state.lastRefresh = `${(`0${date.getHours()}`.slice(-2))}:${(`0${date.getMinutes()}`.slice(-2))}:${(`0${date.getSeconds()}`.slice(-2))}`
  },
  setSearchterm(state, { term }) {
    state.searchterm = term
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },

  replaceRecord(state, { record }) {
    let recordIndex = state.records.findIndex(current => current.uuid === record.data.uuid)
    if (recordIndex !== -1) {
      state.records.splice(recordIndex, 1, new Realisation({ data: record.data, ref: record.ref['@ref'].id }))
    }
  },
  addRecord(state, { record }) {
    let records = state.records
    records.push(
      new Realisation({ data: record.data, ref: record.ref['@ref'].id })
    )
    Vue.set(state, 'records', records)
  },

  updateRecordComments(state, { ref, comments }) {
    let index = state.records.findIndex(record => record.ref === ref)
    if (index < 0) return 

    let record = state.records[index]
    
    record.comments = comments
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
