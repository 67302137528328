
import { commonFilterWrapper } from '../utils'
import { RPL, realisationProcessStatusTranslations } from '@/config'
import store from '@/store'

export default {
  /**
   * Config reference
   * @type {string}
   */
  uuid: 'b04ec143-e712-4788-a74e-7af73b6466c0',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'rvb',

  /**
   * Access requirements
   */
  access: {
    /**
     * Required access checks. Must pass all
     */
    requirements: ['realisationlist'],

    // TODO: Maybe specicify exclude list? If you have one config, exclude this one
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RVBControls',


  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 50,

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      // filters: {
      //   filters: ['reference', 'pid', 'object_id', 'address', 'customer', 'region', 'assetmanager', 'objectmanager', 'rpl'],
      //   columns: '140px 100px 150px 2fr 220px 120px 200px 200px auto auto',
      //   area: `"reference pid object_id address customer region assetmanager objectmanager rpl ."`
      // },
      filters: {
        filters: ['reference', 'pid', 'object_id', 'step', 'address', 'customer', 'region', 'assetmanager', 'objectmanager', 'rpl'],
        columns: '150px 150px 150px 2fr 220px 220px', //  200px 200px auto auto
        area: `"pid object_id reference address region customer" "step step step rpl assetmanager objectmanager"`
      },
      header: {
        properties: ['reference', 'pid', 'object_id', 'address', 'customer', 'step'],
        columns: '100px 150px 4fr minmax(240px, 4fr) 130px 20px 130px 140px auto',
        area: '"pid object_id address customer step step step reference ."',
      }
    }
  },

  headerLabels: {
    reference: 'Referentie',
    pid: 'PID',
    object_id: 'Gebouwnummer',
    step: 'Stap',
    address: 'Adres',
    customer: 'Klant',
    submitted_at: 'Ingezonden',
    processed_at: 'Beoordeeld',
  },
  cellComponents: {
    reference: null,
    pid: 'PID',
    object_id: null,
    step: 'Step',
    address: null,
    customer: null,
    submitted_at: 'UpdatedAt',
    processed_at: 'UpdatedAt'
  },

  /**
   * sorting
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    reference: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    // pid: {
    //   type: 'number',
    //   sortBy: 'pid_nr'
    // },
    object_id: {
      type: 'number',
      sortBy: 'object_id_nr'
    },
    address: {
      type: 'string',
      sortBy: 'address'
    },
    customer: {
      type: 'string',
      sortBy: 'customer'
    },
    processed_at: {
      type: 'number',
      sortBy: 'processed_at_nr'
    },
    step: {
      type: 'number',
      sortBy: 'step_nr'
    },
  },

  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {
    record.summary = record.summary || {}
    let location = record.CurrentLocation || {}
    let obj = location?.Object || {}

    let currentStep = record.getCurrentFlowStatus()

    // console.log(currentStep)
    let stepLabel = `${currentStep?.step || ''}. ${currentStep?.label || ''}`.trim()
    stepLabel = stepLabel !== '.' ? stepLabel : 'Onbekend'
    let step_nr = parseInt(currentStep?.step || 1, 10)
    const regional = RPL.find(rpl => rpl.id === obj?.Address?.RegionalProjectLeader)

    // Is active?
    const statuses = ['completed', 'cancelled', 'onhold']
    const isActive = statuses.every(status => !record.status[status])
    const currentStatus = !isActive && Object.keys(record.status).find(status => statuses.includes(status) && !!record.status[status])

    return {
      pid: location?.Application?.PidUuid, // record.raw?.Location?.[0]?.Application?.PidUuid || 
      // pid_nr: record.raw.case.counter, 
      uuid: record.uuid,

      // used for sorting the reference column
      reference: record.case_ref,
      reference_nr: record.raw.case.counter, 

      object_id: obj?.Address?.BuildingCode || '',
      object_id_nr: parseInt(obj?.Address?.BuildingCode || '', 10),

      // object props
      address: record.address,
      
      name: record.summary.name,
      customer: obj?.Address?.Customer || '',

      submitted_at: record.summary.submitted_at_short,
      submitted_at_nr: record.summary.submitted_at_nr,
      processed_at: record.summary.processed_at_short,
      processed_at_nr: record.summary.processed_at_nr,
      
      judgement_reason: record.judgement ? record.judgement.reason : '',
      realisation_caseref: record.realisationUuid,

      // filters
      region: obj?.Address?.RVBRegion || '',
      assetmanager: obj?.AssetManager || '',
      objectmanager: obj?.ObjectManager || '',
      RPL: regional?.id || '',
      RPLName: regional?.name || '',
      
      // Step
      stepUuid: currentStep?.uuid,
      step: stepLabel,
      stepFilter: stepLabel.replace('.', ''),
      step_nr,
      currentStatus: realisationProcessStatusTranslations[currentStatus] || 'Actief',

      // Turns row into a link
      route: {
        name: `Realisation.Step${step_nr}`,
        params: {
          uuid: record.uuid
        }
    },

    /**
     * Note: using dutch category names because these are also used as slugs
     */
    categories: {
      all: true,
      actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

      gepauzeerd: record.status.onhold === true && record.status.cancelled !== true,
      afgerond: record.status.completed === true,
      geannulleerd: record.status.cancelled === true
    },

    /** 
     * Filter params
     */ 
    filteredOut: false,
    filterReason: null
    }
  },

  export: [{
    btn: {
      label: 'Excel', // Used for render CTA's (action buttons) //
      variant: 'success'
    },
    file: {
      name: 'Realisatieprocessen',
      acronym: 'RVB'
    },
    worksheetColumns: [
      { header: 'Gebouwnummer', key: 'object_id', width: 18 },
      // Address
      { header: 'Straatnaam', key: 'export_street', width: 30 },
      { header: 'Huisnummer', key: 'export_streetnumber', width: 15 },
      { header: 'Plaatsnaam', key: 'export_city', width: 25 },
      // PID
      { header: 'PID', key: 'export_pid', width: 15 },
      // Customer & type
      { header: 'Klant', key: 'customer', width: 20 },
      { header: 'Stelsel', key: 'export_objecttype', width: 20 },

      // Object
      { header: 'Regio', key: 'region', width: 20 },
      { header: 'Regionaal Project Leider', key: 'RPLName', width: 30 },
      { header: 'Object Manager', key: 'objectmanager', width: 30 },
      { header: 'Asset Manager', key: 'assetmanager', width: 30 },

      // Step
      { header: 'Step', key: 'step', width: 35 },
      // Status
      { header: 'Status', key: 'currentStatus', width: 20 },
      
      // Chargingpoint counts
      { header: 'Bestaande laadpunten', key: 'export_existing', width: 25 },
      { header: 'Te vervangen', key: 'export_replaced', width: 15 },
      { header: 'Te herprogrammeren', key: 'export_reprogrammed', width: 25 },
      { header: 'Behoefte Rijkskantoren (4%)', key: 'export_govneed', width: 30 },
      { header: 'Extra behoefte', key: 'export_additional', width: 20 },
      { header: 'Totaal nog te plaatsen', key: 'export_tobeplaced', width: 25 },
      { header: 'Totaal nieuwe situatie', key: 'export_newsituation', width: 25 },
      { header: 'Parkeer locatie', key: 'export_parking_location', width: 20 }, 
      { header: 'Toegankelijkheid parkeren', key: 'export_parking_ccessibility', width: 30 },

      // Our reference
      { header: 'Referentie', key: 'reference', width: 15 },

      // Energy
      { header: 'EAN Code', key: 'export_EANCode', width: 25},
      { header: 'Type net verbinding', key: 'export_TypeOfNetConnection', width: 25 },
      { header: 'Waarde verbindingsvermogen', key: 'export_ConnectionPowerValue', width: 28 }, 
      { header: 'Aantal transformatoren', key: 'export_NumberOfTransformers', width: 25 },
      { header: 'Eigendom van transformator', key: 'export_TransformerOwnership', width: 30 },
      { header: 'Transformatorcapaciteit in kVA', key: 'export_TransformerCapacityInkVA', width: 32 },
      { header: 'Maximaal technisch vermogen in kW', key: 'export_MaximumTechnicalPowerInkW', width: 38 },
      { header: 'Gecontracteerde kracht', key: 'export_ContractedPower', width: 25 }, 
      { header: 'Werkelijke piekbelasting', key: 'export_ActualPeakLoad', width: 25 }, 
      { header: 'Beschikbare capaciteit per laadpunt', key: 'export_CapacitiyAvailablePerChargepoint', width: 35 }, 
      { header: 'Vermogenslimiet', key: 'export_PowerLimit', width: 20 },

      // Comments
      { header: 'Opmerkingen', key: 'export_comments', width: 25 },
    ],
    formatRecords: (records) => {
      const recordByUuid = store.getters['realisation/recordByUuid']
      const getLabelsByPidUuid = store.getters['tenant/getLabelsByPidUuid']

      return records.map(record => {
        
        const fullRecord = recordByUuid({ uuid: record.uuid })
        const Current = fullRecord.CurrentLocation || {}
        const Address = Current.Location || {}
        const ChargingPoints = Current.ParkingAndChargePoints || {}

        const filteredComments = fullRecord.comments?.map(comment => {
          if (!comment.deleted_at && !/test|verwijder/i.test(comment.Message)) {
            return comment.Message
          }
        }).filter(Boolean)

        const props = {
          'export_pid': getLabelsByPidUuid[record.pid] || '',
          'export_street': Address.StreetName || '',
          'export_streetnumber': `${Address.HouseNumber || ''} ${Address.HouseNumberSuffix || ''}`.trim() || '',
          'export_city': Address.CityName || '',
          'export_objecttype': Current?.Object?.Address?.ObjectType || '',
          'export_existing': ChargingPoints.NumberOfExistingChargePoints || 0,
          'export_replaced': ChargingPoints.NumberOfChargingPointsReplaced || 0,
          'export_reprogrammed': ChargingPoints.ReprogramNumberOfChargingPoints || 0,
          'export_govneed': ChargingPoints.ChargePointNeedsGovernmentOffices || 0,
          'export_additional': ChargingPoints.ExtraNeedChargePoints || 0,
          'export_tobeplaced': ChargingPoints.TotalChargePointsStillToBePlaced || 0,
          'export_newsituation': ChargingPoints.TotalChargePointsNewSituation || 0,
          'export_parking_location': ChargingPoints.ParkingLocation || '',
          'export_parking_ccessibility': ChargingPoints.ParkingAccessibility || '',

          'export_EANCode': Current.Energy?.EANCode || '',
          'export_ConnectionPowerValue': Current.Energy?.ConnectionPowerValue || '',
          'export_NumberOfTransformers': Current.Energy?.NumberOfTransformers || 0,
          'export_TransformerOwnership': Current.Energy?.TransformerOwnership || '',
          'export_TransformerCapacityInkVA': Current.Energy?.TransformerCapacityInkVA || '',
          'export_MaximumTechnicalPowerInkW': Current.Energy?.MaximumTechnicalPowerInkW || '',
          'export_ContractedPower': Current.Energy?.ActualPeakLoad || '',
          'export_CapacitiyAvailablePerChargepoint': Current.Energy?.CapacitiyAvailablePerChargepoint || '',
          'export_PowerLimit': Current.Energy?.PowerLimit || '',
          'export_comments': filteredComments.join('\r\n\n') || '',
        };

        return Object.assign(record, props)
      })
    }
  }],


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    reference: {
      cost: 20,
      id: 'reference',
      label: 'Referentie',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.reference.toLowerCase().includes(value)
    }, 
    pid: {
      cost: 2,
      id: 'pid',
      label: 'PID',
      componentName: 'RVBPIDFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.pid === value
    }, 
    object_id: {
      cost: 20,
      id: 'object_id',
      label: 'Gebouwnummer',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.object_id.toLowerCase().includes(value)
    },

    step: {
      cost: 1,
      id: 'step',
      label: 'Stap',
      position: '',
      componentName: 'StepFilter', 
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.stepFilter === value
    },

    address: {
      cost: 20,
      id: 'address',
      label: 'Adres',
      componentName: 'SingleStringFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.address.toLowerCase().includes(value)
    },
    customer: {
      cost: 1,
      id: 'customer',
      label: 'Klant',
      componentName: 'RVBCustomerFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.customer === value
      // callback: ({ record, value }) => record.customer.toLowerCase().includes(value)
    },
    region: {
      cost: 1,
      id: 'region',
      label: 'Regio',
      componentName: 'RVBRegionFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.region === value
      // callback: ({ record, value }) => record.customer.toLowerCase().includes(value)
    },
    assetmanager: {
      cost: 1,
      id: 'assetmanager',
      label: 'Asset Manager',
      componentName: 'RVBAssetManagerFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.assetmanager === value
      // callback: ({ record, value }) => record.customer.toLowerCase().includes(value)
    },
    objectmanager: {
      cost: 1,
      id: 'objectmanager',
      label: 'Object Manager',
      componentName: 'RVBObjectManagerFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.objectmanager === value
      // callback: ({ record, value }) => record.customer.toLowerCase().includes(value)
    },
    rpl: {
      cost: 1,
      id: 'rpl',
      label: 'Regionaal Project Leider',
      componentName: 'RVBRPLFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.RPL === value
    },

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default'
        }, {
          text: 'Actief',
          value: 'actief'
        }, {
          text: 'Gepauzeerd',
          value: 'gepauzeerd'
        }, {
          text: 'Afgerond',
          value: 'afgerond'
        }, {
          text: 'Geannuleerd',
          value: 'geannulleerd'
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },
}