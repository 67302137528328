<template>
  <BlockRepeater 
    class="PeopleToVisit" 
    title=""
    label="Voeg een bezoeker toe"
    :path="path" 
    :values="values"
    :inline="true"
    :disabled="disabled" 
    :viewer="viewer"
    v-slot="slotProps">
    <FieldSet 
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
      :viewer="viewer"
    />
  </BlockRepeater>
</template>

<script>

import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'PeopleToVisit',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'PeopleToVisit'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "name": {
          "title": "Naam",
          "type": "string"
        },
        "email": {
          "title": "Email",
          "type": "string"
        }
      },
      layout: [{
        "columns": '330px 330px',
        "fields": [ 'name', 'email' ]
      }]
    }
  }
}
</script>