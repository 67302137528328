<template>
  <div class="OrderConfirmation">
    <p>Totale kosten: € xxx + € {{ additional }} = € zzz</p>
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'OrderConfirmation',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'OrderConfirmation'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layout: [{
        "columns": '1fr',
        "fields": [ 'IsOrderConfirmed' ]
      }, {
        "columns": '1fr',
        "fields": [ 'IsAdditionalWorkConfirmed' ]
      }, {
        "columns": '1fr',
        "fields": [ 'Approver' ]
      }],
      validation: {
        IsOrderConfirmed: {
          required: (value) => {
            return value.length !== 0 && value[0] === 'accepted'
          }
        },
        IsAdditionalWorkConfirmed: {
          required: (value) => {
            return value.length !== 0 && value[0] === 'accepted'
          }
        },
        Approver: {
          required
        }
      }
    }
  },
  computed: {
    additional() {
      try {
        return this.values.CPOAdvice.SumAdditionalCost || 0
      } catch(e) {
        return 0
      }
    },
    type() {
      try {
        return this.values.ParkingSpots.Type === 'station' ? 'laadpaal' : 'laadcluster';
      } catch(e) {
        return 'laadpaal'
      }
    },
    fields() {
      return {
        "IsOrderConfirmed": {
          "type": "string",
          "title": "",
          "enumNames": [
            `Ik geef akkoord op € xxx aan kosten voor de realisatie van een ${this.type}`
          ],
          "enum": [
            "accepted"
          ],
          "format": "checkbox",
        },
        "IsAdditionalWorkConfirmed": {
          "type": "string",
          "title": "",
          "enumNames": [
            `Ik geef akkoord op de meerwerkkosten van € ${this.additional}`
          ],
          "enum": [
            "accepted"
          ],
          "format": "checkbox",
        },
        "Approver": {
          "type": "string",
          "title": "Uitvoerder van gemeente"
        }
      }
    }
  }
}
</script>