
import Location from './Location'

// import { capitalize } from '@/helpers/string'
import { municipalityOptions } from '@/config'

const leadingZero = (num) => `0${num}`.slice(-2);

// let currentYear = (new Date()).getFullYear()
const formatTime = (date) =>
  [date.getHours(), date.getMinutes()] // , date.getSeconds()
  .map(leadingZero)
  .join(':')

/**
 * Realisation
 */
let Realisation = function ({
  ref, data
}) {

  data = this.commonStructure({ ref, data, self: this })
  data.tenant = 'rvb'

  // Set PidUuid
  data.PidUuid = data.CurrentLocation?.Application?.PidUuid || null

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

/**
 * RVB Specific
 * 
 * @param {*} param0 
 * @returns 
 */
Realisation.prototype.getPidNumber = function() {
  return this.$store.getters['tenant/getLabelsByPidUuid'][this.PidUuid] || '-'
}


Realisation.prototype.generateMunicipalityData = ({ data }) => {
  return data.Municipality || null
}

Realisation.prototype.generateAddressData = ({ model }) => {
  const CurrentLocation = model.CurrentLocation

  if (CurrentLocation.Location && CurrentLocation.Location.StreetName) {
    return `${CurrentLocation.Location.StreetName} ${CurrentLocation.Location.HouseNumber} ${CurrentLocation.Location.HouseNumberSuffix} ${ CurrentLocation.Location.CityName }`.replace('  ', ' ')
  }
  return ''
}

Realisation.prototype.generateSearchData = ({ model, data }) => {

  let municipalityName = ''
  if (data.Municipality) {
    let municipalityOption = municipalityOptions.find(option => option.value === data.Municipality)
    if (municipalityOption) {
      municipalityName = municipalityOption.text
    }
  }

  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName: '',
    Municipality: municipalityName
  }
}



Realisation.prototype.commonStructure = function({ ref, data, self }) {
  let model = {}


  let updated_at = 'Onbekend'
  let updated_at_short = ''
  if (data.updated_at) {
    
    let recordDate = new Date(data.updated_at)
    updated_at = `${recordDate.toLocaleDateString("nl-NL")} ${formatTime(recordDate)}`

    // old data
    updated_at_short = {
      date: recordDate.toLocaleDateString('nl-NL', { day: 'numeric', month: 'short', year: '2-digit' }).replace('. ', ' \''),
      time: formatTime(recordDate)
    }
    // if (currentYear > recordDate.getFullYear()) {
    //   updated_at_short = recordDate.toLocaleDateString("nl-NL", { day: 'numeric', month: 'short', year: 'numeric' })
    // } else {
      
    // }
  }


  // The first Location is the current Location
  let CurrentLocation = data.Locations && data.Locations.length !== 0 ? data.Locations[0] : {}



  /****************************************************************************
   * Status
   */
  let status = data.status ? data.status : null
  
  // Fallback... 
  if (status === null) {
    let steps = this.$store.getters['tenant/getWorkflowSpecByName']({ 
      name: 'realisation'
    })?.steps || []

    status = {
      step: steps[0].short || steps[0].step,
      uuid: steps[0].uuid,
      version: steps[0].version,
      label: steps[0].label,
      completed: false,
      cancelled: false,
      onhold: false
    }
  }


  // Go by process flow
  if (data.flow) {
    // console.log("flow available... !", data.flow, ref)

    status = Object.assign(
      status, 
      self.statusByFlow({ flow: data.flow })
    )
    // console.log(status)
  }

  let statusMetaDefaults = {
    onhold: {
      reason: '',
      label: '',
      comment: ''
    },
    cancelled: {
      reason: '',
      label: '',
      comment: ''
    },
    onholdReason:'',
    onholdLabel: '',
    onholdComment: '',
    cancelledReason: '',
    cancelledLabel: '',
    cancelledComment: '',
  }
  let statusMeta = statusMetaDefaults

  if (data.statusMeta) {
    
    // // detect v2 structure
    // if (data.statusMeta.onhold) {
    //   ['onhold', 'cancelled'].forEach(status => {
    //     if (! data.statusMeta[status]) return

    //     ['reason', 'comment', 'label'].forEach(prop => {
    //       let metaProp = `${status}${capitalize(prop)}`
    //       statusMeta[metaProp] = data.statusMeta[status][prop] || ''
    //     })
    //   })
    // } else {
      
    // }
    statusMeta = Object.assign(statusMetaDefaults, data.statusMeta)
  }

  /****************************************************************************
   * Model spec
   */
  model = {
    ref: ref,
    uuid: data.uuid,

    raw: JSON.parse(JSON.stringify(data)),

    case_ref: data.case ? data.case.full: '',

    status,
    statusMeta,

    values: {      
      Location: CurrentLocation ? new Location({ data: CurrentLocation }) : new Location({ data: {} })
    },
    CurrentLocation,

    /**
     * Connected requests
     */
    requestUuids: [],
    relationalChanges: {
      removed: [],
      added: []
    },

    /**
     * Tags
     */
    Tags: data.Tags || [],

    /**
     * TODO: Remarks etc.
     */
    Events: data.Events || [],

    comments: data.comments || [],

    /**
     * The process flow information
     */
    flow: data.flow || null,

    updated_at,
    updated_at_short
  }
  
  model.address = self.generateAddressData({ model, data })

  return model
}


export default Realisation;