
/**
 * Workflow configuration defaults
 *  This does not generate a functional workflow without tenant specific input
 * 
 * @type {object}
 */
export default {
  tenantName: '',

  /**
   * Feature Access checks
   * 
   * @type {Object}
   */ 
  access: {
    'requestlist': [],
    'requestlocations': [],
    'realisationlist': [],
    'realisationbtn': [],
    'realisationcomment': [],
    'canConfirmTrafficDecisions': [],
    'canChangeCommentStatus': [],
    'canModerateComments': [],
    'canChangeStatus': [],
    'automatedViews': [],
    'generateStartDoc': [],
    'admin': [],
    'reporting': [],
    'canAccessDebugger': []
  },

  
  /** 
   * Judgement Reasons
   * 
   * @type {Object[]}
   */ 
  reasonOptions: [],


  /**
   * Monitoring config
   * 
   * @type {Object}
   */ 
  monitoring: {
    enabled: false,
    limit: 0.5
  },


  /**
   * search
   * 
   * @type {Object}
   */ 
  search: {
    requests: {
      keys: [],
      placeholder: ''
    },
    realisations: {
      keys: [],
      placeholder: ''
    }
  },


  /** 
   * onHold & cancel reasons
   * 
   * @type {Object[]}
   */ 
  onHoldReasons: [],

  /**
   * @type {Object[]}
   */
  cancelReasons: [],


  /**
   * Municipality Options
   * 
   * @type {Object[]}
   */
  municipalityOptions: [],


  /** 
   * Steps 
   * 
   * @type {Object[]}
   */
  steps: [],


  /**
   * Reporting default menu
   * 
   * @type {Object[]}
   */
  ReportingMenu: [
    {
      type: 'link',
      label: 'Key Metrics',
      to: {
        name: 'KeyMetrics'
      }
    }, 
    {
      type: 'segment',
      label: 'Aanvragen',
      items: [{
        type: 'link',
        label: 'Beoordelingen',
        to: {
          name: 'RequestJudgement'
        }
      }]
    }, {
      type: 'segment',
      label: 'Realisatie',
      items: [{
        type: 'link',
        label: 'Status overzicht',
        to: {
          name: 'RealisationProcessStatus'
        }
      }]
    }
  ],
  
  
  /**
   * Manuals default menu
   * 
   * @type {Object[]}
   */
  ManualsMenu: [],


  /**
   * Admin default menu
   * 
   * @type {Object[]}
   */
  AdminMenu: [
    {
      type: 'segment',
      label: 'Configuratie',
      items: [{
        type: 'link',
        label: 'Beschikbare tags',
        to: {
          name: 'TagsConfig'
        }
      }]
    }
  ]
}


