<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">
      
      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">

        <template v-if="! locked">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>

      </RealisationHeader>

      <RealisationSidebar :uuid="uuid" />

      <div class="DetailGrid__map" >
        <MapDisconnectedLocationSelection   
          :record="record"
          :values="values"
          :locked="locked" 
          :disabled="disabled" />
      </div>
      <div class="DetailGrid__col1">
        <FileUpload 
          label="Situatie ondergrond"
          doctype="Underground"  
          :uuid="uuid"
          :files="values && values.Docs ? values.Docs.Underground: null"
          :minFileId="minUndergroundDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" />
          
        <StepOneMisc :values="values" :disabled="locked || disabled" />
      </div>

      <div class="DetailGrid__col2">
        <FileUpload 
          label="Inrichtingsplan"
          doctype="ParkingSpots"
          :uuid="uuid"
          :files="values && values.Docs ? values.Docs.ParkingSpots: null"
          :minFileId="minParkingSpotsDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" />

        <ChargingSituation :values="values" :disabled="locked || disabled" />
      </div>
      
      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        
        <template v-if="!locked">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
      
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'
import MapDisconnectedLocationSelection from '@/components/map/MapDisconnectedLocationSelection'
import ChargingSituation from '@/components/definitions/ChargingSituation'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import { uuidValidateV4 } from '@/services/validation'

import BaseRealisationStep from '@/views/BaseRealisationStep'


// AMSTERDAM DEFINITIONS
import StepOneMisc from '@/components/definitions/amsterdam/StepOneMisc'


export default {
  name: "RealisationDetail_1",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    MapDisconnectedLocationSelection, Form, ChargingSituation, StepOneMisc,
    FileUpload,
    Comments
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 1 
    }
  },
  computed: {
  
    // For existing records this is handled by the parent component, but not when it concerns new Records
    requestAdditions() {
      if (this.$route.query.aanvragen && ! this.hasRecord({ uuid: this.uuid })) {
        return this.$route.query.aanvragen.split('|').filter(id => {
          return uuidValidateV4({ id }) && this.requestByUuid({ uuid: id })
        })
      }
      return []
    },

    // Docs
    minParkingSpotsDocsId() {
      try {
        return this.record.values.Docs.ParkingSpots.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },
    minUndergroundDocsId() {
      try {
        return this.record.values.Docs.Underground.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    }
  },
  watch: {
    requestAdditions() {
      this.addRequestsToRealisation()
    }
  },
  created() {
    // console.log("created", this.record, this.uuid)

    // Redirect filtered lists
    if (['cpo', 'gemeente', 'afgerond', 'geannuleerd', 'onhold'].includes(this.uuid)) {
      this.$router.push({
        name: 'RealisationListFiltered',
        params: {
          filter: this.uuid
        }
      })
    } else {

      this.addRequestsToRealisation()

      // Maybe redirect to active step
      if (this.hasRecord && this.step !== 1 && this.$route.name == 'Realisation') {
        this.$router.push({
          name: `Realisation.Step${this.step}`,
          params: {
            uuid: this.uuid
          }
        })
      }
    }
  },
  methods: {

    prepData({ data }) {
      // Document numbers
      data.Docs = {
        ParkingSpots: (this.Docs.ParkingSpots || []).filter(file => file.progress === 100).map(file => file.id),
        Underground: (this.Docs.Underground || []).filter(file => file.progress === 100).map(file => file.id)
      }

      // Convert ParkingSpot Identifiers
      data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers || []
      data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers.map(obj => obj.Identifier).filter(id => !!id)

      return data
    },

    /**
     * Stub
     */
    isValidData({ data }) {
      if (this.goingToNextStep) {
        if ((this.Docs.ParkingSpots || []).filter(file => file.progress === 100).length == 0) {

          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Een inrichtingsplan is vereist om verder te kunnen.'
          return false
        } 
        if (data.ParkingSpots.Identifiers.length === 0) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Minimaal 1 parkeerplaats is vereist.'
          return false
        }
      }
      return true
    },

    /**
     * Add / Remove Requests to Realisation
     */
    addRequestsToRealisation() {
      if (this.record && this.requestAdditions) {
        this.requestAdditions.forEach(uuid => {
          this.setConnectionChange({
            type: 'add',
            requestUuid: uuid,
            realisationUuid: this.record.uuid
          })
        })
      }
    },

    /**
     * Handle Activation / de-activation of the form
     *  Used when the process is not currently in step 1.
     */
    handleCancelChange() {
      // Restore the last Location object to Current Location
      // TODO: Reset the LocationPicker Pin
      this.reloading = true

      this.$nextTick(() => {
        if (this.correction === false) {
          this.record.values.Location = this.record.history[0]
        }

        this.reloading = false
        this.locked = true
        this.correction = false
      })
    },
    handleStartChange() {
      this.record.values.Location = this.record.values.Location.LocationCopy({ 
        version: this.record.nextLocationVersion()
      })
      this.locked = false
    },
    handleStartCorrection() {
      this.locked = false
      this.correction = true
    }
  }
};
</script>
