<template>
  <div class="TrafficDecision">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TrafficDecision',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Verkeersbesluit'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'TrafficDecision'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PublicationDate": {
            "type": "string",
            "title": "Publicatie verkeersbesluit",
            "format": 'date',
        },
        "DefinitiveDate": {
            "type": "string",
            "title": "Einddatum bezwaarperiode",
            "format": 'date',
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'PublicationDate' ]
      }, {
        "columns": '1fr',
        "fields": [ 'DefinitiveDate' ]
      }],
      validation: {
        PublicationDate: {
          required
        },
        DefinitiveDate: {
          required
        }
      }
    }
  },
  methods: {
    handleInput({ name, value }) {
      if (['TrafficDecision.PublicationDate'].includes(name) && value) {
        this.$emit('setDefinitiveDate', this.format(this.addDays(new Date(value), 42)))
      }
    },
    addDays(date, days) {
      const copy = new Date(Number(date))
      copy.setDate(date.getDate() + days)
      return copy
    },
    format(date) {
      let month = '' + (date.getMonth() + 1)
      let day = '' + date.getDate()
      let year = date.getFullYear()

      month = month.length < 2 ? `0${month}` : month
      day = day.length < 2 ? `0${day}` : day

      return `${year}-${month}-${day}`
    }
  }
}
</script>