<template>
  <b-list-group-item 
    class="Table__header" 
    :style="headerStyle">

    <div 
      v-for="segment in headerSegments" 
      :key="segment.field" 
      :style="{'grid-area' : segment.property }"
      class="HeaderSegment">

      <div 
        v-if="segment.sortBy && segment.sortBy.length !== 0"
        class="HeaderSegment__sort">
        <b-icon 
          @click="toggleSortingDirection({ property: segment.property, direction: 'up' })"
          :icon="segment.direction === 'up' ? 'caret-up-fill' : 'caret-up'"></b-icon>
        <b-icon 
          @click="toggleSortingDirection({ property: segment.property, direction: 'down' })"
          :icon="segment.direction === 'down' ? 'caret-down-fill' : 'caret-down'"></b-icon>
      </div>

      <div class="HeaderSegment__label">
        {{ segment.label }}
      </div>

    </div>

  </b-list-group-item>
</template>

<script>

import TableState from './mixins/TableState.vue'

export default {
  name: 'TableHeader',
  mixins: [ TableState ],
  computed: {

    /**************************************************************************
     * Segments
     */

    /**
     * 
     * @type {object[]}
     */
    headerSegments() {
      return this.headerLayout.properties.map(property => {
        return {
          property,
          direction: this.sortingStatusByField[property] || null,
          sortBy: !! (this.sortByConfigs[property] || false),
          label: this.config.headerLabels[property] || ''
        }
      })
    },

    sortByConfigs() {
      return this.config.sorting || {}
    },

    sortingStatusByField() {
      return Object.keys(this.sortByConfigs)
        .reduce((result, property) => {
          let sortBy = this.activeSorting.find(sort => sort.property === property)
          result[property] = sortBy ? sortBy.direction : null
          return result
        }, {})
    },


    /**************************************************************************
     * Layout
     */

    /**
     * @type {object}
     */
    headerLayout() {
      let layout = this.layout
      return Object.assign({
        columns: 'auto',
        area: '',
        gap: '8px'
      }, (layout.header || layout.row || {}))
    },

    /**
     * @type {object}
     */
    headerStyle() {
      return {
        'grid-template-columns': this.headerLayout.columns,
        'grid-template-areas': this.headerLayout.area,
        'gap': this.headerLayout.gap
      }
    },
  },
  methods: {
    /**
     * Update the sorting direction of a property
     */
    toggleSortingDirection({ property, direction }) {
      let index = this.activeSorting.findIndex(sort => sort.property === property)

      if (index !== -1) {
        if (this.activeSorting[index].direction === direction) {
          this.activeSorting.splice(index, 1)
        } else {
          this.activeSorting[index].direction = direction
        }

      } else {
        this.activeSorting.push({
          property, direction
        })
      }
    }
  }
}
</script>

<style lang="scss">
.Table__header {
  display: grid !important;
  grid-template-rows: auto;

  font-weight: bold;

  &.list-group-item {
    padding: 0.25rem 1.25rem;
  }

  .HeaderSegment {
    display: flex;
    align-content: space-between;
    align-items: center;

    &__label {
      // text-align: center;
      flex-grow: 1;
    }
    &__sort {
      display: flex;
      flex-direction: column;
      align-items: center;
      flex-shrink: 0;
      margin-right: 4px;

      svg {
        cursor: pointer;
        user-select: none;
      }
    }
  }
}

</style>