/**
 * Check the lifetime of the current Auth0 session
 *
 * Notes on default Auth0 config
 * 
 * Default token lifetimes
 * IDT - 36000 (10 uur)
 * RT - 2592000 / 1296000
 */

import { getInstance } from "./index";

/**
 * Extending a session requires a proper configuration of Auth0.
 *  Auth0 docs are all over the place, so here is the short of it:
 * 
 * 1. In Auth0 dashboard
 * - Enable Refresh Token Rotation in the Application
 * - Enable Offline Access in the API 
 *  
 * 2 Use the Auth0 SDK (auth0-spa-js)
 * - Use the createAuth0Client factory method
 * - Pass useRefreshToken = true as one of the configuration options
 * 
 * Links: 
 * https://auth0-docs-staging.herokuapp.com/docs/secure/tokens/refresh-tokens/configure-refresh-token-rotation
 * https://auth0.github.io/auth0-spa-js/interfaces/auth0clientoptions.html#userefreshtokens
 * https://auth0.com/docs/get-started/apis/api-settings#access-settings
 * https://dev.auth0.com/docs/authenticate/login/configure-silent-authentication
 */
export const extendSession = async () => {
  return await getInstance()
    .getTokenSilently({
      ignoreCache: true // to force it to make an api call to Auth0
    })
    .catch(e => {
      if (e.error === 'login_required') {
        console.log("Login required... ")
      }

      throw e
    })
}

/**
 * Read the expire time of the id token and compare it with the current time
 */
export const getRemainingSessionLifetime = async() => {
  const authService = getInstance()

  const claims = await authService.getIdTokenClaims()

  // No session, means 0 time left...
  if (! claims) {
    return 0
  }

  // Remaining time in seconds, rounded down
  return Math.floor( ( (claims.exp * 1000) - Date.now() ) / 1000 )
}

/**
 * Iteratively check the time remaining on the session token.
 */
export const startSessionChecker = ({ onExpired, onUpdate, onWarning, warningTreshold }) => {
  let timeout = null

  console.log("start session checker")
  
  const checkSession = async () => {
    if (timeout) {
      clearTimeout(timeout)
    }

    // Remaining time in seconds
    const remainingTime = await getRemainingSessionLifetime()
    console.log(remainingTime)

    if (onUpdate) {
      onUpdate({ remainingTime }) 
    }
  
    /* eslint-disable */
    switch(true) {
      case remainingTime <= 0:
        if (onExpired) {
          onExpired()
        }
        break

      case remainingTime < (warningTreshold || 240):
        if (onWarning) {
          onWarning({ remainingTime })
        }
        // No break! 

      /**
       * Increase the check frequency as we get closer to 0 seconds
       *  The tresholds are set to trigger before the last iteration 
       *  of the previous interval
       */
      case remainingTime <= 311:
        timeout = setTimeout(checkSession, 1 * 1000)
        break

      case remainingTime < 631:
        timeout = setTimeout(checkSession, 10 * 1000)
        break

      case remainingTime >= 631:
        timeout = setTimeout(checkSession, 30 * 1000)
        break;
    }
    /* eslint-enable */
  }

  checkSession()
}
