<template>
  <div class="GridOperatorApproval">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { minValue } from 'vuelidate/lib/validators'

export default {
  name: 'CPOAdditionalCosts',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Werkzaamheden'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'CPOAdvice'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "SumAdditionalCost": {
          "type": "number",
          "title": "Totale kosten voor aanvullende werkzaamheden"
        },
        "AdditionalCostDescription": {
            "type": "string",
            "title": "Door de gemeente uit te voeren",
            "format": "textarea",
            "rows": 2
        }
      },
      layout: [
      // {
      //   "columns": '1fr',
      //   "fields": [ 'SumAdditionalCost' ]
      // }, 
      {
        "columns": '1fr',
        "fields": [ 'AdditionalCostDescription' ]
      }],
      validation: {
        'SumAdditionalCost': { 
          minValue: minValue(0) 
        }
      }
    }
  }
}
</script>