<template>
  <div class="MenuGeneratorLink">
    <router-link 
      class="link"
      :to="item.to">
      {{ item.label }}
    </router-link>
  </div>
</template>

<script>
export default {
  name: 'MenuGeneratorLink',
  props: {
    item: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.MenuGeneratorLink {
  position: relative;
  margin-bottom: 0.4rem;

  .link {
    position: relative;
    text-decoration: none !important;

    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      // left: 0.5rem;
      // width: calc(100% - 1rem);
      width: 100%;
      height: 2px;
      background: #00B46B;
    }

    &.exact-active:after, 
    &:hover:after {
      content: "";
    }
  }
}
</style>