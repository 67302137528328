
import { commonFilterWrapper } from '../utils'

import { Regions } from '@/config'

let regionToSlug = {}


function convertRegionToSlug({ region }) {
  return region.toLowerCase().replace('-', '').trim()
}

function mapObjectKeysToValue({ obj, value }) {
  return Object.keys(obj).reduce((result, key) => {
    result[key] = value
    return result
  }, {})
}

let RegionSlugs = Regions.map(region => {
  let slug = convertRegionToSlug({ region })
  regionToSlug[slug] = region
  return slug
}).sort((a, b) => b.localeCompare(a) ? -1 : 1)


export default {
  
  /**
   * Config reference
   * @type {string}
   */
  uuid: '079c2131-92dd-4371-80e3-7f6fb09cc9da',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'rvb-customer-reporting',

  /**
   * Access requirements
   */
  access: {
    requirements: [],
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RVBControls',


  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 1000,
  pageSizeOptions: [1000],

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: [],
        columns: '',
        area: ``
      },
      header: { // copied to row
        properties: [
          "ObjectType", "Customer", "Total"
        ].concat(RegionSlugs),
        columns: ['1fr', '1fr', '1fr'].concat(RegionSlugs.map(() => '1fr')).join(' '),
        area: `"${['ObjectType', 'Customer'].concat(RegionSlugs).join(' ')} Total"`
      }
    }
  },

  headerLabels: Object.assign({
    ObjectType: 'Stelsel',
    Customer: 'Klant',
    Total: 'Totaal'
  }, regionToSlug),

  // 
  cellComponents: Object.assign({
    ObjectType: null,
    Customer: null,
    Total: null
  }, mapObjectKeysToValue({ obj: regionToSlug, value: null })),

  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'string',
      sortBy: 'ObjectTypeSort',
      direction: 'up'
    }
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {

    let location = record.CurrentLocation || {}
    
    let data = {
      ObjectType: location?.Object?.Address?.ObjectType || '-',
      Region: location?.Object?.Address?.RVBRegion || '-',
      Customer: location?.Object?.Address?.Customer || '-',
    }


    return Object.assign(data, {
      /** 
      * Filter params
      */ 
      filteredOut: false,
      filterReason: null
    }, {
      categories: {
        all: true,
        actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

        gepauzeerd: record.status.onhold === true && record.status.cancelled !== true,
        afgerond: record.status.completed === true,
        geannulleerd: record.status.cancelled === true
      },
    })
  },

  /**
   * Data can be aggregated
   */
  dataPresentationMapper: ({ records }) => {

    let entry = Object.assign({
      ObjectType: '',
      Customer: '',
      Total: 0
    }, mapObjectKeysToValue({
      obj: regionToSlug,
      value: 0
    }))

    let data = {
      'total': Object.assign({
        ObjectType: 'Totaal',
        ObjectTypeSort: 'ZZZZZZZZZZZZZZZZZZZ',
        Total: 0
      }, mapObjectKeysToValue({
        obj: regionToSlug,
        value: 0
      })),
    }


    records.forEach(record => {

      let ObjectType = record.ObjectType
      let Customer = record.Customer
      let Region = record.Region
      let slug = convertRegionToSlug({ region: Region })

      let id = `${ObjectType}-${Customer}`

      if (! data[id]) {
        data[id] = Object.assign({}, entry, {
          Customer,
          ObjectType,
          ObjectTypeSort: ObjectType
        })
      }
    
      data[id][slug] = data[id][slug] + 1
      data[id].Total = data[id].Total + 1

      data['total'][slug] = data['total'][slug] + 1
      data['total']['Total'] = data['total']['Total'] + 1
    })

    return Object.values(data)
  },

  totalMapper: () => {

    return null
  },


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default'
        }, {
          text: 'Actief',
          value: 'actief'
        }, {
          text: 'Gepauzeerd',
          value: 'gepauzeerd'
        }, {
          text: 'Afgerond',
          value: 'afgerond'
        }, {
          text: 'Geannuleerd',
          value: 'geannulleerd'
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },

}