<template>
  <BlockRepeater 
    class="TagConfig" 
    title=""
    inline
    divider
    compact
    :label="label"
    :path="path" 
    :values="mappedValues"
    :disabled="disabled" 
    :permanent="evaluatePermanentState"
    :deleted="evaluateDeletedState"
    v-slot="slotProps">
    <FieldSet 
      label=""
      :values="mappedValues"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'Tags',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: 'Voeg een tag toe'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Tags'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "label": {
          //"title": "Tag",
          "type": "string"
        },
        "enabled": {
          //title: 'Actief',
          format: 'checkbox',
          options: [{
            value: true,
            text: 'Actief'
          }]
        },
        "request": {
          //title: 'Aanvraag',
          format: 'checkbox',
          options: [{
            value: true,
            text: 'Aanvragen'
          }]
        },
        "process": {
          format: 'checkbox',
          options: [{
            value: true,
            text: 'Realisatie'
          }]
        },
        "uuid": {
          type: 'hidden'
        },
        "uuid-label": {
          title: 'ID: ',
          format: 'string',
          readonly: true,
          className: 'uuid text-muted'
        },
        "realisationCount": {
          title: 'Realisatie: ',
          format: 'string',
          readonly: true,
          className: 'realisation-count text-muted justify-content-end'
        },
        "requestCount": {
          title: 'Aanvragen: ',
          format: 'string',
          readonly: true,
          className: 'request-count text-muted justify-content-end'
        },
        "deleted_at": {
          type: 'hidden'
        }
      },
      layout: [{
        "columns": '1fr 60px 0px', //  auto auto
        "fields": [ 'label', 'enabled', 'deleted_at' ] // 'request', 'process',
      },{
        "columns": '1fr 0px 100px 100px 60px',
        "fields": [ 'uuid-label', 'uuid', 'realisationCount', 'requestCount' ]
      }]
    }
  },
  computed: {
    mappedValues() {
      let values = {} 
      values[this.path] = this.values[this.path].map(item => {
        item['uuid-label'] = item.uuid
        return item
      })
      return values
    }
  },
  // created() {
  //   console.log(this.path, this.values)
  // },
  methods: {
    evaluateDeletedState({ index }) {
      return !! (
        this.values[this.path] && this.values[this.path][index] && this.values[this.path][index].deleted_at
      )
    },
    evaluatePermanentState({ index }) {
      if (index >= this.values[this.path].length) return false

      let item = this.values[this.path][index]
      return (item.realisationCount !== 0 || item.requestCount !== 0)
      
      // return index < this.values[this.path].length
    }
  }
}
</script>

<style lang="scss">
/**
 * TODO: @WouterSchaap: The BlockRepeater component (inline) is copied from RVB Components repo. Styling should be useable in both without modifications like those below
 */
.BlockRepeater.inline .BlockRepeater__add {
  padding-left: 0
}
.BlockRepeaterItem.inline .BlockRepeaterItem__body {
  flex-grow: 1
}
.BlockRepeaterItem.inline .BlockRepeaterItem__footer {
  padding-bottom: 17.5px !important;
}

.TagConfig {
  .BlockRepeaterItem.inline {
    align-items: flex-start;
  }
  .form-group {
    margin-bottom: 0.5rem;
    display: flex;
    align-items: center;

    > div {
      width: 100%;
    }
  }
  .uuid, .request-count, .realisation-count {
    margin-bottom: 0;
    top: -0.3rem;
    font-size: 70%;

    display: flex;
    flex-direction: row;

    .FieldValue__label {
      margin-right: 0.2rem;
    }
  }
}
</style>