<template>
  <div class="DetailGrid__nav">

    <Feedback :feedback="statusFeedback" :dismissible="false" />

    <StepsNav 
      v-if="hasDepth"
      class="DetailGrid__nav" 
      :step="step" 
      :record="record" />
    <StepsNavSimple 
      v-else
      class="DetailGrid__nav mb-3" 
      :step="step" 
      :record="record" />

    <StepsNav 
      v-if="currentStepIsGroup"
      class="DetailGrid__nav" 
      :depth="2"
      :step="step" 
      :record="record" />

    <b-navbar class="mt-3" type="dark" variant="dark">
      <b-navbar-brand variant="light">
        {{ (step +"").replace('-', ' ') }}. {{ currentStepLabel }}
      </b-navbar-brand>
      <b-navbar-nav class="ml-auto">

        <slot />

      </b-navbar-nav>
    </b-navbar>

    <div class="w-100 p-1 px-3" style="background: rgba(0, 0, 0, 0.05)">
      <span class="mr-3">In te voeren door: <strong>{{ editorRoles }}</strong>.</span>
      <span>Ter inzage voor: <strong>{{ viewerRoles }}</strong>.</span>
    </div>

    <b-alert class="mt-3" :show="!!feedback" :variant="feedback" :dismissible="true">
      {{ feedbackMessage }}
    </b-alert>
  </div>
</template>

<script>
import StepsNav from "@/components/realisation/StepsNav.vue"
import StepsNavSimple from '@/components/realisation/StepsNavSimple.vue'
import Feedback from '@/components/form/Feedback.vue'

import { roleToRoleLabel, onHoldReasons, cancelReasons } from '@/config'
import { unique } from '@/helpers/array.js'
import { mapGetters } from 'vuex'

export default {
  name: 'RealisationHeader',
  components: { StepsNav, StepsNavSimple, Feedback },
  props: {
    step: {
      type: [String, Number],
      default: "1"
    },
    /**
     * The 
     */
    recordStep: {
      type: [String, Number],
      default: 1
    },
    feedback: {
      type: String,
      default: null
    },
    feedbackMessage: {
      type: String,
      default: ''
    },
    record: {
      type: Object,
      required: false
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getWorkflowSpecByName'
    ]),
    hasDepth() {
      let spec = this.getWorkflowSpecByName({
        name: 'realisation'
      })
      return spec.steps.some(step => Array.isArray(step.children) && step.children.length !== 0)
    },
    currentStep() {
      
      // console.log("currentStep", this.step)
      let spec = this.getWorkflowSpecByName({
        name: 'realisation'
      })

      return spec.steps.find(step => (step.step || step.short) == this.step)
    },
    currentStepLabel() {
      return (this.currentStep) ? this.currentStep.label : ''
    },
    /**
     * If a step has children, it's a group, not a step by itself
     *  TODO: Fix naming. Code adjusted to also be true for children in group.
     */
    currentStepIsGroup() {
      if (! this.currentStep) return false

      if (Array.isArray(this.currentStep.children) && this.currentStep.children.length !== 0) {
        return true
      }
      // console.log(this.currentStep)
      if (this.currentStep.short.includes('-')) return true

      return false
    },
    viewerRoles() {
      if (! this.currentStep) return ''

      let roles = this.currentStep.access.read
        .filter(role => role !== 'admin')
        .filter(role => ! this.currentStep.access.edit.includes(role))
        .map(roleToRoleLabel)
      return unique({ arr: roles }).join(', ')
    },
    editorRoles() {
      if (! this.currentStep) return ''

      let roles = this.currentStep.access.edit
        .filter(role => role !== 'admin')
        .map(roleToRoleLabel)
      return unique({ arr: roles }).join(', ')
    },

    statusFeedback() {
      if (! this.record) {
        return {
          show: false,
          message: ''
        }
      }

      if (this.record.status.cancelled) {
        let reason = cancelReasons.find(reason => reason.value === this.record.statusMeta.cancelled.reason)
        reason = reason ? reason.text : (this.record.statusMeta.cancelled.label || this.record.statusMeta.cancelled.reason)
        
        return {
          show: true,
          variant: 'danger',
          message: `Dit proces is afgebroken, met reden "${reason}". ${this.record.statusMeta.cancelled.comment}`
        }
      }

      else if (this.record.status.onhold) {

        let reason = onHoldReasons.find(reason => reason.value === this.record.statusMeta.onhold.reason)
        reason = reason ? reason.text : (this.record.statusMeta.onhold.label || this.record.statusMeta.onhold.reason)
        
        return {
          show: true,
          variant: 'danger',
          message: `Dit proces staat on hold, met reden "${reason}". ${this.record.statusMeta.onhold.comment}`
        }
      }

      return {
        show: false
      }
    }
  }
}
</script>

<style lang="scss">

.compactsteps {
  padding-top: 4px !important;
  padding-bottom: 4px !important;

  .navbar-brand {
    font-size: 1rem !important;
    padding: 0;
  }
}
</style>