<template>
  <div class="RealisationList">
    <TableManager 
      v-show="mainRoute"
      ref="TableManager"
      class="mx-3"
      :loading="loading"
      :sourceRecords="records" 
      :category="tableCategory"
      :uuid="tableUuid" />

    <div class="mt-3 text-center" v-if="mainRoute">
      <div v-if="! loading">
        <router-link 
          v-if="lastOpenedRecord" 
          class="text-muted" 
          :to="{ name: 'Realisation', params: { uuid: lastOpenedRecord } }">
          <b-icon icon="arrow-left-circle" class="mr-1"></b-icon>
          Ga terug naar het laatst geopende realisatie proces
        </router-link>
        
        <div class="text-muted mt-2">Laatst bijgewerkt om {{ lastRefresh }}</div>
        <b-link class="text-muted" @click="handleRefresh">
          <b-icon icon="arrow-repeat" class="mr-1"></b-icon>
          Nu bijwerken
        </b-link>
      </div>
      <div v-else>
        Bezig met verversen...
      </div>
    </div>

    <router-view v-if="! mainRoute"></router-view>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex'

import TableManager from '@/components/Table/TableManager'

export default {
  name: "RealisationList",
  components: { 
    TableManager
  },
  computed: {
    ...mapGetters('realisation', [
      'hasRecords',
      'loading',
      'records',
      'lastRefresh',
      'lastOpenedRecord',
    ]),
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses'
    ]),
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    mainRoute() {
      return ['RealisationList', 'RealisationListFiltered'].includes(this.$route.name)
    },
    filter() {
      let _default = this.isCurrentTenant({ tenant: 'rvb' }) ? 'actief' : 'all'
      return (this.$route.params.filter || _default).toLowerCase()
    },
    tableCategory() {
      return this.isCurrentTenant({ tenant: 'rvb' }) ? 'rvb' : 'realisation'
    },
    tableUuid() {
      return this.isCurrentTenant({ tenant: 'go-ral' }) 
        ? '3406eb6e-f42a-4e9d-914a-5a11e308f867' 
        : null
    }
  },
  watch: {
    /**
     * Replace active category filter
     */
    filter() {
      this.setCategoryFilterFromRoute()
    }
  },
  created() {
    if (! this.canAccessRealisationProcesses) {
      this.$router.push({
        name: 'Dashboard'
      })
    }

    if (! this.hasRecords) {
      this.handleRefresh()
    }
  },
  mounted() {
    this.setCategoryFilterFromRoute()
  },
  methods: {
    ...mapActions('realisation', [
      'loadRecords'
    ]),

    ...mapMutations('relations', [
      'resetRelations',
      'addConnection'
    ]),
    handleRefresh: async function() {
      const token = await this.$auth.getTokenSilently();
      await this.loadRecords({ token })
      
      this.matchRequestToRealisations()
    },

    /**
     * Go through the realisation process objects and connect the request objects
     */
    matchRequestToRealisations() {
      if (! this.canAccessRequests && ! this.canAccessRealisationProcesses) return 

      this.resetRelations()

      ;(this.records || []).forEach(record => {
        (record.requestUuids || []).forEach(uuid => {
          this.addConnection({ 
            requestUuid: uuid,  
            realisationUuid: record.uuid
          })
        })
      })
    },

    setCategoryFilterFromRoute() {
      if (! this.$refs.TableManager || ! this.mainRoute) return 
      // console.log("setCatFilter")
      
      this.$refs.TableManager.replaceActiveFiltersInCategory({
        category: 'category',
        filters: [{ value: this.filter }]
      })
    },
  }
}
</script>

<style lang="scss">
.RealisationList {
  padding: 18px 0 30px;
}
</style>