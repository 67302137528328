<template>
  <div class="StepsNavSimple" :class="{ 'StepsNav--narrow': depth === 2 }">
    <ul class="StepsNavSimple__list">
      <li class="StepsNavSimple__item" v-for="(step) in items" :key="step.short">
        <div
          class="SNOrb"
          :class="{ 
            'SNOrb--active': step.short === active, 
            jsHover: step.short === hover, 
            'SNOrb--available': isAvailable(step.uuid),
            'SNOrb--finished': isCompleted(step.uuid) 
          }">
          <span class="SNOrb__label">
            <b-badge variant="dark">
              {{ `${depth === 2 ? step.short.split('-')[1]: step.short }. ${step.label}` }}
            </b-badge>
          </span>
          <span
            class="SNOrb__step"
            @mouseover="setHover(step.short)"
            @mouseleave="setHover"
            @click="setActive(step.uuid, step.short)">{{ depth === 2 ? step.short.split('-')[1]: step.short }}</span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>

/**
 * TODO: This component needs a refactor in variable naming & structure
 * TODO: Currently it has been patched up to do a job, but it's not sustainable code
 */

import { mapGetters } from 'vuex';

export default {
  name: "StepsNavSimple",
  props: {
    step: {
      type: [String, Number],
      default: 1
    },
    record: {
      type: Object,
      required: true
    },
    depth: {
      type: Number,
      default: 1,
      // TODO: Only supports 1 or 2
      validator(value) {
        return [1,2].includes(value)
      }
    }
  },
  data() {
    return {
      hover: null,
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getWorkflowSpecByName'
    ]),
    steps() {
      return this.getWorkflowSpecByName({ name: 'realisation' })?.steps || []
    },
    stepsByUuid() {
      return this.steps.reduce((result, step) => {
        result[step.uuid] = step
        return result
      }, {})
    },
    active() {
      // console.log("active", this.step)
      if (this.depth === 1) {
        return (this.step+'').split('-')[0]
      }
      
      return this.step

      // Used previously to enforce min / max based on available number of steps
      // return Math.max(1, Math.min(this.items.length, parseInt(this.step, 10))) - 1
    },
    parent() {
      if (this.depth === 1) {
        return null
      }
      return (this.step+'').split('-')[0]
    },
    // TODO: Only supports 1 or 2
    items() {
      let childrenByParent = {}
      let children = []
      let root = []

      this.steps.forEach(step => {
        if (step.children) {
          children = children.concat(step.children)
          let parent = ((step.short || step.step) + '').split('-')[0] || 'unknown'
          childrenByParent[parent] = step.children
        }
      })
      this.steps.forEach(step => {
        if (! children.includes(step.uuid)) {
          root.push(step)
        }
      })

      if (this.depth === 1) {
        // console.log("ROOT ITEMS", root)
        return root
      }

      // console.log(this.depth, childrenByParent, this.parent, childrenByParent[this.parent])

      let items = (childrenByParent[this.parent] || []).map(uuid => this.stepsByUuid[uuid])
      // console.log("CHILD ITEMS", items)
      return items

      // return steps.map(step => step.label)
    },
    recordState() {
      try {
        return this.record.flow.state
      } catch(e) {
        return []
      }
    },
    completedUuids() {
      return this.recordState
        .filter(state => state.completed_at)
        .map(state => state.uuid)
    },
    availableUuids() {
      return this.recordState
        .filter(state => state.started_at)
        .map(state => state.uuid)
    }
  },
  methods: {
    isCompleted(uuid) {
      return this.completedUuids.includes(uuid)
    },
    isAvailable(uuid) {
      return this.availableUuids.includes(uuid)
    },
    setHover(item) {
      if (item >= 0) {
        this.hover = item;
      } else {
        this.hover = null;
      }
    },
    setActive(uuid, short) {
      try {
        // console.log("setActive", uuid, short)

        // Ignore nav to current page
        if (short === this.step) return

        if (! this.isAvailable(uuid)) return

        this.$router.push({
          name: `Realisation.Step${short}`,
          params: {
            uuid: this.record.uuid
          }
        })
      } catch(e) {
        // don't do anything
      }
    },


    /**
     * Piramid
     */
    // flowMatrix() {

    //   /**
    //    * Find step(s) without requirements
    //    */
    //   let withoutRequirements = 'uuid'

    //   let flow = {
    //     root: withoutRequirements
    //   }

    //   console.log(flow)
    //   return [{
    //     uuid: '',
    //     children: [

    //     ]
    //   }]
    // }
  },
};
</script>

<style lang="scss">
  $orb-size: 30px;
  $orb-big-scale: 1.75;
  $orb-color: $gray-400;
  $timing: 0.1s;

.StepsNavSimple {
  width: 100%;
  text-align: left;
  user-select: none;

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    align-items: center;
  }
  &__item {
    display: inline-block;
    flex-basis: 100%;
    flex-grow: 1;
    flex-shrink: 1;

    &:first-child,
    &:last-child {
      .SNOrb__label {
        margin: 0;
        transform: none;
      }
      .SNOrb--active {
        .SNOrb__step {
          transform: none;
        }
      }
    }

    &:last-child {
      flex-basis: auto;

      .SNOrb__label {
        right: 0;
      }
      .SNOrb--active {
        .SNOrb__step {
          margin-left: -(($orb-size * $orb-big-scale)-$orb-size)
        }
      }
    }
  }


  // StepsNavSimple Orb
  .SNOrb {
    position: relative;
    align-items: center;
    padding-top: ($orb-size * 0.5);
    padding-bottom: ($orb-size * 0.5);
    transition-property: padding-top, padding-bottom;
    transition-timing-function: ease-in-out;
    transition-duration: $timing;

    &:before {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      height: 2px;
      width: 100%;
      background-color: $orb-color;
      z-index: -1;
      transition: background-color, $timing;
    }

    &__label {
      position: absolute;
      bottom: 100%;
      color: rgba(0, 0, 0, 0.6);
      display: none;
      margin-left: 0.5 * $orb-size;
      transform: translateX(-50%);
      white-space: nowrap;
    }

    &__step {
      
      cursor: not-allowed;
      display: flex;
      align-items: center;
      justify-content: center;
      line-height: 0;
      width: $orb-size;
      height: $orb-size;
      border-radius: $orb-size;
      background-color: $orb-color;
      color: $gray-800;
      font-size: 0.85em;
      font-weight: bold;
      transition-property: width, height, background-color, color, transform, margin-left;
      transition-timing-function: ease-in-out;
      transition-duration: $timing;
    }
    
    &--available {
      .SNOrb__step {
        cursor: pointer;
        background-color:$gray-800;
        color:white; 
      }

      &:before {
        background-color: $gray-800;
      }
    }

    &--finished {
      .SNOrb__step {
        cursor: pointer;
        background-color:$gray-800;
        color:white;
      }

      &:before {
        background-color: $gray-800;
      }
    }

    &--active {
      padding: 0;

      .SNOrb__step {
        width: $orb-size * $orb-big-scale;
        height: $orb-size * $orb-big-scale;
        background-color: $gray-800;
        color: white;
        transform: translateX(-0.5 * (($orb-size * $orb-big-scale)-$orb-size));
      }

      .SNOrb__label {
        margin-left: 0.5 * ($orb-size * $orb-big-scale);
      }
    }
    

    &.jsHover {
      .SNOrb__label {
        display: inline-block;
      }
    }
  }
}


</style>