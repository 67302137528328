<template>
  <div class="BuildingAdjustments">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
    />
    <FieldSet 
      label=""
      :values="values"
      :fields="fields"
      :layout="layout_two"
      :validation="validation"
      :disabled="disabled"
      path="Progress"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers, required } from 'vuelidate/lib/validators'
import { isUrl } from '@/services/validation'

export default {
  name: 'Assessment',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Beoordeling'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "FuseBoxOption": {
          "title": "Opties voor de hoofdverdeelkast",
          "type": "string",
          "description": "Bij besluiten welke extra kosten tot gevolg hebben, zoals het vervangen van de hoofdverdeelkast, is eerst instemming nodig vanuit het kernteam, of vanuit de centrale klant (specialties). De kosten dienen eerst aan de klant voorgelegd te worden, alvorens er een go/no go komt",
          "enumNames": [
              "Kleine aanpassing benodigd",
              "Grote aanpassing / vervanging benodigd",
              "Hoofdverdeelkast kan niet aangepast of vervangen worden, geen laadpunten plaatsen"
          ],
          "enum": [
              "MinorChangeNeeded",
              "ReplacementNeeded",
              "NoChargePoints"
          ]              
        },
        "PowerOption": {
          "title": "Conclusie over capaciteit voor de laadpunten",
          "type": "string",
          "description": "Bij besluiten welke extra kosten tot gevolg hebben, zoals verzwaring van de installatie, is eerst instemming nodig vanuit het kernteam, of vanuit de centrale klant (specialties). De kosten dienen eerst aan de klant voorgelegd te worden, alvorens er een go/no go komt",
          "enumNames": [
              "Er is genoeg capaciteit zonder static loadbalancing",
              "Er is genoeg capaciteit met static loadbalancing",
              "Er is niet genoeg capaciteit,  een netverzwaring aanvragen",
              "Er is niet genoeg capaciteit, verminder het aantal te plaatsen laadpunten",
              "Er is niet genoeg capaciteit, geen laadpunten plaatsen"
          ],
          "enum": [
              "SufficientPowerWithOutStaticLoadbalancing",
              "SufficientPowerWithStaticLoadbalancing",
              "GridUpgrade",
              "ReduceChargePoints",
              "NoChargePoints"
          ]              
        },
        "SiteReportLink": {
          "type": "string",
          "title": "Link naar het schouwverslag",
          "format": "url"
        }
      },
      layout: [{
        columns: '1fr 1fr',
        fields: ['PowerOption', 'FuseBoxOption']
      }],
      layout_two: [{
        columns: '1fr',
        fields: ['SiteReportLink']
      }],
      validation: {
        "FuseBoxOption": {
          required
        },
        "PowerOption" : {
          required
        },
        SiteReportLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  },
  methods: {

    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name
      })
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name
      })
    }
  }
}
</script>