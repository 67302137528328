<template>
  <BlockRepeater 
    class="Quotation" 
    title=""
    label="Voeg een offerte toe"
    :path="path" 
    :values="values"
    :disabled="disabled" 
    :deleted="evaluateDeletedState"
    v-slot="slotProps">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
      :viewer="viewer"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isUrl } from '@/services/validation'

export default {
  name: 'Quotation',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Party'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PartyName": {
          "type": "string",
          "title": "Leverancier waar de offerte is aangevraagd"
        },
        // "ProjectPhase": {
        //   "type": "string",
        //   "title": "Hoort bij fase",
        //   "enumNames": [
        //     "Fase 2A",
        //     "Fase 2B"
        //   ],
        //   "enum": [
        //     "Fase2A",
        //     "Fase2B"
        //   ]
        // },
        "QuotationDescription": {
          "type": "string",
          "title": "Beschrijving van de werkzaamheden in de aangevraagde offerte",
          "format": "textarea"
        },
        "ReceivedDate": {
          "type": "string",
          "format": "date",
          "title": "Datum dat de offerte is ontvangen"
        },
        "LinkToQuotation": {
          "type": "string",
          "format": "url",
          "title": "Link naar de offerte"
        },
        "deleted_at": {
          type: 'hidden'
        }
      },
      layout: [{
        "columns": "1fr 1fr",
        "fields": [ 'PartyName', 'ReceivedDate' ]
      }, {
        "columns": "1fr 1fr",
        "fields": [ 'LinkToQuotation', 'ProjectPhase' ]
      }, {
        "columns": "1fr 0px",
        "fields": [ 'QuotationDescription', 'deleted_at' ]
      }],
      validation: {
        LinkToQuotation: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  },
  methods: {
    evaluateDeletedState({ index }) {
      return !! (
        this.values[this.path] && this.values[this.path][index] && this.values[this.path][index].deleted_at
      )
    },
  }
}
</script>