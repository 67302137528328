<template>
  <div class="Address">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required, helpers } from 'vuelidate/lib/validators'
import { isAplhanumericUnderscore, isAuthZeroId } from '@/services/validation'

import { customers, Provinces, Regions, ObjectTypes, RPL } from '@/config'

export default {
  name: 'KeyReferences',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Object.Address'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne + additions
      fields: {
        "BuildingCode": {
            "type": "string",
            "title": "Uniek gebouwnummer"
        },
        "ComplexNumber": {
            "type": "string",
            "title": "Bouwwerkcode"
        },
        "ObjectType": {
            "type": "string",
            "title": "Stelsel",
            "enumNames": [''].concat(ObjectTypes),
            "enum": [''].concat(ObjectTypes)
        },
        "RVBRegion": {
            "type": "string",
            "title": "RVB Regio",

            // Addition to Cafienne
            "enumNames": [''].concat(Regions),
            "enum": [''].concat(Regions)
        },
        "RegionalProjectLeader": {
            "type": "string",
            "title": "Regionaal Project Leider",
            // Addition to Cafienne
            "enumNames": [''].concat(RPL.map(user => user.name)),
            "enum": [''].concat(RPL.map(user => user.id))
        },
        "Province": {
            "type": "string",
            "title": "Provincie",
            // Addition to Cafienne
            "enumNames": [''].concat(Provinces),
            "enum": [''].concat(Provinces)
        },
        "CityName": {
            "type": "string",
            "title": "Plaatsnaam"
        },
        "StreetName": {
            "type": "string",
            "title": "Straatnaam"
        },
        "HouseNumber": {
            "type": "string", // Originally a number in Cafienne
            "title": "Huisnummer"
        },
        "HouseNumberSuffix": {
            "type": "string",
            "title": "Toevoeging"
        },
        "Customer":{
            "type":"string",
            "title":"Klant",
            // Addition to Cafienne
            "enumNames": [''].concat(customers),
            "enum": [''].concat(customers)
        }
      },
      layout: [{
        "columns": '1fr 1fr 1fr',
        "fields": [ 'BuildingCode', 'ComplexNumber', 'ObjectType' ]
      }, {
        "columns": '1fr 1fr 1fr',
        "fields": [ 'RVBRegion', 'RegionalProjectLeader', "Customer" ]
      }, {
        "columns": '2fr 1fr 1fr',
        "fields": [ 'StreetName', 'HouseNumber', 'HouseNumberSuffix' ]
      }, {
        "columns": '1fr 1fr',
        "fields": [ 'CityName', 'Province' ]
      }],
      validation: {
        "BuildingCode": {
          required,
          isAplhanumericUnderscore: (value) => !helpers.req(value) || isAplhanumericUnderscore(value),

          /**
           * 
           */
          isUniqueBuildingCode: async (value) => {
            return value !== null
          }
        },
        "RegionalProjectLeader": {
          isAuthZeroId: (value) => !helpers.req(value) || isAuthZeroId(value)
        },
        "Customer": {
          unicodeString: (value) => !helpers.req(value) || /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@+!?:;/& ])+$/.test(value)
        }
      },
      delay: 300,
      validationTimer: null,
      validationPromiseReject: null
    }
  },
  // computed: {
  //   ...mapGetters(['RplUsers'])
  // },
  // watch: {
  //   RplUsers() {
  //     this.setRPLOptions()
  //   }
  // },
  // created() {
  //   this.setRPLOptions()
  // }, 
  // methods: {
  //   setRPLOptions() {
  //     if (this.RplUsers.length) {
  //       this.fields.RegionalProjectLeader.enumNames = this.RplUsers.map(user => user.name)
  //       this.fields.RegionalProjectLeader.enum = this.RplUsers.map(user => user.id)
  //     }
  //   }
  // }
}
</script>