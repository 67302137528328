<template>
  <div class="MapLocationSelection">
    <b-aspect aspect="16:10">
      <MapBox 
        class="RealisationDetail__map"
        :accessToken="accessToken" 
        :mapStyle.sync="mapStyle"
        :options="mapOptions"
        @load="onMapLoaded">
    
        <LayerChargingpoints />
        <LayerRequests 
          :realisationUuid="record.uuid"
          @click="handleOpenRequestPopup" />
        
        <LayerLocationPicker 
          v-if="LocationUuid"
          :useGeocoder="false" 
          :coordinates="coordinates"
          :locked="true" />

        <PopupRequest
          :record="record"
          :uuid="requestUuid" 
          @close="handleOpenRequestPopup" />

        <PopupChargingLocation 
          :locked="locked"
          :selected="LocationUuid"
          :alternative="alternative"
          @location="handlePreValidatedLocation" />

      </MapBox>
    </b-aspect>
    <div class="AddressWrapper d-flex mt-3 w-100">
      <div class="AddressWrapper__instruction mt-3">
        <h4>Locatie selectie</h4>
        <p>
          Er wordt in deze regio uitsluitend gewerkt met vooraf vastgestelde locaties. 
        </p>
        <p v-if="alternative">
          Klik eerst de voorgestelde locatie aan en klik op "Ontkoppel deze locatie".
        </p>
        <p>
          Selecteer een groene locatie op de kaart, en klik op "Gebruik deze locatie" om de coordinaten en het adres over te nemen.
        </p>
      </div>
      <div v-if="address" class="AddressWrapper__data">
        <div class="d-flex flex-column mt-3 w-100">
          <div class="mr-2">
            <strong>Coordinaten</strong>
          </div>
          Latitude: {{ address.coordinates.lat }}<br />
          Longitude: {{ address.coordinates.lng }}
        </div>
        <div class="d-flex flex-column mt-3 w-100">
          <div class="mr-2">
            <strong>Adres</strong>
          </div>
          {{ address.formatted_address }}
        </div>

        <p class="text-muted pt-3" style="font-size: 0.8rem">
          Referentie: {{ LocationUuid }}
        </p>
      </div>
      <div v-else class="AddressWrapper__data">
        <p class="d-flex flex-column mt-3 w-100">Er is nog geen locatie gekozen.</p>
      </div>
    </div>

    <FormField type="hidden" :value="LocationUuid" name="Location.chargingpointUuid" />
  </div>
</template>

<script>

import MapBox from '@/components/common/MapBox.vue'
import LayerChargingpoints from '@/components/map/LayerChargingpoints.vue'
import LayerRequests from '@/components/map/LayerRequests.vue'
import LayerLocationPicker from '@/components/map/LayerLocationPicker.vue'
import PopupRequest from '@/components/map/PopupRequest.vue'
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue'

import FormField from '@/components/form/FormField.vue'

import { LegendControl } from '@/services/mapbox'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import { mapGetters, mapMutations } from 'vuex'
import Vue from 'vue'

export default {
  name: 'MapPreSelectedLocationSelection',
  components: {
    MapBox, 
    LayerRequests, LayerChargingpoints, PopupRequest, LayerLocationPicker, 
    PopupChargingLocation,
    FormField
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    connect: {
      type: Boolean,
      default: true
    },
    alternative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * MapBox geocoder field instance
       */
      geocoder: null,

      /**
       * The input values of the Address fields
       */
      addressValues: null,

      /**
       * The UUID of the request pin that was clicked on.
       *  Passed on to the Request Popup 
       */
      requestUuid: null
    }
  },
  computed: {
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    /**
     * The connected requests
     *  list of uuid's
     */
    requests() {
      return []
    },

    LocationUuid() {
      if (this.addressValues && this.addressValues.Location) {
        return this.addressValues.Location.chargingpointUuid
      }
      return null
    },

    SelectedLocation() {
      if (!this.LocationUuid) return null

      return this.getChargingPointByUUID({
        uuid: this.LocationUuid
      })
    },

    address() {
      let location = this.SelectedLocation
      
      if (! location) return null
      return location.data.address
    },


    mapOptions() {
      if (this.requests.length) {
        return {
          // bbox: [
          //   4.728758231000029,
          //   52.27817457500004,
          //   5.068595450000032,
          //   52.43106396500008
          // ]
        }
      } else {
        let center = [5.4139400703, 51.8794433653] // [4.9041, 52.3676]
        let focus = false

        if (this.coordinates) {
          focus = true
          center = [this.coordinates.lng, this.coordinates.lat]
        } else if (this.$route.query.lat && this.$route.query.lng) {
          focus = true
          center = [this.$route.query.lng, this.$route.query.lat]
        }
        
        return {
          center,
          zoom: focus ? 14 : 11
        }
      }
    },

    /**
     * 
     */
    coordinates() {
      let location = this.SelectedLocation
      
      // console.log("coords", location)

      if (location) {
        let coords = location.data.coordinates
        return {
          lng: coords[0],
          lat: coords[1]
        }
      } else {
        return null
      }
    }
  },
  watch: {
    /**
     * Be on the look out for address changes triggered from the parent component
     */
    values() {
      this.addressValues = this.values
    }
  },
  created() {
    this.addressValues = this.values
  },
  methods: {
    ...mapMutations('chargingpoints', [
      'replaceLocation'
    ]),
    ...mapMutations('realisation', [
      'replaceRecord'
    ]),
    onMapLoaded({ map, mapbox }) {
      this.loaded = true
      this.map = map
      this.mapbox = mapbox

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false
      })

      // this.geocoder.on('result', this.onGeocoderResult)
      this.map.addControl(
        this.geocoder
      )

      let legend = new LegendControl({ 
        items: [{
          label: 'Bestaande laadpaal',
          image: 'chargingpoint-realized'
        }, {
          label: 'In voorbereiding',
          image: 'chargingpoint-in-progress'
        }, {
          label: 'Gevalideerde locatie',
          image: 'chargingpoint-definitive'
        }, {
          label: 'Onbeoordeelde aanvraag',
          image: 'request-open'
        }, {
          label: 'Geaccepteerde aanvraag',
          image: 'request-accepted'
        }, {
          label: 'Gekoppeld aan ander proces',
          image: 'request-connected'
        }, {
          label: 'Gekoppeld aan dit proces',
          image: 'request-selected'
        }]
      })
      this.map.addControl(
        legend
      )
    },
    
    /**
     * 
     */
    handleOpenRequestPopup({ uuid }) {
      this.requestUuid = uuid
    },

    /**
     * 
     */
    async handlePreValidatedLocation({ uuid }) {
      let success = false

      // Deselected = made available again
      if (uuid === null) {
        success = await this.connectLocation({
          locationUuid: this.addressValues.Location.chargingpointUuid,
          status: 'definitive'
        })

        if (success) {
          Vue.set(this.addressValues.Location, "chargingpointUuid", uuid)  
        }

        return
      }

      let location = this.getChargingPointByUUID({ uuid })
      if (! location) return 

      success = await this.connectLocation({
        locationUuid: uuid,
        status: 'in-progress'
      })

      if (success) {
        Vue.set(this.addressValues.Location, "chargingpointUuid", uuid)
      }
      // TODO: handle lack of success: show error message
    },


    async connectLocation({
      locationUuid,
      status
    }) {

      if (! this.record) return false

      // Skip the API call if the workflow record hasn't ever been saved yet
      // Or if the component is configured to skip making a connection
      if (! this.record.ref || this.connect === false) return true

      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/connectlocation', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          locationUuid,
          workflowUuid: this.record.uuid,
          status
        })
      })
      if (! api.ok) {
        return false
      }

      const response = await api.json()
      
      this.replaceLocation({
        location: response.data.location
      })
      this.replaceRecord({
        record: response.data.workflow
      })

      return true
    }
  }
}
</script>

<style lang="scss">
.AddressWrapper {
  &__instruction {
    width: calc(50% - 1rem);
    padding: 1rem;
    margin-right: 1rem;
    background: rgba(0, 0, 0, 0.05); // lighten($info, 40%);
  }
  &__data {
    margin-left: 1rem;
    width: calc(50% - 1rem)
  }
}
</style>