
/**
 * Based on https://stackoverflow.com/a/2901298
 * Note: not using lookbehind variation due to lack of support, leading to crashes.
 * 
 * @param {*} x 
 */
export const numberWithDots = (x) => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
}
export const round2Decimals = (value) => {
  return roundWithDecimals({value, decimals: 2})
}

export const roundWithDecimals = ({value, decimals}) => {
  return parseFloat((value).toFixed(decimals));
}
export const wholeNumber = (x) => {
  return x.toFixed(0);
}
export const uniqueIntId = ({chargingpoint}) => {
  return chargingpoint.data.code + '' + chargingpoint.data.properties.id
}