<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" />

      <template v-if="!reloading">
        <div class="DetailGrid__map">
          <b-alert variant="warning" :show="!locked && advice === 'alternative'">
            Stel onder aan deze pagina een alternatieve locatie voor.
          </b-alert>
        </div>

        <div class="DetailGrid__col1">
          <CPOAdvice :values="values" :disabled="locked || disabled" @advice="handleAdviceChange" />
        </div>
        <div class="DetailGrid__col2">
          <CPOAdditionalCosts :values="values" :disabled="locked || disabled" />
          <FileUpload 
            class="mt-3"
            label="Meerwerk offerte(s)"
            doctype="CPOAdditional"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.CPOAdditional || null) : null"
            :minFileId="minCPOAdditionalDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            @files="handleFileChanges" />

        </div>

        <div class="DetailGrid__wide">
          
          <LocationPreparation :values="values" :disabled="locked || disabled" />

          <div v-if="advice === 'alternative'" >
            <h3>Alternatief locatievoorstel</h3>
            
            <b-alert variant="info" :show="true">
              Deze stap is bedoeld om een alternatieve locatie aan te dragen als CPO. Mocht niet de CPO maar de gemeente een alternatieve locatie aan willen dragen, dan moet dat in stap 1 gebeuren en niet in stap 3. Dit heeft namelijk consequenties voor de benodigde goedkeuringen.
            </b-alert>

            <MapDisconnectedLocationSelection   
              :record="record"
              :values="alternative"
              :locked="locked" 
              :disabled="disabled" />

          </div>
        </div>

        <div  class="DetailGrid__col3">
          <StepOneMisc v-if="advice === 'alternative'" :values="alternative" :disabled="locked || disabled" />

          <FileUpload 
            v-if="refreshFiles === false"
            class="mt-3"
            label="Boven aanzicht"
            :doctype="`TopView${advice === 'alternative' ? '-alt': ''}`"
            :uuid="uuid"
            :files="advice === 'alternative' ? (alternative.Docs.TopView || null) : (values.Docs.TopView || null)"
            :minFileId="minTopViewDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            :preview="true"
            :wide="true"
            @files="handleFileChanges" />
        </div>

        <div  class="DetailGrid__col4">
          <ChargingSituation v-if="advice === 'alternative'" :values="alternative" :disabled="locked || disabled" />

          <FileUpload 
            v-if="refreshFiles === false"
            class="mt-3"
            label="Zij aanzicht"
            :doctype="`SideView${advice === 'alternative' ? '-alt': ''}`"  
            :uuid="uuid"
            :files="advice === 'alternative' ? (alternative.Docs.SideView || null) : (values.Docs.SideView || null)"
            :minFileId="minSideViewDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            :preview="true"
            :wide="true"
            @files="handleFileChanges" />
        </div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import MapDisconnectedLocationSelection from '@/components/map/MapDisconnectedLocationSelection'
import ChargingSituation from '@/components/definitions/ChargingSituation'

import Form from '@/components/form/Form'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

// AMSTERDAM DEFINITIONS
import CPOAdditionalCosts from '@/components/definitions/amsterdam/CPOAdditionalCosts'
import CPOAdvice from '@/components/definitions/amsterdam/CPOAdvice'
import LocationPreparation from '@/components/definitions/amsterdam/LocationPreparation'
import StepOneMisc from '@/components/definitions/amsterdam/StepOneMisc'

import Location from '@/models/Location'

export default {
  name: 'RealisationStep3',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, FileUpload, CPOAdvice, CPOAdditionalCosts, LocationPreparation,
    MapDisconnectedLocationSelection, ChargingSituation, StepOneMisc,
    Comments
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 3,
      advice: null,
      refreshFiles: false
    }
  },
  computed: {
    /**
     * Load or generate the alternative location
     */
    alternative() {
      try {
        let Alternative = this.values.CPOAdvice.Alternative
        if (Alternative) {
          return new Location({ data: Alternative })
        } else {
          throw Error("not a valid location")
        }
      } catch(e) {
        // console.log("not a valid location")
        // Copy of step 1 data
        return this.record.values.Location.LocationCopy({ version: this.record.nextLocationVersion() })
      }
    },

    // Docs
    minCPOAdditionalDocsId() {
      try {
        return this.record.Docs.CPOAdditional.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },

    minTopViewDocsId() {
      try {
        let prop = 'TopView'

        let regCount = this.Docs && Array.isArray(this.Docs[prop])
          ? this.Docs[prop].reduce((result, doc) => Math.max(result, doc.id), 0)
          : 0
        let altCount = this.Docs && Array.isArray(this.Docs[`${prop}-alt`]) 
          ? this.Docs[`${prop}-alt`].reduce((result, doc) => Math.max(result, doc.id), 0)
          : 0
        let result = Math.max(regCount, altCount)
        
        // console.log("min-id", prop, result)
        return result
      } catch(e) {
        return 0
      }
    },
    minSideViewDocsId() {
      try {
        let prop = 'SideView'
        let regCount = this.Docs && Array.isArray(this.Docs[prop])
          ? this.Docs[prop].reduce((result, doc) => Math.max(result, doc.id), 0)
          : 0
        let altCount = this.Docs && Array.isArray(this.Docs[`${prop}-alt`]) 
          ? this.Docs[`${prop}-alt`].reduce((result, doc) => Math.max(result, doc.id), 0)
          : 0
        let result = Math.max(regCount, altCount)
        return result
      } catch(e) {
        return 0
      }
    },

    confirmed() {
      try {
        return !! this.values.CPOAdvice.Confirmed
      } catch(e) {
        return false
      }
    },
  },
  created() {
    if (this.hasRecord) {
      try {
        this.advice = this.values.CPOAdvice.CPOAdvice
      } catch(e) {
        // Expected to fail on the first run
      }
    }
  },
  methods: {

    handleAdviceChange({ value }) {
      this.advice = value
    },

    prepData({ data }) {

      // Add document numbers
      data.Docs = {
        CPOAdditional: (this.Docs.CPOAdditional || []).filter(file => file.progress === 100).map(file => file.id),
        TopView: (this.Docs[`TopView${this.advice === 'alternative' ? '-alt': ''}`] || []).filter(file => file.progress === 100).map(file => {
          return {
            id: file.id,
            ext: file.ext
          }
        }),
        SideView: (this.Docs[`SideView${this.advice === 'alternative' ? '-alt': ''}`] || []).filter(file => file.progress === 100).map(file => {
          return {
            id: file.id,
            ext: file.ext
          }
        }),
      }

      // Convert ParkingSpot Identifiers
      if (data.ParkingSpots) {
        data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers || []
        data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers.map(obj => obj.Identifier).filter(id => !!id)
      }

      // Try to redirect to step 5 if the advice is positive
      if (data.CPOAdvice.CPOAdvice === 'yes') {
        this.redirectToAfterSubmit = 5
      }

      return data
    },
    /**
     * NOTE! Due to a change in the specs, the municipality was locked out of being able to edit anything in this step
     */
    isValidData({ data }) {
      
      try {
        if (! ['yes', 'no', 'alternative'].includes(data.CPOAdvice.CPOAdvice)) {
          throw new Error()
        }
      } catch(e) {
        this.disabled = false
        this.feedback = 'danger'
        this.feedbackMessage = 'Het proces kan niet verder zonder advies.'
        return false
      }
      
      if (this.goingToNextStep) {
        try {
          if (
            data.LocationPreparation.RequiresLocationPreparation.includes('Overige') && 
            data.LocationPreparation.OtherReason === ''
          ) {
            this.feedbackMessage = 'Vul een korte omschrijving van de overige voorbereidingen in.'
            throw new Error("")
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          return false
        }

      }

      // this.disabled = false
      return true
    }
  },
}
</script>
