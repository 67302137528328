<template>
   <div class="MapLocationViewer">
    <b-aspect aspect="16:10">
      <MapBox 
        class="RealisationDetail__map"
        :accessToken="accessToken" 
        :mapStyle.sync="mapStyle"
        :options="mapOptions"
        @load="onMapLoaded">
    
        <LayerChargingpoints />
        <LayerRequests 
          :realisationUuid="record.uuid"
          @click="handleOpenRequestPopup" />
        <LayerRealisationProcesses 
          v-if="! isGORAL"
          :activeUuid="record.uuid"
          @click="handleOpenRealisationPopup" />

        <LayerLocationPicker 
          :locked="true"
          :useGeocoder="false" 
          :coordinates="coordinates" />
          
        <PopupRequest 
          :record="record"
          :uuid="requestUuid" 
          @close="handleOpenRequestPopup" />

        <PopupRealisationProcess
          :uuid="popupRealisationUuid" 
          @close="handleCloseRealisationProcessPopup" />

        <PopupChargingLocation />

      </MapBox>
    </b-aspect>
   </div>
</template>

<script>

import MapBox from '@/components/common/MapBox'
import LayerChargingpoints from '@/components/map/LayerChargingpoints'
import LayerRequests from '@/components/map/LayerRequests'
import LayerLocationPicker from '@/components/map/LayerLocationPicker'
import PopupRequest from '@/components/map/PopupRequest'
import LayerRealisationProcesses from '@/components/map/LayerRealisationProcesses'
import PopupRealisationProcess from '@/components/map/PopupRealisationProcess'
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { LegendControl } from '@/services/mapbox'
import { mapGetters } from 'vuex'

export default {
  name: 'MapLocationViewer',
  components: {
    MapBox, 
    LayerRequests, LayerChargingpoints, 
    LayerLocationPicker, 
    PopupRequest,
    LayerRealisationProcesses, PopupRealisationProcess, PopupChargingLocation
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * MapBox geocoder field instance
       */
      geocoder: null,

      /**
       * The input values of the Address fields
       */
      addressValues: null,

      /**
       * The UUID of the request pin that was clicked on.
       *  Passed on to the Request Popup 
       */
      requestUuid: null,

      /**
       * The realisation uuid of the popup
       */
      popupRealisationUuid: null
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    /**
     * The connected requests
     *  // list of uuid's
     */
    requests() {
      return []
    },

    mapOptions() {
      if (this.requests.length) {
        // TODO: Generate BBOX from request coordinates
        // var bbox = require('@turf/bbox')
        // bbox = bbox.default
        // bbox(feature) // any GEOJSON feature
        // 
        // let feature = { "type": "Feature",
        //   "geometry": {
        //   "type": "LineString",
        //   "coordinates": [
        //     [102.0, 0.0], [103.0, 1.0], [104.0, 0.0], [105.0, 1.0]
        //     ]
        //   }
        // }

        return {
          bbox: [
            4.728758231000029,
            52.27817457500004,
            5.068595450000032,
            52.43106396500008
          ]
        }
      } else {

        let center = this.coordinates ? [this.coordinates.lng, this.coordinates.lat] : [4.9041, 52.3676] 

        return {
          center,
          zoom: this.coordinates ? 14 : 11
        }
      }
    },

    /**
     * 
     */
    coordinates() {
      try {
        if (this.isGORAL) {
          let location = this.getChargingPointByUUID({
            uuid: this.addressValues.Location.chargingpointUuid
          })
          return location.data.address.coordinates
        }

        return {
          lng: parseFloat(this.addressValues.Location.Longitude),
          lat: parseFloat(this.addressValues.Location.Latitude)
        }
      } catch(e) {
        return false
      }
    },
    isGORAL() {
      return this.isCurrentTenant({ tenant: 'go-ral' })
    }
  },
  watch: {
    /**
     * Be on the look out for address changes triggered from the parent component
     */
    values() {
      this.addressValues = this.values
    }
  },
  created() {
    this.addressValues = this.values
  },
  methods: {
    onMapLoaded({ map, mapbox }) {
      this.loaded = true
      this.map = map
      this.mapbox = mapbox

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false,
        
        // Amsterdam
        // bbox: [
        //   4.728758231000029,
        //   52.27817457500004,
        //   5.068595450000032,
        //   52.43106396500008
        // ]
      })

      // this.geocoder.on('result', this.onGeocoderResult)
      this.map.addControl(
        this.geocoder
      )

      let legend = new LegendControl({ 
        items: [{
          label: 'Bestaande laadpaal',
          image: 'chargingpoint-realized'
        }, {
          label: 'Voorgestelde laadpaal',
          image: 'chargingpoint-suggestion'
        }, {
          label: 'In voorbereiding',
          image: 'chargingpoint-in-progress'
        },{
          label: 'Onbeoordeelde aanvraag',
          image: 'request-open'
        }, {
          label: 'Geaccepteerde aanvraag',
          image: 'request-accepted'
        }, {
          label: 'Gekoppeld aan ander proces',
          image: 'request-connected'
        }, {
          label: 'Gekoppeld aan dit proces',
          image: 'request-selected'
        }]
      })
      this.map.addControl(
        legend
      )
    },

    /**
     * 
     */
    handleOpenRequestPopup({ uuid }) {
      this.requestUuid = uuid
    },

    handleOpenRealisationPopup({ uuid }) {
      this.popupRealisationUuid = uuid
    },
    handleCloseRealisationProcessPopup() {
      this.popupRealisationUuid = null
    }
  }
}
</script>

<style>

</style>