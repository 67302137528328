<template>
  <div v-if="!isOpen && isAccepted">
    <div v-if="!realisationUuid && canStartRealisationProcess">
      <b-button variant="primary" @click="handleStartRealisationProcess">
        Start een realisatieproces
      </b-button>
    </div>
    <div v-else-if="realisationUuid && canAccessRealisationProcesses">
      <b-button variant="primary" @click="handleOpenRealisationProcess">
        Naar het realisatieproces
      </b-button>
    </div>
  </div>
</template>

<script>

const uuid = require('uuid')

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationLink',
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('requests', [
      'statusOptionById'
    ]),
    ...mapGetters('relations', [
      'realisationUuidByRequestUuid'
    ]),
    ...mapGetters('user', [
      'canAccessRealisationProcesses',
      'canStartRealisationProcess'
    ]),
    uuid() {
      return this.$route.params.uuid
    },
    status() {
      return this.statusOptionById({ id: this.request.status })
    },
    isOpen() {
      return this.status.id === 2
    },
    isAccepted() {
      return this.status.id === 3
    },
    realisationUuid() {
      return this.realisationUuidByRequestUuid({ uuid: this.uuid })
    },
    /**
     * Whether the loaded request is lacking coordinates
     */
    hasCoordinates() {
      return this.request && this.request.coordinates && this.request.coordinates.lat
    },
  },
  methods: {

    handleStartRealisationProcess() {
      let query = {
        aanvragen: this.uuid
      }
      if (this.hasCoordinates) {
        query.lat = this.request.coordinates.lat
        query.lng = this.request.coordinates.lng
      }

      this.$router.push({
        name: 'Realisation',
        params: {
          uuid: uuid.v4() // generate a v4 uuid for the new record
        },
        query
      })
    },
    handleOpenRealisationProcess() {
      this.$router.push({
        name: 'Realisation',
        params: {
          uuid: this.realisationUuid 
        }
      })
    }
  }
}
</script>

<style>

</style>