

import { municipalityOptions } from '@/config'

/**
 * Realisation
 */
let Realisation = function ({
  ref, data
}) {

  data = this.commonStructure({ ref, data })
  data.tenant = 'go-ral'

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Realisation.prototype.generateMunicipalityData = ({ data }) => {
  return data.Municipality || null
}

Realisation.prototype.generateAddressData = function({ model }) {
  const CurrentLocation = model.CurrentLocation

  if (CurrentLocation.Location && CurrentLocation.Location.chargingpointUuid) {
    let location = this.$store.getters['chargingpoints/getChargingPointByUUID']({ 
      uuid: CurrentLocation.Location.chargingpointUuid
    })
    if (! location) return ''

    location = location.data.address
    let number = (`${location.number || ''} ${location.suffix || ''}`).trim()
    return (`${location.street || ''} ${number}, ${location.city || ''}`).trim().trim(',')
  }

  return ''
}

Realisation.prototype.generateSearchData = ({ model, data }) => {

  let municipalityName = ''
  if (data.Municipality) {
    let municipalityOption = municipalityOptions.find(option => option.value === data.Municipality)
    if (municipalityOption) {
      municipalityName = municipalityOption.text
    }
  }

  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName: '',
    Municipality: municipalityName
  }
}


export default Realisation;