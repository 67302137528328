<template>
  <div class="TableControls mb-3">
    <div class="TableControls__header">
      <component 
        v-if="componentName"
        :is="componentName"
        class="TableControls__permanent" />
      <div v-else class="TableControls__permanent"></div>
      <div 
        v-if="hasAnyTabs" 
        class="TableControls__icons">
          <template v-for="(t, index) in tabs">
            <div v-if="t.allowed" :key="index" :class="{ 'active': tab === t.label }" @click="setTab(t.label)">
              <div v-if="!t.noLabel" v-text="t.label" />
              <b-icon :icon="t.icon" />
            </div>
          </template>
      </div>
    </div>
    <div v-if="tab" class="TableControls__main">
      <component :is="`Table${tab}`" :config="config" :class="`TableControls__${tab}`" />
    </div>
  </div>
</template>

<script>
import {
  loadAllComponentsFromFolder
} from './utils.js'
import TableExport from './TableExport'
import TableFilters from './TableFilters'
import TableSettings from './TableSettings'
import TableState from './mixins/TableState'
import { mapGetters } from 'vuex'

export default {
  name: 'TableControls',

  mixins: [ TableState ],

  components: {
    TableFilters, 
    TableSettings, 
    TableExport,
    ...loadAllComponentsFromFolder({
      ComponentContext: require.context('@/components/Table/controls', true, /\.vue$/i)
    })
  },
  data() {
    return {
      // Active tab
      tab: null
    }
  },
  computed: {
    ...mapGetters('user', ['canAccessExport']),
    /**
     * Does the active layout have filters configured
     */
    hasFilterConfig() {
      let activeLayout = (this.layout.filters || {})
      let filtersWithComponents = (activeLayout.filters || [])
        .map(property => this.config.filters[property] || {})
        .filter(filter => filter.componentName !== null)
      return filtersWithComponents.length !== 0
    },

    /**
     * The only setting right now, is the selection of configurations
     */
    hasSettings() {
      return this.config.settings !== false
    },
    hasExport () {
      return !!this.config.export && this.canAccessExport
    },
    componentName() {
      return this.config.controlsComponentName || false
    },
    tabs() {
      return [
        { label: 'Export', icon: 'download', allowed: this.hasExport },
        { label: 'Filters', icon: 'filter', allowed: this.hasFilterConfig },
        { label: 'Settings', icon: 'gear', allowed: this.hasSettings, noLabel: true }
      ]
    },
    hasAnyTabs() {
      return this.tabs.some(t => t.allowed)
    }
  },
  methods: {
    setTab(tab) {
      this.tab = this.tab !== tab ? tab : null;
    }
  }
}
</script>

<style lang="scss">
.TableControls {

  &__header {
    display: flex;
    // border-bottom: 1px solid $gray-300;
    padding-bottom: 3px;
  }

  &__permanent {
    flex-grow: 1;
  }
  &__icons {
    flex-shrink: 0;
    padding-left: 12px;
    display: flex;
    align-self: flex-end;
    min-height: 24px;

    > div {
      display: flex;
      margin-right: 0.3em;
      padding: 0 0.5em;
      position: relative;
      cursor: pointer;  
      user-select: none;
      align-items: center;

      svg {
        position: relative;
      }
      
      &:before {
        top: -10%;
        left: 0;
        position: absolute;
        width: 100%;
        height: 126%;
        border-top-left-radius: 0.25rem;
        border-top-right-radius: 0.25rem;
        border: 1px solid $gray-300;
        // border-bottom: none;
        border-radius: 0.25rem;
      }

      &:hover:before {
        content: ''
      }

      &.active:before {
        content: '';
        background: $gray-200;
        border-bottom: 0;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }

      &:hover {
        color: #00B46B;
      }
      div {
        position: relative;
        z-index: 9;
        margin-right: 0.5em;
      }
      &:last-child {
        margin-right: 0;
      }
    }    
  }

  &__main {
    background: $gray-200;
    padding: 0.5rem;
    border-bottom-left-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
    display: flex;
    justify-content: flex-end;
  }

  &__Export {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0.5em 0;
    .btn {
      min-width: 9em;
      margin-left: 0.6em;
    }
  }

  &__Export, &__Settings {
    display: flex;
    justify-content: flex-end;
  }

  &__Export {
    align-items: center;
    padding: 0.5em 0;
    .btn {
      min-width: 9em;
      margin-left: 0.6em;
    }
  }

}
</style>