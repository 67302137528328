<script>

import {
  municipalityOptions
} from '@/config'

import SingleSelectFilter from './SingleSelectFilter.vue'

export default {
  name: 'MunicipalityFilter',
  mixins: [ SingleSelectFilter ],
  computed: {
    options() {
      return municipalityOptions
        .reduce(( options, option ) => {
          option.disabled = false
          // option.value = option.text
          options.push(option)
          return options
        }, [{ value: 'Onbekend', text: 'Onbekend' }])
    }
  }
}
</script>