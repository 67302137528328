<script>
import { AssetManagers } from '@/config'

import SingleSelectFilter from './SingleSelectFilter.vue'

export default {
  name: 'RVBAssetManagerFilter', 
  mixins: [ SingleSelectFilter ],
  computed: {
    options() {
      return AssetManagers.map(option => {
        return {
          value: option,
          text: option
        }
      })
    }
  }
}
</script>