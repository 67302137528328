<template>
  <div class="MapLocationSelection">
    <b-aspect aspect="16:10">
      <MapBox 
        class="RealisationDetail__map"
        :accessToken="accessToken" 
        :mapStyle.sync="mapStyle"
        :options="mapOptions"
        @load="onMapLoaded">
    
        <LayerChargingpoints />
        <LayerRequests 
          :realisationUuid="record.uuid"
          @click="handleOpenRequestPopup" />
        <LayerRealisationProcesses
          :activeUuid="record.uuid" 
          @click="handleOpenRealisationPopup" />

        <LayerLocationPicker 
          :useGeocoder="true" 
          :coordinates="coordinates"
          :locked="locked"
          @location="handleLocationPicker" />

        <PopupRequest
          :record="record"
          :uuid="requestUuid" 
          @close="handleOpenRequestPopup" />

        <PopupRealisationProcess
          :uuid="popupRealisationUuid" 
          @close="handleCloseRealisationProcessPopup" />

        <PopupChargingLocation />

      </MapBox>
    </b-aspect>
    <div class="AddressWrapper d-flex mt-3 w-100">
      <div class="AddressWrapper__instruction mt-3">
        <h4>Pin &amp; adres invoer</h4>
        <p>
          De plaatsing van de pin en het invoeren van het adres zijn geheel gescheiden.
        </p>
        <p>
          Bij het verschuiven van de pin verschijnt wel een adres suggestie welke naar wens overgenomen kan worden in de adres invoer.  
        </p>
      </div>
      <div class="AddressWrapper__data">
        <div v-if="geocoderAddress" class="d-flex flex-column mt-3 w-100">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <strong>Pin - Adres suggestie ter overname</strong>
            <b-button
              v-if="revGeocoderComponents"
              class="ml-2 flex-shrink-0"
              size="sm"
              @click="handleUseGeocoderAddress">
              Overnemen
              <b-icon icon="chevron-down"></b-icon>
            </b-button>
          </div>
          <div class="d-flex flex-grow-1 mb-2">
            <div class="flex-grow-1">
              {{ geocoderAddress }}
            </div>
          </div>
        </div>
        <div class="d-flex flex-column mt-3 w-100">
          <div class="mr-2">
            <strong>Pin - Coordinaten</strong>
          </div>
        </div>
        <Address  
          :values="addressValues" 
          :disabled="locked || disabled" 
          @address="handleAddressUpdate"
          @coordinates="handleAddressCoordinates" />
      </div>
    </div>
  </div>
</template>

<script>

import MapBox from '@/components/common/MapBox'
import LayerChargingpoints from '@/components/map/LayerChargingpoints'
import LayerRequests from '@/components/map/LayerRequests'
import LayerLocationPicker from '@/components/map/LayerLocationPicker'
import PopupRequest from '@/components/map/PopupRequest'

import LayerRealisationProcesses from '@/components/map/LayerRealisationProcesses'
import PopupRealisationProcess from '@/components/map/PopupRealisationProcess'
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue'

import { formatPostalCode } from '@/helpers/string'

import Address from '@/components/definitions/Address'

import { TextControl, LegendControl } from '@/services/mapbox'

import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

export default {
  name: 'MapDisconnectedLocationSelection',
  components: {
    MapBox, 
    LayerRequests, LayerChargingpoints, LayerLocationPicker, PopupRequest,
    Address,
    LayerRealisationProcesses, PopupRealisationProcess, PopupChargingLocation
  },
  props: {
    record: {
      type: Object,
      required: true
    },
    values: {
      type: Object,
      required: true
    },
    locked: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * MapBox geocoder field instance
       */
      geocoder: null,

      /**
       * The Google Reverse Geocoder output (based on dropped pin / coordinate input fields)
       */
      revGeocoderComponents: null,
      revGeocoderCoordinates: null,

      /**
       * The Google Geocoder output (based on address input fields)
       */

      /**
       * The input values of the Address fields
       */
      addressValues: null,

      /**
       * The UUID of the request pin that was clicked on.
       *  Passed on to the Request Popup 
       */
      requestUuid: null,

      /**
       * The CTRL instruction 
       */
      instructionMapControl: null,

      /**
       * realisation uuid of the realisation popup
       */
      popupRealisationUuid: null
    }
  },
  computed: {
    /**
     * The connected requests
     *  // list of uuid's
     */
    requests() {
      return []
    },

    mapOptions() {
      if (this.requests.length) {
        return {
          // bbox: [
          //   4.728758231000029,
          //   52.27817457500004,
          //   5.068595450000032,
          //   52.43106396500008
          // ]
        }
      } else {
        let center = [4.9041, 52.3676]
        let focus = false

        if (this.coordinates) {
          focus = true
          center = [this.coordinates.lng, this.coordinates.lat]
        } else if (this.$route.query.lat && this.$route.query.lng) {
          focus = true
          center = [this.$route.query.lng, this.$route.query.lat]
        }
        
        return {
          center,
          zoom: focus ? 14 : 11
        }
      }
    },

    /**
     * 
     */
    coordinates() {
      if (this.revGeocoderCoordinates) {
        return {
          lng: this.revGeocoderCoordinates[0],
          lat: this.revGeocoderCoordinates[1]
        }
      }
      else if (this.addressValues && this.addressValues.Location && this.addressValues.Location.Latitude && this.addressValues.Location.Longitude) {
        return {
          lng: parseFloat(this.addressValues.Location.Longitude),
          lat: parseFloat(this.addressValues.Location.Latitude)
        }
      } else {
        return null
      }
    },

    /**
     * 
     */
    geocoderAddress() {
      if (this.revGeocoderComponents === false) return 'De pin locatie leverde geen adres op.'

      if (! this.revGeocoderComponents) return ''
      let address = this.revGeocoderComponents

      return `${address.street}${address.street_number ? ' '+address.street_number : ''}, ${formatPostalCode(address.postalCode)} ${address.city}`
    }
  },
  watch: {
    record() {
      // Reset geocoder stuff
      this.revGeocoderComponents = null
      this.revGeocoderCoordinates = null
    },
    /**
     * Be on the look out for address changes triggered from the parent component
     */
    values() {
      this.addressValues = this.values
      this.revGeocoderCoordinates = null
      this.revGeocoderComponents = null
    },
    locked() {
      if (this.instructionMapControl == null || ! this.map) return
        try {
        if (this.loaded && this.map.hasControl(this.instructionMapControl)) {
          this.map.removeControl(
            this.instructionMapControl
          )
        } else {
          this.map.addControl(
            this.instructionMapControl
          )
        }
      } catch(e) {
        // 
      }
    }
  },
  created() {
    this.addressValues = this.values
  },
  methods: {
    onMapLoaded({ map, mapbox }) {
      this.loaded = true
      this.map = map
      this.mapbox = mapbox

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false,
        
        // Amsterdam
        // bbox: [
        //   4.728758231000029,
        //   52.27817457500004,
        //   5.068595450000032,
        //   52.43106396500008
        // ]
      })

      // this.geocoder.on('result', this.onGeocoderResult)
      this.map.addControl(
        this.geocoder
      )

      this.instructionMapControl = new TextControl({ label: 'Houd CTRL vast om de pin te (ver)plaatsen.' })
      if (! this.locked) {
        this.map.addControl(
          this.instructionMapControl
        )
      }

      let legend = new LegendControl({ 
        items: [{
          label: 'Bestaande laadpaal',
          image: 'chargingpoint-realized'
        }, {
          label: 'Onbeoordeelde aanvraag',
          image: 'request-open'
        }, {
          label: 'Geaccepteerde aanvraag',
          image: 'request-accepted'
        }, {
          label: 'Gekoppeld aan ander proces',
          image: 'request-connected'
        }, {
          label: 'Gekoppeld aan dit proces',
          image: 'request-selected'
        }]
      })
      this.map.addControl(
        legend
      )
    },

    /**
     * When the user places the location pin
     */
    handleLocationPicker({ coordinates, components }) {
      this.revGeocoderComponents = components
      this.revGeocoderCoordinates = coordinates

      // console.log(coordinates, components)

      // Immediately update the coordinate fields
      this.addressValues.Location.Longitude = coordinates[0]
      this.addressValues.Location.Latitude = coordinates[1]
    },
    
    /**
     * When the user changes the coordinates in the Latitude / Longitude input fields
     */
    handleAddressCoordinates({ lng, lat }) {
      this.revGeocoderCoordinates = [lng, lat]
    },

    /**
     * Handle a user initiated change in one of the address fields
     */
    handleAddressUpdate: async function({
      StreetName,
      HouseNumber,
      HouseNumberSuffix,
      PostalCode,
      CityName
    }) {
      // Check if anything has changed
      if (
        this.addressValues.Location.StreetName !== StreetName || 
        this.addressValues.Location.HouseNumber !== HouseNumber ||
        this.addressValues.Location.HouseNumberSuffix !== HouseNumberSuffix ||
        this.addressValues.Location.PostalCode !== PostalCode ||
        this.addressValues.Location.CityName !== CityName
      ) {
        // Update the address
        this.addressValues.Location.StreetName = StreetName || ''
        this.addressValues.Location.HouseNumber = HouseNumber || ''
        this.addressValues.Location.HouseNumberSuffix = HouseNumberSuffix || ''
        this.addressValues.Location.PostalCode = formatPostalCode(PostalCode || '')
        this.addressValues.Location.CityName = CityName || ''
      }
    },

    /**
     * 
     */
    handleOpenRequestPopup({ uuid }) {
      this.requestUuid = uuid
    },

    /**
     * https://stackoverflow.com/a/59368102
     */
    splitNumber(str) {
      const [, ...arr] = str.match(/(\d*)([\s\S]*)/)
      return arr
    },
    handleUseGeocoderAddress() {
      let address = this.revGeocoderComponents

      let numberAndSuffix = this.splitNumber(address.street_number || '')

      this.handleAddressUpdate({
        StreetName: address.street,
        HouseNumber: numberAndSuffix[0] || '', 
        HouseNumberSuffix: (numberAndSuffix[1] || '').trim(),
        PostalCode: formatPostalCode(address.postalCode),
        CityName: address.city
      })
    },

    handleOpenRealisationPopup({ uuid }) {
      this.popupRealisationUuid = uuid
    },
    handleCloseRealisationProcessPopup() {
      this.popupRealisationUuid = null
    }
  }
}
</script>

<style lang="scss">
.AddressWrapper {
  &__instruction {
    width: 30%;
    padding: 1rem;
    background: rgba(0, 0, 0, 0.05); // lighten($info, 40%);
  }
  &__data {
    margin-left: 2rem;
    width: calc(70% - 2rem)
  }
}
</style>