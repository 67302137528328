<template>
  <div class="PageSize">
    <label for="pageSize">Regels per pagina</label>
    <b-form-select 
      id="pageSize" 
      v-model="pageSize" 
      :disabled="pageSizeOptions.length === 1"
      :options="pageSizeOptions" />
  </div>
</template>

<script>
import TableState from '../mixins/TableState.vue'

export default {
  mixins: [ TableState ],
  computed: {
    pageSizeOptions() {
      if (Array.isArray(this.config.pageSizeOptions)) {
        return this.config.pageSizeOptions
      } 
      return [10, 20, 50, 100]
    },
    pageSize: {
      get() {
        return this.settings.pageSize
      },
      set(pageSize) {
        this.setPageSize({ pageSize })
      }
    }
  }
}
</script>

<style>
.PageSize {
  grid-area: pageSize;
}
</style>