


const uuid = require('uuid');

export const uuidValidateV4 = ({ id }) => {
  return uuid.validate(id) && uuid.version(id) === 4;
}

export const isNumeric = (string) => /^\d+$/.test(string)
export const isFloat = value => parseFloat(value) === value
export const isFloatString = value => parseFloat(value)+'' === value
export const isString = (string) => (typeof string === 'string' || string instanceof String)
export const isObject = (item) => (item && typeof item === 'object' && !Array.isArray(item));
export const isBoolean = (bool) => bool === true || bool === false
// Also allow space and dash
export const isAlphanumericIsh = (string) => /^[a-z0-9 -]+$/i.test(string)
// Simple, based on known exceptions
export const isDutchPostalcode = (string) => /^[1-9][0-9]{3} ?(?!SA|SD|SS)[A-Z]{2}$/i.test(string)
// Simple, based on generic number plate structure
export const isDutchNumberplate = (string) => /^([a-z]{1,3}|[\d]{1,3})-?([a-z]{1,3}|[\d]{1,3})-?([a-z]{1,3}|[\d]{1,3})$/i.test(string)
// https://regexr.com/3aevr - slightly modified
export const isDutchPhoneNumber = (string) => /^((\+|00(\s|\s?-\s?)?)31(\s|\s?-\s?)?(\(0\)[-\s]?)?|0)[1-9]((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/.test(string)
// https://emailregex.com - slightly modified 
export const isEmail = (string) => /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x21\x23-\x5b\x5d-\x7f]|\\[\x5d-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x21-\x5a\x53-\x7f]|\\[\x5d-\x7f])+)\])/.test(string)
// https://dev.to/calvinpak/simple-url-validation-with-javascript-4oj5 - no IE support
export const isUrl = (url) => { try { new URL(url); } catch (e) { return false; } return true; }

export const isLatitude = (string) => parseFloat(string) <= 90 && parseFloat(string) >= -90
export const isLongitude = (string) => parseFloat(string) <= 180 && parseFloat(string) >= -180

export const unicodeString = (string) => /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@+!?:;/ ])+$/.test(string)
export const multilineUnicodeString = (string) => /^([\u00c0-\u01ffa-zA-Z0-9'\-,._@+!?:;/ \r\n])+$/.test(string)

/**
 * Alphanumeric + underscore
 */
export const isAplhanumericUnderscore = (string) => /^[a-z0-9_]+$/i.test(string)


/**
 * Auth0 ids
 *  For now just accept a-z0-9|
 */
export const isAuthZeroId = (string) => /^[a-z0-9|]+$/i.test(string)