<template>
  <b-button 
    v-if="btnRequirementsMet" 
    :disabled="busy" 
    variant="primary" 
    class="w-100" 
    @click="handleDownload">
    Download een gegenereerd startdocument
  </b-button>
</template>

<script>
import { saveAs } from 'file-saver';
import { generateDoc } from '@/services/processdoc'

import { mapGetters } from 'vuex'

export default {
  name: 'DocDownload',
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      busy: false
    }
  },
  computed: {
    ...mapGetters('requests', [
      'requestByUuid'
    ]),
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('relations', [
      'requestUuidsByRealisationUuid'
    ]),
    ...mapGetters('user', [
      'canGenerateStartDoc',
      'canAccessRequests',
      'canAccessRequestLocations'
    ]),
    record() {
      return this.recordByUuid({
        uuid: this.uuid
      })
    },
    location() {
      return this.record.values.Location
    },
    Docs() {
      try {
        return this.location.Docs || {}
      } catch(e) {
        return {}
      }
    },

    /**
     * Whether all required input to generate a document is available
     */
    btnRequirementsMet() {
      return this.canGenerateStartDoc && (this.Docs.TopView || []).length && (this.Docs.SideView || []).length
    },


    /**
     * The related requests UUIDS
     *  Simply return an empty array if the user has no access to request information
     */
    requestUuids() {
      return ( this.canAccessRequests || this.canAccessRequestLocations )
        ? this.requestUuidsByRealisationUuid({ uuid: this.record.uuid }) 
        : []
    },

    /**
     * The related request objects
     */
    connectedRequests() {
      return this.requestUuids.slice().map(uuid => {
        return this.requestByUuid({ uuid })
      })
    },
  },
  methods: {
    async handleDownload() {
      this.busy = true

      try {
        const token = await this.$auth.getTokenSilently();
        let blob = await generateDoc({ 
          record: this.record, 
          connectedRequests: this.connectedRequests, 
          token 
        })
        
        saveAs(blob, `startdocument - ${this.record.case_ref}.docx`);
        // console.log("Document created successfully");

        this.busy = false

      } catch (e) {
        // console.log(e)
        this.busy = false
      }
    }
  }
}
</script>