<template>
  <div class="VisitBox">
      <h3>
        {{ label }}
      </h3>
      <div class="VisitBox__body">
        <div class="VisitBox__highlighted">
          <FieldSet 
            label=""
            :values="internalValues"
            :fields="fields"
            :validation="validation"
            :path="`${path}.Invite`"
            :layout="layout"
            :disabled="disabled" 
            :viewer="viewer"
          />
        </div>

        <!-- inline repeater -->
        <PeopleToVisit 
          :values="values" 
          :path="`${path}.PeopleToVisit`" 
          :disabled="disabled" 
          :viewer="viewer" />

      </div>
    </div>
</template>

<script>
import PeopleToVisit from '@/components/definitions/rvb/PeopleToVisit'
import FieldSet from '@/components/form/FieldSet'
import { required } from 'vuelidate/lib/validators'
import { deepClone } from '@/helpers/object'

export default {
  name: 'VisitBox',
  components: { PeopleToVisit, FieldSet },
  props: {
    label: {
      type: String,
      default: 'Plan een datum'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Progress'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: {
        "date": {
          "type": "string",
          "format": "date", // "date-time" // TODO: We can't currently combine date & time in 1 field type
          "title": "Datum"
        },
        "start": {
          "type": "string",
          "format": "time", // "date-time" // TODO: We can't currently combine date & time in 1 field type
          "title": "Begintijd"
        },
        "end": {
          "type": "string",
          "format": "time", // "date-time" // TODO: We can't currently combine date & time in 1 field type
          "title": "Eindtijd"
        }
      },
      layout: [{
        "columns": '1fr 1fr 1fr',
        "fields": [ 'date', 'start', 'end' ]
      }, {
        "columns": '1fr',
        "fields": [ 'subject' ]
      }],
      validation: {
        "date": {
          required,
        },
        "start": {
          required,
        },
        "end": {
          required,
        },
      },
      internalValues: {}
    }
  },
  watch: {
    values() {
      this.mapValues()
    }
  },
  created() {
    this.mapValues()
  },
  methods: {
    mapValues() {
      let date = null
      let start = null
      let end = null
      let segments = null

      try {
        start = this.values.Progress.Invite.start
      } catch(e) { 
        // 
      }
      try {
        end = this.values.Progress.Invite.end
      } catch(e) { 
        // 
      }

      let data = deepClone(this.values)

      data.Progress = data.Progress || {}
      data.Progress.Invite = data.Progress.Invite || {}

      if (start && start.includes('T')) {
        segments = start.split('T')
        if (segments.length !== 1) {
          date = segments[0]
          segments = segments[1].split('.')
          start = segments[0]

          data.Progress.Invite.date = date
          data.Progress.Invite.start = start
        }
      }
      if (end && end.includes('T')) {
        segments = end.split('T')
        if (segments.length !== 1) {
          date = date === null ? segments[0] : date
          segments = segments[1].split('.')
          end = segments[0]

          data.Progress.Invite.date = date
          data.Progress.Invite.end = end
        }
      }

      this.internalValues = data
    }
  }
}
</script>



<style lang="scss">
.VisitBox {

  > h3 {
    margin-top: $padding;
    margin-bottom: $padding-sm;
  }
  
  &__body {
    border-radius: 3px;
    border: 1px solid $gray-400;
    display: block;
    margin-bottom: $padding;
    max-width: 100%;
    width: 100%;

    padding: $padding;
  }

  &__highlighted {
    border-radius: 3px;
    padding: $padding-sm;
    background-color: $gray-200;
    margin-bottom: $padding;
  }
  
}
</style>