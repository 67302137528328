<template>
  <b-card v-if="record && canChangeStatus && !completed" class="RealisationStatusPanel">
    <h4>Status wijzigen</h4>

    <p v-if="cancelled">
      Het proces is momenteel geannuleerd
    </p>
    <p v-if="onhold">
      Het proces staat momenteel on hold
    </p>
    <p v-if="locationUnavailable">
      Herstel is niet meer mogelijk omdat de locatie inmiddels aan een ander proces is gekoppeld.
    </p>

    <div class="d-flex justify-content-between align-items-end">
      <b-button :disabled="busy || cancelled" :variant="action === 'onhold' ? 'warning' : 'outline-warning'" class="mr-2" @click="setAction({ action: onhold ? 'resume' : 'onhold' })">
        {{ onhold ? 'Hervat' : 'Zet on hold' }}
      </b-button>
      <span v-if="action">Toelichting</span>
      <b-button :disabled="busy || locationUnavailable" :variant="action === 'cancel' ? 'danger' : 'outline-danger'" @click="setAction({ action: cancelled ? 'restore' : 'cancel' })">
        {{ cancelled ? 'Herstel' : 'Annuleer' }}
      </b-button>
    </div>
    
    <Feedback class="mt-2" :feedback="feedback" />

    <transition>
      <div v-if="action">
        <div v-if="['onhold', 'cancel'].includes(action)">
          <b-select :disabled="busy" :options="reasonOptions" v-model="reason" class="mt-2"></b-select>
          <b-textarea :disabled="busy" v-model="comment" class="mt-2"></b-textarea>
        </div>
        <b-button :disabled="busy" variant="dark" class="w-100 mt-2" @click="handleConfirm">{{ actionConfirmLabel }}</b-button>
      </div>
    </transition>
  </b-card>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import Feedback from '@/components/form/Feedback.vue'

import { onHoldReasons, cancelReasons } from '@/config'
import { multilineUnicodeString } from '@/services/validation'

export default {
  components: {
    Feedback,
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      busy: false,

      action: null,
      actionReason: null,
      comment: '',

      onHoldReasons,
      cancelReasons,
      feedback: {
        show: false,
        message: ''
      }
    }
  },
  computed: {
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('user', [
      'canChangeStatus'
    ]),
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),

    record() {
      return this.recordByUuid({
        uuid: this.uuid
      })
    },

    locationUnavailable() {
      try {
        // This check is only relevant for GO-RAL
        if (! this.isCurrentTenant({ tenant: 'go-ral' })) {
          return false
        }
        if (! this.cancelled) {
          return false
        }

        let location = this.getChargingPointByUUID({
          uuid: this.record.CurrentLocation.Location.chargingpointUuid
        })

        return location.data.properties.status !== 'definitive'
      } catch(e) {
        return true
      }
    },

    completed() {
      try {
        return !! this.record.status.completed
      } catch(e) {
        return false
      }
    },
    onhold() {
      try {
        return !! this.record.status.onhold
      } catch(e) {
        return false
      }
    },
    cancelled() {
      try {
        return !! this.record.status.cancelled
      } catch(e) {
        return false
      }
    },

    cancelReason() {
      try {
        return this.actionReason ? this.actionReason : this.record.statusMeta.cancelled.reason
      } catch(e) {
        return null
      }
    },
    onholdReason() {
      try {
        return this.actionReason ? this.actionReason : this.record.statusMeta.onhold.reason
      } catch(e) {
        return null
      }
    },

    reason: {
      get() {
        return this.action === 'cancel' ? this.cancelReason : this.onholdReason
      },
      set(value) {
        this.actionReason = value
      }
    },
    reasonOptions() {
      return this.action === 'cancel' ? this.cancelReasons : this.onHoldReasons
    },

    actionConfirmLabel() {
      switch(true) {
        case this.action === 'restore':
          return 'Herstel het proces'
        case this.action === 'cancel':
          return 'Annuleer het proces'
        case this.action === 'resume':
          return 'Hervat het proces'
        case this.action === 'onhold':
          return 'Zet on hold'
      }

      return 'Bevestigen'
    }

  },
  watch: {
    action() {
      this.reason = ''
      this.comment = ''
    }
  },
  methods: {
    ...mapMutations('realisation', [
      'replaceRecord'
    ]),
    ...mapMutations('chargingpoints', [
      'replaceLocation'
    ]),
    setAction({ action }) {
      this.action = this.action === action ? null : action
    },
    handleConfirm: async function() {
      try {
        const ref = this.record.ref || false

        if (! ref) return

        this.busy = true

        // TODO: Validation of reason & comment when relevant (e.g. action = onhold || cancel)
        if (['onhold', 'cancel'].includes(this.action)) {
          if (! this.reason) {
            this.feedback = {
              show: true,
              variant: 'danger',
              message: 'Er moet een reden geselecteerd worden'
            }
            this.busy = false
            return;
          }

          if (
            (this.action === 'onhold' && ! this.onHoldReasons.map(reason => reason.value).includes(this.reason))
            || (this.action === 'cancel' && ! this.cancelReasons.map(reason => reason.value).includes(this.reason))
          ){
            this.feedback = {
              show: true,
              variant: 'danger',
              message: 'Er moet een geldige reden geselecteerd worden'
            }
            this.busy = false
            return;
          }

          if (this.comment !== '') {
            if (this.comment.length > 300) {
              this.feedback = {
                show: true,
                variant: 'danger',
                message: 'De reden omschrijving mag maximaal 300 karakters lang zijn'
              }
              this.busy = false
              return;
            }
            if (! multilineUnicodeString(this.comment)) {
              this.feedback = {
                show: true,
                variant: 'danger',
                message: 'De reden omschrijving bevat karakters welke om veiligheidsredenen niet geaccepteerd worden.'
              }
              this.busy = false
              return;
            }
          }
        }

        let data = {
          step: 'status',
          action: this.action,
          reason: this.reason,
          comment: this.comment
        }

        // Make the call
        const token = await this.$auth.getTokenSilently();
        const api = await fetch('/api/realisation_status', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ref,
            data
          })
        })
        if (!api.ok) {

          // console.log(api)
          throw new Error('Unable to update the status')
        }
        const response = await api.json()

        this.feedback = {
          show: true,
          variant: 'success',
          message: 'De status wijziging is opgeslagen'
        }

        this.replaceRecord({
          record: response.data.record
        })

        if (Array.isArray(response.data.locations)) {
          response.data.locations.forEach(location => {
            this.replaceLocation({ location })
          })
        }

        // 
        this.action = null
        this.busy = false
      } catch(e) {
        console.log(e)
        
        this.busy = false

        this.feedback = {
          show: true,
          variant: 'danger',
          message: 'De status wijziging is niet opgeslagen'
        }
      }
    }
  }
}
</script>

<style lang="scss">
.RealisationStatusPanel {
  h4, p {
    text-align: center;
  }
}
</style>