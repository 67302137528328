<template>
  <div 
    class="TableFilters"
    :style="filterStyle">
    <div 
      v-for="(filter,index) in filtersWithComponents" 
      :key="`filter_${index}`"
      :style="{'grid-area' : filter.id }">
      <component 
        :filterConfig="filter"
        v-bind:is="filter.componentName" />
    </div>
  </div>
</template>

<script>

import {
  loadAllComponentsFromFolder
} from './utils.js'

import TableState from './mixins/TableState.vue'

export default {
  name: 'TableFilters',

  mixins: [ TableState ],

  components: loadAllComponentsFromFolder({
    ComponentContext: require.context('@/components/Table/filters', true, /\.vue$/i)
  }),

  computed: {
    
    filtersWithComponents() {
      return this.filterLayout.filters
        .map(property => this.config.filters[property] || {})
        .filter(filter => filter.componentName !== null)
    },

    /**************************************************************************
     * Layout
     */

    /**
     * @type {object}
     */
    filterLayout() {
      return Object.assign({
        columns: 'auto',
        area: '',
        gap: '8px'
      }, (this.layout.filters || {}))
    },

    /**
     * @type {object}
     */
    filterStyle() {
      return {
        'grid-template-columns': this.filterLayout.columns,
        'grid-template-areas': this.filterLayout.area,
        'gap': this.filterLayout.gap
      }
    },
  },
} 
</script>

<style lang="scss">
.TableFilters {
  display: grid;
  grid-template-rows: auto;
  flex: 1;
}
</style>