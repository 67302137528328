<template>
  <b-navbar class="PageNav justify-content-between position-fixed fixed-top" type="dark">
    <!-- type="light" variant="light" -->

    <!-- <HeaderLogo class="flex-shrink-1 mr-3" /> -->
    <router-link :to="{ name: 'Dashboard' }">
      <EVToolsLogo context="navbar" class="flex-shrink-1 mr-3" />
    </router-link>

    <b-navbar-nav class="align-self-center my-0 flex-grow-1">
      <b-nav-item 
        class="no-border pt-2"
        :to="{ name: 'Dashboard' }">
        Workflow
      </b-nav-item>
    </b-navbar-nav>

    <b-navbar-nav class="d-flex flex-grow-1 mr-3" align="right" variant="light">
      <b-nav-item v-if="showRequestList" :to="{ name: 'RequestList' }">
        Aanvragen
      </b-nav-item>
      <b-nav-item v-if="showRealisationProcessList" :to="{ name: 'RealisationList' }">
        Realisatieprocessen
      </b-nav-item>
      <b-nav-item v-if="canAccessReporting" :to="{ name: 'Reporting' }">
        Rapportages
      </b-nav-item>
    </b-navbar-nav>

    <b-button 
      class="mr-3"
      v-if="showRealistionProcessButton" 
      variant="light" 
      size="sm"
      @click="handleOpenNewRecord">
      Nieuw realisatieproces<b-icon icon="plus"></b-icon>
    </b-button>
    
    <router-link
      v-if="canAccessManuals"
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Manuals' }">
      <SvgIcon icon="circle-question" :hasFill="true" />
    </router-link>

    <router-link 
      v-if="canAccessConfig" 
      class="d-inline-flex align-center mr-3"
      :to="{ name: 'Config' }">
      <SvgIcon icon="cog-regular" :hasFill="true" />
    </router-link>
    
    <span
      class="NavBar__Profile d-inline-flex align-center mr-3"
      :class="{ 'NavBar__Profile--visible': showingProfile }"
      @click="showProfile">
      <SvgIcon icon="user-regular" :hasFill="true" />
    </span>
    <!-- <b-nav align="right">
      <b-nav-item @click="logout">
        <b-button variant="light">
          Uitloggen
          <b-icon icon="box-arrow-right"></b-icon>
        </b-button>
      </b-nav-item>
    </b-nav> -->

    <ProfileCard v-if="showingProfile" :style="{ right: profileCardOffset }" />

  </b-navbar>
</template>

<script>
// import HeaderLogo from '@/components/HeaderLogo.vue'
import EVToolsLogo from '@/components/EVToolsLogo.vue'
import ProfileCard from '@/components/profile/ProfileCard.vue'
import SvgIcon from '@/components/common/SvgIcon'

import { mapGetters, mapMutations } from 'vuex'

const uuid = require('uuid')

export default {
  name: 'PageNav',
  components: {
    // HeaderLogo,
    EVToolsLogo,
    ProfileCard,
    SvgIcon
  },
  data() {
    return {
      showingProfile: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses',
      'canStartRealisationProcess',
      'canAccessManuals',
      'canAccessConfig',
      'canAccessReporting'
    ]),
    ...mapGetters('overlay', [
      'isOverlayVisible'
    ]),

    showRequestList() {
      return this.canAccessRequests
    },
    showRealisationProcessList() {
      return this.canAccessRealisationProcesses
    },
    showRealistionProcessButton() {
      return this.canStartRealisationProcess
    },

    /**
     * We need to avoid the profile card covering up the scrollbar of the main page content
     */
    profileCardOffset() {
      return '1.25em' // `${getScrollbarWidth()}px`
    }
  },
  watch: {
    isOverlayVisible() {
      if (! this.isOverlayVisible) {
        this.showingProfile = false
      }
    },
    showingProfile() {
      if (this.showingProfile) {
        this.showOverlay()
        this.unfreezeOverlay()
      } else {
        this.hideOverlay()
      }
    }
  },
  methods: {
    ...mapMutations('overlay', [
      'hideOverlay',
      'showOverlay',
      'unfreezeOverlay'
    ]),
    handleOpenNewRecord() {
      this.$router.push({
        name: 'Realisation',
        params: {
          uuid: uuid.v4() // generate a v4 uuid for the new record
        }
      })
    },
    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    },
    showProfile() {
      this.showingProfile = ! this.showingProfile
    },
  }
}
</script>

<style lang="scss">
.PageNav {
  // max-width: 1200px;
  margin: 0 auto;
  min-height: 52px;
  // margin-bottom: 30px;

  // EV Tools primary
  background: #041E42 !important;
  padding: 0px 0px 0px 7px !important;

  // TODO: avoid this hover & .active quick fix
  // &.navbar-light .navbar-nav .nav-link:hover, 
  // &.navbar-light .navbar-nav .nav-link:focus {
  //   color: rgba(236, 0, 0, 0.5)
  // }
  // &.navbar-light .navbar-nav .show > .nav-link, 
  // &.navbar-light .navbar-nav .active > .nav-link, 
  // &.navbar-light .navbar-nav .nav-link.show, 
  // &.navbar-light .navbar-nav .nav-link.active {
  //   color: rgba(236, 0, 0, 1)
  // }
  .nav-link.active, .nav-link:hover {
    position: relative;

    &:after {
      position: absolute;
      bottom: 7px;
      left: 0.5rem;
      content: '';
      width: calc(100% - 1rem);
      height: 2px;
      background: #00B46B;
    }
    // border-bottom: 1px solid red;
  }

  .no-border .nav-link {
    &:after {
      display: none;
    }
  }

  .badge {
    top: -10px;
    position: relative;
    right: 3px;
  }
  .SvgIcon {
    font-size: 1.75rem;
    fill: white;
    cursor: pointer;

    &:hover {
      fill: darken(white, 20%);
    }
  }

  &__description {
    height: 1em;
    color: white;
    // text-transform: uppercase;

    @media (max-width: 1200px) {
      span {
        display: none;
      }
    }

    @media (max-width: 920px) {
      display: none;
    }
  }

  .ProfileCard, .NavBarMenu {
    position: absolute;
    top: calc(80px - 0.75rem);
    box-sizing: 0px 1px 3px 0px #dfe2e5;
  }
}
</style>