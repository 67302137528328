<template>
  <b-tab v-if="showMailTab">
    <template #title>
      <b-icon class="mr-2" icon="info-circle-fill"></b-icon>
      E-mails
    </template>
    <p class="mt-4">
      Lijst met verzonden e-mails over dit locatievoorstel
    </p>
    <b-alert :show="true" variant="warning">
      Dit is nu enkel nog een debug functie en niet geschikt voor productie.
    </b-alert>

    <ul>
      <li v-for="(mail,index) in mails" :key="`mail_${index}`">
        {{ mail }}
      </li>
    </ul>
  </b-tab>
</template>

<script>
export default {
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    location() {
      try {
        return this.record.values.Location
      } catch(e) {
        return {
          Location: {}
        }
      }
    },
    showMailTab() {
      return process.env.VUE_APP_HAS_MAIL_TAB === 'Y'
    },
    mails() {
      let location = this.location
      return location.MailLog ? Object.keys(location.MailLog) : []
    },
  }
}
</script>
