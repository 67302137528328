<template>
  <div class="DetailGrid__footer">

    <b-alert :show="!!feedback" :variant="feedback" :dismissible="true">
      {{ feedbackMessage }}
    </b-alert>

    <b-navbar type="dark" variant="dark">
      <b-navbar-nav class="ml-auto">

        <slot />
        
      </b-navbar-nav>
    </b-navbar>
    <b-alert variant="lightgray" :show="true">
      <strong>Correcties</strong> worden, indien mogelijk, toegepast zonder de voortgang van het proces te beinvloeden. <br/>
      Een <strong>wijziging</strong> zet het proces altijd terug naar de gewijzigde stap.
    </b-alert>
  </div>
</template>

<script>
export default {
  name: 'RealisationFooter',
  props: {
    feedback: {
      type: String,
      default: null
    },
    feedbackMessage: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>