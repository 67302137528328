<template>
  <b-list-group-item 
    :style="rowStyle"
    class="Table__row"
    :class="{ 'Table__row--link': hasRoute }"
    :to="hasRoute ? recordData.route : null">

    <div 
      v-for="(segment,index) in mappedSegments" 
      :key="`segment_${index}`"
      :style="segment.style"
      class="">

      <component 
        v-if="segment.componentName" 
        v-bind:is="segment.componentName"
        :value="segment.value" />

      <template v-else>
        {{ segment.value }}
      </template>

    </div>
  </b-list-group-item>
</template>

<script>

import TableState from './mixins/TableState.vue'

import {
  loadAllComponentsFromFolder
} from './utils.js'

export default {
  components: loadAllComponentsFromFolder({
    ComponentContext: require.context('@/components/Table/cells', true, /\.vue$/i)
  }),
  mixins: [ TableState ],
  props: {
    recordData: {
      type: Object,
      required: true
    }
  },
  computed: {
    cellComponents() {
      return this.config.cellComponents || {}
    },
    segments() {
      return this.rowLayout.properties
    },
    mappedSegments() {
      return this.segments.map(property => {

        // TODO: Take into account missing values, here and/or in the mapper 
        let value = (! this.recordData[property] && this.recordData[property] !== 0)
          ? ''
          : this.recordData[property]

        return {
          value,
          style: {
            'grid-area': property
          },
          componentName: this.cellComponents[property] || null,
        }
      })
    },


    /**************************************************************************
     * Layout
     */

    /**
     * @type {object}
     */
    rowLayout() {
      return Object.assign({
        columns: 'auto',
        area: '',
        gap: '8px'
      }, (this.layout.row || this.layout.header || {}))
    },

    /**
     * @type {object}
     */
    rowStyle() {
      return {
        'grid-template-columns': this.rowLayout.columns,
        'grid-template-areas': this.rowLayout.area,
        'gap': this.rowLayout.gap
      }
    },


    /**************************************************************************
     * Whether the row has a linked route
     */
    hasRoute() {
      return !! this.recordData.route
    }
  }
}
</script>

<style lang="scss">
.Table__row {
  display: grid !important;
  grid-template-rows: auto;

  &.list-group-item {
    padding: 0.25rem 1.25rem;
  }

  > div {
    display: flex;
    align-items: center;
  }

  // &--link:hover {
  //   cursor: pointer;
  //   color: red;
  // }
}
</style>