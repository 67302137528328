<template>
  <div style="display:none"></div>
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin'
import { mapGetters } from 'vuex'
import {uniqueIntId} from "@/helpers/number";

/**
 * This layer loads and shows the charging points
 *  For now no interaction is included
 */
export default {
  name: 'LayerChargingpoints',
  mixins: [ MapBoxMixin ],
  data() {
    return {
      /**
       * Icon counter. We're loading 6 icons (see requiredIcons)
       */
      iconCounter: 0,
      requiredIcons: ['realized', 'alert', 'suggestion', 'definitive', 'rejected', 'in-progress']
    }
  },
  computed: {
    ...mapGetters('chargingpoints', ['chargingpoints']),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

  },
  watch: {
    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      } 
      if (ready) {
        this.loadIcons()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    },

    /**
     * When the charginpoints list changes we reload the layer
     */
    chargingpoints() {
      if (this.iconsLoaded) {
        this.updateOrAddLayer()
      }
    }
  },
  created() {
    if (this.loaded) {
      this.loadIcons()
    }
    // if (! Array.isArray(this.chargingpoints) || this.chargingpoints.length === 0) {
    //   this.loadChargingPoints()
    // }
  },
  methods: {
    // ...mapMutations('chargingpoints', ['setChargingPoints']),

    /**
     * 
     */
    // loadChargingPoints: async function() {
    //   const token = await this.$auth.getTokenSilently();
    //   const api = await fetch('/api/chargingpoints', {
    //     method: "POST",
    //     headers: {
    //       authorization: 'Bearer ' + token
    //     }
    //   });
    //   const response = await api.json()

    //   if (response.data && response.data.chargingpoints) {
    //     this.setChargingPoints({ chargingpoints: response.data.chargingpoints })
    //   }
    // },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.map.loadImage(
          require(`@/assets/image/legend/chargingpoint-${name}.png`),
          (err, image) => {
            if (err) throw err;
            if (! this.map.hasImage(`chargingpoint-${name}`)) {
              this.map.addImage(`chargingpoint-${name}`, image);
            }
            this.iconCounter++
        })
      })
    },

    /**
     * Translate datapoints into GeoJSON
     */
    generateGeoJson({ chargingpoints }) {
      chargingpoints = Array.isArray(chargingpoints) ? chargingpoints : []

      return {
        "type": "FeatureCollection",
        "features": chargingpoints.map(point => {
          return {
            "id": uniqueIntId({chargingpoint: point}),
            "type": "Feature",
            "properties": Object.assign(
              point.data.properties, {
              refId: point.ref['@ref'].id,
              uuid: point.data.uuid || null
            }),
            "geometry": {
              "type": "Point",
              "coordinates": point.data.coordinates
            }
          }
        })
      }
    },

    /**
     * If there already is a layer, only update the dataset that feeds it
     */
    updateOrAddLayer() {
      if (! this.map) return 

      let source = this.map.getSource('chargingpoints')
      if (source) {
        this.updateLayer()
      } else {
        this.addLayer()
      }
    },

    /**
     * Update the source data of the layer
     */
    updateLayer() {
      if (! this.map) return

      let source = this.map.getSource('chargingpoints')
      if (! source) return 

      source.setData(this.generateGeoJson({ 
        chargingpoints: this.chargingpoints
      }))
    },

    /**
     * Load the chargingpoint layer
     */
    addLayer() {

      if (! this.map) return

      // Clean up first if we must
      let source = this.map.getSource('chargingpoints')
      if (source) {
        if (this.map.getLayer('chargingpoints')) {
          this.map.removeLayer('chargingpoints')
        }
        if (this.map.getLayer('chargingpoints-text')) {
          this.map.removeLayer('chargingpoints-text')
        }
        
        this.map.removeSource('chargingpoints')
      }
      

      // Add the generated source 
      this.map.addSource('chargingpoints', {
        type: 'geojson',
        data: this.generateGeoJson({ 
          chargingpoints: this.chargingpoints
        })
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['realisation-processes', 'realisation-processes-text', 'requests', 'requests-text', 'locationpin']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        "id": "chargingpoints",
        "type": "symbol",
        "source": "chargingpoints",
        "minzoom": 10,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          "icon-allow-overlap": true,
          "icon-anchor": 'bottom',
          "icon-image": [
            "match", 
            ['get', 'status'], 
            "definitive", "chargingpoint-definitive",
            "rejected", 'chargingpoint-rejected',
            "in-progress", 'chargingpoint-in-progress',
            "realized", 'chargingpoint-realized',
            // "realized-private", 'chargingpoint-realized-private',
            // "fastcharger-realized", 'chargingpoint-fastcharger-realized',
            // "fastcharger-planned", 'chargingpoint-fastcharger-planned',
            "suggestion", 'chargingpoint-suggestion',
            "alert", 'chargingpoint-alert', 
            "nodefaultimage" // Hide unkown points
          ], 
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0.05,
            18,
            0.2
          ],
        },
        "paint": {
          // "icon-color": "",
          "icon-opacity": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            0.8
          ]
        }
      }, positionBelow) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({ 
        "id": "chargingpoints-text",
        "type": "symbol",
        "source": "chargingpoints",
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': [
            "match", 
            ['get', 'status'], 
            // "definitive", ['get', 'id'],
            // "in-progress", ['get', 'id'],
            "realized", ['get', 'id'],
            // "realized-private", ['get', 'id'],
            // "fastcharger-realized", ['get', 'id'],
            // "fastcharger-planned", ['get', 'id'],
            // "suggestion", ['get', 'id'],
            // "alert", 'chargingpoint-alert', 
            "" // Hide suggestions
          ],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 12,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14.4,
            0,
            14.6,
            0.8
          ]
        }
      }, positionBelow)
    }

  }
}
</script>

