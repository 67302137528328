<template>
  <MapBoxPopup 
    :coordinates="coordinates" 
    :show="show"
    :offset="[0, -30]" 
    @close="handleClose">
    <div>
      <div v-for="chargingPoint in visibleChargingPoints" 
        class="d-flex flex-column mb-3" 
        :key="chargingPoint ? chargingPoint.uuid : 'none'">
        
        <div v-if="chargingPoint"> 
          <div v-if="chargingPoint.address">
            {{ chargingPoint.address }}
          </div>
          <div>
            {{ chargingPoint.statusLabel }}
          </div>
          <div>
            EVtools ID: {{ `${chargingPoint.code}-${chargingPoint.id}` }}
          </div>
          <div v-if="chargingPoint.meta.source === 'import-royal-haskoning'">
            Type: {{ chargingPoint.meta.type }}
          </div>
          
          <div class="mt-2" v-if="statesWithControls.includes(chargingPoint.status)">

            <template v-if="locked === false">
              <a 
                v-if="chargingPoint.selected" 
                @click="handleSelectLocation({ uuid: null }, $event)" href="">
                <b-icon icon="x-circle" /> Ontkoppel deze locatie
              </a>
              <a 
                v-else-if="selected === null && chargingPoint.status === 'definitive'" 
                @click="handleSelectLocation({ uuid: chargingPoint.uuid }, $event)" href="">
                <b-icon icon="geo-fill" /> Gebruik deze locatie
              </a>
            </template>
            
            <template v-else-if="chargingPoint.selected">
              <p>
                Deze locatie is verbonden aan dit realisatieproces
              </p>
            </template>

            <router-link 
              v-else-if="chargingPoint.workflowUuid"
              :to="{ name: 'Realisation.Step1', params: { uuid: chargingPoint.workflowUuid }}">
              Bekijk het proces {{ chargingPoint.workflowCaseRef }}
            </router-link>
            
          </div>
          <div class="mt-3" v-if="chargingPoint.remark">
            {{ chargingPoint.remark }}
          </div>
        </div>
      </div>

      <b-pagination 
        class="justify-content-center m-auto pagination-sm"
        v-if="chargingPoints.length > 1"
        hide-goto-end-buttons
        
        v-model="currentPage"
        :total-rows="chargingPoints.length"
        :per-page="1" />
    </div>
  </MapBoxPopup>
</template>

<script>
import MapBoxMixin from '@/components/common/MapBoxMixin.vue'
import MapBoxPopup from '@/components/common/MapBoxPopup'
import { mapGetters } from 'vuex'

export default {
  name: 'PopupChargingLocation',
  components: {
    MapBoxPopup
  },
  mixins: [ MapBoxMixin ],
  props: {
    locked: {
      type: Boolean,
      default: true
    },
    selected: {
      type: String,
      default: null
    },
    alternative: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layerName: 'chargingpoints',
      show: false,
      uuids: [],
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    chargingPoints() {
      return this.uuids
        .map(uuid => this.getChargingPointByUUID({ uuid }))
        .map(chargingPoint => {
          let data = chargingPoint.data
          let status = data.properties.status
          let location = {
            uuid: data.uuid,
            coordinates: data.coordinates,
            address: (data.address.formatted_address + '').replace(', Nederland', ''),
            status,
            statusLabel: this.mapStatusToLabel({ status }),
            id: data.properties.id,
            code: data.code,
            remark: data.properties.remark,
            ref: chargingPoint.ref['@ref'].id,
            meta: {
              source: null,
              type: null
            },
            selected: data.uuid === this.selected
          }

          // Verify whether the workflow is accessible for the user
          if (data.workflowUuid) {
            let workflow = this.recordByUuid({
              uuid: data.workflowUuid
            })
            if (workflow) {
              location.workflowUuid = data.workflowUuid
              location.workflowCaseRef = workflow.case_ref
            }
          }

          // Royal Haskoning Special
          if (data.meta && data.meta.source === 'import-royal-haskoning') {
            location.meta.source = data.meta.source
            location.meta.type = data.meta?.import.proactive_period !== 0 ? 'Proactief' : 'Regulier'
          }

          return location
        })
    },
    visibleChargingPoints() {
      return [ this.chargingPoints[this.currentPage - 1] || false ]
    },
    coordinates() {
      if (this.chargingPoints.length) {
        return this.chargingPoints[0].coordinates
      }
      return [0,0]
    },
    hasRequiredInformation() {
      return this.chargingPoints.length !== 0
    },
    statesWithControls() {
      let states = ['definitive', 'in-progress']
      if (this.alternative) {
        states.push('rejected')
      }
      return states
    }
  },
  watch: {
    hasRequiredInformation(hasRequiredInformation) {
      this.show = hasRequiredInformation
    }
  },
  created() {
    this.bind()
  },
  beforeDestroy() {
    this.unbind()
  },
  methods: {
    bind() {
      if (! this.map) return 

      // click event
      this.map.on('click', this.layerName, this.handleLayerClickEvent)
      this.map.on('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      // Cursor
      this.map.on('mouseenter', this.layerName, this.showPointer)
      this.map.on('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.on('mouseleave', this.layerName, this.hidePointer)
      this.map.on('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },
    unbind() {
      if (! this.map) return

      this.map.off('click', this.layerName, this.handleLayerClickEvent)
      this.map.off('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      this.map.off('mouseenter', this.layerName, this.showPointer)
      this.map.off('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.off('mouseleave', this.layerName, this.hidePointer)
      this.map.off('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    handleLayerClickEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      let features = e.features

      this.$nextTick(function(){
        this.currentPage = 1
        this.uuids = features
          .filter(feature => feature?.properties.uuid)
          .map(feature => feature.properties.uuid)

        if (this.uuids.length) {
          this.show = true
        }
      })
    },

    handleClose() {
      this.show = false
      // this.uuids = []
    },

    handleSelectLocation({ uuid }, e) {
      e.preventDefault()
      // console.log("handle location", uuid)
      this.$emit('location', { uuid })
    },

    mapStatusToLabel({ status }) {
      return {
        alert: 'Aandachtspunt',
        rejected: 'Afgekeurd',
        suggestion: 'Voorgesteld',
        definitive: 'Gevalideerd',
        'in-progress': 'In voorbereiding',
        realized: 'Gerealiseerd',
      }[status] || 'onbekend'
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 30px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>