<template>
  <div class="Address">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required, helpers } from 'vuelidate/lib/validators'
import { unicodeString } from '@/services/validation'

export default {
  name: 'Address',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "ChargePointOperator": {
            "type": "string",
            "title": "CPO"
        },
        "Remarks": {
            "type": "string",
            "title": "Toelichting",
            "format": "textarea"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'ChargePointOperator' ]
      }, {
        "columns": '1fr',
        "fields": [ 'Remarks' ]
      }],
      validation: {
        "ChargePointOperator": {
          required,
          unicodeString: (value) => !helpers.req(value) || unicodeString(value)
        }
      }
    }
  }
}
</script>