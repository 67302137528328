<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">
      
      <RealisationHeader :record="record" :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step">

       
        <FlowInteraction
          position="top"
          :first="currentStep === '1'"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />
      </RealisationHeader>

      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <div v-if="isGORALTenant" class="DetailGrid__map">
        <MapPreSelectedLocationSelection   
          :record="record"
          :values="values"
          :locked="locked" 
          :disabled="disabled"
          :connect="!! record.ref" />
      </div>
      <div v-else class="DetailGrid__map" >
        <MapDisconnectedLocationSelection   
          :record="record"
          :values="values"
          :locked="locked" 
          :disabled="disabled" />
      </div>
      <div class="DetailGrid__col1">          
        <StepOneMisc :values="values" :disabled="locked || disabled" :hasRef="!!record.ref" />
      </div>

      <div class="DetailGrid__col2">
        <ChargingSituation :values="values" :disabled="locked || disabled" />
      </div>

      <div class="DetailGrid__wide">
        <AutomatedViewsBtn :uuid="uuid" :disabled="locked || disabled" />
      </div>

      <div class="DetailGrid__col3">

        <FileUpload 
          class="mt-3"
          label="Boven aanzicht"
          doctype="TopView"
          :uuid="uuid"
          :files="values && values.Docs ? (values.Docs.TopView || []) : null"
          :minFileId="minTopViewDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          :preview="true"
          :wide="true"
          @files="handleFileChanges" />
          <p class="text-muted" v-if="(locked || disabled) && (values && values.Docs ? values.Docs.TopView : []).length === 0">
            Er is geen boven aanzicht beschikbaar
          </p>
      </div>
      <div class="DetailGrid__col4">
        <FileUpload 
          class="mt-3"
          label="Zij aanzicht"
          doctype="SideView"
          :uuid="uuid"
          :files="values && values.Docs ? (values.Docs.SideView || []) : null"
          :minFileId="minSideViewDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          :preview="true"
          :wide="true"
          @files="handleFileChanges" />
          <p class="text-muted" v-if="(locked || disabled) && (values && values.Docs ? values.Docs.SideView : []).length === 0">
            Er is geen zij aanzicht beschikbaar
          </p>
      </div>

      <div class="DetailGrid__col5">
        <LocationPreparation :values="values" :disabled="locked || disabled" />
      </div>

      <div class="DetailGrid__col6">

        <CPOFinUnit v-if="isCPO && isPNCTenant" :values="values" :disabled="locked || disabled" />

        <CPOAdditionalCosts :values="values" :disabled="locked || disabled" />

        <h3>Handmatig startdocument</h3>
        <FileUpload 
          label=""
          doctype="ParkingSpots"
          :uuid="uuid"
          :files="values && values.Docs ? values.Docs.ParkingSpots: null"
          :minFileId="minParkingSpotsDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" />
        <p class="text-muted" v-if="(locked || disabled) && (values && values.Docs ? values.Docs.ParkingSpots : []).length === 0">
          Er is geen handmatig ingevuld startdocument beschikbaar. 
        </p>
        <template v-if="(values && values.Docs ? values.Docs.ParkingSpots : []).length === 0">
          <DocDownload class="mb-3" :uuid="uuid" />
        </template>

        <!-- <FileUpload 
          class="mt-3"
          label="Meerwerk offerte(s)"
          doctype="CPOAdditional"  
          :uuid="uuid"
          :files="values && values.Docs ? (values.Docs.CPOAdditional || null) : null"
          :minFileId="minCPOAdditionalDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" /> -->
      </div>
      
      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        
        <FlowInteraction
          position="bottom"
          :first="currentStep === '1'"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />
      </RealisationFooter>
      
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'
import MapDisconnectedLocationSelection from '@/components/map/MapDisconnectedLocationSelection'
import MapPreSelectedLocationSelection from '@/components/map/MapPreSelectedLocationSelection.vue'
import ChargingSituation from '@/components/definitions/ChargingSituation'
import StepOneMisc from '@/components/definitions/StepOneMisc'
import CPOAdditionalCosts from '@/components/definitions/CPOAdditionalCosts'
import CPOFinUnit from '@/components/definitions/CPOFinUnit'
import LocationPreparation from '@/components/definitions/LocationPreparation'
import FileUpload from '@/components/FileUpload'
import DocDownload from '@/components/realisation/DocDownload.vue'
import AutomatedViewsBtn from '@/components/realisation/AutomatedViewsBtn.vue'

import Comments from '@/components/Comments'

import FlowInteraction from '@/components/flow/FlowInteraction.vue'

import { uuidValidateV4 } from '@/services/validation'

import BaseRealisationStep from '@/views/BaseRealisationStep'

import { mapGetters } from 'vuex'

export default {
  name: "RealisationDetail_1",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    MapDisconnectedLocationSelection, MapPreSelectedLocationSelection, 
    Form, ChargingSituation, StepOneMisc,
    CPOAdditionalCosts, LocationPreparation, CPOFinUnit,
    FileUpload,
    Comments,
    DocDownload,
    AutomatedViewsBtn,
    FlowInteraction
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "1" 
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    // For existing records this is handled by the parent component, but not when it concerns new Records
    requestAdditions() {
      if (this.$route.query.aanvragen && ! this.hasRecord({ uuid: this.uuid })) {
        return this.$route.query.aanvragen.split('|').filter(id => {
          return uuidValidateV4({ id }) && this.requestByUuid({ uuid: id })
        })
      }
      return []
    },

    // Docs
    minParkingSpotsDocsId() {
      try {
        return this.minIdByDocType({ doctype: 'ParkingSpots' })
        // return this.record.values.Docs.ParkingSpots.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },
    minTopViewDocsId() {
      try {
        let Max = this.minIdByDocType({ doctype: 'TopView' })
        let MaxAlt = this.minIdByDocType({ doctype: 'TopView-alt' })
        return Math.max(Max, MaxAlt)
      } catch(e) {
        return 0
      }
    },
    minSideViewDocsId() {
      try {
        let Max = this.minIdByDocType({ doctype: 'SideView' })
        let MaxAlt = this.minIdByDocType({ doctype: 'SideView-alt' })
        return Math.max(Max, MaxAlt)
      } catch(e) {
        return 0
      }
    },
    /**
     * We currently also use the park-n-charge step components for go-ral and opcharge
     */
    isPNCTenant() {
      return this.isCurrentTenant({ tenant: 'park-n-charge' }) 
    },
    isGORALTenant() {
      return this.isCurrentTenant({ tenant: 'go-ral' })
    },
    isCPO() {
      return this.hasRole({ role: 'cpo' })
    }
    // minCPOAdditionalDocsId() {
    //   try {
    //     return this.record.values.Docs.CPOAdditional.reduce((result, id) => Math.max(result,id), 0)
    //   } catch(e) {
    //     return 0
    //   }
    // },

  },
  watch: {
    requestAdditions() {
      this.addRequestsToRealisation()
    }
  },
  created() {
    // console.log("created", this.record, this.uuid)

    // Redirect filtered lists
    if (['cpo', 'gemeente', 'afgerond', 'geannuleerd', 'onhold'].includes(this.uuid.toLowerCase())) {
      this.$router.push({
        name: 'RealisationListFiltered',
        params: {
          filter: this.uuid
        }
      })
    } else {

      this.addRequestsToRealisation()

      // Maybe redirect to active step
      if (this.hasRecord && this.step !== 1 && this.$route.name == 'Realisation') {
        this.$router.push({
          name: `Realisation.Step${this.step}`,
          params: {
            uuid: this.uuid
          }
        })
      }
    }
  },
  methods: {

    prepData({ data }) {
      // Document numbers
      data.Docs = {
        // (this.Docs.ParkingSpots || []).filter(file => file.progress === 100).map(file => file.id),
        ParkingSpots: this.prepDocData({ doctype: 'ParkingSpots' }),  
        TopView: this.prepDocData({ doctype: 'TopView' }),
        SideView: this.prepDocData({ doctype: 'SideView' }),
      }

      // Convert ParkingSpot Identifiers
      data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers || []
      data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers.map(obj => obj.Identifier).filter(id => !!id)

      return data
    },

    /**
     * Stub
     */
    isValidData({ data }) {

      if (this.isGORALTenant) {
        if (! data.Location.chargingpointUuid) {
          this.feedbackMessage = 'Selecteer eerst een locatie.'
          this.disabled = false
          this.feedback = 'danger'
          return false
        }
      }

      if (this.goingToNextStep) {
        // if ((this.Docs.ParkingSpots || []).filter(file => file.progress === 100).length == 0) {

        //   this.disabled = false
        //   this.feedback = 'danger'
        //   this.feedbackMessage = 'Een inrichtingsplan is vereist om verder te kunnen.'
        //   return false
        // } 
        // if (data.ParkingSpots.Identifiers.length === 0) {
        //   this.disabled = false
        //   this.feedback = 'danger'
        //   this.feedbackMessage = 'Minimaal 1 parkeerplaats is vereist.'
        //   return false
        // }

        try {
          if (
            data.LocationPreparation.RequiresLocationPreparation.includes('Overige') && 
            data.LocationPreparation.OtherReason === ''
          ) {
            this.feedbackMessage = 'Vul een korte omschrijving van de overige voorbereidingen in.'
            throw new Error("")
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          return false
        }
      }
      return true
    },

    /**
     * Add / Remove Requests to Realisation
     */
    addRequestsToRealisation() {
      if (this.record && this.requestAdditions) {
        this.requestAdditions.forEach(uuid => {
          this.setConnectionChange({
            type: 'add',
            requestUuid: uuid,
            realisationUuid: this.record.uuid
          })
        })
      }
    },

    /**
     * Handle Activation / de-activation of the form
     *  Used when the process is not currently in step 1.
     */
    handleCancelChange() {
      // Restore the last Location object to Current Location
      // TODO: Reset the LocationPicker Pin
      this.reloading = true

      this.$nextTick(() => {
        if (this.correction === false) {
          this.record.values.Location = this.record.history[0]
        }

        this.reloading = false
        this.locked = true
        this.correction = false
      })
    },
    handleStartChange() {
      this.record.values.Location = this.record.values.Location.LocationCopy({ 
        version: this.record.nextLocationVersion()
      })
      this.locked = false
    },
    handleStartCorrection() {
      this.locked = false
      this.correction = true
    }
    
  }
};
</script>
