<template>
  <div class="LocationPreparation">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'LocationPreparation',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Locatie voorbereiding'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'LocationPreparation'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      layout: [{
        "columns": '1fr',
        "fields": [ 'RequiresLocationPreparation' ]
      }, { 
        "columns": '1fr',
        "fields": [ 'OtherReason' ]
      }],
      validation: {},

      InternalRequiresLocationPreparation: null
    }
  },
  computed: {
    fields() {

      let other = false

      if (this.InternalRequiresLocationPreparation && Array.isArray(this.InternalRequiresLocationPreparation)) {
        other = this.InternalRequiresLocationPreparation.includes('Overige')
      } else {
        try {
          other = this.values.LocationPreparation.RequiresLocationPreparation.includes('Overige')
        } catch(e) {
          //
        }
      }

      return {
        "RequiresLocationPreparation": {
            "type": "string",
            "title": "Welke voorbereidingen zijn nodig vanuit de CPO, uit te voeren door de gemeente? (meerdere antwoorden mogelijk)",
            "enumNames": [
              "Aanbrengen flespaal met bebording",
              "Aanbrengen belijning",
              "Verwijderen bossages lager dan 1 meter",
              "Verwijderen bossages hoger dan 1 meter",
              "Aanbrengen twee diamantkoppalen",
              "Aanbrengen één diamantkoppaal",
              "Aanbrengen omtegeling",
              "Aanbrengen oortje in bestrating",
              "Aanbrengen oortje in asfalt",
              "Aanbrengen biggenruggen in bestrating",
              "Aanbrengen biggenruggen in asfalt",
              "Aanbrengen rvs paalbeschermer",
              "Overige"
            ],
            "enum": [
              "Aanbrengen flespaal met bebording",
              "Aanbrengen belijning",
              "Verwijderen bossages lager dan 1 meter",
              "Verwijderen bossages hoger dan 1 meter",
              "Aanbrengen twee diamantkoppalen",
              "Aanbrengen één diamantkoppaal",
              "Aanbrengen omtegeling",
              "Aanbrengen oortje in bestrating",
              "Aanbrengen oortje in asfalt",
              "Aanbrengen biggenruggen in bestrating",
              "Aanbrengen biggenruggen in asfalt",
              "Aanbrengen rvs paalbeschermer",
              "Overige"
            ],
            'format': 'checkbox'
        },
        "OtherReason" : {
          "type": "string",
          "title": "Korte omschrijving van de overige voorbereidingen (indien overige is geselecteerd)",
          "format": "textarea",
          "disabled": ! other
        }
      }
    }
  },
  methods: {
    handleInput({ name, value }) {
      if (name === `${this.path}.RequiresLocationPreparation`) {
        this.InternalRequiresLocationPreparation = value
      }
    },
  }
}
</script>