<template>
  <b-badge pill variant="dark">
    {{ requestUuidsByRealisationUuid({ uuid: value }).length || 0 }}
  </b-badge>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    ...mapGetters('relations', [
      'requestUuidsByRealisationUuid'
    ]),
  }
}
</script>

<style>

</style>