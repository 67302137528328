<script>
import { customers } from '@/config'
import { mapGetters } from "vuex";

import SingleSelectFilter from './SingleSelectFilter.vue'

export default {
  name: 'RVBCustomerFilter', 
  mixins: [ SingleSelectFilter ],
  computed: {
    ...mapGetters('user', [
      'roleDetailsByRole',
      'hasRole'
    ]),
    options() {
      return this.customers.map(customer => {
        return {
          value: customer,
          text: customer
        }
      })
    },
    customers() {
      if (this.hasRole({ role: 'customer' })) {
        return this.roleDetailsByRole({ role: 'customer' })
      }

      return customers
    },
  }
}
</script>