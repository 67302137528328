<template>
  <div class="FieldSet">
    <FieldSetHeader v-if="label" :rank="headerRank" :title="label" />
    <Row 
      v-for="(row,index) in rows" 
      :key="`row_${index}`" 
      :fields="row.fields" 
      :columns="row.columns || 'auto'"
      :path="path"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
      />
  </div>
</template>

<script>
import FieldSetHeader from '@/components/form/FieldSetHeader'
import Row from '@/components/form/Row'

import { helpers } from 'vuelidate/lib/validators'
import { unicodeString, multilineUnicodeString } from '@/services/validation'

export default {
  name: 'FieldSet',
  components: { FieldSetHeader, Row },
  props: {
    // The optional fieldset header
    label: {
      type: String
    },
    // The fields, along with their properties
    fields: {
      type: Object,
      required: true
    },
    // The field values
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    // The field validation rules (Vuelidate rules)
    validation: {
      type: Object,
      default: function() {
        return {}
      }
    },

    // The fieldset layout
    layout: {
      type: Array,
      required: true
    },
    // The nesting structure of the Fieldset, using dot & block notation (e.g. 'parent.child[0].grandchild')
    path: {
      type: String,
      default: ''
    },
    /**
     * Whether the fields are disabled
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * Viewer mode
     */
    viewer: {
      type: Boolean,
      default: false
    }

    // Whether or not this is a repeating fieldset
    // repeater: {
    //   type: Boolean,
    //   default: false
    // },
    // // Repeater position
    // position: {
    //   type: Number,
    //   default: 0
    // }
  },
  computed: {
    /**
     * Combine Layout & Field details to Row specs
     */
    rows() {
      return this.layout.map(row => this.prepRowDetails({ row }))
    },

    /**
     * Pass on the appropriate field values, taking into account the path & repeater properties of this fieldset
     * Note, because array notation is done with .# no special consideration for repeaters has to be in place
     */
    mappedValues() {

      if (this.path === '') {
        return this.values
      }
      let segments = this.path.split('.')
      let values = this.values

      segments.forEach(segment => {
        values = values[segment] || {}
      })

      return values

      // An alternative solution that leverages https://www.npmjs.com/package/dot-object
      // return Object.keys(this.fields).reduce((fields, fieldName) => {
      //  fields[fieldName] = this.values[`${this.path}.${fieldName}`] || null
      //  return fields 
      // }, {})

      // And a combination of both could lead to
      // return this.values[this.path] || {}
    },
    /**
     * Determine the Fieldset header rank based on the value path.
     *  The rank should not be higher than 3, or lower than 6
     */
    headerRank() {
      return Math.min(6, Math.max(3, this.path.split('.').length + 2))
    }
  },
  methods: {
    /**
     * Turn the row 
     */
    prepRowDetails({ row }) {
      return {
        columns: row.columns || '1fr 1fr 1fr',
        fields: row.fields.map(fieldName => {
            return {
              name: fieldName,
              spec: this.fields[fieldName] || null,
              value: this.mappedValues[fieldName] || this.mappedValues[fieldName] === 0 || this.mappedValues[fieldName] === false ? this.mappedValues[fieldName] : '',
              validationRules: this.validation[fieldName] || {},
              readonly: this.fields[fieldName] ? (this.fields[fieldName].readonly || this.fields[fieldName].viewer || false) : false
            }
          })
          .filter(field => field.spec !== null)
          .map(field => this.mapFieldProps({ field }))
      }
    },
    mapFieldProps({ field }) {
      
      // console.log(field)

      let props = {
        label: field.spec.title,
        value: field.value,
        validationRules: field.validationRules,
        disabled: field.spec.disabled || this.disabled, // A field level disabled state takes priority
        readonly: field.spec.readonly || field.spec.viewer || false
      }

      if (field.spec.type === 'array') {
        throw new Error('Repeater fields are not supported yet.')
      }

      if (field.spec.options) {
        if (field.spec.format === 'checkbox') {
          props.type = 'checkbox'
        } else if (
          field.spec.format === 'radio' || 
          (field.spec.options.length === 2 && field.spec.options.every(option => option.text.length < 10))
        ) {
          props.type = 'radio' // radio
        } else {
          props.type = 'select'
        }

        props.options = field.spec.options

      } else if (field.spec.enum) {

        if (field.spec.format === 'checkbox') {
          props.type = 'checkbox'
        } else if (
          field.spec.format === 'radio' || 
          (field.spec.enum.length === 2 && field.spec.enumNames.every(text => text.length < 10))
        ) {
          props.type = 'radio' // radio
        } else {
          props.type = 'select'
        }

        let disabledOptions = field.spec.disabledOptions && Array.isArray(field.spec.disabledOptions) && field.spec.disabledOptions.length !== 0
          ? field.spec.disabledOptions
          : false

        props.options = field.spec.enum.map((value, index) => {
          return {
            value,
            text: field.spec.enumNames[index],
            disabled: disabledOptions ? disabledOptions.includes(value) : false
          }
        })
      }
      else if (field.spec.format === 'textarea') {
        props.type = 'textarea'
      }
      else if (field.spec.format === 'date') {
        props.type = 'datepicker'
      } 
      else if (field.spec.format === 'time') {
        props.type = 'timepicker'
      }
      else if (field.spec.type === 'boolean') {
        props.type = 'radio'
        props.options = [{
          value: true,
          text: 'Ja'
        }, {
          value: false,
          text: 'Nee'
        }]
      } 
      else if (field.spec.type === 'number') {
        props.type = 'number'
      }
      else if (field.spec.type === 'integer') {
        props.type = 'text'
        props.format = 'float'
      }
      else if (field.spec.type === 'instruction') {
        props.type = 'instruction'
        props.instruction = field.spec.instruction
      }
      else if (field.spec.type === 'hidden') {
        props.type = 'hidden'
      }
      else {
        props.type = 'text'
      }

      if (field.spec.className) {
        props.className = field.spec.className
      }

      if (field.spec.description) {
        props.info = field.spec.description
      }

      // Apply UnicodeString validation to all string input, unless the field already haa a custom unicodeString rule
      if (field.spec.type === 'string' && Object.keys(props.validationRules).length === 0) {
        if (props.type === 'textarea') {
          props.validationRules.multilineUnicodeString = ((value) => !helpers.req(value) || multilineUnicodeString(value))
        } else {
          props.validationRules.unicodeString = ((value) => !helpers.req(value) || unicodeString(value))
        }
      }

      // props.permaDisabled = ""
      // props.placeholder = ""

      field.props = props
      return field
    },
    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name
      })
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name
      })
    }
  }
}
</script>