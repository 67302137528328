<template>
  <div class="TrafficDecision">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'TrafficDecision',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Verkeersbesluit'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'TrafficDecision'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PublicationDate": {
            "type": "string",
            "title": "Publicatie verkeersbesluit",
            "format": 'date',
        },
        "DefinitiveDate": {
            "type": "string",
            "title": "Verwachte datum definitief verkeersbesluit",
            "format": 'date',
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'PublicationDate' ]
      },{
        "columns": '1fr',
        "fields": [ 'DefinitiveDate' ]
      }],
      validation: {
        PublicationDate: {
          required
        },
        DefinitiveDate: {
          required
        }
      }
    }
  }
}
</script>