import Vue from 'vue'
import VueRouter from 'vue-router'

import { guards, overlayBlock } from '@/services/router-guards'
import { authGuard } from "@/auth/authGuard";

import Main from '@/views/Main'

import List from '@/views/List'
import Detail from '@/views/Detail'

import RealisationList from '@/views/RealisationList'

import Config from '@/views/Config'
import TagConfigPanel from '@/views/admin/TagConfigPanel.vue'
import PIDConfigPanel from '@/views/admin/PIDConfigPanel.vue'

// Reporting
import Reporting from '@/views/Reporting'
import KeyMetrics from '@/views/reporting/KeyMetrics'
import RequestJudgement from '@/views/reporting/RequestJudgement'
import RealisationProcessStatus from '@/views/reporting/RealisationProcessStatus'

// RVB Reporting
import RVBChargingpointCount from '@/views/reporting/RVBChargingpointCount.vue'
import RVBChargingpointsByObjectType from '@/views/reporting/RVBChargingpointsByObjectType.vue'
import RVBChargingpointsByRegion from '@/views/reporting/RVBChargingpointsByRegion.vue'
import RVBChargingpointsByPID from '@/views/reporting/RVBChargingpointsByPID.vue'
import RVBGridImprovementCount from '@/views/reporting/RVBGridImprovementCount.vue'
import RVBCustomers from '@/views/reporting/RVBCustomers.vue'

// Manuals
import Manuals from '@/views/Manuals.vue'
import ManualPage from '@/views/manuals/ManualPage.vue'

import { steps, stepComponents } from '@/config'

Vue.use(VueRouter)

const routes = [

  /**
   * Realisation process
   */
  {
    path: '/realisatie',
    name: 'RealisationList',
    component: RealisationList,
    children: steps.reduce((routes, step) => {
      if (routes.length === 0) {
        routes.push({
          path: ':uuid',
          name: 'Realisation',
          component: step.component ? step.component.default : stepComponents[step.uuid].default
        })
        routes.push({
          path: ':filter',
          name: 'RealisationListFiltered',
          component: RealisationList
        })
      }

      routes.push({
        path: `:uuid/${step.step || step.short}`,
        name: `Realisation.Step${step.step || step.short}`,
        component: step.component ? step.component.default : stepComponents[step.uuid].default
      })

      return routes
    }, [])
  },
  
  /**
   * Requests
   */
  {
    path: '/aanvragen',
    name: 'RequestList',
    component: List
  },
  {
    path: '/aanvragen/:uuid',
    name: 'RequestDetail',
    component: Detail
  },

  /**
   * Config
   */
  {
    path: '/beheer',
    name: 'Config',
    component: Config,
    children: [
      {
        path: 'tags',
        name: 'TagsConfig',
        component: TagConfigPanel
      },
      {
        path: 'pids',
        name: 'PidsConfig',
        component: PIDConfigPanel
      }
    ]
  },

  /**
   * 
   */
  {
    path: '/rapportage',
    name: 'Reporting',
    component: Reporting,
    children: [
      { 
        path: 'key-metrics', 
        name: 'KeyMetrics',
        component: KeyMetrics 
      },
      {
        path: 'beoordelingen',
        name: 'RequestJudgement',
        component: RequestJudgement
      },
      {
        path: 'realisatiestatus',
        name: 'RealisationProcessStatus',
        component: RealisationProcessStatus
      },

      // RVB
      {
        path: 'aantal-laadpunten',
        name: 'RVBChargingpointCount',
        component: RVBChargingpointCount
      },
      {
        path: 'laadpunten-per-stelsel',
        name: 'RVBChargingpointsByObjectType',
        component: RVBChargingpointsByObjectType
      },
      {
        path: 'laadpunten-per-regio',
        name: 'RVBChargingpointsByRegion',
        component: RVBChargingpointsByRegion
      },
      {
        path: 'laadpunten-per-pid',
        name: 'RVBChargingpointsByPID',
        component: RVBChargingpointsByPID
      },
      {
        path: 'aantal-netverzwaringen',
        name: 'RVBGridImprovementCount',
        component: RVBGridImprovementCount
      },
      {
        path: 'klanten',
        name: 'RVBCustomers',
        component: RVBCustomers
      }
      
    ]
  },

  /**
   * Manual pages
   */
  {
    path: '/handleidingen',
    name: 'Manuals',
    component: Manuals,
    children: [
      {
        path: ':slug',
        name: 'ManualPage',
        component: ManualPage
      }
    ]
  },
  
  /**
   * Main - currently redirects to RequestList or RealisationList based on role
   */
  {
    path: '/',
    name: 'Dashboard',
    component: Main
  }
  
]

const router = new VueRouter({
  mode: 'history',
  routes,
  linkActiveClass: 'active',
  linkExactActiveClass: 'active exact-active'
})

router.beforeEach(guards([ authGuard, overlayBlock({ Vue }) ]))

export default router