import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import version from './version'
import user from './user'
import requests from './requests'
import realisation from './realisation'
import relations from './relations'
import chargingpoints from './chargingpoints'
import monitoring from './monitoring'
import tenant from './tenant'
import overlay from './overlay'
import EVtables from './EVtables'

/**
 * 
 */
export default new Vuex.Store({
  state: {
    /**
     * 
     */
    loaded: false
  },

  getters: {
    loaded: state => state.loaded
  },
  actions: {
  },
  mutations: {
    setLoadedState(state, { loaded }) {
      state.loaded = loaded
    }
  },

  modules: {
    chargingpoints,
    realisation,
    requests,
    relations,
    version,
    user,
    monitoring,
    tenant,
    overlay,
    EVtables
  }
})
