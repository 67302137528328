<template>
  <div 
    class="FlowInteraction FlowInteraction">
    <b-button 
      v-if="! first"
      variant="outline-light" 
      class="ml-3" 
      size="sm" 
      type="submit" 
      :disabled="disabled"
      @click.prevent.stop="handlePrevious">
      Vorige stap
    </b-button>

    <template v-if="! locked">  
      <b-button 
        variant="outline-light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled">
        Opslaan
      </b-button>
      <b-button 
        v-if="isCompletedStep || completed"
        variant="outline-light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled"
        @click.prevent.stop="handleCancelChange">
        Annuleren
      </b-button>
      <b-button 
        v-if="! last"
        variant="light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled || disableNextBtn" 
        @click.prevent.stop="handleSubmitAndNext">
        Volgende stap
      </b-button>
      <b-button 
        v-if="last"
        variant="light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled || disableNextBtn"
        @click.prevent.stop="handleComplete">
        Afronden
      </b-button>
    </template>

    <template v-else-if="mayEdit && isCompletedStep">
      <b-button 
        v-if="position !== 'top'"
        variant="outline-light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled"
        @click.prevent.stop="handleStartCorrection">
        Corrigeren
      </b-button>
      <b-button 
        v-if="openForChange && ! last && position !== 'top'" 
        variant="outline-light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled"
        @click.prevent.stop="handleStartChange">
        {{ editLabel }}
      </b-button>
      <b-button 
        v-if="! last"
        variant="light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled || disableNextBtn"
        @click.prevent.stop="handleNext">
        Volgende stap
      </b-button>
    </template> 

    <template v-else-if="! last">
      <b-button 
        variant="light" 
        class="ml-3" 
        size="sm" 
        type="submit" 
        :disabled="disabled || ! isCompletedStep"
        @click.prevent.stop="handleNext">
        Volgende stap
      </b-button>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    /**
     * UI context
     */
    position: {
      type: String,
      default: 'top'
    },
    first: {
      type: Boolean,
      default: false
    },
    last: {
      type: Boolean,
      default: false
    },

    editLabel: {
      type: String,
      default: 'Wijzigen'
    },

    /**
     * Record Context
     */
    step: {
      type: [Number, String],
      required: true
    },
    currentStep: {
      type: [Number, String],
      required: true
    },
    locked: {
      type: Boolean,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    completed: {
      type: Boolean,
      required: true
    },
    disableNextBtn: {
      type: Boolean,
      required: true
    },
    mayEdit: {
      type: Boolean,
      required: true
    },
    openForChange: {
      type: Boolean,
      required: true
    },

    isCompletedStep: {
      type: Boolean,
      default: false
    },
    isAvailableStep: {
      type: Boolean,
      default: true
    }
  },
  // created() {
  //   console.log(this.$props)
  // },
  methods: {
    handleNext() {
      this.$emit('handleNext')
    },
    handlePrevious() {
      this.$emit('handlePrevious')
    },
    handleSubmitAndNext() {
      this.$emit('handleSubmitAndNext')
    },
    handleCancelChange() {
      this.$emit('handleCancelChange')
    },
    handleStartCorrection() {
      this.$emit('handleStartCorrection')
    },
    handleStartChange() {
      this.$emit('handleStartChange')
    },
    handleComplete() {
      this.$emit('handleComplete')
    }
  }
}
</script>

<style>

</style>