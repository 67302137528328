<template>
  <div>
    <strong class="Legend__title">Legenda</strong>
    <ul class="Legend__list">
      <li v-for="legend in legends" :key="legend.label">
        <img width="20" :src="image({ name: legend.image })" />
        <div>
          {{ legend.label }}
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { image } from '@/helpers/assets'

import { dataLayers } from '@/config'

export default {
  data() {
    let data = {
      /**
       * Legend items
       */
      legends: [{
        label: 'Bestaande laadpaal',
        image: 'chargingpoint-realized'
      }, {
        label: 'In voorbereiding',
        image: 'chargingpoint-in-progress'
      }, {
        label: 'Gevalideerde locatie',
        image: 'chargingpoint-definitive'
      }, {
        label: 'Voorgestelde locatie',
        image: 'chargingpoint-suggestion'
      }, {
        label: 'Onbeoordeelde aanvraag',
        image: 'request-open'
      }, {
        label: 'Geaccepteerde aanvraag',
        image: 'request-accepted'
      }, {
        label: 'Gekoppeld aan ander proces',
        image: 'request-connected'
      }, {
        label: 'Gekoppeld aan dit proces',
        image: 'request-selected'
      }]
    }

    // Check data layer config for optional layers
    ;[{
      label: 'Vergunningsgebieden',
      image: 'parking-permit-areas',
      id: 'parking-permit-areas'
    }].forEach(legend => {
      let config = dataLayers[legend.id] || {}

      if (config.active) {
        data.legends.push(legend)
      }
    })

    return data
  },
  methods: {
    image
  }
}
</script>

<style>

</style>