<template>
  <div class="GridOperatorApproval">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'GridOperatorApproval',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Netbeheerder'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'GridOperatorApproval'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "IsGridOperatorAccepted": {
            "type": "string",
            "title": "",
            "enumNames": [
                "Akkoord",
                "Afgekeurd"
            ],
            "enum": [
              'yes',
              'no'
            ],
            'format': 'radio'
        },
        "GridOperatorName": {
            "title": "Contactpersoon netbeheerder",
            "type": "string"
        },
        "GridOperatorComments": {
            "type": "string",
            "title": "Toelichting (incl. eventueel meerwerk)",
            "format": "textarea"
        },
        "GridOperatorApprovalRequestedDate": {
            "type": "string",
            "title": 'Reactie gevraagd',
            "format": "date"          
        },
        "GridOperatorApprovalReceivedDate": {
            "type": "string",
            "title": 'Reactie ontvangen',
            "format": "date"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'IsGridOperatorAccepted' ]
      },{
        "columns": '1fr',
        "fields": [ 'GridOperatorComments' ]
      },  {
        "columns": '1fr',
        "fields": [ "GridOperatorName" ]
      }, {
        "columns": '1fr',
        "fields": [ 'GridOperatorApprovalRequestedDate' ]
      }, {
        "columns": '1fr',
        "fields": [ 'GridOperatorApprovalReceivedDate' ]
      }],
      validation: {}
    }
  }
}
</script>