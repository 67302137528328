<template>
  <div class="MapBox">
    <div ref="container"></div>
    <slot v-if="loaded" />
  </div>
</template>

<script>
/**
 * @copyright MIT 
 * @author Wouter van Dam (wouter@journeyworks.nl)
 * 
 * This is a basic, generic Vue wrapper component for MapBox. 
 * This module is included in the source code of the application itself for simplicity and reliability
 */

import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css';

/**
 * The Mapbox instance should not be reactive
 * TODO: This caused issues when two or more maps were used in a single application
 */
// let map = false;

let devMode = process.env.NODE_ENV === 'development'

/**
 * A Generic MapBox wrapper component
 */
export default {
  name: 'MapBox',
  provide() {
    const self = this;
    return {
      get mapbox() {
        return self.mapbox;
      },
      get map() {
        return self.map;
      },
      get loaded() {
        return self.loaded
      }
    };
  },
  props: {
    accessToken: {
      type: String,
      required: true
    },
    mapStyle: {
      type: String,
      required: true
    },
    options: {
      type: Object,
      default: function() {
        return {}
      }
    }
  },
  data() {
    return {
      loaded: false,
      map: null,
      mapbox: null
    }
  },
  mounted() {
    if (devMode) console.log("Mounting Mapbox")

    this.mapbox = mapboxgl
    this.mapbox.accessToken = this.accessToken

    this.map = new this.mapbox.Map(
      Object.assign(this.options, {
        container: this.$refs.container,
        style: this.mapStyle,
        preserveDrawingBuffer: true // Enables export to png
      })
    )
    // TODO: This solution is not reliable. Find a solution that captures 'empty' tiles
    // this.map.on('error', function() {
    //   // console.log('A MapBox error event occurred.');
    // });
    this.map.on('load', () => {
      if (devMode) console.log("Mapbox Load event")
      
      this.loaded = true
      this.$emit('load', { map: this.map, mapbox: this.mapbox })
    })
  },
  beforeDestroy() {
    if (devMode) console.log("Destroying Mapbox")

    this.$nextTick(() => {
      if (this.map) this.map.remove()
    })
  }
}
</script>

<style>
.MapBox {
  width: 100% !important;
  height: 100% !important;  
}
.mapboxgl-map {
  width: 100% !important;
  height: 100% !important;
}

</style>
