


/**
 * Description of available query params
 * https://developers.google.com/maps/documentation/streetview/overview#url-parameters
 */
export const getStreetViewStaticURL = ({ lat, lng }) => {

  const baseURL = 'https://maps.googleapis.com/maps/api/streetview'

  // A4 is 210mm x 297mm - subtract left and right margin from A4 size
  let width = 2100 - (2 * 254) // 1592
  let height = 1000

  // Size is limited to 640x640
  // See: https://developers.google.com/maps/documentation/streetview/usage-and-billing#image-sizes
  // This limit used to be higher in their business plan, but that plan is no longer available for purchase
  const cap = 640
  height = Math.ceil(height / (width / cap))
  width = cap

  const queryStringSegments = {
    // Required
    location: `${lat},${lng}`,
    size: `${width}x${height}`,
    key: process.env.VUE_APP_GOOGLE_PUBLIC_API_KEY, // temp key restricted to home ip

    // Optional
    fov: 120, // max value, showing wide view
    return_error_code: true, // to avoid a gray placeholder image in case of error
    source: 'outdoor' // to avoid indoor and PhotoSpheres images
  }

  const queryString = Object.keys(queryStringSegments)
    .reduce((str, key) => `${str}&${key}=${queryStringSegments[key]}`, '?')
    .replace('?&', '?')
  
  const url = `${baseURL}${queryString}`
  return url
}