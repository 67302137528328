

export default {
  tenantName: 'Amsterdam',

  /**
   * Feature Access checks
   */ 
  access: {
    'requestlist': ['municipality'],
    'requestlocations': ['municipality'],
    'realisationlist': ['cpo', 'municipality'],
    'realisationbtn': ['municipality'],
    'realisationcomment': ['cpo', 'municipality'],
    'canConfirmTrafficDecisions': ['municipality'],
    'canChangeCommentStatus': ['municipality'],
    'canChangeStatus': ['municipality'],
    'automatedViews': [],
    'generateStartDoc': [],
    'admin': ['admin'],
    'reporting': ['admin'],
    'eventlog': []
  },


  /** 
   * Judgement Reasons
   */ 
  reasonOptions: [
    { value: 0, text: 'Gelegenheid op eigen terrein', short: 'Eigen parkeerplaats', custom: false },
    { value: 1, text: 'Geen parkeervergunning', short: 'Geen parkeervergunning', custom: false },
    { value: 2, text: 'Ongeldige documenten', short: 'Voertuigbewijs', custom: false },
    { value: 3, text: 'Verwijzing naar bestaande locatie', short: 'Verwijzing', custom: true, placeholder: 'Adres van bestaande locatie...' },
    { value: 11, text: 'Uw voertuig heeft onvoldoende elektrisch rijbereik', short: 'Rijbereik', custom: false },
    { value: 12, text: 'Anders, namelijk...', short: 'Anders', custom: true, placeholder: 'Een toelichting...' },
  ],


  /**
   * Monitoring config
   */ 
  monitoring: {
    enabled: !! process.env.VUE_APP_MONITORING_API_URL,
    limit: 0.5
  },


  /**
   * search
   */ 
  search: {
    requests: {
      keys: ['summary.name', 'summary.address', 'case_ref', 'ref', 'uuid', 'vehicle.numberplate', 'search.numberplate', 'search.postalcode'],
      placeholder: 'Zoek op referentie, naam, adres of kenteken'
    },
    realisations: {
      keys: ['case_ref', 'address', 'status.label', 'values.Location.ChargePointOperator', 'ref', 'uuid'],
      placeholder: 'Zoek op referentie, adres of stap'
    }
  },


  /** 
   * onHold & cancel reasons
   */ 
  onHoldReasons: [{
    text: 'Voorlopige Voorziening (VOVO)',
    value: 'vovo'
  }, {
    text: 'Overige',
    value: 'other'
  }],

  cancelReasons: [{
    text: 'Overige',
    value: 'other'
  }],


  /**
   * Municipality Options
   */
  municipalityOptions: [
    { value: "0363", text: 'Amsterdam', disabled: false }
  ],


  /** 
   * Steps 
   */
  steps: [
    {
      step: 1,
      uuid: '51779421-2d39-4092-9068-cbe5966e5c04',
      component: require('@/views/amsterdam/RealisationStep1'),
      version: 1,
      label: 'Locatievoorstel',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 2,
      uuid: '2b26ca6c-95ee-42ac-b419-093cf3029e21',
      component: require('@/views/amsterdam/RealisationStep2'),
      version: 1,
      label: 'Check stadsdeel en netbeheerder',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 3,
      uuid: '8693b388-5182-4357-b1ed-118982249902',
      component: require('@/views/amsterdam/RealisationStep3'),
      version: 1,
      label: 'Advies CPO',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 4,
      uuid: '3e568133-b001-4c67-94de-2a84ab4c5b1a',
      component: require('@/views/amsterdam/RealisationStep4'),
      version: 1,
      label: 'Beoordeling advies CPO',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 5,
      uuid: 'ff3c45ad-6014-4c14-b4eb-2b8b22612ad9',
      component: require('@/views/amsterdam/RealisationStep5'),
      version: 1,
      label: 'Verkeersbesluit en opdrachtbevestiging',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 6,
      uuid: '4ff4ed2f-4ac7-497e-b61d-c5bf33320f54',
      component: require('@/views/amsterdam/RealisationStep6'),
      version: 1,
      label: 'Aanvragen netaansluiting',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 7,
      uuid: '8fe18012-b5ab-4127-a1d1-f624d07fc19a',
      component: require('@/views/amsterdam/RealisationStep7'),
      version: 1,
      label: 'Plan realisatie',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 8,
      uuid: '93ecdcff-a0fa-44f3-94c2-b6284d9f19c8',
      component: require('@/views/amsterdam/RealisationStep8'),
      version: 1,
      label: 'Realisatie en inbedrijfstelling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 9,
      uuid: 'ffd47b72-ca4f-499f-a127-e2fe2c892620',
      component: require('@/views/amsterdam/RealisationStep9'),
      version: 1,
      label: 'SAT',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    }
  ],
  dataLayers: {
    'parking-permit-areas': {
      active: true,
      url: 'mapbox://evtools.nl_g_0363_parkeerzones',
      source: 'nl_g_0363_parkeerzones'
    }
  }
}


