<template>
  <div v-if="isOpen">
    <h4 class="mb-3">Beoordeling {{isEditing ? 'herzien' : ''}}:</h4>

    <div class="mb-3">
      <b-button
        v-for='status in judgementOptions'
        class="status"
        size="sm"
        :disabled="busy"
        :variant="judgement === status.id ? status.variant : 'light'"
        :key="status.name"
        @click="changeJudgementStatus({ status: status.id })"
      >
        <b-icon :icon="status.icon"></b-icon>
        {{ status.label }}
      </b-button>

      <b-button
        v-if="isEditing"
        class="status cancel-editing-judgement float-right"
        variant="light"
        size="sm"
        key="cancel-editing-judgement"
        v-b-tooltip.hover
        title="annuleren"
        @click="cancelEditingJudgement"
      >
        <b-icon icon="x"></b-icon>
      </b-button>
    </div>

    <!-- judgement 4: reject request -->
    <template v-if="judgement === 4">
      <b-form-select class="mb-4" v-model="reason" :options="reasonOptions" :select-size="4" :disabled="busy"></b-form-select>
    </template>
    <template v-if="judgement === 4 && isCustomReason">
      <b-form-textarea class="mb-3" v-model="remarks" :state="remarkIsValid" :disabled="busy" :placeholder="reasonPlaceholder"></b-form-textarea>
    </template>

    <b-alert class="mb-3" dismissible :show="feedback !== ''" :variant="feedbackVariant" @dismissed="feedback=''">
      {{ feedback }}
    </b-alert>

    <b-button
      class="mt-3 mb-3"
      :disabled="! allowConfirmation || busy"
      size="sm"
      variant="dark"
      @click="confirmJudgementStatus"
    >
      <template v-if="judgement === 5">
        <b-icon icon="trash-fill"></b-icon>
        <span class="ml-2">
          Bevestigen het verwijderen
        </span>
      </template>
      <template v-else>
        <b-icon icon="envelope"></b-icon>
        <span class="ml-2">
          Bevestig en verstuur de beoordeling
        </span>
      </template>
    </b-button>

  </div>
</template>

<script>

/**
 * Judgement of a request is a uniform mechanism for all tenants
 *  What differs per tenant is the list of reasons for rejection
 *  Some rejection reasons allow for an explanation (see remark watcher for input validation)
 */

import { unicodeString } from '@/services/validation'

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'Judgement',
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      /** 
       * Form state
       **/ 
      busy: false,
      feedback: '',
      feedbackVariant: 'danger',

      /**
       * Form input
       */
      judgement: null,
      reason: null,
      remarks: '',

      remarkIsValid: null
    }
  },
  computed: {
    ...mapGetters('requests', [
      'statusOptions', 
      'statusOptionById', 
      'reasonOptions'
    ]),

    status() {
      return this.statusOptionById({ id: this.request.status })
    },
    isEditing() {
      return this.request.editJudgement
    },
    isOpen() {
      return this.status.id === 2 || this.isEditing
    },
    isAccepted() {
      return this.status.id === 3
    },

    /**
     * Judgement
     */
    judgementOptions() {
      // status 2 = open for judgement
      return this.statusOptions.filter(option => option.id !== 2)
    },

    /**
     * Check whether all conditions are met
     */
    allowConfirmation() {
      if (
        this.isEditing
        && (this.judgement === this.request.status) // don't allow re-evaluations with same status
        && (this.request.status === 4 && this.reason === this.request.judgement.reason) // except if reason for rejection has changed
      ) {
        return false
      }

      return [3, 5].includes(this.judgement)
        || (this.judgement === 4 && this.isValidReason && (! this.isCustomReason || this.remarkIsValid))
    },
    isValidReason() {
      return !! this.reasonOptions.find(reason => reason.value === this.reason)
    },
    isCustomReason() {
      let reason = this.reasonOptions.find(reason => reason.value === this.reason)
      return reason ? !! reason.custom : false
    },
    reasonPlaceholder() {
      let reason = this.reasonOptions.find(reason => reason.value === this.reason)
      return reason ? reason.placeholder || 'Een toelichting' : 'Een toelichting'
    },
  },
  watch: {
    remarks(remarks) {
      if (remarks === '') {
        this.remarkIsValid = null
      } else {
        this.remarkIsValid = remarks.length < 4096 && unicodeString(remarks)
      }
    }
  },
  methods: {
    ...mapMutations('requests', [
      'updateRequest'
    ]),

    changeJudgementStatus({ status }) {
      this.judgement = (status === this.judgement) ? null : status
    },

    /**
     * Update the request
     */
    confirmJudgementStatus: async function() {
      if (this.allowConfirmation && ! this.busy) {
        
        this.busy = true

        try {
          // fix: reason with id 0 is evaluated to false
          let reasonLabel = ! Number.isNaN(this.reason) ? this.reasonOptions.filter(reason => reason.value === this.reason) : null
          reasonLabel = (reasonLabel && reasonLabel.length) ? reasonLabel[0].short : ''

          const payload = {
            ref: this.request.ref,
            data: {
              isCorrection: this.request.editJudgement,
              judgement: this.judgement,
              judgementLabel: this.statusOptionById({ id: this.judgement }).name,
              reason: this.judgement === 4 ? this.reason : null,
              reasonLabel: this.judgement === 4 ? reasonLabel : '',
              remarks: (this.judgement === 4 && this.isCustomReason) ? this.remarks : ''
            }
          }
          const token = await this.$auth.getTokenSilently();
          const api = await fetch('/api/judgement', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
          })
          const response = await api.json()

          if (response.statusCode !== 200) { 
            throw new Error('Something went wrong')
          }

          // Update the request in the local store
          this.updateRequest({
            ref: payload.ref,
            data: {
              editJudgement: false,
              status: {
                id: payload.data.judgement,
                label: payload.data.judgementLabel
              },
              judgement: {
                isCorrection: this.request.editJudgement,
                reason: payload.data.reason,
                reasonLabel: payload.data.reasonLabel,
                remarks: payload.data.remarks,
                processed_at: response.data.timestamp
              },
            }
          })
          
        } catch(e) {
          console.log(e)
          this.feedbackVariant = 'danger'
          this.feedback = "Er ging iets fout. Data niet opgeslagen."
        }
        this.busy = false
      }
    },
    cancelEditingJudgement() {
      this.updateRequest({
        ref: this.request.ref,
        data: {
          editJudgement: false,
        }
      })
    },
  }
}
</script>

<style lang="scss">
.cancel-editing-judgement {
  &:hover {
    cursor: pointer;
  }
}
</style>