
import { formatPostalCode } from '@/helpers/string'

/**
 * A Location is part of Realisation record
 *  One Realisation record can hold multiple Locations
 */
let Location = function({
  data, copy
}) {
  let model = {}

  // short circuit making copies
  if (copy || this.LooksAndQuacksLikeCopy({ data })) {
    model = this.makePlainCopy({ data })
  } else {

    model = this.commonStructure({ data })

    /**
     * Tenant specific fields
     */
    model.ChargePointOperator = "PARKnCHARGE"
    model.GridOperator = data.GridOperator || ""
    model.Municipality = data.Municipality || null
    model.FinUnit = data.FinUnit || ""
  }

  // 
  Object.keys(model).forEach(key => {
    this[key] = model[key]
  })
}


Location.prototype.getAddress = function() {
  try {
    let location = this.Location
    let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
    return (`${location.StreetName || ''} ${number}, ${formatPostalCode(location.PostalCode || '')} ${location.CityName || ''}`).trim().trim(',')
  } catch(e) {
    return false
  }
}


Location.prototype.LocationCopy = function({ version }){
  let oldData = JSON.parse(JSON.stringify(this))

  let data = {
    Location: oldData.Location,
    Remarks: oldData.Remarks, 
    ChargePointOperator: oldData.ChargePointOperator,
    GridOperator: oldData.GridOperator, 
    ParkingSpots: oldData.ParkingSpots,
    CPOAdvice: {
      SumAdditionalCost: oldData.CPOAdvice.SumAdditionalCost || '',
      AdditionalCostDescription: oldData.CPOAdvice.AdditionalCostDescription || ''
    },
    LocationPreparation: {
      RequiresLocationPreparation: oldData.LocationPreparation.RequiresLocationPreparation || [],
      OtherReason: oldData.LocationPreparation.OtherReason || ''
    },
    Docs: {
      ParkingSpots: oldData.Docs.ParkingSpots || [],
      // Underground: oldData.Docs.Underground || [],
      CPOAdditional: oldData.Docs.CPOAdditional || []
    },
    FinUnit: oldData.FinUnit,
    Municipality: oldData.Municipality
  }
  
  data.version = version

  return new Location({ data, copy: true })
}


export default Location;