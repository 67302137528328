<template>
  <div class="DateOfEnergyConnection">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DateOfEnergyConnection',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'DateOfCommissioning'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "DateOfEnergyConnection": {
          "type": "string",
          "title": "Datum aanmelding energieleverancier",
          "format": 'date',
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'DateOfEnergyConnection' ]
      }],
      validation: {}
    }
  }
}
</script>