<template>
  <BlockRepeater 
    class="" 
    title=""
    inline
    label="Voeg een parkeerplaats toe"
    :path="path" 
    :values="values"
    :disabled="disabled" 
    v-slot="slotProps">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'Parkingspots',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Parkingspots'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "Identifier": {
          "title": "",
          "type": "string"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'Identifier' ]
      }]
    }
  },
  // created() {
  //   console.log(this.path)
  // }
}
</script>

<style>
/**
 * TODO: @WouterSchaap: The BlockRepeater component (inline) is copied from RVB Components repo. Styling should be useable in both without modifications like those below
 */
.BlockRepeater.inline .BlockRepeater__add {
  padding-left: 0
}
.BlockRepeaterItem.inline .BlockRepeaterItem__body {
  flex-grow: 1
}
.BlockRepeaterItem.inline .BlockRepeaterItem__footer {
  padding-bottom: 17.5px !important;
}
</style>