<template>
  <div class="FileUpload" v-if="reference">
    <p class="FileUpload__hint">
      {{ label }}
    </p>
    <div v-if="!locked && !disabled" class="upload" :class="{'upload--active': dragging}">
      <input
        class="upload__input"
        type="file"
        :name="`f-${doctype}`"
        :id="`f-${doctype}`"
        @change="fileInput"
        multiple
        :accept="acceptedInputTypes"
      />
      <label
        class="upload__label upload-label"
        :for="`f-${doctype}`"
        @drop="drop"
        @dragover="dragover"
        @dragenter="dragenter"
        @dragleave="dragleave"
      >
        <span v-if="!activeDragFiles" class="d-flex flex-column">
          <span class='upload-label__text'>Sleep de bestanden in dit vlak of</span>
          <span>
            <span class="textLink">selecteer bestanden</span>
            <b-icon icon="download"></b-icon>
          </span>
        </span>
        <span v-else>{{ activeDragFiles }} bestanden geselecteerd</span>
      </label>
    </div>
    <div v-if="!locked && !disabled" class="doc-type">
      <button class="doc-type__switch" @click.prevent="docTypeVisible = !docTypeVisible">
        Welk soort documenten zijn toegestaan?
        <b-icon icon="chevron-down" v-if="docTypeVisible" />
        <b-icon icon="chevron-top" v-else/>
      </button>
      <p class="doc-type__text" v-if="docTypeVisible">
        {{ consiseInputTypes }}
      </p>
    </div>
    <ul v-if="! preview" class="file-list">
      <li
        class="file"
        v-for="(file) in listedFiles"
        :key="`file_${file.id}`"
        :class="{
          'file--in-progress': file.progress !== 100 && !file.error,
          'file--error': file.error,
        }"
      >    
        <div class="file__info" v-if="file.progress === 100 && !file.error">
          <span>
            <b-icon icon="file-earmark-text"></b-icon>
            {{ file.name }}
          </span>
          <br />
          <button v-if="!locked && !disabled" class="text-button" @click.prevent="removeFile(file.id)">
            <b-icon icon="x"></b-icon><span class="text-button__label">Wissen</span>
          </button>
          <button class="text-button" @click.prevent="handleDownload({ id: file.id, ext: file.ext || null })">
            <b-icon icon="download"></b-icon><span class="text-button__label">Download</span>
          </button>
        </div>
        <div class="file__info" v-else-if="!file.error">
          <span>{{ file.name }} wordt geüpload</span>
          <button class="text-button" @click.prevent="removeFile(file.id)">
            <b-icon icon="x"></b-icon><span class="text-button__label">Annuleren</span>
          </button>
        </div>
        <div class="file__info" v-else>
          <!-- <span>
            <b-icon icon="file-earmark-text"></b-icon>{{ file.name }}
          </span>
          <br/> -->
          <span class="file__error">{{ file.error }}</span>  
        </div>
        <div class="file__progress">
          <div
            class="file__progress-bar"
            v-if="file.progress !== 100 && !file.error"
            :style="{ width: file.progress + '%' }"
          ></div>
        </div>
      </li>
    </ul>
    <ul v-else class="file-list-preview">
      <li 
        class="preview"
        :class="{wide: wide}"
        v-for="file in listedFiles"
        :key="`file_${file.id}`">
        <div v-if="file.loaded">
          <b-aspect aspect="16:9">
            <img :src="file.url" />
          </b-aspect>

          <div class="btns">
            <button v-if="!locked && !disabled" class="text-button" @click.prevent="removeFile(file.id)">
              <b-icon icon="x"></b-icon><span class="text-button__label">Wissen</span>
            </button>
            <button class="text-button" @click.prevent="handleDownload({ id: file.id, ext: file.ext || null })">
              <b-icon icon="download"></b-icon><span class="text-button__label">Download</span>
            </button>
          </div>
        </div>
        
        <div v-else>
          <b-aspect aspect="16:9">
            <img src="data:image/gif;base64,R0lGODdhpwBTAJEAALS0tLS0tO7u7gAAACwAAAAApwBTAAACt4SPqcvtD6OctNqLs968+w+G4kiW5omm6sq27gvH8kzX9o3n+s73/g8MCofEovGITCqXzKbzCY1Kp9Sq9YrNarfcrvcLDovH5LL5jE6r1+y2+w2Py+emgP2eCSjudrpB74cDGGgzSEhjeJB4mLJ44ugYZxg5Qcn4MJlgObKp1tnxSRh6ScKXOFoBiUrK2ur6ChsrO0tba3uLm6u7y9vr+wscLDxMXGx8jJysvMzc7PwMHS09TR1SAAA7" />
          </b-aspect>
          Een moment geduld a.u.b.
        </div>
      </li>
    </ul>
  </div>
  <div v-else class="FileUpload">
    <p class="FileUpload__hint">
      {{ label }}
    </p>
    <b-alert show variant="info">Het locatievoorstel moet eerst een keer worden opgeslagen, waarna bestanden kunnen worden geupload.</b-alert>
  </div>
</template>

<script>
import { getDownloadURL } from '@/services/aws'

import { saveAs } from 'file-saver';

export default {
  name: "FileUpload",
  props: {
    label: String,
    hint: String,
    doctype: String,

    uuid: {
      type: String,
      required: true
    },
    files: {
      type: Array
    },
    /**
     * The minimal file id is provided separately because this counter spans multiple location versions
     */
    minFileId: {
      type: Number,
      required: true
    },

    // Only available to existing record...
    reference: {
      type: String,
      default: null
    },
    // Only available to existing record...
    casefull: {
      type: String,
      default: null
    },

    disabled: {
      type: Boolean,
      default: false
    },
    locked: {
      type: Boolean,
      default: false
    },
    
    /**
     * Whether to render a preview of the uploaded image
     */
    preview: {
      type: Boolean,
      default: false
    },
    wide: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      internalFiles: [],
      listedFiles: [],
      dragging: false,
      activeDragFiles: null,
      dragEnterTarget: null,
      counter: 0,
      docTypeVisible: false,

      loading: true
    };
  },
  computed: {
    acceptedInputTypes() {
      return this.preview 
        ? ["image/jpg", "image/jpeg", "image/png"]
        : ["image/jpg", "image/jpeg", "image/png", "application/pdf"]
    },
    consiseInputTypes() {
      const pipe = (...cb) => data => cb.reduce((acc, curr) => curr(acc) ,data)
      const mpr = cb => data => data.map(cb);
      const split = mpr(p => p.split('/'));
      const getLast = mpr(p => p.pop());
      const addDot = mpr(p => '.' + p);
      const flatComma = p => p.reduce((acc, curr) => acc + ', ' + curr);
      const convertInputType = pipe(
        split,
        getLast,
        addDot,
        flatComma
      );
      return convertInputType(this.acceptedInputTypes);
    },
    nextId() {
      let nextId = this.internalFiles.reduce((result,file) => Math.max(result, file.id + 1), this.minFileId + 1) 
      // console.log("next-id", nextId)
      return nextId
    }
  },
  watch: {
    files: {
      handler(files) {
        if (! Array.isArray(files) || files.length === 0) {
          this.internalFiles = []
          return
        }

        // console.log(files, this.doctype)

        this.internalFiles = files.map(id => {
          let ext = null

          if (typeof id !== 'number') {
            ext = id.ext || null
            id = id.id
          }
          return {
            id,
            ext,
            error: null,
            progress: 100,
            name: `${this.casefull}-${this.doctype.replace('-alt', '')}-${id}`,
            file: null,
            url: null,
            loaded: false
          }
        })
      },
      deep: true
    },
    internalFiles: {
      handler(){
        this.listedFiles = this.internalFiles.filter(file => file.removed !== true && file.error !== true)
        
        // console.log(this.internalFiles, this.listedFiles, this.doctype)

        this.$emit('files', { 
          doctype: this.doctype, // Intentional no replace -alt
          files: this.listedFiles // .map(file => file.id) 
        })
      },
      deep: true,
    },
    listedFiles: {
      async handler() {

        if (! this.preview) return

        for (const [i, file] of this.listedFiles.entries()) {
          if (i === 'never') {
            console.log(i)
          }
          
          if (file.url === null) {
            file.url = await this.getFileUrl({ id: file.id, ext: file.ext || null })
            file.loaded = true
          }
        }
      },
      deep: true
    }
  },
  mounted() {
    if (!Array.isArray(this.files)) return 

    this.internalFiles = this.files.map(id => {
      let ext = null

      if (typeof id !== 'number') {
        ext = id.ext || null
        id = id.id
      }
      return {
        id,
        ext,
        error: null,
        progress: 100,
        name: `${this.casefull}-${this.doctype.replace('-alt', '')}-${id}`,
        file: null,
        url: null,
        loaded: false
      }
    })
  },
  methods: {
    // ...mapMutations('requests', ['setFilesDetails']),
    addFile(f) {

      if (this.locked || this.disabled) return 

      // console.log(f)
      const error = this.checkFileType(f);
      const id = this.nextId
      
      let ext = null
      try {
        ext = f.type.split('/')[1].toLowerCase()
      } catch(e) {
        // nothing
      }

      const file = { 
        id,
        ext,
        error, 
        progress: 0, 
        name: f.name,
        loaded: false,
        file: f // File object
      }
      this.internalFiles.push(file);
      const index = this.internalFiles.findIndex(file => file.id === id)

      if (file.error) return
      // console.log(index, id)
      
      const reader = new FileReader();

      reader.addEventListener('loadend', () => {
        try {
          fetch(`${process.env.VUE_APP_AWS_ENDPOINT}/backoffice-upload`, {
            method: "POST",
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              ref: this.reference || false,
              uuid: this.uuid,
              casefull: this.casefull || false,
              index: id,
              doctype: this.doctype.replace('-alt', ''),
              type: f.type
            })
          })
          .then((response) => {
            if (! response.ok) throw new Error("not ok")

            return response.json();
          })
          .then((json) => {
            if (! json.uploadURL) throw new Error("not ok")
            
            return new Promise((resolve) => {
              const request = new XMLHttpRequest()
              request.open("PUT", json.uploadURL)

              request.upload.addEventListener('progress', (e) => {
                let file = this.internalFiles[index]
                file.progress = (e.loaded / e.total) * 100;
                this.internalFiles.splice(index, 1, file)
                // this.internalFiles[index].progress = (e.loaded / e.total) * 100;
              })
              request.addEventListener('load', () => {
                if (request.status < 300) {
                  resolve(request)
                } else {
                  throw new Error("not ok")
                }
              })
              request.addEventListener('error', () => {
                throw new Error('not ok')
              })
              request.addEventListener('abort', () => {
                throw new Error('not ok')
              })
              request.send(new Blob([reader.result], {type: f.type}))
            })
          })
          .then(async () => {
            // Make certain the progress is 100
            let file = this.internalFiles[index]
            file.progress = 100

            if (this.preview) {
              file.url = await this.getFileUrl({ id: file.id, ext: file.ext || null })
              file.loaded = true
            } 

            this.internalFiles.splice(index, 1, file)
            
            // this.internalFiles[index].progress = 100
          })
          .catch(() => {
            let file = this.internalFiles[index]
            file.error = 'Het uploaden van het document is mislukt'
            this.internalFiles.splice(index, 1, file)
            // this.internalFiles[index].error = 'Het uploaden van het document is mislukt'
          });
        } catch(e) {
          let file = this.internalFiles[index]
          file.error = 'Het uploaden van het document is mislukt'
          this.internalFiles.splice(index, 1, file)
          // this.internalFiles[index].error = 'Het uploaden van het document is mislukt'
        }
      });
      reader.readAsArrayBuffer(f);
    },
    fileInput(event) {
      const files = event.target.files;
      if (!files) return;
      [...files].map(this.addFile);
    },
    drop(event) {

      if (this.disabled || this.locked) return 

      event.preventDefault();
      const droppedFiles = event.dataTransfer.files;
      this.dragging = false;
      this.activeDragFiles = null;
      if (!droppedFiles) return;
      [...droppedFiles].map(this.addFile);
    },
    checkFileType(file) {
      return this.acceptedInputTypes.find((type) => type === file.type)
        ? null
        : "Dit type document kan niet worden geüpload"
    },
    dragenter(event) {
      event.preventDefault();
      if(!this.dragEnterTarget) {
        this.dragging = true;
        this.dragEnterTarget = event.target;
        this.activeDragFiles = event.dataTransfer.items.length;
      } else if (event.srcElement !== this.dragEnterTarget) {
        this.counter = 1;
      }
    },
    dragover(event){
      event.preventDefault();
    },
    dragleave(event) {
      event.preventDefault();
      if(!this.counter && event.srcElement === this.dragEnterTarget) {
        this.dragEnterTarget = null;
        this.dragging = false;
        this.activeDragFiles = 0;
      } else {
        this.counter = 0;
      }
    },
    removeFile(id) {
      if (this.disabled || this.locked) return 
      
      const index = this.internalFiles.findIndex(file => file.id === id)
      let file = this.internalFiles[index]
      file.removed = true
      this.internalFiles.splice(index, 1, file)
    },

    getFileUrl: async function({ id, ext }) {
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await getDownloadURL({
          token,
          ref: this.reference,
          uuid: this.uuid,
          casefull: this.casefull,
          index: id,
          doctype: this.doctype.replace('-alt', ''),
          ext 
        })

        return response.downloadURL

      } catch(e) {

        // console.log(e)
      }
    },

    /**
     * Get temporary download link & start download
     */
    handleDownload: async function({ id, ext }) {
      try {
        const token = await this.$auth.getTokenSilently();
        const response = await getDownloadURL({
          token,
          ref: this.reference,
          uuid: this.uuid,
          casefull: this.casefull,
          index: id,
          doctype: this.doctype.replace('-alt', ''),
          ext 
        })

        // Chrome, FF, Edge
        if (window.browser && window.browser.downloads) {
          window.browser.downloads.download({
            url : response.downloadURL
          })
        } else { // Safari, etc.
          const res = await fetch(response.downloadURL)
          const blob = await res.blob()
          saveAs(blob, response.filename)
        }
      } catch(e) {
        // console.log(e)
        this.$bvModal.show('file-error')
      }
    },
  },
};
</script>

<style lang="scss">
.FileUpload {
  max-width: 620px;
  min-width: 70px;
  // margin-bottom: 30px;

  .FileUpload__question {
    margin-bottom: 0;
  }
  .FileUpload__hint {
    margin-bottom: 10px;
  }

  .file-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .file {
    margin-bottom: 20px;
    .file__info {
      display: grid;
      grid-template-columns: 1fr auto;
      gap: 10px;
      font-size: 16px;
      font-weight: 600;
      margin-left: 0;
      margin-bottom: 5px;

      > :first-child {
        display: grid;
        grid-template-columns: auto 1fr;
        align-items: center;
        word-break: break-word;
      }

      span > .b-icon {
        margin-right: 0.3em;
      }
    }
    .file__progress {
      background-color: $neutral-grey2;
      display: block;
      width: 100%;
      min-height: 2px;
    }

    .file__progress-bar {
      background-color: $neutral-grey3;
      display: block;
      height: 4px;
    }

    &--in-progress {
      .file__progress {
        background-color: $neutral-grey1;
      }
    }

    &--error {
      color: $primary-red;
      fill: $primary-red;

      @include breakpoint('s') {
        .file__info {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  .upload {
    background-color: $neutral-grey1;
    text-align: center;
    margin-bottom: 10px;
    height: 100px;

    .upload__input {
      opacity: 0;
      position: absolute;
      z-index: -1;

      &:focus,
      &:active {
        + .upload__label {
          outline: 2px solid $primary-black;
        }
      }
    }

    .upload__label {
      display: flex;
      /* padding: 23px; */
      height: 100%;
      cursor: pointer;
      justify-content: center;
      align-items: center;

      > span {
        display: inline-flex;
        align-items: center;

        > * {
          margin-left: 5px;
        }
      }

      .b-icon {
        margin-left: 0.3rem;
        fill: $primary-darkblue;
      }
    }

    .upload-label {
      @include breakpoint('s') {
        .upload-label__text {
          display: none;
        }
      }
    }

    &.upload--active {
      background-color: $neutral-grey2;
    }

    &:hover {
      .textLink {
        color: $primary-red;
      }

      .b-icon {
        fill: $primary-red;
      }
    }
    .textLink {
      color: $primary-darkblue;
      text-decoration: underline;
    }
  }
  .doc-type {
      margin-bottom: 10px;
    .doc-type__switch {
      display: flex;
      align-items: center;
      background-color: transparent;
      font: inherit;
      border: none;
      cursor: pointer;
      font-size: 16px;
      line-height: 22px;
      fill: $primary-darkblue;
      color: $primary-darkblue;
      .b-icon {
        margin-left: 0.3rem;
      }

      &:focus, &:hover {
        outline: 3px solid $primary-black;
      }
    }
  }
  .text-button {
    display: flex;
    align-items: center;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: $primary-darkblue;
    fill: $primary-darkblue;
    background-color: transparent;
    border: 0;
    border-radius: 0;
    cursor: pointer;

    .text-button__label {
      @include breakpoint('s') {
        display: none;
      }
    }

    &:hover {
      color: $primary-red;
      fill: $primary-red;
    }

    &:focus,
    &:active {
      outline: 2px solid $primary-black;
    }

    .b-icon {
      margin-right: 0.3em;
    }
  }
  .b-icon {
    width: 14px;
  }

  .file-list-preview {
    margin: 0;
    padding: 0;
  }
  .preview {
    list-style: none;
    margin: 0;
    padding: 10px;
    background: $neutral-grey1;
    position: relative;
    display: inline-block;
    width: calc(50% - 5px);
    margin-bottom: 1rem;

    &:nth-child(2n + 2) {
      margin-left: 10px;
    }

    &.wide {
      width: 100%;
      margin-left: 0;

      .btns {
        display: flex;
        justify-content: space-between;

        button {
          display: inline-block;
        }
      }
    }
    

    .b-aspect-content {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      display: inline-block;
      margin: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }
}
</style>