<template>
  <b-tab v-if="showTab">
    <template #title>
      <b-icon class="mr-2" icon="info-circle-fill"></b-icon>
      Kenmerken
    </template>

    <table class="table table-striped table-sm mb-4">
      <tbody>
        <tr v-for="(val, key) in summary" :key="key">
          <th scope="row">{{ key }}</th>
          <td v-if="key === 'Adres'">
            {{ val }}
            <br />
            <a target="_blank" :href='`https://www.google.com/maps/search/?api=1&amp;query=${encodeURIComponent(val)}`'>
              Open adres in Google Maps <b-icon icon="box-arrow-up-right"></b-icon>
            </a>
          </td>
          <td v-else>{{ val }}</td>
        </tr>
      </tbody>
    </table>

    <RealisationStatusPanel v-if="hasCaseRef" class="mt-5 mb-5" :uuid="uuid" />
  </b-tab>
</template>

<script>

import RealisationStatusPanel from '@/components/realisation/RealisationStatusPanel.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationSidebarRVBTab',
  components: {
    RealisationStatusPanel
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getLabelsByPidUuid'
    ]),
    uuid() {
      return this.record.uuid
    },
    hasCaseRef() {
      return !! this.record.case_ref
    },
    location() {
      try {
        return this.record.CurrentLocation
      } catch(e) {
        return {}
      }
    },
    showTab() {
      return process.env.VUE_APP_TENANT === 'rvb'
    },
    summary() {
      if (! this.hasCaseRef) {
        return {
          
        }
      }

      try {
        let pid = this.location?.Application?.PidUuid
        let summary = {
          Adres: this.record.address,
          PID: this.getLabelsByPidUuid[pid] || '',
          Gebouwnummer: this.location?.Object?.Address?.BuildingCode,
          Regio: this.location?.Object?.Address?.RVBRegion,
        }
        summary['Klant'] = this.location?.Object?.Address?.Customer
        
        // summary['RPL'] = this.location.Address.RegionalProjectLeader
        summary['Referentie'] = this.record.case_ref

        return summary
      } catch(e) {
        // console.log(e)

        return {}
      }
    }
  }
}
</script>
