




export default function ({
  ref, data
}) {
  
  // console.log("Request Data", data)

  const contact = data.baseData ? (data.baseData.contact || {}) : {}
  const addressData = data.baseData ? data.baseData.address : {}

  let address = `${addressData.street} ${addressData.streetNumber} ${addressData.streetNumberSuffix || ''}`.trim()
  address = `${address}, ${addressData.postalCode}`
  address = `${address}, ${addressData.city}`
  

  // console.log(addressData)

  let common = this.commonStructure({ ref, data })

  data = Object.assign(common, {
    tenant: 'park-n-charge',
    contact: contact,
    address: addressData,

    additional: data.additionalData,

    summary: Object.assign(common.summary, {
      name: contact.name,
      address,
    }),
    search: {
      postalcode: data.baseData.address && data.baseData.address.postalCode ? data.baseData.address.postalCode.replace(' ', '').trim() : '',
      city: data.baseData.address && data.baseData.address.city ? data.baseData.address.city.toLowerCase().trim() : ''
    },

    coordinates: {
      nomatch: data.processMeta && data.processMeta.geocoder ? (data.processMeta.geocoder.nomatch || false) : false,
      lat: data.baseData.coordinates ? (data.baseData.coordinates.lat || null) : null,
      lng: data.baseData.coordinates ? (data.baseData.coordinates.lng || null) : null
    },
    submitted_at: common.created_at
  })

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}


