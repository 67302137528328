<template>
  <div v-if="hasAccess">
    <h3 id="table_5" class="mt-3">
      Aanvragen per klant
    </h3>
    <TableManager 
      ref="TableManager"
      :loading="loading"
      :sourceRecords="recordData" 
      category="rvb-customer-reporting"
      uuid="b8457838-bb24-4a90-8d8f-a12c2a02d075" />

  </div>
  <div v-else>
    U heeft geen toegang tot klantrapportages
  </div>
</template>

<script>
import { RPL } from '@/config'

import TableManager from '@/components/Table/TableManager'

import { mapGetters } from "vuex";

export default {
  components: { 
    TableManager
  },
  computed: {
    ...mapGetters('realisation', [
      'records',
      'loading'
    ]),
    ...mapGetters('user', [
      'securityCheck'
    ]),
    ...mapGetters('tenant', [
      'getLabelsByPidUuid'
    ]),
    hasAccess() {
      return this.securityCheck({ attribute: 'customerreports' })
    },
    recordData() {
      return this.records.map(record => {

        // let isCompleted = record.CurrentLocation?.Progress?.AllPartiesSignedDate || false
        let obj = record.CurrentLocation?.Object || {}
        let address = obj?.Address || {}
        let parking = record.CurrentLocation?.ParkingAndChargePoints || {}
        let buildingOptions = record.CurrentLocation?.BuildingOptions || {}

        // ... 
        let PIDNumber = record.CurrentLocation?.Application?.PidUuid || false
        if (PIDNumber) {
          PIDNumber = this.getLabelsByPidUuid[PIDNumber] || '-'
        }
        let _data = {
          PIDNumber: PIDNumber || '-',

          StreetName: address.StreetName,
          RVBRegion: address.RVBRegion,
          RegionalProjectLeader: this.RPLAuthToName({ id: address.RegionalProjectLeader || '' }),

          ObjectManager: obj.ObjectManager,
          AssetManager: obj.AssetManager,

          NumberOfExistingChargePoints: parking.NumberOfExistingChargePoints,
          ChargePointNeedsGovernmentOffices: parking.ChargePointNeedsGovernmentOffices,
          ExtraNeedChargePoints: parking.ExtraNeedChargePoints,

          PowerOption: this.PowerOptionToLabel({ option: buildingOptions.PowerOption || '' }),
          FuseBoxOption: this.FuseBoxOptionToLabel({ option: buildingOptions.FuseBoxOption || '' }),
          AllPartiesSignedDate: record.CurrentLocation?.Progress?.AllPartiesSignedDate,

          Customer: address.Customer,

          // Status info
          status: record.status
        }

        return _data
      })
    }
  },

  methods: {
    RPLAuthToName({ id }) {
      let user = RPL.find(user => user.id === id)
      if (user) {
        return user.name
      }
      return ''
    },
    PowerOptionToLabel({ option }) {
      let options = {
        "SufficientPowerWithOutStaticLoadbalancing": "Er is genoeg capaciteit zonder static loadbalancing",
        "SufficientPowerWithStaticLoadbalancing": "Er is genoeg capaciteit met static loadbalancing",
        "GridUpgrade": "Er is niet genoeg capaciteit,  een netverzwaring aanvragen",
        "ReduceChargePoints": "Er is niet genoeg capaciteit, verminder het aantal te plaatsen laadpunten",
        "NoChargePoints": "Er is niet genoeg capaciteit, geen laadpunten plaatsen",

        "Sufficient power without static loadbalancing": "Er is genoeg capaciteit zonder static loadbalancing",
        "Sufficient power when you apply loadbalancing": "Er is genoeg capaciteit met static loadbalancing",
        "There is not enough power, grid upgrade": "Er is niet genoeg capaciteit,  een netverzwaring aanvragen",
        "There is not enough power, reduce number of charging points": "Er is niet genoeg capaciteit, verminder het aantal te plaatsen laadpunten",
        "There is not enough power, do not place any charging points": "Er is niet genoeg capaciteit, geen laadpunten plaatsen"
      }

      return options[option] || ''
    },
    FuseBoxOptionToLabel({ option }) {
      let options = {
        "MinorChangeNeeded": "Kleine aanpassing benodigd",
        "ReplacementNeeded": "Grote aanpassing / vervanging benodigd",
        "NoChargePoints": "Hoofdverdeelkast kan niet aangepast of vervangen worden, geen laadpunten plaatsen"
      }

      return options[option] || ''
    }
  }
}
</script>

<style>

</style>