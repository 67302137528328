<template>
  <div class="PowerPermission">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />

  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isNumeric, isUrl } from '@/services/validation'

export default {
  name: 'PowerPermission',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Offerte voor netverzwaring'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions.PowerPermission'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "GridOperatorName": {
            "type": "string",
            "title": "Netbeheerder"
        },
        "GridOperatorQuotationRequestDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de offerte voor netverzwaring is aangevraagd"
            "title": "Datum van offerte aanvraag"
        },
        "GridOperatorQuotationReceiveDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de offerte voor netverzwaring is ontvangen"
            "title": "Datum van offerte ontvangst"
        },
        "GridOperatorQuotationLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar de netverzwaring offerte"
            "title": "Link naar offerte"
        },
        "Amount": {
            "type": "string",
            // "title": "Kosten van de netverzwaring"
            "title": "Kosten in €"
        }
      },
      layout: [{
        "columns": "1fr 1fr 1fr",
        "fields": [ 'GridOperatorName', 'GridOperatorQuotationRequestDate', 'GridOperatorQuotationReceiveDate' ]
      },{
        "columns": "1fr 1fr 1fr",
        "fields": [ 'Amount', 'GridOperatorQuotationLink' ]
      }],
      validation: {
        "Amount": {
          isNumeric: (value) => !helpers.req(value) || isNumeric(value)
        },
        GridOperatorQuotationLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  }
}
</script>