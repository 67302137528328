
import { commonFilterWrapper } from '../utils'

function percOfTotal({ value, total }) {
  return `${(parseInt(value, 10) / total * 100).toFixed(2)}%`
}

export default {
  
  /**
   * Config reference
   * @type {string}
   */
  uuid: '10deca9b-d99b-4f0c-a1f7-1db51d58d562',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'rvb-customer-reporting',

  /**
   * Access requirements
   */
  access: {
    requirements: [],
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RVBControls',


  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 1000,
  pageSizeOptions: [1000],

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: [],
        columns: '',
        area: ``
      },
      header: { // copied to row
        properties: [
          "status", "demand", "demand_perc", "extra_demand", "extra_demand_perc"
        ],
        columns: '1fr 1fr 1fr 1fr 1fr',
        area: '"status demand demand_perc extra_demand extra_demand_perc"'
      },
    }
  },

  headerLabels: {
    status: 'Status',
    demand: '4% Behoefte',
    demand_perc: '% Behoefte',
    extra_demand: 'Extra Behoefte',
    extra_demand_perc: '% Extra Behoefte'
  },
  cellComponents: {
    status: null,
    demand: null,
    demand_perc: null,
    extra_demand: null,
    extra_demand_perc: null
  },

  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'position'
    }
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {

    let location = record.CurrentLocation || {}
    
    let data = {
      completed: location?.Progress?.AllPartiesSignedDate || false,
      demand: location?.ParkingAndChargePoints?.ChargePointNeedsGovernmentOffices || 0,
      extra_demand: location?.ParkingAndChargePoints?.ExtraNeedChargePoints || 0
    }


    return Object.assign(data, {
      /** 
      * Filter params
      */ 
      filteredOut: false,
      filterReason: null
    }, {
      categories: {
        all: true,
        actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

        gepauzeerd: record.status.onhold === true && record.status.cancelled !== true,
        afgerond: record.status.completed === true,
        geannulleerd: record.status.cancelled === true
      },
    })
  },

  /**
   * Data can be aggregated
   */
  dataPresentationMapper: ({ records }) => {

    let data = {
      'in-progress': {
        position: 3,
        status: 'Niet in bedrijf',
        demand: 0,
        demand_perc: 0,
        extra_demand: 0,
        extra_demand_perc: 0
      },
      'completed': {
        position: 2,
        status: 'In bedrijf',
        demand: 0,
        demand_perc: 0,
        extra_demand: 0,
        extra_demand_perc: 0
      },
      'total': {
        position: 1,
        status: 'Totaal',
        demand: 0,
        demand_perc: '100%',
        extra_demand: 0,
        extra_demand_perc: '100%'
      },
    }

    records.forEach(record => {
      let demand = record.demand
      let extra_demand = record.extra_demand

      if (record.completed) {
        data['completed'].demand = data['completed'].demand + parseInt(demand, 10)
        data['completed'].extra_demand = data['completed'].extra_demand + parseInt(extra_demand, 10)
      } else {
        data['in-progress'].demand = data['in-progress'].demand + parseInt(demand, 10)
        data['in-progress'].extra_demand = data['in-progress'].extra_demand + parseInt(extra_demand, 10)
      }

      data['total'].demand = data['total'].demand + parseInt(demand, 10)
      data['total'].extra_demand = data['total'].extra_demand + parseInt(extra_demand, 10)
    })

    data['completed'].demand_perc = percOfTotal({ value: data['completed'].demand, total: data['total'].demand})
    data['in-progress'].demand_perc = percOfTotal({ value: data['in-progress'].demand, total: data['total'].demand})
    data['completed'].extra_demand_perc = percOfTotal({ value: data['completed'].extra_demand, total: data['total'].extra_demand})
    data['in-progress'].extra_demand_perc = percOfTotal({ value: data['in-progress'].extra_demand, total: data['total'].extra_demand})

    return Object.values(data)
  },

  totalMapper: () => {

    return null
  },


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default'
        }, {
          text: 'Actief',
          value: 'actief'
        }, {
          text: 'Gepauzeerd',
          value: 'gepauzeerd'
        }, {
          text: 'Afgerond',
          value: 'afgerond'
        }, {
          text: 'Geannuleerd',
          value: 'geannulleerd'
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },

}