<template>
  <ParentPage class="Reporting" :menu="menu" :rootRouteName="rootRouteName"  />
</template>

<script>
import { mapGetters } from 'vuex'

import { ReportingMenu } from '@/config'

import ParentPage from '@/components/ParentPage.vue'

export default {
  name: "Config",
  components: {
    ParentPage
  },
  data() {
    return {
      menu: ReportingMenu,
      rootRouteName: 'Reporting'
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessReporting'
    ])
  },
  created() {
    // Access check
    if (! this.canAccessReporting) {
      this.$router.push({ 
        name: 'Dashboard'
      })
    }
  }
};
</script>

