<template>
  <div class="LayoutSelection">
    <label for="layoutSelection">Tabel layout</label>
    <b-form-select 
      id="layoutSelection" 
      v-model="tableLayout" 
      :disabled="tableLayoutOptions.length < 2"
      :options="tableLayoutOptions" />
  </div>
</template>

<script>
import TableState from '../mixins/TableState.vue'

export default {
  mixins: [ TableState ],
  computed: {
    tableLayout: {
      get() {
        return this.settings.layout
      },
      set(layout) {
        this.setTableLayout({ layout })
      }
    },
    tableLayoutOptions() {
      return Object.keys(this.config.layouts).map(key => {
        return {
          value: key,
          text: this.config.layouts[key].label
        }
      })
    }
  }
}
</script>

<style>
.LayoutSelection {
  grid-area: layoutSelection;
}
</style>