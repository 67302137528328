/**
 * Handles the Tenant config
 *  TODO: This currently only handles tags
 */

import Vue from "vue"

/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  tenant: process.env.VUE_APP_TENANT,
  loading: false,

  /**
   * Configuration attributes
   *  { uuid: '', label: '', enabled: true, request: true, process: true, deleted_at: null }
   */
  tags: [],

  /**
   * Configuration attributes
   *  { uuid: '', label: '', enabled: true, deleted_at: null }
   */
  pids: [],

  /**
   * Key - value storage of workflow uuids
   *  { realisation: 'uuid' }
   */
  workflows: {},

  workflowspecs: {},

  selectOptions: {}
}

const getters = {
  /****************************************************************************
   * Alle 
   */
  getTenant: state => state.tenant,

  /**
   * Check whether a tenant is the current tenant
   */
  isCurrentTenant: state => ({ tenant }) => state.tenant === tenant,
  

  /****************************************************************************
   * Tag related getters
   */

  /**
   * All tags, disabled, deleted or not
   */
  getTags: state => state.tags,

  /**
   * The tags that can be selected
   */
  getEnabledTags: state => state.tags.filter(tag => !! tag.enabled && ! tag.deleted_at),

  /**
   * Get a list of tag labels by uuid. Includes all tags
   */
  getLabelsByTagUuid: state => state.tags.reduce(( result, tag ) => {
    result[tag.uuid] = tag.label
    return result
  }, {}),


  /****************************************************************************
   * PID related getters
   */
  getPIDs: state => state.pids,


  /**
   * The pids that can be selected
   */
   getEnabledPids: state => state.pids.filter(pid => !! pid.enabled),

   /**
    * Get a list of pid labels by uuid. Includes all pids
    */
   getLabelsByPidUuid: state => state.pids.reduce(( result, pid ) => {
     result[pid.uuid] = pid.label
     return result
   }, {}),
 

  /****************************************************************************
   * Workflow processes
   */

  /**
   * Get the flow uuid by name (e.g. realisation)
   */
  getWorkflowUuidByName: state => ({ name }) => state.workflows[name] || null,

  getWorkflowSpecByUuid: state => ({ uuid }) => state.workflowspecs[uuid] || null,

  getWorkflowSpecByName: state => ({ name }) => {
    let uuid = state.workflows[name] || null
    return uuid ? (state.workflowspecs[uuid] || null) : null
  },

  /****************************************************************************
   * Select options
   */
  getSelectOptionsByName: state => ({ name }) => {
    return state.selectOptions[name] || []
  }
}
const actions = {
  /**
   * Load the tenant configuration
   */
  loadConfig: async ({ state, commit }, { token }) => {
    if (state.loading) return 

    commit('setLoadingState', { loading: true })

    const api = await fetch('/api/config', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (! api.ok) {
      commit('setLoadingState', { loading: false })
    } else {
      const response = await api.json()

      commit('setConfig', {
        config: response.data
      })
      commit('setLoadingState', { loading: false })
    }
  },

  loadWorkflowSpecs: async ({ state, commit }, { token }) => {

    let uuids = Object.values(state.workflows)
    
    for (let i = 0; i < uuids.length; i++) {
      const api = await fetch('/api/workflowspecification', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          uuid: uuids[i]
        })
      })

      if (api.ok) {
        const response = await api.json()
        commit('setWorkflowSpecByUuid', {
          uuid: uuids[i],
          spec: response.data
        })
      }
    }
  }
}
const mutations = {
  setLoadingState(state, { loading }) {
    state.loading = loading
  },
  /**
   * Set the configuration in the store
   */
  setConfig(state, { config }) {
    if (config.tenant !== state.tenant) {
      throw new Error("Wrong tenant config")
    }
    
    // console.log(config)

    state.tags = config.tags || []
    state.pids = (config.pids || []).sort((a, b) => a.label.localeCompare(b.label))
    state.workflows = config.workflows || {}
    state.selectOptions = config.selectOptions || {}
  },

  setWorkflowSpecByUuid(state, { uuid, spec }) {
    Vue.set(state.workflowspecs, uuid, spec)
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
