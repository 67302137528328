
/**
 * Generate a temporary signed download url for direct download from S3
 */
export const getDownloadURL = async function({
  token, 
  ref, 
  uuid,
  casefull,
  index,
  doctype,
  ext
}) {
  const api = await fetch(`${process.env.VUE_APP_AWS_ENDPOINT}/backoffice-url`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      ref,
      uuid,
      casefull,
      index,
      doctype,
      ext 
    })
  })
  if (! api.ok) {
    throw new Error("server error")
  } 
  const response = await api.json()
  if (! response.downloadURL) {
    throw new Error("missing url")
  }

  return response
}

/**
 * Generate a temporary signed upload url for direct upload to S3
 */
export const getUploadURL = async function({
  token,
  ref,
  uuid,
  casefull,
  index,
  doctype,
  type
}) {
  const api = await fetch(`${process.env.VUE_APP_AWS_ENDPOINT}/backoffice-upload`, {
    method: "POST",
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    },
    body: JSON.stringify({
      ref,
      uuid,
      casefull,
      index,
      doctype,
      type
    })
  })

  if (! api.ok) {
    throw new Error("server error")
  }
  
  const response = await api.json()
  if (! response.uploadURL) {
    throw new Error("missing url")
  }

  return response
}

/**
 * Simple upload of a file to a specified url. Used for direct upload to S3
 */
export const uploadFile = async function({ file, uploadURL }) {
  return await fetch(uploadURL, {
    method: 'PUT',
    body: file
  })
} 

