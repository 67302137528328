<template>
  <b-overlay 
    v-if="enableFeature"
    :show="showStatusPanel">
    <b-card v-if="record" class="RealisationStatusPanel">
      <h4>{{ statusLabel }}</h4>

      <div 
        v-if="canConfirmTrafficDecisions && ! completed" 
        class="w-100">

        <b-button 
          v-if="! ConfirmedDate"
          :disabled="busy" 
          :variant="action ? 'success' : 'outline-success'" 
          class="w-100" 
          @click="handleSetAction({ action: 'confirm' })">
          Markeer VKB als onherroepelijk
        </b-button>

        <b-button
          v-else 
          :disabled="busy" 
          :variant="action ? 'danger' : 'outline-danger'" 
          class="w-100"
          @click="handleSetAction({ action: 'cancel' })">
          Maak de onherroepelijk status ongedaan
        </b-button>
      </div>
      
      <p v-if="PublicationDate" class="mt-2" >
        Publicatie: {{ PublicationDate }}<br />
        Defintief op: {{ DefinitiveDate }}
      </p>

      <Feedback class="mt-2" :feedback="feedback" />

      <transition>
        <div v-if="action">
          <b-button 
            :disabled="busy" 
            variant="dark" 
            class="w-100" 
            @click="handleConfirm">
            Bevestigen
          </b-button>
        </div>
      </transition>
    </b-card>

    <template #overlay>
      <div class="text-center">
        <!-- <span v-if="cancelled">
          Dit proces is niet actief.
        </span>
        <span v-else>
          Er is nog geen VKB gepubliceerd.
        </span> -->
      </div>
    </template>
  </b-overlay>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

import Location from '@/models/Location'
import Realisation from '@/models/Realisation'

import Feedback from '@/components/form/Feedback.vue'

/**
 * 
 */
export default {
  name: 'RealisationVKBStatus',
  components: {
    Feedback
  },
  props: {
    uuid: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      action: null,

      feedback: {
        show: false,
        message: ''
      }
    }
  },
  computed: {
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('user', [
      'canConfirmTrafficDecisions',
      'someoneHasAccess'
    ]),

    /**
     * @returns {Realisation|null}
     */
    record() {
      return this.recordByUuid({
        uuid: this.uuid
      }) || null
    },
    /**
     * @return {Location|null}
     */
    location() {
      return (this.record instanceof Realisation) 
        ? this.record.getLocation()
        : null
    },

    completed() {
      try {
        return !! this.record.status.completed
      } catch(e) {
        return false
      }
    },

    cancelled() {
      try {
        return !! this.record.status.cancelled
      } catch(e) {
        return false
      }
    },
    TrafficDecisionDetails() {
      return (this.location instanceof Location)
        ? this.location.getTrafficDecisionDetails()
        : {}
    },
    /**
     * 
     */
    PublicationDate() {
      return this.TrafficDecisionDetails.PublicationDate || false
    },
    DefinitiveDate() {
      return this.TrafficDecisionDetails.DefinitiveDate || '-'
    },
    ConfirmedDate() {
      return this.TrafficDecisionDetails.ConfirmedDate || false
    },

    /**
     * Title to summarize current status
     */
    statusLabel() {
      if (! this.PublicationDate) {
        return 'VKB is nog niet gepubliceerd'
      }
      else if (! this.ConfirmedDate) {
        return 'VKB is nog niet onherroepelijk'
      } 
      else if (this.ConfirmedDate) {
        return 'VKB is onherroepelijk'
      }

      return 'VKB status is onbekend' 
    },

    /**
     * Show an overlay with a message if the VKB is not yet published, or the process has been cancelled
     */
    showStatusPanel() {
      return ! this.record || ! this.PublicationDate || this.cancelled
    },
    /**
     * Check whether any role in this tenant can actually access this feature
     */
    enableFeature() {
      return this.someoneHasAccess({ attribute: 'canConfirmTrafficDecisions' })
    }
  },
  methods: {
    ...mapMutations('realisation', [
      'replaceRecord'
    ]),
    handleSetAction({ action }) {
      this.action = this.action === null ? action : null
    },
    async handleConfirm() {
      try {
        this.busy - true

        let payload = {
          ref: this.record.getRef(),
          action: this.action
        }

        const token = await this.$auth.getTokenSilently();
        const api = await fetch('/api/realisation_vkb_status', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify(payload)
        })
        if (!api.ok) {

          // console.log(api)
          throw new Error('Unable to update the status')
        }
        const response = await api.json()

        this.feedback = {
          show: true,
          variant: 'success',
          message: this.action === 'confirm' 
            ? 'Het VKB is als onherroepelijk gemarkeerd'
            : 'Het VKB is niet langer onherroepelijk'
        }

        this.replaceRecord({
          record: response.data.record
        })

        // reset panel state
        this.action = null
        this.busy = false

      } catch(e) {
        // console.log(e)
        this.busy = false

        this.feedback = {
          show: true,
          variant: 'danger',
          message: 'De status wijziging is niet opgeslagen'
        }
      }
    }
  }
}
</script>

<style lang="scss">

</style>