<template>
  <div class="FieldValue" :class="className">
    <div class="FieldValue__label text-muted">
      {{ label }}
    </div>
    <div class="FieldValue__value">
      {{ displayValue }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'FieldValue',
  props: {
    value: {
      type: [String, Boolean, Number, Date, File],
      default: ''
    },
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text'
    },
    // Used by `type === select & radio`
    options: {
      type: Array,
      default: () => {
        return []
      }
    },
    className: {
      type: String,
      default: ''
    }
  },
  computed: {
    displayValue() {
      if (this.type === 'select' || this.type === 'radio') {
        let selected = this.options.find(option => option.value === this.value)
        return selected ? (selected.text || '-') : '-' 
      }
      // If the date value is formatted like YYYY-MM-DD, turn it around.
      if (this.type === 'datepicker') {
        let date = this.value.split('-')
        if (date.length !== 0 && date[0].length === 4) {
          date = date.reverse()
        }
        date = date.join('-')
        return date !== '' ? date : '-'
      }

      return this.value !== '' ? this.value : '-'
    }
  }
}
</script>

<style lang="scss">

.FieldValue {
  font-size: 16px;
  position: relative;
  margin-bottom: 1.5em;

  &__label {
    color: #7F8FA4
  }
}
</style>