const RECORD_TYPE = {
  REQUEST: 'ChargerRequests',
  REALISATIONPROCESS: 'RealisationProcesses',
}

const RECORD_TYPES = Object.values(RECORD_TYPE)

module.exports = {
  RECORD_TYPE,
  RECORD_TYPES,
}