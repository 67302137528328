<script>
import SingleSelectFilter from './SingleSelectFilter.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'RVBPIDFilter', 
  mixins: [ SingleSelectFilter ],
  computed: {
    ...mapGetters('tenant', [
      'getPIDs'
    ]),
    options() {
      let options = this.getPIDs.map(pid => {
        return {
          value: pid.uuid,
          text: pid.label
        }
      })
      return options
    }
  }
}
</script>