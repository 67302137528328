<template>
  <b-tab v-if="canAccessDebugger">
    <template #title>
      <b-icon class="mr-2" icon="bug-fill"></b-icon>
      Debugger
    </template>
    <p class="mt-2">
      Output goes to console
    </p>

    <div class="d-flex flex-column">
      <b-button @click="outputCurrentRecord">
        Current Record
      </b-button>
      <b-button class="mt-2" @click="outputCurrentRecordWorkflow">
        Current Record workflow
      </b-button>
      <b-button class="mt-2" @click="outputCurrentUser">
        Current User Roles
      </b-button>

      
      <b-input-group class="mt-3" prepend="Record by Uuid">
        <b-form-input v-model="recordUuid"></b-form-input>
        <b-input-group-append>
          <b-button 
            variant="info"
            :disabled="busy || recordUuid === ''"
            @click="outputRecordByUuid">GO</b-button>
        </b-input-group-append>
      </b-input-group>

    </div>

  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'RealisationSidebarDebugTab',
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      recordUuid: ''
    }
  },
  computed: {
    ...mapGetters('user', [
      'securityCheck',
      'roleDetails'
    ]),
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('tenant', [
      'getWorkflowSpecByUuid'
    ]),
    canAccessDebugger() {
      return this.securityCheck({
        attribute: 'canAccessDebugger'
      })
    }
  },
  methods: {
    outputCurrentRecord() {
      console.log(this.record)
      console.log(this.record.getCurrentFlowStatus())
    },
    outputCurrentUser() {
      console.log(this.roleDetails)
    },
    outputRecordByUuid() {
      let record = this.recordByUuid({
        uuid: this.recordUuid
      }) || {}

      console.log(record)
    },
    outputCurrentRecordWorkflow() {
      console.log(
        this.getWorkflowSpecByUuid({
          uuid: this.record.flow.uuid
        })
      )
    }
  }
}
</script>
