<template>
  <div class="CategoryMenuFilter">
    <div 
      v-for="(category,index) in categories" 
      :key="`category_${index}`"
      class="mr-3"
      :class="{ 'selected' : value === category.value || (value === '' && category.value === 'all') }"
      @click="handleCategoryFilter({ category })">
      {{ category.text }}
      <b-badge>{{ category.count }}</b-badge>
    </div>
  </div>
</template>

<script>
import SingleStringFilter from './SingleStringFilter.vue'

export default {
  name: 'CategoryMenuFilter',
  mixins: [ SingleStringFilter ],
  computed: {
    categories() {
      /**
       * Exclude effect of category filter in count
       */
      let filtered = this.records.filter(
        record => record.filteredOut !== true || record.filterReason === this.filterConfig.id
      )

      return this.filterConfig.options.map(category => {
        category.count = filtered.filter(
          record => !! record.categories[category.value]
        ).length
        return category
      })
    },
  },
  methods: {
    handleCategoryFilter({ category }) {
      let value = category.value

      // Check whether the filter is already active
      let filter = this.fieldFilter
      if (filter) {
        // remove filter
        if (value === '' || category.reset) {
          this.activeFilters.splice(this.fieldFilterIndex, 1)

        // update filter
        } else {
          filter.label = value
          filter.value = value
        }
      } else if (value && ! category.reset) {
        // Create active filter entry
        this.activeFilters.push({
          category: this.filterConfig.id,
          hidden: !! this.filterConfig.hidden,
          value: value
        })
      }

      // Update the selected value
      this.value = value

      // finally: update the route, if there is one
      try {
        if (category.route) {
          this.$router.push(category.route)
        }
      } catch(e) {
        // Route update gives bonus points. The show must go on if it fails.
      }
    }
  }
}
</script>

<style lang="scss">
.CategoryMenuFilter {

  display: flex;

  > div {
    cursor: pointer;
    user-select: none;
  }

  .selected, > div:hover {
    color: #00B46B;

    .badge {
      background: #00B46B;
    }
  }
  
}

</style>