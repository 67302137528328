<template>
  <div class="BrokerApproval">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'BrokerApproval',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Stadsdeel'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BrokerApproval'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "IsBrokerAccepted": {
            "type": "string",
            "title": "",
            "enumNames": [
                "",
                "Akkoord",
                // "Afgekeurd",
                "Geen reactie",
                "Negatief advies"
            ],
            "enum": [
              '',
              'yes',
              // 'no',
              "blanco",
              "negative-advice"
            ],
            // 'format': 'radio'
        },
        "BrokerName": {
            "title": "Contactpersoon Stadsdeel",
            "type": "string"
        },
        "BrokerComments": {
            "title": "Toelichting",
            "type": "string",
            "format": "textarea"
        },
        "BrokerApprovalRequestedDate": {
            "title": "Reactie gevraagd",
            "type": "string",
            "format": "date"
        },
        "BrokerApprovalReceivedDate": {
            "title": "Reactie ontvangen",
            "type": "string",
            "format": "date"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'IsBrokerAccepted' ]
      }, {
        "columns": '1fr',
        "fields": [ 'BrokerComments' ]
      }, {
        "columns": '1fr',
        "fields": [ 'BrokerName' ]
      }, {
        "columns": '1fr',
        "fields": [ 'BrokerApprovalRequestedDate' ]
      }, {
        "columns": '1fr',
        "fields": [ 'BrokerApprovalReceivedDate' ]
      }],
      validation: {}
    }
  }
}
</script>