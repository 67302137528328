
import { commonFilterWrapper } from '../utils'


export default {
  
  /**
   * Config reference
   * @type {string}
   */
  uuid: '191a5278-afe8-4d92-90b4-0dc4554fb5f9',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'rvb-customer-reporting',

  /**
   * Access requirements
   */
  access: {
    requirements: [],
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RVBControls',


  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 1000,
  pageSizeOptions: [1000],

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: [],
        columns: '',
        area: ``
      },
      header: { // copied to row
        properties: [
          "PIDNumber", "status", "demand", "extra_demand"
        ],
        columns: '1fr 1fr 1fr 1fr',
        area: '"PIDNumber status demand extra_demand"'
      },
      row: {
        properties: [
          "PIDNumber", "status", "demand", "extra_demand", "status_two", "demand_two", "extra_demand_two"
        ],
        columns: '1fr 1fr 1fr 1fr',
        area: '"PIDNumber status_two demand_two extra_demand_two" ". status demand extra_demand"'
      }
    }
  },

  headerLabels: {
    PIDNumber: 'PID',
    status: 'Status',
    demand: 'Behoefte',
    extra_demand: 'Extra Behoefte'
  },
  cellComponents: {
    PIDNumber: null,

    // completed
    status: null,
    demand: null,
    extra_demand: null,

    // not completed
    status_two: null,
    demand_two: null,
    extra_demand_two: null
  },

  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'string',
      sortBy: 'PIDNumber',
      direction: 'up'
    }
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {

    let location = record.CurrentLocation || {}
    
    let data = {
      PIDNumber: record.getPidNumber(),
      completed: location?.Progress?.AllPartiesSignedDate || false,
      demand: location?.ParkingAndChargePoints?.ChargePointNeedsGovernmentOffices || 0,
      extra_demand: location?.ParkingAndChargePoints?.ExtraNeedChargePoints || 0
    }


    return Object.assign(data, {
      /** 
      * Filter params
      */ 
      filteredOut: false,
      filterReason: null
    }, {
      categories: {
        all: true,
        actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

        gepauzeerd: record.status.onhold === true && record.status.cancelled !== true,
        afgerond: record.status.completed === true,
        geannulleerd: record.status.cancelled === true
      },
    })
  },

  /**
   * Data can be aggregated
   */
  dataPresentationMapper: ({ records }) => {

    let entry = {
      PIDNumber: '',

      // completed
      status: 'In bedrijf',
      demand: 0,
      extra_demand: 0,

      // not completed
      status_two: 'Niet in bedrijf',
      demand_two: 0,
      extra_demand_two: 0
    }

    let data = {
      'total': {
        PIDNumber: 'Totaal',
        demand: 0,
        extra_demand: 0,
        status: '',
        status_two: '',
        demand_two: '',
        extra_demand_two: ''
      },
    }

    records.forEach(record => {

      let pid = record.PIDNumber

      if (! data[pid]) {
        data[pid] = Object.assign({}, entry, {
          PIDNumber: pid
        })
      }

      let demand = record.demand
      let extra_demand = record.extra_demand
      
      if (record.completed) {
        data[pid].demand = data[pid].demand + parseInt(demand, 10)
        data[pid].extra_demand = data[pid].extra_demand + parseInt(extra_demand, 10)
      } else {
        data[pid].demand_two = data[pid].demand_two + parseInt(demand, 10)
        data[pid].extra_demand_two = data[pid].extra_demand_two + parseInt(extra_demand, 10)
      }

      data['total'].demand = data['total'].demand + parseInt(demand, 10)
      data['total'].extra_demand = data['total'].extra_demand + parseInt(extra_demand, 10)
    })

    return Object.values(data)
  },

  totalMapper: () => {

    return null
  },


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default'
        }, {
          text: 'Actief',
          value: 'actief'
        }, {
          text: 'Gepauzeerd',
          value: 'gepauzeerd'
        }, {
          text: 'Afgerond',
          value: 'afgerond'
        }, {
          text: 'Geannuleerd',
          value: 'geannulleerd'
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },

}