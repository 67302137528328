<template>
  <b-tab v-if="canAccessEventLog">
    <template #title>
      <b-icon class="mr-2" icon="card-list"></b-icon>
      Events
    </template>
    <p class="mt-4">
      Lijst met gebeurtenissen behorende bij dit proces
    </p>
    <b-alert :show="true" variant="warning">
      Dit is een vroege preview. Deze functie is nog niet gereed voor productie.
    </b-alert>

    <div>
      <div v-for="(eventGroupByVersion,index) in eventsByVersionByUserAndTime" :key="`eventVersionGroup_${index}`">
        <strong>
          Locatie voorstel {{ eventGroupByVersion.version }} 
        </strong>

        <div v-for="(eventGroup,index) in eventGroupByVersion.groupedEvents" :key="`eventGroup_${index}`">
          {{ index }}
        </div>
        
      </div>
    </div>
  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessEventLog'
    ]),
    events() {
      try {
        return this.record.Events || []
      } catch(e) {
        return []
      }
    },

    eventsByVersionByUserAndTime() {

      let result = this.events
        .reduce((groupedEvents, event) => {
          let userAndTime = `${event.userId}-${event.ts}`
          let version = event.version || 1

          // First event with this version
          if (! groupedEvents[version]) {
            groupedEvents[version] = {
              version,
              groupedEvents: {}
            }
          } 

          // First event with user + time combination
          if (! groupedEvents[version].groupedEvents[userAndTime]) {
            groupedEvents[version].groupedEvents[userAndTime] = {
              userId: event.userId,
              ts: event.ts,
              events: []
            }
          }

          groupedEvents[version].groupedEvents[userAndTime].events.push(
            event
          )

          return groupedEvents
        }, {})
      
      // console.log(result)

      return result
      
    }
  }
}
</script>
