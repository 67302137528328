<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">
      
      <RealisationHeader :record="record" :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step">
        <FlowInteraction
          position="top"
          :first="currentStep === '1'"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationHeader>

      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <div class="DetailGrid__map" >

        <PIDSelect 
          :values="values" 
          :disabled="locked || disabled" />

        <KeyReferences
          :values="values"
          :disabled="locked || disabled" />

        <Object          
          class="mb-4"
          :values="values"
          :disabled="locked || disabled"  />

        <!-- <MapDisconnectedLocationSelection   
          :record="record"
          :values="values"
          :locked="locked" 
          :disabled="disabled" /> -->

        <ParkingAndChargePoints 
          class="mt-4"
          :values="values"
          :disabled="locked || disabled" />

        <Energy 
          class="mt-4"
          :values="values"
          :disabled="locked || disabled" />
      </div>
      
      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        
        <FlowInteraction
          position="bottom"
          :first="currentStep === '1'"
          :step="step"
          :currentStep="currentStep"
          :locked="locked"
          :disabled="disabled"
          :completed="completed"
          :isCompletedStep="isCompletedStep"
          :disableNextBtn="disableNextBtn"
          :mayEdit="mayEdit"
          :openForChange="openForChange"
          @handleNext="handleNext"
          @handlePrevious="handlePrevious"
          @handleSubmitAndNext="handleSubmitAndNext"
          @handleCancelChange="handleCancelChange"
          @handleStartCorrection="handleStartCorrection"
          @handleStartChange="handleStartChange"
          @handleComplete="handleComplete" />

      </RealisationFooter>
      
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import PIDSelect from '@/components/definitions/rvb/PIDSelect.vue'
import KeyReferences from '@/components/definitions/rvb/KeyReferences.vue'
import Object from '@/components/definitions/rvb/Object.vue'
import ParkingAndChargePoints from '@/components/definitions/rvb/ParkingAndChargePoints.vue'
import Energy from '@/components/definitions/rvb/Energy.vue'


import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'
// import MapDisconnectedLocationSelection from '@/components/map/MapDisconnectedLocationSelection'


import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

import FlowInteraction from '@/components/flow/FlowInteraction.vue'

export default {
  name: "RealisationDetail_1",
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    // MapDisconnectedLocationSelection, 
    Form,
    Comments,

    FlowInteraction,

    // RVB
    PIDSelect,
    Object,
    KeyReferences,
    ParkingAndChargePoints,
    Energy,

  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: '1' 
    }
  },
  created() {
    // console.log("created", this.record, this.uuid)

    // Redirect filtered lists
    if (['actief', 'gepauzeerd', 'afgerond', 'geannulleerd'].includes(this.uuid.toLowerCase())) {
      this.$router.push({
        name: 'RealisationListFiltered',
        params: {
          filter: this.uuid
        }
      })
    } else {

      // Maybe redirect to active step
      if (this.hasRecord && this.step !== 1 && this.$route.name == 'Realisation') {
        this.$router.push({
          name: `Realisation.Step${this.step}`,
          params: {
            uuid: this.uuid
          }
        })
      }
    }
  }
};
</script>
