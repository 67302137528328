<template>
  <div class="RequestJudement">
    <h4 class="pb-1">Aantal beoordeelde aanvragen</h4>
    <!-- <p class="pb-3">
      Toelichting op grafiek... 
    </p> -->
    <p>
      Jaartal: 
      <span 
        class="GraphFilter__year"
        :class="{ 'active': activeYear === availableYear }"
        v-for="availableYear in availableYears" 
        :key="availableYear"
        @click="handleSelectActiveYear({ year: availableYear })">
        {{ availableYear }}
      </span>
    </p>

    <div v-show="activeYear">
      <GChart
        type="ColumnChart"
        :data="chartDataTable"
        :options="chartOptions" />
    </div>

  </div>
</template>

<script>
import { GChart } from 'vue-google-charts'

import { mapGetters } from 'vuex'


const monthLabelByNumber = {
  0: 'jan',
  1: 'feb',
  2: 'mar',
  3: 'apr',
  4: 'mei',
  5: 'jun',
  6: 'jul',
  7: 'aug',
  8: 'sep',
  9: 'okt',
  10: 'nov',
  11: 'dec'
}

export default {
  name: 'RequestJudement',
  components: {
    GChart
  },
  data() {
    return {
      activeYear: null
    }
  },
  computed: {
    ...mapGetters('requests', [
      'requests',
      'reasonOptions',
      'requestsByStatus'
    ]),


    // Accepted Requests Submit Dates
    acceptedRequestsSubmitDates() {
      return this.requestsByStatus({
        status: 3 // accepted
      })
      .map(request => {
        let date = new Date(request.summary.submitted_at_nr)

        return {
          year: date.getFullYear(),
          month: date.getMonth()
        }
      })
    },

    // Rejected Requests Submit Dates
    rejectedRequestsSubmitDates() {
      return this.requestsByStatus({
        status: 4 // open status
      })
      .map(request => {
        let date = new Date(request.summary.submitted_at_nr)

        return {
          reason: request.judgement.reason,
          year: date.getFullYear(),
          month: date.getMonth()
        }
      })
    },


    // TODO: Better name
    preppedAcceptedData() {
      return this.acceptedRequestsSubmitDates
        // only show the active year
        .filter(request => request.year === this.activeYear)

        // 
        .reduce((result, request) => {
          let month = monthLabelByNumber[request.month]
          result[month] = (result[month] || 0) + 1 
          return result
        }, {})
    },

    preppedRejectedData() {
      return this.rejectedRequestsSubmitDates
        // only show the active year
        .filter(request => request.year === this.activeYear)

        // 
        .reduce((result, request) => {
          let month = monthLabelByNumber[request.month]
          result[month] = result[month] || {}
          result[month][request.reason] = (result[month][request.reason] || 0) + 1
          return result
        }, {})
    },


    availableYears() {
      let years = this.acceptedRequestsSubmitDates.reduce(( unique, request ) => {
        unique[request.year] = true
        return unique
      }, {})
      
      years = this.rejectedRequestsSubmitDates.reduce(( unique, request ) => {
        unique[request.year] = true
        return unique
      }, years)

      years = Object.keys(years)
        .map(year => parseInt(year, 10))
        .sort()
      // console.log("available years", years)
      return years
    },

    /**
     * Bar chart visualisation options
     */
    chartOptions() {
      return {
        // layout
        height: 450,
        width: 1000,
        chartArea: { left: 50, top: 10, right: 20, bottom: 100 },

        // other
        isStacked: true,
        legend: { position: 'bottom' },
        
        // axes
        hAxis: {
          title: "Aanvraagdatum"
        },
        annotations: {
          textStyle: {
            color: 'black',
          },
          alwaysOutside: true,
          stem: {
            color: 'transparent'
          }
        }

        // TODO: Apply stack colors
        // series: {
        //   0: { color: 'green' },
        //   1: { color: 'blue' },
        //   // etc.
        // }
      }
    },


    chartDataTableHeaderRow() {
      // Status options, obtained from the tenant config.
      let StatusLabels = this.reasonOptions.map(reason => reason.text)

      // Each status option is a data column in the data table
      let headerRow = ['Maand', 'Geaccepteerd'].concat(StatusLabels)

      // The final column holds the sum, which is shown (annotated) on or above each bar in the graph
      headerRow.push({ role: 'annotation' }) 
      return headerRow
    },

    /**
     * The data for rending a graph is provided in table format
     *  For a bar chart, the first row in the datatable constains the labels, 
     *  but also the role of the column in the datatable. This is not to be confused with the actual bars. 
     */
    chartDataTable() {

      // Set the header row as first row in our data table
      const headerRow = this.chartDataTableHeaderRow
      let chartDataTable = [ headerRow ]

      // Generate a data row (column) for every month
      let reasonValues = this.reasonOptions.map(reason => reason.value)
      Object.values(monthLabelByNumber)
        .forEach(month => {

          // Reset the sum on every data row
          let sum = 0

          // The month label is the first column in data row
          let barData = [ month ]

          // Start with accepted requests
          let acceptedCount = this.preppedAcceptedData[month] || 0
          barData.push(acceptedCount)

          sum = sum + acceptedCount

          // Go over all of the reject reasons
          reasonValues.forEach(reason => {
            let count = ((this.preppedRejectedData[month] || {})[reason] || 0)
            sum = sum + count
            barData.push(
              count
            )
          })

          // the final column holds the sum
          barData.push(sum)

          // Add the data table row to the graph dataset
          chartDataTable.push(barData)
        })
      
      return chartDataTable
    },
  },
  created() {
    if (this.activeYear === null && this.availableYears.length !== 0) {
      this.activeYear = this.availableYears[this.availableYears.length - 1]
    }
  },
  methods: {
    handleSelectActiveYear({ year }) {
      this.activeYear = year
    }
  }
}
</script>

<style lang="scss">

.GraphFilter__year {
  color: #6c757d;
  display: inline-block;
  cursor: pointer;

  &:hover, &.active {
    color: #00B46B;
    text-decoration: underline;
  }
  &.active {
    font-weight: bold;
  }
  &:not(:last-child) {
    margin-right: 0.3rem;
  }
}

</style>