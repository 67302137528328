/**
 * Import Dependency
 */

/**
 * Import API
 */

import {checkStatus, returnJson} from "@/helpers/api";
// import Vue from "vue";
import {Bugfender} from "@bugfender/sdk";

/**
 * Declare Variable
 */
const state = {
  locationsData: [],

  selectedChargerUUID: null,

  /**
   * The selected connection point to show
   */
  selected: [],
}

const getters = {
  getSelectedChargerUUID: state => state.selectedChargerUUID,

  getLocationDataByUUID: state => ({uuid}) => {
    let dataset = state.locationsData
      .find(location => location.uuid === uuid) || null

    return (!dataset || dataset.nodata) ? null : dataset
  },
  getSelectedLocationData: (_, getters) => {
    return getters['getLocationDataByUUID']({uuid: getters['getSelectedChargerUUID']})
  },
  getAllLocationdata: state => state.locationsData,
  getSelectedLocationUUIDs: state => state.selected || [],
  getSelectedLocationsWithData: (state, getters) => getters['getSelectedLocationUUIDs']
    .filter(uuid => getters['getLocationDataByUUID']({uuid}))
    .map(uuid => getters['getLocationDataByUUID']({uuid})),
}

const actions = {
  async fetchConnectionPointsByChargingpoint({commit}, {chargingpoint}) {
    if (!chargingpoint) return null

    let code = chargingpoint?.data.code || null
    let uuid = chargingpoint?.data.uuid || null

    if ((! uuid) || (! code)) return null

    const token = await this.$auth.getTokenSilently()
    return await fetch(`${process.env.VUE_APP_MONITORING_API_URL}/location`, {
      method: "POST",
      headers: {
        authorization: 'Bearer ' + token,
        'x-from': 'workflow',
        'x-from-id': 'amsterdam',
      },
      body: JSON.stringify({uuid, code})
    })
      .then(await checkStatus)
      .then(returnJson)
      .then(response => {
        let locationData = { nodata: true }

        if (response.data?.length) {
          locationData = response.data[0]
          locationData.nodata = false
        }

        // Add some reference data related to the charger the monitoring data belongs to
        locationData.chargerNumber = `${chargingpoint?.data.code}-${chargingpoint?.data.properties.id}` || null
        locationData.evse = chargingpoint?.data.properties.evse || null
        locationData.operator = chargingpoint?.data.properties.operator || null
        locationData.uuid = chargingpoint?.data.properties.uuid || null
        locationData.coordinates = chargingpoint?.data.coordinates || null

        commit('addLocationData', {locationData}) // aggregated data
      })
      .catch(Bugfender.error)
  },
  addOrRemoveSelectedCharger({dispatch, commit, getters, rootGetters}, {uuid}) {
    if (getters['getSelectedLocationUUIDs'].includes(uuid)) {
      commit('removedSelected', {uuid})
      return
    }

    commit('addSelected', {uuid})

    if (! getters['getLocationDataByUUID']({uuid})) {
      dispatch('fetchConnectionPointsByChargingpoint', {
        chargingpoint: rootGetters['chargingpoints/getChargingPointByUUID']({uuid})
      })
    }
  }
}

const mutations = {
  addLocationData: function (state, {locationData}) {
    let index = state.locationsData.findIndex(stateLocationData => locationData.uuid === stateLocationData.locationUuid)
    if (index === -1) {
      state.locationsData.push(locationData)
    }
  },

  setSelectedChargerUUID(state, {uuid}) {
    state.selectedChargerUUID = uuid
  },

  addSelected(state, {uuid}) {
    state.selected.push(uuid)
  },
  removedSelected(state, {uuid}) {
    state.selected = state.selected.filter(selected => selected !== uuid)
  },
  clearSelected(state) {
    state.selectedChargerUUID = null
    state.selected = []
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
