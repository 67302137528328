<template>
  <div>
    <h3 id="table_5" class="mt-3">
      Laadpunten per PID
    </h3>
    <TableManager 
      ref="TableManager"
      class="RVBChargingpointCount"
      :loading="loading"
      :sourceRecords="records" 
      category="rvb-customer-reporting"
      uuid="191a5278-afe8-4d92-90b4-0dc4554fb5f9" />

  </div>
</template>

<script>

import TableManager from '@/components/Table/TableManager'
import { mapGetters } from 'vuex'

export default {
  name: '',
  components: {
    TableManager
  },

  computed: {
    ...mapGetters('realisation', [
      'records',
      'loading'
    ]),
  }

}
</script>

<style lang="scss">
.RVBChargingpointCount {
  // .Table__row {
  //   div:first-child {
  //     font-weight: bold;
  //   }
  // }
  .Table__row:last-child {
    font-weight: bold;
  }
}
</style>