<template>
  <div v-if="loaded" class="DetailView">

    <div class="DetailView__wrapper">
      <div class="DetailView__main">
        <h2 class="h3">Referentie: {{ request.case_ref }}</h2>

        <Tags v-if="showTagsPanel" :record="request" />

        <Information :request="request" />

        <p class="text-muted mt-5 mb-4">
          Database referenties: {{ request.uuid }}, {{ request.ref }}
        </p>
      </div>

      <div class="DetailView__sidebar">

        <Judgement :request="request" />

        <RealisationLink :request="request" />

        <SummaryTable :request="request" />

        <MapRequest :uuid="uuid" @open="handleOpenModal" />

      </div>

      <TheComments :record="request" :type="RECORD_TYPE.REQUEST"/>
    </div>

    <b-modal id="file-error" title="Foutmelding">
      <p>Het bestand kon niet worden geopend. Dit kan diverse oorzaken hebben, waaronder:</p>
      <ul>
        <li>Het bestand bestaat niet (meer)</li>
        <li>Het bestand komt niet door de veiligheidsscan</li>
      </ul>
      <template #modal-footer="{ok}">
        <b-button size="sm" @click="ok()">
          OK
        </b-button>
      </template>
    </b-modal>

    <MapRequestModal
      v-if="hasMonitoring"
      :uuid="uuid"
      :show="showModal"
      @close="handleCloseModal" />
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import MapRequest from '@/components/map/MapRequest'
import Information from '@/components/request/Information'
import SummaryTable from '@/components/request/SummaryTable.vue'
import Judgement from '@/components/request/Judgement.vue'
import RealisationLink from '@/components/request/RealisationLink.vue'
import MapRequestModal from '@/components/map/MapRequestModal.vue'
import Tags from '@/components/Tags.vue'
import TheComments from "@/components/Comments";

import { monitoringConfig } from '@/config'

import { RECORD_TYPE } from "@/../shared/valueholders/recordTypes";

export default {
  name: "Detail",
  components: {
    TheComments,
    MapRequest,
    Information,
    SummaryTable,
    Judgement,
    RealisationLink,
    MapRequestModal,
    Tags
  },
  data() {
    return {
      showModal: false,

      /**
       * Whether the tenant has purchased the monitoring feature
       */
      hasMonitoring: !! monitoringConfig.enabled
    }
  },
  computed: {
    ...mapGetters('requests', [
      'hasRequest',
      'requestByUuid'
    ]),
    ...mapGetters('user', [
      'canAccessRequests'
    ]),
    ...mapGetters('tenant', [
      'getEnabledTags'
    ]),
    loaded() {
      return this.request !== null
    },
    uuid() {
      return this.$route.params.uuid
    },
    request() {
      return this.requestByUuid({ uuid: this.uuid })
    },

    /**
     * Only show the tag panel if there are tags connected to the request
     * or if there are tags that can be connected to the request
     */
    showTagsPanel() {
      return this.getEnabledTags.length || this.request.hasTags()
    }
  },

  created() {
    this.RECORD_TYPE = RECORD_TYPE
    /**
     * Navigate to dashboard view if the current user has no municipality access
     *  TODO: Use tenant config to check for access & redirect
     */
    if (! this.canAccessRequests) {
      this.$router.push({ name: 'Dashboard' })

      /**
       * Navigate to list view if the request isn't available (yet)
       */
    } else if (! this.hasRequest({ uuid: this.uuid })) {
      this.$router.push({ name: 'RequestList' })
    } else {
      this.setLastOpenedRequest({
        uuid: this.uuid
      })
    }
  },
  methods: {
    ...mapMutations('requests', [
      'setLastOpenedRequest'
    ]),
    goBack() {
      this.$router.push({
        name: 'RequestList'
      })
    },
    handleOpenModal() {
      this.showModal = true
    },
    handleCloseModal() {
      this.showModal = false
    }
  },
};
</script>

<style lang="scss">
.DetailView {
  max-width: 1200px;
  margin: 0 auto;
  text-align: left;
  padding: 18px 0 30px;

  .DetailView__wrapper {
    box-sizing: content-box;
    position: relative;
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr auto;
  }

  .DetailView__main,
  .DetailView__sidebar {
    padding-top: 30px;
  }

  .DetailView__main {
    a {
      cursor: pointer;
    }
    h2 {
      margin-bottom: 30px;
    }
    table {
      th {
        width: 225px
      }
      td:nth-child(3n) {
        text-align: right;
        min-width: 40px;
      }
    }
    // table:nth-of-type(2) {
    //   th {
    //     width: 80%;
    //   }
    // }
  }

  .DetailView__sidebar {
    position: sticky;
    top: 0;
    align-self: start;

    width: 400px;

    select, input {
      width: 400px;
    }
  }

  .status:not(:last-child) {
    margin-right: 5px;
  }
}
</style>