<template>
  <div style="display:none"></div>
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin'
import { mapGetters } from 'vuex'

/**
 * This layer shows the Request locations
 */
export default {
  name: 'LayerRequests',
  mixins: [ MapBoxMixin ],
  props: {
    /**
     * The active Request
     *  Used on the Request detail page
     */
    uuid: {
      type: String,
      default: null
    },

    /**
     * The selected Requests
     *  Used on the LocationSelection page
     */
    realisationUuid: {
      type: String,
      default: null
    },

    /**
     * Whether to use isochrone instead of circle
     */
    showRadius: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 5 icons (see requiredIcons)
       */
      iconCounter: 0,
      requiredIcons: [
        'active',   // the active Request
        'open',     // requests with open status
        'accepted', // requests with accepted status, with no connection
        'selected', // requests that are selected to be connected to the active location proposal
        'connected' // requests that are connected to a location proposal
      ],
      layerName: 'requests',

      delayedRepaint: null
    }
  },
  computed: {
    ...mapGetters('requests', ['requests']),
    ...mapGetters('relations', ['requestUuidsByRealisationUuid', 'realisationUuidByRequestUuid', 'changeCount']),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

    shownRequest() {
      return this.requests.filter(request => {
        // uuid matches active request, or the request is open or accepted
        return request.uuid === this.uuid || [2, 3].includes(request.status)
      })
    },
    activeRequest() {
      return this.shownRequest.find(request => request.uuid === this.uuid)
    }
  },
  watch: {
    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      } 
      if (ready) {
        this.init()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    },
    changeCount() {
      this.updateLayerData()
    },
    realisationUuid(newValue, oldValue) {
      this.updateLayerData()
    }
  },
  created() {
    if (this.loaded) {
      this.init()
    }
  },
  beforeDestroy() {
    if (this.map) {
      // this.map.off('click', this.layerName, this.handleClickMarkerEvent)
      // this.map.off('mouseenter', this.layerName, this.showPointer)
      // this.map.off('mouseleave', this.layerName, this.hidePointer)
    }
    this.clearLayer()
  },
  methods: {
    
    /********************************************************************************
     * Setup
     */

    init() {
      // Load the layer icons
      this.loadIcons()

      // click event
      // this.map.on('click', this.layerName, this.handleClickMarkerEvent)
      // this.map.on('click', `${this.layerName}-text`, this.handleClickMarkerEvent)

      // // Cursor
      // this.map.on('mouseenter', this.layerName, this.showPointer)
      // this.map.on('mouseenter', `${this.layerName}-text`, this.showPointer)
      // this.map.on('mouseleave', this.layerName, this.hidePointer)
      // this.map.on('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.map.loadImage(
          require(`@/assets/image/legend/request-${name}.png`),
          (err, image) => {
            if (err) throw err;

            if (! this.map.hasImage(`request-${name}`)) {
              this.map.addImage(`request-${name}`, image);
            }
            this.iconCounter++
        })
      })
    },

    /********************************************************************************
     * The events
     */

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Emit a click event upon clicking a request icon
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      this.$emit('click', { uuid: e.features[0].properties.uuid })
    },


    /********************************************************************************
     * The Layer
     */

    /**
     * Translate request requests into GeoJSON
     */
    generateGeoJson() {
      let requests = Array.isArray(this.shownRequest) ? this.shownRequest : []

      let selected = this.requestUuidsByRealisationUuid({
        uuid: this.realisationUuid
      }) || []

      return {
        "type": "FeatureCollection",
        "features": requests.map(request => {
          let icon = ''

          if (request.uuid === this.uuid) {
            icon = 'active'
          } else if (selected.includes(request.uuid)) {
            icon = 'selected'
          } else if (this.realisationUuidByRequestUuid({
            uuid: request.uuid
          })) {
            icon = 'connected'
          } else if (request.status === 2) {
            icon = 'open'
          } else if (request.status === 3) {
            icon = 'accepted'
          }

          return {
            "type": "Feature",
            "properties": {
              "uuid": request.uuid,
              "icon": `request-${icon}`,
              "reference": request.case_ref,
              "size": icon === 'active' ? 0.1 : 0.05
            },
            "geometry": {
              "type": "Point",
              "coordinates": [request.coordinates.lng, request.coordinates.lat]
            }
          }
        })
      }
    },

    updateLayerData() {
      if (! this.loaded) return
      let source = this.map.getSource(this.layerName)

      if (! source) return
      source.setData(this.generateGeoJson())
    },


    /**
     * Source: https://stackoverflow.com/a/37794326
     */
    metersToPixelsAtMaxZoom({ meters, latitude }) {
      return meters / 0.075 / Math.cos(latitude * Math.PI / 180)
    },

    /**
     * Load the request layer
     */
    addLayer() {
      if (! this.map) return

      this.clearLayer()

      // Add the generated source 
      this.map.addSource(this.layerName, {
        type: 'geojson',
        data: this.generateGeoJson()
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['locationpin']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)


      if (this.activeRequest && this.showRadius) {
        this.map.addLayer({ 
          "id": `${this.layerName}-circle`,
          "type": "circle",
          "source": this.layerName,
          "minzoom": 10,
          "layout": {},
          "paint": {
            "circle-radius": {
              stops: [
                [0, 0],
                [20, this.metersToPixelsAtMaxZoom({ 
                  meters: 200, 
                  latitude: this.activeRequest.coordinates.lat 
                })]
              ],
              base: 2
            },
            "circle-color": '#ec0000',
            "circle-opacity": 0.2
          }
        }, positionBelow) 
        this.map.setFilter(`${this.layerName}-circle`, ['==', ['get', 'icon'], 'request-active'])
      }

      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        "id": this.layerName,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 10,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          "icon-allow-overlap": true,
          "icon-image": ['get', 'icon'], 
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            ['get', 'size'],
            18,
            0.2
          ],
          "icon-offset": [0, -125]
        },
        "paint": {
          // "icon-color": "",
          "icon-opacity": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            1
          ]
        }
      }, positionBelow) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({ 
        "id": `${this.layerName}-text`,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': ['get', 'reference'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 11,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.2,
            0.8
          ]
        }
      }, positionBelow)
      
    },

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (! this.map) return 

      // Clean up first if we must
      let source = this.map.getSource(this.layerName)
      if (source) {
        if (this.map.getLayer(this.layerName)) {
          this.map.removeLayer(this.layerName)
        }
        if (this.map.getLayer(`${this.layerName}-text`)) {
          this.map.removeLayer(`${this.layerName}-text`)
        }
        if (this.map.getLayer(`${this.layerName}-circle`)) {
          this.map.removeLayer(`${this.layerName}-circle`)
        }
        
        this.map.removeSource(this.layerName)
      }
    }
  }
}
</script>

