<template>
  <div style="display:none"></div>
</template>

<script>
import MapBoxMixin from '@/components/common/MapBoxMixin'

import { dataLayers } from '@/config'

/**
 * This layer shows the parking permit areas
 */
export default {
  name: 'LayerParkingPermitAreas',
  mixins: [ MapBoxMixin ],
  props: {
    loaded: {
      type: Boolean,
      required: true,
    }
  },
  data() {
    return {
      layerConfig: {
        active: false,
        name: 'parking-permit-areas',
        url: '',
        source: '',
      }
    }
  },
  watch: {
    loaded(ready) {
      if (ready) {
        this.init()
      }
    },
  },
  created() {
    try {
      let config = dataLayers[this.layerConfig.name] || {}
      this.layerConfig = Object.assign(this.layerConfig, config)
    } catch(e) {
      // 
    }

    if (this.loaded) {
      this.init()
    }
  },
  beforeDestroy() {
    this.clearLayer()
  },
  methods: {

    /********************************************************************************
     * Setup
     */

    init() {
      this.addLayer()
    },

    /**
     * Load the parking permit areas layer
     */
    addLayer() {
      if (! this.map) return

      this.clearLayer()

      // console.log(this.layerConfig.url)
      this.map.addSource(this.layerConfig.name, {
        type: "vector",
        url: this.layerConfig.url
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['realisation-processes', 'realisation-processes-text']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      this.map.addLayer({
        "id": this.layerConfig.name,
        "type": "fill",
        "source": this.layerConfig.name,
        "source-layer": this.layerConfig.source,
        "minzoom": 0,
        "paint": {
          "fill-color": ["get", "color"],
          "fill-opacity": .1
        },
        "layout": {}
      }, positionBelow)

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({
        "id": `${this.layerConfig.name}-text`,
        "type": "symbol",
        "source": this.layerConfig.name,
        "source-layer": this.layerConfig.source,
        "paint": {
          "text-color": "#000000",
          "text-opacity": .8
        },
        "layout": {
          "text-field": ["get", "gebied_naam"],
          "text-font": ["Open Sans Regular"],
          "symbol-placement": "point",
          "text-size": 18
        },
      }, positionBelow)
    },

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (! this.map) return

      // Clean up first if we must
      let source = this.map.getSource(this.layerConfig.name)
      if (source) {
        if (this.map.getLayer(this.layerConfig.name)) {
          this.map.removeLayer(this.layerConfig.name)
        }
        if (this.map.getLayer(`${this.layerConfig.name}-text`)) {
          this.map.removeLayer(`${this.layerConfig.name}-text`)
        }

        this.map.removeSource(this.layerConfig.name)
      }
    }
  }
}
</script>

