<template>
  <div class="PlannedRealisation">
    <FieldSet 
      label="Geplande realisatiedatum"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
    <FieldSet 
      label="Voorbereiding"
      class="pt-3"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layoutTwo"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { unicodeString } from '@/services/validation'

export default {
  name: 'PlannedRealisation',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'PlannedRealisation'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PlannedDate": {
          "type": "string",
          "title": "",
          "format": 'date',
        },
        "Contractor": {
          "type": "string",
          "title": "Aannemer"
        },
        "Permit": {
          "type": "string",
          "title": "Vergunning werkzaamheden aangevraagd",
          "enumNames": [
            "Ja",
            "NVT"
          ],
          "enum": [
            'yes',
            'nvt'
          ],
          'format': 'radio'
        },
        "GridOperatorContract": {
          "type": "string",
          "title": "Tekendatum offerte netbeheerder",
          "format": 'date',
        },
        "ChargePointOrderDate": {
          "type": "string",
          "title": "Besteldatum laadpaal",
          "format": 'date',
        },
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'PlannedDate' ]
      }],
      layoutTwo: [{
        "columns": '1fr 1fr',
        "fields": [ 'GridOperatorContract', 'ChargePointOrderDate' ]
      }, {
        "columns": '1fr 1fr',
        "fields": [ 'Contractor', 'Permit' ]
      }],
      validation: {
        "Contractor": {
          unicodeString: (value) => !helpers.req(value) || unicodeString(value)
        }
      }
    }
  }
}
</script>