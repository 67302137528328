<template>
  <div class="TableSettings">
    <PageSize class="TableSettings__pageSize" />
    <LayoutSelection class="TableSettings__layouts" />
  </div>
</template>

<script>
/**
 * Number of records per page
 * Dropdown of available configurations?
 * Dropdown for / selection of layouts within configurations (e.g. with / without tags)? 
 * Toggle: always filters open
 * Expert options: Fuzzy search tweaking ?
 */

import TableState from './mixins/TableState.vue'
import PageSize from './settings/PageSize.vue'
import LayoutSelection from './settings/LayoutSelection.vue'

export default {
  mixins: [ TableState ],
  components: {
    PageSize, LayoutSelection
  }
}
</script>

<style lang="scss">
.TableSettings {
  display: grid;
  grid-template-columns: 150px 150px 1fr;
  grid-template-areas: "layoutSelection pageSize. ";
  row-gap: 8px;
  column-gap: 8px;
}
</style>