

/**
 * Custom MapControl - Bootstrap button
 * 
 * usage: 
 
  const callback = (e, control) {
    control.disable({ label: 'feedback message...' })
    // activity ...
    control.enable()
  }
  map.addControl(
    new ButtonControl({
      label: 'Label',
      callback
    })
  )
 */
export const ButtonControl = function({
  callback, label
}) {
  if (!callback) {
    throw Error("Callback is required")
  }
  this.label = label
  this.callback = callback
}

ButtonControl.prototype = {
  disabled: false,
  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl btn btn-primary';
    this.setLabel({ label:  this.label || 'Download' })
    this._container.addEventListener('click', this.handleClick.bind(this))
    return this._container;
  },
  onRemove() {
    this._container.removeEventListener('click', this.handleClick)
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  },
  getDefaultPosition() {
    return 'bottom-right'
  },
  /**
   * Additional methods
   */
  handleClick: function (e) {
    if (this.disabled) return
    this.callback(e, this)
  },
  setLabel({ label }) {
    this._container.textContent = label || 'Bezig...';
  },
  disable({ label }) {
    this.disabled = true
    this._container.classList.add('disabled')
    this.setLabel({ label })
  },
  enable() {
    this.disabled = false
    this._container.classList.remove('disabled')
    this.setLabel({ label:  this.label || 'Download' })
  }
}

export const IconButtonControl = function({
  callback, icon
}) {
  if (!callback) {
    throw Error("Callback is required")
  }
  this.icon = icon
  this.callback = callback
}

IconButtonControl.prototype = {
  disabled: false,
  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('div');
    this._container.className = 'IconBtn mapboxgl-ctrl';
    this._container.style = 'cursor: pointer; user-select: none;-webkit-user-select: none;'
    this._container.innerHTML = `<svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="icon" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="b-iconstack b-icon bi" style="font-size: 165%"><g><svg viewBox="0 0 16 16" fill="currentColor" class="bi-arrows-angle-expand b-icon bi"><g><g><path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"></path></g></g></svg><svg viewBox="0 0 16 16" fill="currentColor" class="bi-arrows-angle-expand b-icon bi"><g><g transform="translate(8 8) rotate(90) translate(-8 -8)"><path fill-rule="evenodd" d="M5.828 10.172a.5.5 0 0 0-.707 0l-4.096 4.096V11.5a.5.5 0 0 0-1 0v3.975a.5.5 0 0 0 .5.5H4.5a.5.5 0 0 0 0-1H1.732l4.096-4.096a.5.5 0 0 0 0-.707zm4.344-4.344a.5.5 0 0 0 .707 0l4.096-4.096V4.5a.5.5 0 1 0 1 0V.525a.5.5 0 0 0-.5-.5H11.5a.5.5 0 0 0 0 1h2.768l-4.096 4.096a.5.5 0 0 0 0 .707z"></path></g></g></svg></g></svg>`
    this._container.addEventListener('click', this.handleClick.bind(this))
    return this._container;
  },
  onRemove() {
    this._container.removeEventListener('click', this.handleClick)
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  },
  getDefaultPosition() {
    return 'top-left'
  },
  /**
   * Additional methods
   */
  handleClick: function (e) {
    if (this.disabled) return
    this.callback(e, this)
  },
  disable() {
    this.disabled = true
    this._container.classList.add('disabled')
  },
  enable() {
    this.disabled = false
    this._container.classList.remove('disabled')
  }
}

/**
 * Custom MapControl - Bootstrap button
 * 
 * usage: 
 
  const callback = (e, control) {
    console.log("Style Changed")
  }
  map.addControl(
    new StyleControl({
      callback
    })
  )
 */
export const StyleControl = function({
  callback, styles
}) {
  this.styles = styles
  this.callback = callback
}

StyleControl.prototype = {
  active: 0,
  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map
    this._container = document.createElement('div')
    this._container.className = 'mapboxgl-ctrl StyleToggle'
    this._container.classList.add(`StyleToggle--${this.styles[1].class}`)
    this._container.textContent = '';
    this._container.addEventListener('click', this.handleClick.bind(this))
    return this._container
  },
  onRemove() {
    this._container.removeEventListener('click', this.handleClick)
    this._container.parentNode.removeChild(this._container)
    this._map = undefined
  },
  getDefaultPosition() {
    return 'bottom-right'
  },
  /**
   * Additional methods
   */
  handleClick: function (e) {
    
    if (this.active === 0) {
      this._map.setStyle(this.styles[1].url)
      this._container.classList.remove(`StyleToggle--${this.styles[1].class}`)
      this._container.classList.add(`StyleToggle--${this.styles[0].class}`)
      this.active = 1
    } else {
      this._map.setStyle(this.styles[0].url)
      this._container.classList.remove(`StyleToggle--${this.styles[0].class}`)
      this._container.classList.add(`StyleToggle--${this.styles[1].class}`)
      this.active = 0
    }
    if (this.callback) {
      this.callback(e, this)
    }
  }
}

export const TextControl = function({
  label
}) {
  this.label = label
}

TextControl.prototype = {
  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map;
    this._container = document.createElement('strong');
    this._container.className = 'mapboxgl-ctrl alert alert-light';
    this._container.textContent = this.label
    return this._container;
  },
  onRemove() {
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  },
  getDefaultPosition() {
    return 'top-left'
  }
}


export const LegendControl = function({
  label, items
}) {
  this.label = label || 'Legenda'
  this.items = items || []
}

LegendControl.prototype = {
  open: false,
  labelEl: null,

  /**
   * MapBox API
   */
  onAdd(map) {
    this._map = map;

    this.labelEl = document.createElement('strong')
    this.labelEl.textContent = this.label
    this.labelEl.style = 'display: block; width: 100%; cursor: pointer; user-select: none;-webkit-user-select: none;'
    this.labelEl.addEventListener('click', this.handleClick.bind(this))

    this.ulEl = document.createElement('ul')
    this.ulEl.className = 'Legend__list'
    this.ulEl.style = 'list-style-type: none; padding: 0.5rem 0 0; margin: 0'

    this.items.forEach(item => {
      const li = document.createElement('li')
      li.style = 'margin-top: 0.5rem'

      const div = document.createElement('span')
      div.textContent = item.label
      div.style = "display: inline-block; margin-left: 0.5rem"

      const img = document.createElement('img')
      img.src = require(`@/assets/image/legend/${item.image}.png`)
      img.width = 20;

      li.appendChild(img)
      li.appendChild(div)

      this.ulEl.appendChild(li)
    })

    this._container = document.createElement('div')
    this._container.id = 'legend-ctrl'
    this._container.className = 'Legend mapboxgl-ctrl alert alert-light'
    
    this._container.appendChild(this.labelEl)

    if (this.open) {
      this._container.appendChild(this.ulEl)
    }

    return this._container;
  },
  onRemove() {
    this.labelEl.removeEventListener('click', this.handleClick)
    this._container.parentNode.removeChild(this._container);
    this._map = undefined;
  },
  getDefaultPosition() {
    return 'top-left'
  },
  handleClick() {
    if (this.open) {
      this._container.removeChild(this.ulEl)
      this.open = false
    } else {
      this._container.appendChild(this.ulEl)
      this.open = true
    }
  }
}


