<template>
  <div v-if="setupCompleted" class="TableWrapper">

    <Table 
      ref="table" 
      v-if="! noAccessibleConfigs"
      :loading="loading" />

    <div v-else>
      U heeft onvoldoende rechten om deze lijstweergave te bekijken.
    </div>
    
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

import { configs } from './devconfig'
import { removeAllActiveFiltersByCategory } from './utils'

import Table from './Table.vue'

/**
 * TableState is use as local Vuex store
 *  This isolates the table instance state from outside influence
 */
// import TableState from './mixins/TableState.vue'

/**
 * TODO: Store table specific configuration (e.g. active filters, sorting options) on destroy, in localstorage
 * TODO: reference table specific configuration from localstorage
 * TODO: Change from localstorage to Auth0 user profile ? 
 * 
 * 
 * TODO: Maybe interesting: https://teamhood.com/engineering/better-vue-performance-with-selective-object-reuse/
 */
export default {
  name: 'TableManager',
  components: {
    Table
  },
  provide() {
    return {
      tableIdentifier: this.tableIdentifier
    }
  },
  // mixins: [ TableState ],
  props: {
    /**
     * The table configuration category
     */
    category: {
      type: String,
      requird: true
    },

    /**
     * 
     */
    sourceRecords: {
      type: Array,
      required: true
    },

    /**
     * Indicates an asynchonous action is taking place to retrieve new records
     */
    loading: {
      type: Boolean,
      default: false
    },

    /**
     * Used as reference for retrieving / storing table settings in localstorage
     *  if left empty the category name is used
     */
    name: {
      type: String,
      default: null
    },

    /**
     * A particular table configuration
     */
    uuid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      setupCompleted: false,
      tableName: false,
      tableCategory: false
    }
  },
  computed: {
    ...mapGetters('user', [
      'securityCheck'
    ]),
    ...mapGetters('EVtables', [
      'getActiveStoredTableState',
      'activeConfig'
    ]),


    /**
     * Switch to internal table name & category to block changes through props
     */
    tableIdentifier() {
      if (this.tableName && this.tableCategory) {
        return `${this.tableCategory}.${this.tableName}`
      }
      return `${this.category}.${this.name || this.category}`
    },

    /**************************************************************************
     * Configuration
     */

    /**
     * Filter to only configs connected to the category of this table
     */
    configsInCategory() {
      return Object.keys(configs)
        .filter(uuid => configs[uuid].category === this.category)
        .map(uuid => configs[uuid])
    },

    /**
     * Check which configs are accessible by the current user
     */
    accessibleConfigs() {
      return this.configsInCategory.filter(config => {
        // No requirements spec = good to go
        if (
          ! config.access 
          || ! Array.isArray(config.access.requirements)
          || config.access.requirements.length === 0
        ) {
          return true
        }

        // For those with requirements, check if all are met by the active user
        return config.access.requirements.every(
          req => this.securityCheck({ attribute: req })
        )
      })
      // Simplify to the bare minimum
      .map(config => {
        return {
          uuid: config.uuid
        }
      })
    },

    /**
     * Check whether there are any configs accessible at all
     */
    noAccessibleConfigs() {
      return this.accessibleConfigs.length === 0
    },

    /**
     * Return the specified config uuid, unless it is not accessible for this user
     *  If no config uuid was specified, use the first accessible config
     * 
     * TODO: reference table specific configuration from localstorage
     */
    configUuid() {
      if (! this.uuid) {
        return this.accessibleConfigs[0].uuid
      }

      return (this.accessibleConfigs.map(config => config.uuid).includes(this.uuid)) 
        ? this.uuid
        : false  
    },

    config() {
      return this.configsInCategory
        .find(config => config.uuid === this.activeConfig({ identifier: this.tableIdentifier })) || false
    },
  },
  watch: {
    /**
     * When the source record set changes in any way, refresh the table
     */
    sourceRecords: {
      handler() {
        this.setRecords({ 
          identifier: this.tableIdentifier,
          records: this.mapRecords() 
        })
        if (this.$refs.table) {
          this.$refs.table.filterAndRefresh()
        }
      },
      deep: true
    },
    config() {
      if (this.setupCompleted) {
        this.resetSettings()
      }
    }
  },
  created() {
    this.setup()
  },
  beforeDestroy() {
    // Store config by name & config uuid combination
    // Add name, config uuid, settings & active filters

    // this.storeTableState({
    //   category: this.tableCategory,
    //   name: this.tableName,
    //   config: this.activeConfig,

    //   settings: this.settings,
    //   activeFilters: this.activeFilters,
    //   activeSorting: this.activeSorting
    // })

    // console.log("before destroy")
  },
  methods: {
    ...mapMutations('EVtables', [
      'storeTableState',
      'setAccessibleConfigs',
      'setActiveConfig',
      'setupTableState',
      'setRecords'
    ]),
    setup() {
      this.tableName = this.name || this.category
      this.tableCategory = this.category
      
      this.setAccessibleConfigs({ 
        configs: this.accessibleConfigs, 
        identifier: this.tableIdentifier 
      })
      this.setActiveConfig({ 
        uuid: this.configUuid, 
        identifier: this.tableIdentifier 
      })

      let storedTableState = this.getActiveStoredTableState({
        identifier: this.tableIdentifier
      })

      if (! storedTableState) {
        this.setupTableState({ 
          identifier: this.tableIdentifier,
          config: this.config
        })
      }

      this.setRecords({ 
        identifier: this.tableIdentifier,
        records: this.mapRecords() 
      })

      this.$nextTick(() => {
        

        this.setupCompleted = true
      })
    },

    resetSettings() {
      // if (this.setupCompleted) {
      //   this.storeTableState({
      //     category: this.tableCategory,
      //     name: this.tableName,
      //     config: this.activeConfig,

      //     settings: this.settings,
      //     activeFilters: this.activeFilters,
      //     activeSorting: this.activeSorting
      //   })
      // }
      // this.setPageSize({
      //   pageSize: this.config.pageSize || 10
      // })
    },

    /**
     * Run the reconds through the data mapper from the config
     */
    mapRecords() {
      if (this.config && this.config.dataMapper) {
        return this.sourceRecords.map(this.config.dataMapper).slice()
      } else {
        return []
      }
    },


    /**************************************************************************
     * External API methods
     */

    /**
     * Replace the filters from a particular filter category
     *  Note: proper values & labels need to be provided
     */
    replaceActiveFiltersInCategory({ category, filters }) {
      let filterConfig = this.config.filters[category]

      const storedTableState = this.getActiveStoredTableState({
        identifier: this.tableIdentifier
      })

      // Only move forward if we know the category
      if (! filterConfig ) return

      // Remove current active filters in this category
      removeAllActiveFiltersByCategory({
        activeFilters: storedTableState.activeFilters,
        category
      })

      // Add the new filters
      filters.forEach(filter => {
        storedTableState.activeFilters.push({
          category,
          categoryLabel: filterConfig.label,
          hidden: !! filterConfig.hidden,
          value: filter.value,
          label: filter.label || filter.value
        })
      })
    }
  }
}
</script>

<style>

</style>