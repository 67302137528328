<template>
  <div class="CPOFinUnit">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CPOFinUnit',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Financiële Unit'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "FinUnit": {
          "type": "string",
          "title": "",
          "enumNames": [
              "unit001",
              "unit002",
              "unit003",
              "unit004",
              "unit005",
              "unit006",
              "unit007",
              "unit008",
              "unit009",
              "unit010"
          ],
          "enum": [
              "unit001",
              "unit002",
              "unit003",
              "unit004",
              "unit005",
              "unit006",
              "unit007",
              "unit008",
              "unit009",
              "unit010"
          ]
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'FinUnit' ]
      }],
      validation: {
        'FinUnit': { 
          required
        }
      }
    }
  }
}
</script>