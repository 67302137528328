<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <div v-if="! reloading" class="DetailGrid__col1">
        <TrafficDecision :values="values" :disabled="locked || disabled" @setDefinitiveDate="handleDefinitiveDate" />

        <div v-if="isGORALTenant">
          <h3>Verkeersbesluit document</h3>
          <FileUpload 
            class="mt-3"
            label=""
            doctype="TrafficDecision"  
            :uuid="uuid"
            :files="(values && values.Docs) ? values.Docs.TrafficDecision : null"
            :minFileId="minTrafficDecisionDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            @files="handleFileChanges" />

          <p v-if="locked && (values && values.Docs ? (values.Docs.TrafficDecision || []) : []).length === 0">
            Er is geen verkeersbesluit document beschikbaar
          </p>
        </div>
      </div>
      <div v-if="! reloading" class="DetailGrid__col2">
        <div class="VKB_Instruction mt-3">
          <h4>
            Automatische datum koppeling
          </h4>
          <p>
            Bij het invoeren en wijzigen van de publicatie datum wordt de einddatum van de bezwaarperiode automatisch vastgesteld op 6 weken vanaf de publicatiedatum.
          </p>
          <p>
            Deze automatisch vastgestelde datum is altijd vrij aanpasbaar, maar wordt weer overschreven bij iedere wijziging van de publicatie datum.
          </p>
        </div>
      </div>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import TrafficDecision from '@/components/definitions/TrafficDecision'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationStep5',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, Comments,
    TrafficDecision,
    FileUpload
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "4"
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    // Docs
    minTrafficDecisionDocsId() {
      try {
        return this.record.values.Docs.TrafficDecision.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },
    isGORALTenant() {
      return this.isCurrentTenant({ tenant: 'go-ral' })
    }
  },
  methods: {

    prepData({ data }) {
      // Add document numbers
      data.Docs = {
        TrafficDecision: (this.Docs.TrafficDecision || []).filter(file => file.progress === 100).map(file => file.id)
      }

      // Falsy => empty string
      if (! data.TrafficDecision.PublicationDate) {
        data.TrafficDecision.PublicationDate = ''
      }
      if (! data.TrafficDecision.DefinitiveDate) {
        data.TrafficDecision.DefinitiveDate = ''
      }

      return data
    },

    /**
     * Typical 'v1.5' hack to update a value based on input from another field
     */
    handleDefinitiveDate(date) {
      this.record.values.Location.TrafficDecision.DefinitiveDate = date
    }
  }
}
</script>

<style>
.VKB_Instruction {
  padding: 1rem;
  background: rgba(0, 0, 0, 0.05);
}
</style>