<template>
  <div class="FuseBoxPermission">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />

  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isNumeric, isUrl } from '@/services/validation'

export default {
  name: 'FuseBoxPermission',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Offerte voor grote aanpassing hoofdverdeelkast / vervanging'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions.FuseBoxPermission'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "FuseBoxContractorName": {
            "type": "string",
            // "title": "Leverancier voor grote aanpassing hoofdverdeelkast / vervanging"
            "title": "Leverancier"
        },
        "FuseBoxContractorQuotationRequestDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de offerte voor een grote aanpassing hoofdverdeelkast / vervanging  is aangevraagd"
            "title": "Datum van offerte aanvraag"
        },
        "FuseBoxContractorQuotationReceiveDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de offerte voor een grote aanpassing hoofdverdeelkast / vervanging is ontvangen"
            "title": "Datum van offerte ontvangst"
        },
        "FuseBoxContractorQuotationLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar de offerte voor een grote aanpassing hoofdverdeelkast / vervanging"
            "title": "Link naar offerte"
        },
        "Amount": {
            "type": "string",
            // "title": "Kosten van een grote aanpassing hoofdverdeelkast / vervanging"
            "title": "Kosten in €"
        }
      },
      layout: [{
        "columns": "1fr 1fr 1fr",
        "fields": [ 'FuseBoxContractorName', 'FuseBoxContractorQuotationRequestDate', 'FuseBoxContractorQuotationReceiveDate' ]
      },{
        "columns": "1fr 1fr 1fr",
        "fields": [ 'Amount', 'FuseBoxContractorQuotationLink' ]
      }],
      validation: {
        "Amount": {
          isNumeric: (value) => !helpers.req(value) || isNumeric(value)
        },
        FuseBoxContractorQuotationLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  }
}
</script>