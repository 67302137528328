<template>
  <div 
    v-if="visibleActiveFilters.length" 
    class="TableActiveFilters d-flex mb-3 ml-2 align-items-center">

    <div class="mr-2 flex-shrink-0 text-muted" style="font-size: 75%">
      Actieve filters
    </div>

    <div>
      <b-badge 
        v-for="(filter,index) in activeFilters"
        class="mr-2"
        :class="{ 'hidden' : filter.hidden }"
        :key="`filter_${index}`">
        {{ filter.categoryLabel }}: {{ filter.label }}

        <b-button-close 
          @click="handleRemove({ index })" />
      </b-badge>
    </div>

  </div>
</template>

<script>

import FilterLogic from './mixins/FilterLogic.vue'

import TableState from './mixins/TableState.vue'

/**
 * The mixin handles the record filtering logic.
 *  This component is focused on displaying the active filters
 */
export default {
  name: 'TableActiveFilters',
  mixins: [ FilterLogic, TableState ],
  computed: {
    visibleActiveFilters() {
      return this.activeFilters.filter(filter => filter.hidden !== true)
    }
  },
  methods: {
    handleRemove({ index }) {
      this.activeFilters.splice(index, 1)
    }
  }
}
</script>

<style lang="scss">
.TableActiveFilters {
  .hidden {
    display: none;
  }

  .badge {
    .close {
      font-size: 125%;
      line-height: 1;
      color: inherit;
      margin-left: 0.25rem;
      float: none;
    }
  }
}
</style>