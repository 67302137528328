<template>
  <div>
    <h5>Voertuiggegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">Merk</th>
        <td colspan="2">
          {{ vehicle.brand }}
        </td>
      </tr>
      <tr>
        <th scope="row">Model</th>
        <td colspan="2">
          {{ vehicle.model }}
        </td>
      </tr>
      <tr>
        <th scope="row">Kenteken</th>
        <td>
          <span class="mr-2">{{ vehicle.numberplate }}</span>
          <a target="blank" :href="`https://ovi.rdw.nl/default.aspx?kenteken=${vehicle.numberplate}`">
            RDW kentekencheck <b-icon icon="box-arrow-up-right"></b-icon>
          </a>
        </td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.numberplate } }">
            <b-badge variant="dark">
              {{ requestCountByNumberplate({ numberplate: search.numberplate }) }}
              <b-icon class="ml-2" icon="search"></b-icon>
            </b-badge>  
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">
          Documenten
        </th>
        <td colspan="2">
          <b-icon class="mr-2" icon="download"></b-icon>
          <template v-for="(number,index) in vehicledocs">
            <a class="mb-4 mr-2" :key="`file_${index}`" @click="handleDoc({ type: 'vehicle', number, request })">{{ request.case_ref }}-vehicle-{{ index }}</a>
          </template>
        </td>
      </tr>
    </table>


    <h5>Parkeergegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">
          Parkeervergunning
        </th>
        <td>{{ softrequirements.parkingpermit ? 'Ja' : 'Nee' }}</td>
      </tr>
      <tr class="pb-2">
        <th scope="row">Parkeerplaats op kenteken</th>
        <td>{{ softrequirements.parkingspot ? 'Ja' : 'Nee' }}</td>
      </tr>
      <tr>
        <th scope="row">
          Documenten
        </th>
        <td>
          <b-icon v-if="parkingspotdocs.length" class="mr-2" icon="download"></b-icon>
          <span v-else>-</span>
          <template v-for="(number,index) in parkingspotdocs">
            <a class="mb-4 mr-2" :key="`file_${index}`" @click="handleDoc({ type: 'parkingspot', number, request })">{{ request.case_ref }}-parkingspot-{{ index }}</a>
          </template>
        </td>
      </tr>
    </table>


    <h5>Adresgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">Straat</th>
        <td colspan="2">
          {{ contact.street }}
        </td>
      </tr>
      <tr>
        <th scope="row">Huisnummer</th>
        <td colspan="2">
          {{ contact.streetnumber }} {{ contact.streetsuffix }}
        </td>
      </tr>
      <tr>
        <th scope="row">Postcode</th>
        <td>{{ formatPostalCode(contact.postalCode) }}</td>
        <td>
          <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
            <b-badge variant="dark">
              {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
              <b-icon class="ml-2" icon="search"></b-icon>
            </b-badge>  
          </router-link>
        </td>
      </tr>
      <tr>
        <th scope="row">Plaats</th>
        <td colspan="2">
          {{ contact.city }}
        </td>
      </tr>
    </table>

    <h5>Tijdslot &amp; opmerkingen</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">Tijdslot</th>
        <td>{{ timeslot }}</td>
      </tr>
      <tr>
        <th scope="row">Opmerkingen</th>
        <td>{{ remark ? remark : '-' }}</td>
      </tr>
    </table>

    <h5>Contactgegevens:</h5>
    <table class="table table-sm mb-4">
      <tr>
        <th scope="row">Voorletters</th>
        <td>{{ contact.initials }}.</td>
      </tr>
      <tr>
        <th scope="row">Achternaam</th>
        <td>{{ contact.surname }}</td>
      </tr>
      <tr>
        <th scope="row">Telefoonnummer</th>
        <td>{{ contact.phone }}</td>
      </tr>
      <tr>
        <th scope="row">Emailadres</th>
        <td>{{ contact.email }}</td>
      </tr>
    </table>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import { saveAs } from 'file-saver';
import { formatPostalCode } from '@/helpers/string'

export default {
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('requests', [      
      'requestCountByNumberplate',
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    address() {
      return this.request.address
    },
    additional() {
      return this.request.additional
    },
    remark() {
      return this.request.raw.remarks || ''
    },
    contact() {
      return this.request.contact
    },

    /**
     * Amsterdam
     */
    vehicle() {
      return this.request.vehicle
    },
    vehicledocs() {
      return this.request.docs.vehicle
    },
    softrequirements() {
      return this.request.softrequirements
    },
    parkingspotdocs() {
      return this.request.docs.parkingspot
    },
    /**
     * These timeslot options have to be kept in sync with the request form
     */
    timeslot() {
      const timeslots = [
        "Tussen 00:00 en 07:00 uur",
        "Tussen 07:00 en 12:00 uur",
        "Tussen 12:00 en 17:00 uur",
        "Tussen 17:00 en 24:00 uur"
      ]
      return timeslots[this.request.raw.timeslot]
    },
  },
  methods: {
    formatPostalCode,
    /**
     * Get temporary download link & start download
     *  TODO: Refactor to helper function. Take notice of token & modal ref...
     */
    handleDoc: async function({ type, number, request }) {
      try {
        const token = await this.$auth.getTokenSilently();
        const api = await fetch(`${process.env.VUE_APP_AWS_ENDPOINT}/url`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ref: request.ref,
            uuid: request.uuid,
            casefull: request.case_ref,
            index: number,
            doctype: type
          })
        })
        if (! api.ok) {
          throw new Error("server error")
        } 
        const response = await api.json()
        if (! response.downloadURL) {
          throw new Error("missing url")
        }
        // Chrome, FF, Edge
        if (window.browser && window.browser.downloads) {
          window.browser.downloads.download({
            url : response.downloadURL
          })
        } else { // Safari, etc.
          const res = await fetch(response.downloadURL)
          const blob = await res.blob()
          saveAs(blob, response.filename)
        }
      } catch(e) {
        // console.log(e)
        this.$bvModal.show('file-error')
      }
    }
  }
}
</script>

<style>

</style>