<template>
  <div>
    <Form 
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid" 
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :record="record" :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <template v-if="!reloading">
        <div class="DetailGrid__map">
          <b-alert variant="warning" :show="!locked && advice === 'alternative'">
            Stel onder aan deze pagina een alternatieve locatie voor.
          </b-alert>
        </div>

        <div class="DetailGrid__col1">
          <CPOAdvice :values="values" :disabled="locked || disabled" @advice="handleAdviceChange" />

        </div>
        <div class="DetailGrid__col2">

          <MapLocationViewer 
            :record="record"
            :values="values" />

          <FileUpload 
            class="pt-3"
            label="Handmatig startdocument"
            doctype="ParkingSpots"
            :uuid="uuid"
            :files="values && values.Docs ? values.Docs.ParkingSpots : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true" />
          <p class="text-muted" v-if="(values && values.Docs ? values.Docs.ParkingSpots: []).length === 0">
            Er is geen handmatig ingevuld startdocument beschikbaar. 
          </p>
          <template v-if="(values && values.Docs ? values.Docs.ParkingSpots : []).length === 0">
            <DocDownload class="mb-3" :uuid="uuid" />
          </template>

          <!-- <strong class="d-block pt-3">Pin coordinaten</strong>
          <Address 
            :record="record"
            :values="alternative"
            :viewer="true" /> -->

        </div>

        <div class="DetailGrid__wide">

          <div v-if="advice === 'alternative'" >
            <h3>Alternatief locatievoorstel</h3>
            <MapPreSelectedLocationSelection 
              v-if="isGORALTenant"  
              :record="record"
              :values="alternative"
              :locked="locked" 
              :disabled="disabled"
              :alternative="true"
              :connect="false" />
            <MapDisconnectedLocationSelection   
              v-else
              :record="record"
              :values="alternative"
              :locked="locked" 
              :disabled="disabled" />

          </div>
        </div>

        <div v-if="advice === 'alternative'" class="DetailGrid__col3">

          <FileUpload 
            class="mt-3"
            label="Boven aanzicht"
            doctype="TopView-alt"
            :uuid="uuid"
            :files="alternative && alternative.Docs ? (alternative.Docs.TopView || []) : null"
            :minFileId="minTopViewDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            :preview="true"
            :wide="true"
            @files="handleFileChanges" />
            <p class="text-muted" v-if="(locked || disabled) && (alternative && alternative.Docs ? alternative.Docs.TopView : []).length === 0">
              Er is geen boven aanzicht beschikbaar
            </p>
        </div>
        <div v-if="advice === 'alternative'" class="DetailGrid__col4">
          <FileUpload 
            class="mt-3"
            label="Zij aanzicht"
            doctype="SideView-alt"
            :uuid="uuid"
            :files="alternative && alternative.Docs ? (alternative.Docs.SideView || []) : null"
            :minFileId="minSideViewDocsId"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="locked"
            :disabled="disabled"
            :preview="true"
            :wide="true"
            @files="handleFileChanges" />
            <p class="text-muted" v-if="(locked || disabled) && (alternative && alternative.Docs ? alternative.Docs.SideView : []).length === 0">
              Er is geen zij aanzicht beschikbaar
            </p>
        </div>

        <div v-if="advice === 'alternative'" class="DetailGrid__col5">
          <StepOneMisc :values="alternative" :disabled="locked || disabled" />
        </div>

        <div v-if="advice === 'alternative'" class="DetailGrid__col6">
          <ChargingSituation :values="alternative" :disabled="locked || disabled" />
        </div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import MapDisconnectedLocationSelection from '@/components/map/MapDisconnectedLocationSelection'
import MapPreSelectedLocationSelection from '@/components/map/MapPreSelectedLocationSelection.vue'

import ChargingSituation from '@/components/definitions/ChargingSituation'
import StepOneMisc from '@/components/definitions/StepOneMisc'

import MapLocationViewer from '@/components/map/MapLocationViewer.vue'
// import Address from '@/components/definitions/Address.vue'

import Form from '@/components/form/Form'
import CPOAdvice from '@/components/definitions/CPOAdvice'
import FileUpload from '@/components/FileUpload.vue'
import DocDownload from '@/components/realisation/DocDownload.vue'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

import Location from '@/models/Location'

import { mapGetters } from 'vuex'

export default {
  name: 'RealisationStep3',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, CPOAdvice, FileUpload, 
    MapDisconnectedLocationSelection, MapPreSelectedLocationSelection, 
    ChargingSituation, StepOneMisc,
    Comments, MapLocationViewer,
    // Address, 
    DocDownload
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "2",
      advice: null
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    /**
     * Load or generate the alternative location
     */
    alternative() {
      try {
        let Alternative = this.values.CPOAdvice.Alternative
        if (Alternative) {
          return new Location({ data: Alternative })
        } else {
          throw Error("not a valid location")
        }
      } catch(e) {
        // console.log("not a valid location")
        // Copy of step 1 data
        return this.record.values.Location.LocationCopy({ version: this.record.nextLocationVersion() })
      }
    },

    confirmed() {
      try {
        return !! this.values.CPOAdvice.Confirmed
      } catch(e) {
        return false
      }
    },

    minTopViewDocsId() {
      try {
        let Max = this.minIdByDocType({ doctype: 'TopView' })
        let MaxAlt = this.minIdByDocType({ doctype: 'TopView-alt' })
        return Math.max(Max, MaxAlt)
      } catch(e) {
        return 0
      }
    },
    minSideViewDocsId() {
      try {
        let Max = this.minIdByDocType({ doctype: 'SideView' })
        let MaxAlt = this.minIdByDocType({ doctype: 'SideView-alt' })
        return Math.max(Max, MaxAlt)
      } catch(e) {
        return 0
      }
    },
    isGORALTenant() {
      return this.isCurrentTenant({ tenant: 'go-ral' })
    }
  },
  watch: {
    reloading() {
      this.loadAdvice()
    },
    hasRecord() {
      this.loadAdvice()
    }
  },
  created() {
    if (this.hasRecord) {
      this.loadAdvice()
    }
  },
  methods: {

    loadAdvice() {
      try {
        this.advice = this.values.CPOAdvice.CPOAdvice
      } catch(e) {
        // Expected to fail on the first run
      }
    },

    handleAdviceChange({ value }) {
      this.advice = value
    },

    prepData({ data }) {
      if (this.advice === 'alternative') {
        data.Docs = {
          TopView: this.prepDocData({ doctype: 'TopView-alt' }),
          SideView: this.prepDocData({ doctype: 'SideView-alt' }),
        }
      } else {
        data.Docs = {
          TopView: [],
          SideView: []
        }
      }

      // Convert ParkingSpot Identifiers
      if (data.ParkingSpots) {
        data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers || []
        data.ParkingSpots.Identifiers = data.ParkingSpots.Identifiers.map(obj => obj.Identifier).filter(id => !!id)
      }


      // Try to redirect to step 5 if the advice is positive
      if (data.CPOAdvice.CPOAdvice === 'yes') {
        this.redirectToAfterSubmit = 4
      }

      return data
    },
    /**
     * NOTE! Due to a change in the specs, the municipality was locked out of being able to edit anything in this step
     */
    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (! ['yes', 'no', 'alternative', 'alternative-without'].includes(data.CPOAdvice.CPOAdvice)) {
            throw new Error()
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Het proces kan niet verder zonder beoordeling.'
          return false
        }

        try {
          if (data.CPOAdvice.CPOAdvice !== 'yes' && (! data.CPOAdvice.CPOComments || data.CPOAdvice.CPOComments.trim() === '')) {
            throw new Error()
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Het proces kan bij een afwijzing of alternatief niet verder zonder toelichting.'
          return false
        }
      }

      // this.disabled = false
      return true
    }
  },
}
</script>
