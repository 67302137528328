<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" />

      <div v-if="! reloading" class="DetailGrid__col1">
        <b-alert :show="Skip">
          De huidige locatie is gekozen op advies van de CPO. De meerwerkkosten kunnen behoren bij de oorspronkelijke locatie. Controleer deze informatie en pas dit zonodig aan in stap 3.
        </b-alert>

        <OrderConfirmation :values="values" :disabled="locked || disabled" />

        <FileUpload 
          class="mt-3"
          label="Meerwerk offertes"
          doctype="CPOAdditional"  
          :uuid="uuid"
          :files="values && values.Docs ? values.Docs.CPOAdditional : null"
          :minFileId="0"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="true"
          :disabled="true" />
        <p v-if="(values && values.Docs ? (values.Docs.CPOAdditional || []) : []).length === 0">
          Er zijn geen meerwerk offertes ingevoerd
        </p>
      </div>
      <div v-if="! reloading" class="DetailGrid__col2">
        <TrafficDecision :values="values" :disabled="locked || disabled" />

        <h3>Verkeersbesluit document</h3>
        <FileUpload 
          class="mt-3"
          label=""
          doctype="TrafficDecision"  
          :uuid="uuid"
          :files="(values && values.Docs) ? values.Docs.TrafficDecision : null"
          :minFileId="minTrafficDecisionDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" />

        <p v-if="locked && (values && values.Docs ? (values.Docs.TrafficDecision || []) : []).length === 0">
          Er is geen verkeersbesluit document beschikbaar
        </p>
      </div>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import OrderConfirmation from '@/components/definitions/OrderConfirmation'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'


// AMSTERDAM DEFINITIONS
import TrafficDecision from '@/components/definitions/amsterdam/TrafficDecision'


export default {
  name: 'RealisationStep5',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, FileUpload, Comments,
    OrderConfirmation,
    TrafficDecision
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 5
    }
  },
  computed: {
    // Docs
    minTrafficDecisionDocsId() {
      try {
        return this.record.values.Docs.TrafficDecision.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    }
  },
  // watch: {
  //   values() {
  //     // console.log(this.values)
  //     // console.log(this.values.Docs.TrafficDecision)
  //   }
  // },
  created() {
    // console.log("step 5")
    // console.log(this.record)
    // console.log(this.values)
  },
  methods: {
    prepData({ data }) {

      data.OrderConfirmation.IsOrderConfirmed = data.OrderConfirmation.IsOrderConfirmed[0]
      data.OrderConfirmation.IsAdditionalWorkConfirmed = data.OrderConfirmation.IsAdditionalWorkConfirmed[0]

      // Add document numbers
      data.Docs = {
        TrafficDecision: (this.Docs.TrafficDecision || []).filter(file => file.progress === 100).map(file => file.id)
      }
      return data
    },
    
    isValidData() { // { data }
    // Validation is taking place at the field level, as all fields are always required
      return true
    }
  },
}
</script>