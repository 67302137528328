
/**
 * Import Dependency
 */

import Request from '@/models/Request'

import { deepmerge } from '@/../shared/helpers/object'

import { reasonOptions } from '@/config'

/**
 * This list does not change, so it's kept outside of the state object
 */
const statusOptions = [
  // id: 0 = onvolledig
  // id: 1 = automatisch afgewezen
  {
    id: 2,
    name: "open",
    label: "opnenen",
    icon: "file-earmark-fill",
    variant: "dark",
  },
  {
    id: 3,
    name: "geaccepteerd",
    label: "accepteren",
    icon: "check-circle-fill",
    variant: "success",
  },
  {
    id: 4,
    name: "afgewezen",
    label: "afwijzen",
    icon: "x-circle-fill",
    variant: "danger",
  },
  {
    id: 5,
    name: "verwijderd",
    label: "verwijderen",
    icon: "trash-fill",
    variant: "warning",
  },
]




/**
 * Import API
 */

/**
 * Declare Variable
 */
 const state = {
  /**
   * Locks the UI while loading / refreshing / sending data
   */
  loading: false,
  lastRefresh: '',

  /**
   * The search term in the list view
   */
  searchterm: '',
  currentPage: 1,

  /**
   * List of requests
   */
  requests: [],
  activeStatusId: 2, // open

  /**
   * Keep track of the last viewed request (in this session)
   */
  lastOpenedRequest: null,
}

const getters = {
  loading: state => state.loading,
  lastRefresh: state => state.lastRefresh,

  searchterm: state => state.searchterm,
  currentPage: state => state.currentPage,

  lastOpenedRequest: state => state.lastOpenedRequest,

  /**
   * List
   */
  requests: state => state.requests.sort((a, b) => a.counter > b.counter ? -1: 1),
  hasRequests: state => state.requests.length !== 0,
  requestsByStatus: (state, getters) => ({ status }) => getters.requests.filter(request => request.status === status),
  requestsByActiveStatus: (state, getters) => getters.requestsByStatus({ status: state.activeStatusId }),

  /**
   * Requests without coordinates
   */
  requestsWithoutCoordinates: (state, getters) => getters.requests.filter(request => request.coordinates.lat === null && request.coordinates.nomatch !== true),

  /**
   * Detail
   */
  hasRequest: state => ({ uuid }) => {
    return Array.isArray(state.requests) && state.requests.filter(request => request.uuid === uuid).length > 0
  },
  requestByUuid: state => ({ uuid }) => {
    let request = state.requests.filter(request => request.uuid === uuid)
    return request.length ? request[0] : null
  },

  requestCountByPostalcode: state => ({ postalcode }) => {
    return state.requests.filter(request => request.search.postalcode === postalcode).length
  },
  requestCountByNumberplate: state => ({ numberplate }) => {
    return state.requests.filter(request => request.search.numberplate === numberplate).length
  },
  requestCountByCity: state => ({ city }) => {
    return state.requests.filter(request => request.search.city === city.toLowerCase()).length
  },

  /**
   * Status options (kept outside of store)
   */
  statusOptions: () => statusOptions,
  statusOptionById: () => ({ id }) => {
    let option = statusOptions.filter(option => option.id === id)
    return option.length ? option[0] : {}
  },
  activeStatus: (state, getters) => getters.statusOptionById({ id: state.activeStatusId }),
  activeStatusId: state => state.activeStatusId,
  activeStatusName: (state, getters) => getters.activeStatusOption.name,

  reasonOptions: () => reasonOptions,
}
const actions = {
  loadRequests: async ({ state, commit }, { token }) => {

    if (state.loading) return 

    commit('setLoadingState', { loading: true })

    const api = await fetch('/api/list', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`
      }
    })
    if (! api.ok) {
      commit('setLoadingState', { loading: false })
    } else {
      const response = await api.json()

      const requests = response.data.requests.map(request => {
        return new Request({ data: request.data, ref: request.ref['@ref'].id })
      })

      commit('setRequests', {
        requests
      })
      commit('setLoadingState', { loading: false })
    }
  },
}
const mutations = {
  setLoadingState(state, { loading }) {
    state.loading = loading
  },
  setRequests(state, { requests }) {
    state.requests = requests
    const date = new Date(Date.now())
    state.lastRefresh = `${(`0${date.getHours()}`.slice(-2))}:${(`0${date.getMinutes()}`.slice(-2))}:${(`0${date.getSeconds()}`.slice(-2))}`
  },
  
  setActiveStatus(state, { status }) {
    state.activeStatusId = status
  },
  setSearchterm(state, { term }) {
    state.searchterm = term
  },
  setCurrentPage(state, page) {
    state.currentPage = page
  },
  
  setLastOpenedRequest(state, { uuid }) {
    state.lastOpenedRequest = uuid
  },

  /**
   * Update a request in the local store
   */
  updateRequest(state, { ref, data }) {      
    // Get current request entry by ref
    let requestIndex = state.requests.findIndex(request => request.ref === ref)
    if (requestIndex < 0) return 

    // Create a new Request object
    let request = state.requests[requestIndex]
    request = new Request({ 
      ref,
      data: deepmerge(request.raw, data)
    })

    // Out with the old, in with the new
    state.requests.splice(requestIndex, 1, request)
  },

  updateRequestComments(state, { ref, comments }) {
    let index = state.requests.findIndex(record => record.ref === ref)
    if (index < 0) return

    let request = state.requests[index]

    request.comments = comments
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}

