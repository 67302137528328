
import { Document, Packer, Header, Footer, Paragraph, ImageRun, TextRun, TabStopType, TabStopPosition, HeadingLevel, PageBreak, PageNumber, AlignmentType } from "docx"; 

import { getDownloadURL } from '@/services/aws'
import { isObject } from '@/services/validation'

let sizeOf = require('buffer-image-size');


const getFirstDocByType = ({ record, doctype }) => {
  let location = record.getLocation()

  return location.Docs[doctype] && Array.isArray(location.Docs[doctype]) 
    ? location.Docs[doctype][0]
    : null
}

const getImageFromAWS = async ({ Doc, doctype, record, token }) => {
  let img = Doc

  if (!img) {
    img = getFirstDocByType({ record, doctype })
  }

  if (! isObject(img)) {
    img = {
      id: img,
      ext: null
    }
  }

  img.link = await getDownloadURL({
    token, 
    ref: record.ref, 
    uuid: record.uuid,
    casefull: record.case_ref,

    index: img.id,
    doctype,
    ext: img.ext || null
  })

  let file = await fetch(img.link.downloadURL);
  let arrayBuffer = await file.arrayBuffer()
  img.buffer = Buffer.from(arrayBuffer)

  let size = sizeOf(img.buffer)
  let sizeFactor = size.width > 600 ? 600 / size.width : 1

  img.calcSize = {
    width: size.width * sizeFactor,
    height: size.height * sizeFactor
  } 

  return img
}



export const generateDoc = async ({ record, connectedRequests, token }) => {

  let casefull = record.getCaseRef()
  let location = record.getLocation()
  let coordinates = 'onbekend'

  if (location.hasCoordinates()) {
    coordinates = location.getCoordinates()
    coordinates = `Lat ${coordinates.lat} N, Lng ${coordinates.lng} E`
  }

  
  let topviewDoc = await getImageFromAWS({
    doctype: 'TopView',
    record,
    token
  })
  let sideviewDoc = await getImageFromAWS({
    doctype: 'SideView',
    record,
    token
  })


  /**
   * DOC content blocks
   */

  let children = [
    // new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Locatie',
          bold: true,
        })
      ]
    }),
    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Realisatie adres: '
        }),
        new TextRun({
          text: `\t${location.getAddress()}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500 
        },
      ],
    }),
    new Paragraph({
      children: [
        new TextRun("Coordinaten: "),
        new TextRun({
          text: `\t${coordinates}`
        })
      ],
      tabStops: [
        {
          type: TabStopType.LEFT,
          position: 2500
        },
      ],
    }),
    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: "Bovenaanzicht",
          bold: true,
        })
      ],
    }),
    // new Paragraph(''),
    new Paragraph({
      children: [
        new ImageRun({
          data: topviewDoc.buffer,
          transformation: {
            width: topviewDoc.calcSize.width,
            height: topviewDoc.calcSize.height
          }
        })
      ]
    }),
    new Paragraph(''),
    // new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: "Zijaanzicht",
          bold: true
        })
      ],
    }),
    // new Paragraph(''),
    new Paragraph({
      children: [
        new ImageRun({
          data: sideviewDoc.buffer,
          transformation: {
            width: sideviewDoc.calcSize.width,
            height: sideviewDoc.calcSize.height
          }
        }),
        new PageBreak()
      ]
    }),

    /********************************************************************
     * PAGE 2
     */
    new Paragraph({
      children: [
        new TextRun({
          text: "Inrichting",
          bold: true
        })
      ],
    }),
    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Laadsituatie: '
        }),
        new TextRun({
          text: `\t${location.ParkingSpots.Type === 'hub' ? 'laadcluster' : 'laadpaal' }`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),

    new Paragraph({
      children: [
        new TextRun({
          text: 'Aantal laadpalen: '
        }),
        new TextRun({
          text: `\t${location.ParkingSpots.NumberOfChargingStations}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Aantal parkeerplaatsen: '
        }),
        new TextRun({
          text: `\t${location.ParkingSpots.NumberOfParkingSpots}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),

    new Paragraph({
      children: [
        new TextRun({
          text: 'Parkeerplaatsen: '
        }),
        new TextRun({
          text: `\t${location.ParkingSpots.Identifiers.map(item => item.Identifier).join(', ')}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    })
  ]

  /**
   * FINANCIAL UNIT CONDITIONAL
   */
  if (record.tenant === 'park-n-charge') {
    children = children.concat([
      new Paragraph(""),
      new Paragraph({
        children: [
          new TextRun({
            text: 'Financiële unit: '
          }),
          new TextRun({
            text: `\t${location.FinUnit}`
          })
        ],
        tabStops: [
          {
              type: TabStopType.LEFT,
              position: 2500
          },
        ],
      })
    ])
  }

  children = children.concat([
    /**
     * Remarks
     */
    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: "Toelichting",
          bold: true
        })
      ],
    }),
    new Paragraph(''),
    new Paragraph(location.Remarks || ''),

    /**
     * Remarks
     */
     new Paragraph(''),
     new Paragraph({
       children: [
         new TextRun({
           text: "Locatie voorbereiding",
           bold: true
         })
       ],
     }),
     new Paragraph(''),
     new Paragraph({
      children: [
        new TextRun({
          text: 'Netbeheerder: '
        }),
        new TextRun({
          text: `\t${location.GridOperator}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),

    new Paragraph({
      children: [
        new TextRun({
          text: 'Werkzaamheden: '
        }),
        new TextRun({
          text: `\t${location.LocationPreparation.RequiresLocationPreparation.join(', ')}`
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),

    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Overige: '
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),
    new Paragraph(location.LocationPreparation.OtherReason || '-'),

    new Paragraph(''),
    new Paragraph({
      children: [
        new TextRun({
          text: 'Door gemeente uit te voeren: '
        })
      ],
      tabStops: [
        {
            type: TabStopType.LEFT,
            position: 2500
        },
      ],
    }),
    new Paragraph(location.CPOAdvice.AdditionalCostDescription || '-'),
    new Paragraph(''),
  ])


  /**
   * DOC MAIN Spec
   */
  const docSpec = {
    sections: [
      {
        headers: {
          default: new Header({
            children: [
              new Paragraph({
                text:`Startdocument - ${casefull}`,
                heading: HeadingLevel.HEADING_1
              }),
            ],
            tabStops: [
              {
                  type: TabStopType.RIGHT,
                  position: TabStopPosition.MAX,
              },
            ],
          }),
        },
        footers: {
          default: new Footer({
            children: [
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun(`Realisatie adres: ${location.getAddress()}`),
                  new TextRun({
                    children: [' / CPO: ', location.ChargePointOperator || '']
                  })
                ]
              }),
              new Paragraph({
                alignment: AlignmentType.CENTER,
                children: [
                  new TextRun({
                    children: ["Pagina ", PageNumber.CURRENT],
                  }),
                  new TextRun({
                    children: [" / ", PageNumber.TOTAL_PAGES],
                  })
                ]
              })
            ],
          }),
        },

        children
      },
    ],
  }

  if (connectedRequests.length !== 0) {
    docSpec.sections[0].children.push(
      new Paragraph({
        children: [
          new TextRun({
            text: "Verbonden aanvragen",
            bold: true
          })
        ],
      })
    )
    docSpec.sections[0].children.push(
      new Paragraph("")
    )
  }
  
  connectedRequests.forEach(request => {
    docSpec.sections[0].children.push(
      new Paragraph({
        text: `${request.case_ref}: ${request.summary.address}`,
        bullet: {
          level: 0
        }
      })
    )
  })

  const doc = new Document(docSpec);

  return await Packer.toBlob(doc)
}
