<script>

import { mapGetters, mapMutations } from 'vuex'

import { configs } from '../devconfig'


export default {
  name: 'TableState',
  inject: ['tableIdentifier'],
  computed: {
    ...mapGetters('EVtables', [
      'getActiveStoredTableState',
      'activeConfig'
    ]),

    instanceKey() {
      return `${this.tableIdentifier}.${this.activeConfig({ 
        identifier: this.tableIdentifier
      })}`
    },

    tableState() {
      return this.getActiveStoredTableState({
        identifier: this.tableIdentifier
      })
    },

    settings() {
      return this.tableState.settings
    },

    /**
     * Configuration
     */
    config() {
      let uuid = this.activeConfig({ 
        identifier: this.tableIdentifier 
      })
      
      if (! configs[uuid]) {
        throw new Error("Unknown config")
      }

      return configs[uuid]
      
      // return this.accessibleConfigs
      //   .find(config => config.uuid === this.activeConfig({ identifier: this.tableIdentifier })) || false
    },
    accessibleConfigs() {
      return this.tableState.accessibleConfigs
    },

    /**
     * At first pretend there is only 1 config
     */
    layout() {
      return this.config.layouts[this.settings.layout]
    },

    /**
     * Records
     */
    records() {
      return this.tableState.records
    },

    activeFilters() {
      return this.tableState.activeFilters
    },
    activeSorting() {
      return this.tableState.activeSorting
    }
  },
  methods: {
    ...mapMutations('EVtables', [
      'storeTableState'
    ]),

    /**
     * Settings
     */
    setPageSize({ pageSize }) {
      this.tableState.settings.pageSize = pageSize
    },
    setTableLayout({ layout }) {
      this.tableState.settings.layout = layout
    },

    /**
     * Restore state
     */
    restoreState({ state }) {
      this.tableState.settings = state.settings
      this.tableState.activeFilters = state.activeFilters
      this.tableState.activeSorting = state.activeSorting
    },
  }
}
</script>