<template>
  <div></div>
</template>

<script>

import Realisation from '@/models/Realisation'

import dot from 'dot-object'
import { mapGetters, mapMutations } from 'vuex'


import { isObject } from '@/services/validation' // , isNumeric
// import { convertLetterToNumber } from '@/helpers/string'

import Vue from 'vue'

export default {
  name: "BaseRealisationStep",
  data() {
    return {
      uuid: null,
      disabled: false,

      /**
       * A flag that is briefly set when cancelling a change of an already completed step
       *  It's used to reload the components by briefly changing their visibility
       */
      reloading: false,

      currentStep: 1,

      /**
       * Whether the form is active.
       *  It's not when the step has already been completed, and the user didn't explicitly activate the form.
       */
      locked: true,

      /**
       * Whether the step details are being corrected (meaning the progress should remain unaffected upon saving data)
       */
      correction: false,

      /**
       * Whether we're saving before going to the next step
       */
      goingToNextStep: false,
      redirectToAfterSubmit: null,

      /**
       * 
       */
      feedback: null,
      feedbackMessage: '',

      /**
       * Docs = docs belonging to active Location, by doctype
       * RealistionDocs = docs belonging to active Realisation process, by doctype
       */
      Docs: {},
      RealisationDocs: {}

    }
  },
  computed: {
    ...mapGetters('requests', [
      'requestByUuid'
    ]),
    ...mapGetters('realisation', [
      'hasRecord',
      'recordByUuid'
    ]),
    ...mapGetters('relations', [
      'changeCount',
      'requestUuidsByRealisationUuid'
    ]),
    ...mapGetters('user', [
      'hasRole',
      'canAccessRequests'
    ]),
    ...mapGetters('tenant', [
      'getWorkflowUuidByName',
      'isCurrentTenant'
    ]),
    ...mapGetters('tenant', [
      'getWorkflowSpecByName'
    ]),
    steps() {
      return this.getWorkflowSpecByName({ name: 'realisation' })?.steps || []
    },
    stepsByUuid() {
      return this.steps.reduce((result, step) => {
        result[step.uuid] = step
        return result
      }, {})
    },
    routeUuid() {
      return this.$route.params.uuid
    },
    
    /**
     * Create a new record if the requested record cannot be found
     */
    record() {
      let record = this.recordByUuid({ uuid: this.uuid }) || null

      if (record !== null) {
        return record
      }

      // TODO: Check whether we can move this to config? 
      switch(process.env.VUE_APP_TENANT) {
        case 'park-n-charge':
          return new Realisation({ ref: null, data: {
            uuid: this.uuid,
            Locations: [{
              ChargePointOperator: 'PARKnCHARGE'
            }]
          }})
      }

      return new Realisation({ ref: null, data: {
        uuid: this.uuid
      }})
    },
    
    /**
     * The active step of the realisation proces
     */
    step() {
      if (! this.record) return 1

      // Get the step number based on uuid
      let step = this.stepsByUuid[this.record.status.uuid]
      return step ? (step.step || step.short) : "1"
    },

    /**
     * Get the active & current step data from the configuration
     */
    currentStepData() {
      return this.getStepDataByNumber({ number: this.currentStep })
    },

    /**
     * The location details
     */
    values() {
      try {
        return this.record.values.Location
      } catch(e) {
        return {
          Location: {}
        }
      }
    },
    CurrentLocation() {
      return this.values
    },

    /**
     * Location version
     */
    version() {
      try {
        return this.values.version
      } catch(e) {
        return 1
      }
    },

    /**
     * The related requests UUIDS
     */
    requestUuids() {
      return this.requestUuidsByRealisationUuid({ uuid: this.record.uuid })
    },
    
    /**
     * The related request objects
     */
    connectedRequests() {
      return this.requestUuids.slice().map(uuid => {
        return this.requestByUuid({ uuid })
      })
    },

    /**
     * Whether the step can still be changed
     */
    openForChange() {
      return this.record.status.completed === false 
        && this.record.status.cancelled !== true 
        && this.record.status.onhold !== true
    },

    /**
     * Whether the CPO Advice should be / was skipped
     *  This is due to the current location being suggested by the CPO
     */
    Skip() {
      try {
        return this.values.CPOAdvice.Skip
      } catch(e) {
        return false
      }
    },
    completed() {
      try {
        return !! this.record.status.completed
      } catch(e) {
        return false
      }
    },
    onhold() {
      try {
        return !! this.record.status.onhold
      } catch(e) {
        return false
      }
    },
    cancelled() {
      try {
        return !! this.record.status.cancelled
      } catch(e) {
        return false
      }
    },
    cancelledOrOnHold() {
      return this.onhold || this.cancelled
    },
    disableNextBtn() {
      return this.cancelledOrOnHold && ! this.completedUuids.includes(
        this.currentStepData.uuid
      )
    },

    /**
     * Access
     */
    mayEdit() {
      let currentStepConfig = this.currentStepData

      return currentStepConfig.access.edit.some(role => this.hasRole({ role }))

      // return (
      //   (this.isMunicipality && currentStepConfig.access.edit.includes('municipality'))
      //   || (this.isCPO && currentStepConfig.access.edit.includes('cpo') 
      //   || (this.isRVB && currentStepConfig.access.edit.includes('rvb')))
      // )
    },

    /**
     * Record Flow State
     */

    recordState() {
      try {
        return this.record.flow.state
      } catch(e) {
        return []
      }
    },
    completedUuids() {
      return this.recordState
        .filter(state => state.completed_at)
        .map(state => state.uuid)
    },
    availableUuids() {
      return this.recordState
        .filter(state => state.started_at)
        .map(state => state.uuid)
    },
    isCompletedStep() {
      return this.completedUuids.includes(
        this.currentStepData.uuid
      )
    },
    isAvailableStep() {
      return this.availableUuids.includes(
        this.currentStepData.uuid
      )
    }
  },
  watch: {
    routeUuid(newValue) {
      if (newValue !== null) {
        this.uuid = newValue
      }
      this.clearChanges()
    },
    hasRecord() {
      if (! this.hasRecord) {
        this.redirectToStart()
      }
    },
    /**
     * When the record changes we check whether the form should be active
     */
    record() {
      
      this.locked = ! this.mayEdit 
        || this.isCompletedStep 
        || this.completed 
        || this.onhold 
        || this.cancelled

      if (this.hasRecord) {
        this.RealisationDocs = this.record.Docs
      }
    },
    changeCount() {
      if (this.changeCount !== 0) {
        this.storeRelationChanges()
      }
    }
  },
  created() {
    this.uuid = this.routeUuid

    if (! this.hasRecord) {
      // console.log("no record")
      this.redirectToStart()
    } else if (this.isBeyondCurrentStep()) {
      // console.log("Don't think so!", this.step, this.currentStep)
      this.redirectToStep({ step: this.step })
    } else {
      this.setLastOpenedRecord({ uuid: this.uuid })
    }
  },
  beforeDestroy() {
    this.clearChanges()
  },
  methods: {
    ...mapMutations('realisation', [
      'addRecord',
      'replaceRecord',
      'setLastOpenedRecord'
    ]),
    ...mapMutations('relations', [
      'setConnectionChange',
      'finalizeChanges',
      'clearChanges'
    ]),
    ...mapMutations('chargingpoints', [
      'replaceLocation'
    ]),

    getStepDataByNumber({ number }) {
      return this.steps
        .find(step => (step.step || step.short) == number) || null
    },

    redirectToStart() {
      if (this.currentStep === 1) return // Already there...

      this.$router.push({
        name: "Realisation",
        params: {
          uuid: this.uuid
        }
      })
    },
    handleNext() {
      // console.log("Handle Next")

      let stepState = this.record
        .nextAvailableFlowStep({ currentUuid: this.currentStepData.uuid })

      // console.log(stepState, this.stepsByUuid[stepState.uuid].short )

      this.redirectToStep({ step: this.stepsByUuid[stepState.uuid].short })
      

      // TODO: currentStep is no longer a number... 
      // this.redirectToStep({ step: this.currentStep + 1 })
    },
    handlePrevious() {
      // TODO: currentStep is no longer a number... 
      let stepState = this.record
        .previousAvailableFlowStep({ currentUuid: this.currentStepData.uuid })
      this.redirectToStep({ step: this.stepsByUuid[stepState.uuid].short })
      // this.redirectToStep({ step: this.currentStep - 1 })
    },
    redirectToStep({ step }) {
      // console.log("redirect", this.redirectToAfterSubmit)
      this.$router.push({
        name: `Realisation.Step${step}`,
        param: {
          uuid: this.uuid
        }
      })
    },

    /**
     * Submit and go to the next step
     */
    handleSubmitAndNext: async function() {
      // console.log("SUBMIT")

      this.$refs.form.validate()
      if (! this.$refs.form.isValid()) {
        this.handleValidationError()
        return 
      }

      this.goingToNextStep = true
      let success = await this.handleSubmit()
      this.goingToNextStep = false

      if (success) {
        if (this.redirectToAfterSubmit) {
          this.redirectToStep({ step: this.redirectToAfterSubmit })
        } else {
          this.handleNext()
        }
      }
    },

    // Stub
    handleComplete() {},

    /**
     * Handle Validation Error
     */
    handleValidationError() {
      this.feedback = 'danger'
      this.feedbackMessage = "Controleer of alle velden (juist) zijn ingevuld."
    },


    handleFileChanges({ doctype, files }) {
      // this.Docs[doctype] = files
      Vue.set(this.Docs, doctype, files)
    },

    /**
     * Stub
     */
    prepIntention({ step, correction, complete, target }) {
      return { step, correction, complete, target }
    },

    /**
     * Stub
     */
    prepData({ data }) {
      return data
    },

    /**
     * Stub
     */
    isValidData() {
      return true
    },

    prepStatus() { // { data }
      // TODO: Maybe remove this method, now that this status has been replaced with Intention?
      return {}
    },

    /**
     * Handle Submit event
     */
    handleSubmit: async function() {
      try {

        let intention = this.prepIntention({
          step: this.currentStepData.uuid,
          correction: this.correction,
          complete: this.goingToNextStep,
          target: null // TODO: implement?
        })

        // console.log("submit")

        this.feedback = null
        this.feedbackMessage = ''
        this.disabled = true

        // Get & tranform form input
        const form = this.$refs['form']
        const values = form.getValues()
        let data = dot.object(values)

        // console.log(values)
        // console.log(data)

        data = this.prepData({ data })

        // Version
        data.version = this.version

        data.requestUuids = this.requestUuids

        // Get the record reference, or add the uuid in case of a new record
        const ref = this.record.ref || false
        if (ref === false) {
          // TODO: Check whether flow is booting up. Add context to Intention ?
          // if (this.currentStep === 1) {
          data.uuid = this.uuid
          // } else {
          //   throw new Error("Het interne realisatieproces referentie nummer ontbreekt")
          // }
        }

        data.step = this.currentStepData.uuid


        data.status = this.prepStatus({ data })
        data.correction = this.correction

        if ( ! this.isValidData({ ref, data })) {
          // console.log("invalid data")
          // Note: The validation method should set the feedback
          this.disabled = false
          return false
        }

        // set the proper endpoint
        let endpoint = '/api/realisation_step'
        if (! ref) {
          endpoint = '/api/realisation_create'

          // And add the flow uuid if we're creating a record
          // TODO: Note that in the future, the precise flow has to be known before 
          // TODO:  the form can be loaded. That is why the UI provides the uuid.
          // TODO: Maybe provide an init endpoint that returns a shell record?
          // TODO: That is, with the flow state evaluated, but no record saved to FaunaDB. 
          // TODO:  --- UPDATE: flow spec is loaded through the tenant config. Use that.
          data.flowUuid = this.getWorkflowUuidByName({ 
            name: 'realisation' 
          })
        }

        /**
         * This is to 'upgrade' existing realisation processes
         */
        if (! this.record.getFlowUuid()) {
          data.flowUuid = this.getWorkflowUuidByName({ 
            name: 'realisation' 
          })
        }

        // Make the call
        const token = await this.$auth.getTokenSilently();
        const api = await fetch(endpoint, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ref,
            data,
            intention
          })
        })
        if (! api.ok) {
          // console.log(api.status)
          if (api.status === 401) {
            this.feedbackMessage = 'U heeft niet de rechten om deze handeling uit te voeren. Mogelijk is uw sessie verlopen.'
          }
          if (api.status === 400) {
            this.feedbackMessage = 'De invoer is ongeldig verklaard. Neem contact op indien dit zich blijft voordoen.'
          }
          throw new Error(api.error)
        }
        const response = await api.json()
        
        this.finalizeChanges()
        this.clearChanges()

        if (ref) {
          this.replaceRecord({
            record: response.data.record
          })
        } else {
          this.addRecord({
            record: response.data.record
          })

          if (Array.isArray(this.requestUuids) && this.requestUuids.length !== 0) {
            await this.storeRelationChanges()
          }
        }

        if (Array.isArray(response.data.locations)) {
          response.data.locations.forEach(location => {
            this.replaceLocation({ location })
          })
        }

        this.disabled = false
        return true

      } catch(e) {
        // console.log(e, e.message)

        this.disabled = false
        this.feedback = 'danger'
        this.feedbackMessage = this.feedbackMessage || 'De informatie kon niet verwerkt worden door de server.'
        return false
      }
    },
    /**
     * Add / Remove Requests to Realisation
     */
    addRequestsToRealisation() {
      if (this.record && this.requestAdditions) {
        this.requestAdditions.forEach(uuid => {
          this.setConnectionChange({
            type: 'add',
            requestUuid: uuid,
            realisationUuid: this.record.uuid
          })
        })
      }
    },
    handleBreakConnection({ uuid }) {
      this.setConnectionChange({
        type: 'remove',
        requestUuid: uuid,
        realisationUuid: this.record.uuid
      })
    },

    storeRelationChanges: async function() {
      try {
        const ref = this.record.ref || false

        // Don't try to make a call that is going to fail
        // TODO: Show an error? Or not, because the call is intended to be ignored (e.g. RVB)?
        if (! this.canAccessRequests) return

        this.feedback = null
        this.feedbackMessage = ''

        if (! ref) return

        let data = {
          step: 'relations',
          requestUuids: this.requestUuids
        }

        // Make the call
        const token = await this.$auth.getTokenSilently();
        const api = await fetch('/api/realisation_relations', {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ref,
            data
          })
        })
        if (!api.ok) {
          // console.log(api.status)
          if (api.status === 401) {
            this.feedbackMessage = 'U heeft niet de rechten om deze handeling uit te voeren. Mogelijk is uw sessie verlopen.'
          }
          if (api.status === 400) {
            this.feedbackMessage = 'De invoer is ongeldig verklaard. Neem contact op indien dit zich blijft voordoen.'
          }
          // console.log(api)
          throw new Error('Unable to store connection')
        }
        const response = await api.json()

        this.finalizeChanges()
        this.clearChanges()

        if (ref) {
          this.replaceRecord({
            record: response.data.record
          })
        } else {
          this.addRecord({
            record: response.data.record
          })
        }

      } catch(e) {
        // console.log(e)
        this.feedback = 'danger'
        this.feedbackMessage = this.feedbackMessage || 'De aanvraag kon niet verbonden worden. Probeer het nog een keer.'
        this.clearChanges()
      }
    },
    handleStartChange() {
      this.locked = false
    },
    handleCancelChange() {
      this.reloading = true

      this.$nextTick(() => {
        this.reloading = false
        this.locked = true
        this.correction = false
      })
    },
    handleStartCorrection() {
      this.handleStartChange()
      this.correction = true
    },

    /**
     * Docs
     */
    minIdByDocType({ doctype }) {

      let docs = this.RealisationDocs[doctype] || null
      if (! Array.isArray(docs)) return 0

      return docs.reduce(( max, doc ) => {
        if (isObject(doc) && doc.id) {
          return Math.max(max, doc.id)
        } else {
          return Math.max(max, doc)
        }
      }, 0)
    },
    prepDocData({ doctype }) {
      let docs = this.Docs[doctype] || []

      return docs.filter(file => file.progress === 100).map(file => {
        return {
          id: file.id,
          ext: file.ext
        }
      })
    },

    // TODO: This is a simplification !
    // TODO: Limit: parent steps / sub-steps order
    // TODO: Should use Record State... 
    isBeyondCurrentStep() {

      // console.log("isBeyond", this.currentStepData, this.availableUuids)


      let uuid = this.currentStepData.uuid

      return ! this.availableUuids.includes(uuid)


      // // Ensure our base input is a string
      // let step = this.step + ''
      // let currentStep = this.currentStep + ''
      
      // // Split in segments, divided by -
      // let stepSegments = step.split('-').map(segment => {
      //   if (isNumeric(segment)) {
      //     return parseInt(segment, 10)
      //   }
      //   return convertLetterToNumber(segment)
      // })

      // let currentSegments = currentStep.split('-').map(segment => {
      //   if (isNumeric(segment)) {
      //     return parseInt(segment, 10)
      //   }
      //   return convertLetterToNumber(segment)
      // })

      // if (stepSegments[0] === currentSegments[0]) {
      //   return stepSegments[1] < currentSegments[1]
      // }

      // return stepSegments[0] < currentSegments[0]

      // return this.step < this.currentStep
    }
  }
};
</script>

<style lang="scss">
.RealisationDetail {
  text-align: left;
  padding: 18px 0 30px;

  &__map {
    position: relative;
    max-width: 100%;
    height: 100%;
  }

  &__aside {
    position: sticky !important;
    top: 0;
  }
  &__RequestList {
    list-style: none;
    margin: 0;
    padding: 0;

    .b-icon {
      cursor: pointer;

      &:hover {
        color: #ec0000;
      }
    }
  }
}
</style>