<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="completed"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button v-else variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleComplete">
            Afronden
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" />

      <div class="DetailGrid__col1">
        <!-- <h3>Opleveringsinformatie</h3>
        <FinalDelivery :values="values" :disabled="locked || disabled" /> -->
        <h3>Laadpaal ID's</h3>
        <ChargerIds :values="chargerIds" :disabled="locked || disabled" />
      </div>
      <div class="DetailGrid__col2">
        <h3>SAT documentatie</h3>
        <FileUpload 
          class="mt-3"
          label=""
          doctype="DeliveryDocumentation"  
          :uuid="uuid"
          :files="values && values.Docs ? (values.Docs.DeliveryDocumentation || null) : null"
          :minFileId="minDeliveryDocumentationDocsId"
          :reference="record.ref || null"
          :casefull="record.case_ref || null"
          :locked="locked"
          :disabled="disabled"
          @files="handleFileChanges" />
        <p v-if="locked && (values && values.Docs ? (values.Docs.DeliveryDocumentation || []) : []).length === 0">
          Er is geen opleveringsdocumentatie beschikbaar
        </p>
      </div>

      <div class="DetailGrid__col2">
        <!-- <h3>Maillijst</h3>
        <p>Status: Todo</p>
        <b-button>
          Stuur email
        </b-button> -->
      </div>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="completed"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button v-else variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleComplete">
            Afronden
          </b-button>
        </template>
        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

// import FinalDelivery from '@/components/definitions/FinalDelivery'
import ChargerIds from '@/components/definitions/ChargerIds'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'


export default {
  name: 'RealisationStep9',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, FileUpload, Comments,
    // FinalDelivery, 
    ChargerIds
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 9
    }
  },
  computed: {
    minDeliveryDocumentationDocsId() {
      try {
        return this.record.values.Docs.DeliveryDocumentation.reduce((result, id) => Math.max(result,id), 0)
      } catch(e) {
        return 0
      }
    },
    // Apply data mapping
    chargerIds() {
      try {
        return {
          ChargerIds: {
            ChargerIds: this.values.ChargerIds.ChargerIds.map(identifier => {
              return {
                identifier
              }
            })
          }
        }
      } catch(e) {
        return this.values
      }
    }
  },
  created() {
    // console.log("step 9")
    // console.log(this.record)
    // console.log(this.values)
  },
  methods: {
    handleComplete: async function() {
      this.$refs.form.validate()
      if (! this.$refs.form.isValid()) {
        this.handleValidationError()
        return 
      }

      this.goingToNextStep = true
      let success = await this.handleSubmit()
      this.goingToNextStep = false

      if (success) {
        this.locked = true
      }
    },
    prepData({ data }) {

      data.Docs = {
        DeliveryDocumentation: (this.Docs.DeliveryDocumentation || []).filter(file => file.progress === 100).map(file => file.id)
      }
      if (data.ChargerIds) {
        data.ChargerIds = data.ChargerIds.map(entry => entry.Identifier).filter(entry => ![null, undefined].includes(entry))
      } else {
        data.ChargerIds = []
      }

      return data
    },
    prepStatus() { // { data }
      let status = {
        uuid: this.currentStepData.uuid
      }

      // Going to the next step?
      if (this.goingToNextStep && ! this.locked) {

        status = {
          uuid: this.currentStepData.uuid,
          label: "Afgerond",
          completed: true
        }
      }
      return status
    },
    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (
            // data.FinalDelivery.LocationReadyDate === '' ||
            // data.FinalDelivery.ChargerReadyDate === '' ||
            // data.FinalDelivery.BatchCode === '' || 
            data.ChargerIds.length < 1
          ) {
            throw new Error("")
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Afronden is niet mogelijk zonder minimaal 1 laadpaal ID in te vullen.'
          return false
        }
      }
      return true
    }
  },
}
</script>