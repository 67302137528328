<template>
  <div>
    <b-alert :show="noMatch" variant="danger">
      Het opgegeven adres leverde geen locatie op binnen de postcode <strong>{{ postalcode }}</strong>. Controleer of het adres juist is ingevoerd.
    </b-alert>
    <b-alert :show="! noMatch && ! hasCoordinates" variant="info">
      De coordinaten van de locatie worden momenteel opgehaald.
    </b-alert>

    <b-aspect :aspect="aspect">
      <MapBox 
        :accessToken="accessToken" 
        :mapStyle.sync="mapStyle"
        :options="mapOptions"
        @load="onMapLoaded">
        
        <LayerRealisationProcesses 
          v-if="! isGORAL" 
          @click="handleOpenRealisationPopup" />
        <LayerRequests :uuid="uuid" />

        <LayerChargingpoints />

        <PopupRealisationProcess
          v-if="! isGORAL"
          :uuid="realisationUuid" 
          @close="handleCloseRealisationProcessPopup" />

        <PopupChargingLocation />

      </MapBox>
    </b-aspect>

    <LegendRequest />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder'
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

import MapBox from '@/components/common/MapBox.vue'
import LayerChargingpoints from '@/components/map/LayerChargingpoints.vue'
import LayerRequests from '@/components/map/LayerRequests.vue'
import LayerRealisationProcesses from '@/components/map/LayerRealisationProcesses.vue'
import PopupRealisationProcess from '@/components/map/PopupRealisationProcess.vue'
import PopupChargingLocation from '@/components/map/PopupChargingLocation.vue'

import { formatPostalCode } from '@/helpers/string'

import LegendRequest from '@/components/map/LegendRequest.vue'

import { IconButtonControl } from '@/services/mapbox'

import { monitoringConfig } from '@/config'

/**
 * Shows 
 *  - the active Request location,
 *  (- the other Requests)
 *  - the existing chargingpoints
 */
export default {
  name: 'MapRequest',
  components: {
    MapBox, LayerChargingpoints, LayerRequests, LayerRealisationProcesses,
    PopupRealisationProcess, PopupChargingLocation,
    LegendRequest
  },
  props: {
    /**
     * The Request Uuid
     */
    uuid: {
      type: String,
      required: true
    },
    aspect: {
      type: String,
      default: '16:10'
    }
  },
  data() {
    return {
      /**
       * MapBox instance
       */
      map: null,

      /**
       * MapBox SDK reference
       */
      mapbox: null,

      /**
       * MapBox is ready
       */
      loaded: false,

      /**
       * MapBox config
       */
      accessToken: process.env.VUE_APP_MAPBOX_TOKEN,
      mapStyle: process.env.VUE_APP_MAPBOX_STYLE,

      /**
       * A default Mapbox Marker on the Request address position
       */ 
      requestMarker: null,

      /**
       * MapBox geocoder field
       */
      geocoder: null,

      /**
       * The uuid of the clicked realisation process
       */
      realisationUuid: null,

      /**
       * Reference to icon btn control
       */
      iconBtnControl: null,

      /**
       * Whether the tenant has purchased the monitoring feature
       */
      hasMonitoring: !! monitoringConfig.enabled
    }
  },
  computed: {
    ...mapGetters('requests', ['requestByUuid']),
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    request() {
      return this.requestByUuid({ uuid: this.uuid })
    },
    postalcode() {
      return this.request && this.request.address && this.request.address.postalCode 
        ? formatPostalCode(this.request.address.postalCode)
        : '-'
    },
    /**
     * Whether the loaded request is lacking coordinates
     */
    hasCoordinates() {
      return this.request && this.request.coordinates && this.request.coordinates.lat
    },
    noMatch() {
      // console.log(this.request)
      return this.request && this.request.coordinates && this.request.coordinates.nomatch
    },
    mapOptions() {
      let center = this.hasCoordinates 
        ? [this.request.coordinates.lng, this.request.coordinates.lat]
        : [4.9041, 52.3676] 

      return {
        center,
        zoom: this.hasCoordinates ? 14 : 10
      }
    },
    isGORAL() {
      return this.isCurrentTenant({ tenant: 'go-ral' })
    }
  },
  beforeDestroy() {
    if (this.requestMarker) {
      this.requestMarker.remove()
    }
  },
  methods: {
    onMapLoaded({ map, mapbox }) {
      this.loaded = true
      this.map = map
      this.mapbox = mapbox

      // Add the GeoCoder plugin
      // This geocoder is only for navigation purposes
      // License restrictions prevent other usage
      this.geocoder = new MapboxGeocoder({
        accessToken: this.accessToken,
        mapboxgl: this.mapbox,

        countries: 'nl',
        types: 'address',
        language: 'nl',

        collapsed: true,
        placeholder: 'Zoek op adres',
        marker: false,
        
        // Amsterdam
        // bbox: [
        //   4.728758231000029,
        //   52.27817457500004,
        //   5.068595450000032,
        //   52.43106396500008
        // ]
      })
      this.map.addControl(
        this.geocoder
      )

      if (this.hasMonitoring) {
        this.iconBtnControl = new IconButtonControl({
          callback: this.handleIconBtn
        })
        this.map.addControl(
          this.iconBtnControl
        )
      }
      
      if (this.hasCoordinates) {
        this.addMarker()
      }
    },
    addMarker() {
      if (this.requestMarker) return

      // // Add the Request as a regular MapBox Marker
      // this.requestMarker = new this.mapbox.Marker({
      //   'color': '#dc3545'
      // })
      //   .setLngLat(this.mapOptions.center)
      //   .addTo(this.map)
    },
    handleOpenRealisationPopup({ uuid }) {
      this.realisationUuid = uuid
    },
    handleCloseRealisationProcessPopup() {
      this.realisationUuid = null
    },

    handleIconBtn() {
      // console.log("icon btn")
      this.$emit('open')
    }
  }
}
</script>

<style lang="scss">
.Legend__title {
  display: block;
  padding-top: 1.5rem;
}
.Legend__list {
  list-style-type: none; 
  padding: 0.5rem 0 0; 
  margin: 0;

  li {
    margin-top: 0.5rem;

    div {
      display: inline-block; 
      margin-left: 0.5rem;
    }

  }
}

.IconBtn {
  position: relative;
  box-shadow: 0 0 10px 2px rgba(0,0,0,.1);
  background: white;
  width: 36px;
  height: 36px;
  border-radius: 4px;
  color: #757575;

  > svg {
    position: absolute;
    top: 8px;
    left: 8px;
  }

  &:hover {
    color: black;
  }
}

</style>