<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />

      <template v-if="! reloading">
        <div class="DetailGrid__map">
          <PlannedRealisation :values="values" :disabled="locked || disabled" />
        </div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import PlannedRealisation from '@/components/definitions/PlannedRealisation'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

export default {
  name: 'RealisationStep6',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, Comments,
    PlannedRealisation
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "6"
    }
  },
  created() {
    // console.log("step 6")
    // console.log(this.record)
    // console.log(this.values)
  },
  methods: {
    prepData({ data }) {

      // Check for Null and other falsy values. Default to ""
      if (! data.PlannedRealisation.PlannedDate) {
        data.PlannedRealisation.PlannedDate = ''
      }
      if (! data.PlannedRealisation.Contractor) {
        data.PlannedRealisation.Contractor = ''
      }
      if (! data.PlannedRealisation.Permit) {
        data.PlannedRealisation.Permit = ''
      }
      if (! data.PlannedRealisation.ChargePointOrderDate) {
        data.PlannedRealisation.ChargePointOrderDate = ''
      }
      if (! data.PlannedRealisation.GridOperatorContract) {
        data.PlannedRealisation.GridOperatorContract = ''
      }

      return data
    },
    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (
            data.PlannedRealisation.PlannedDate === ''
          ) {
            throw new Error("Het proces kan niet verder zonder een realisatiedatum.")
          }
          if (
            data.PlannedRealisation.Contractor === ''
          ) {
            throw new Error("Het proces kan niet verder zonder aannemer.")
          }
          if (
            data.PlannedRealisation.Permit === ''
          ) {
            throw new Error("Het proces kan niet verder zonder vergunning informatie.")
          }
          if (
            data.PlannedRealisation.ChargePointOrderDate === ''
          ) {
            throw new Error("Het proces kan niet verder zonder besteldatum van de laadpaal.")
          }

        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = e.message
          return false
        }
      }
      return true
    }
  },
}
</script>