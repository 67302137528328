<template>
  <div>
    BaseSingleValueFilter
  </div>
</template>

<script>

import TableState from './TableState.vue'

export default {
  name: 'BaseFilter',
  mixins: [ TableState ],
  props: {
    filterConfig: {
      type: Object,
      required: true
    }
  },
  created() {
    if (this.name === 'BaseFilter') {
      throw new Error("BaseFilter is not meant to be used directly")
    }
  }
}
</script>