
import DEFConfig from '@/config/defaults'
import AMSConfig from '@/config/amsterdam'
import PNCConfig from '@/config/park-n-charge'
import GORConfig from '@/config/go-ral'
import OPCConfig from '@/config/opcharge'
import RVBConfig from '@/config/rvb'


/******************************************************************************
 * Select and export the Config of the active tenant
 */
let ConfigByTenant = {
  'amsterdam': AMSConfig,
  'opcharge': OPCConfig,
  'go-ral': GORConfig,
  'park-n-charge': PNCConfig,
  'rvb': RVBConfig
}

export const Config = Object.assign(DEFConfig, (ConfigByTenant[process.env.VUE_APP_TENANT] || {}))

// console.log(Config)

/******************************************************************************
 * Export config components
 */
export const tenantName = Config.tenantName
export const reasonOptions = Config.reasonOptions
export const navigationConfig = Config.access
export const monitoringConfig = Config.monitoring
export const searchConfig = Config.search
export const onHoldReasons = Config.onHoldReasons
export const cancelReasons = Config.cancelReasons
export const municipalityOptions = Config.municipalityOptions
export const steps = Config.steps
export const stepComponents = Config.stepComponents || {}
export const dataLayers = Config.dataLayers || {}
export const realisationProcessStatusTranslations = Config.realisationProcessStatusTranslations || {}

// Genrally uses default menu
export const AdminMenu = Config.AdminMenu || []
export const ReportingMenu = Config.ReportingMenu || []
export const ManualsMenu = Config.ManualsMenu || []


/******************************************************************************
 * Helper methods
 */
export const roleToRoleLabel = (role) => {
  switch(role) {
    case 'cpo':
      return 'CPO'
    case 'municipality':
      return 'Gemeente'
    case 'admin':
      return 'Beheerder'
    case 'caseworker':
    case 'rvb':
      return 'Medewerker'
    case 'customer': 
      return 'Klant'
    case 'guest':
    case 'caseviewer':
      return 'Gast'
  }
  return ''
}


export const stepNumbersByEditor = steps.reduce((result, step) => {
  step.access.edit.forEach(editor => {
    result[editor] = result[editor] || []
    result[editor].push(step.step || step.short)
  })

  return result
}, {})


export const stepsByUuid = steps.reduce((result, step) => {
  result[step.uuid] = step
  return result
}, {})



/******************************************************************************
 * RVB CONFIG
 */

export const customers = [
  'Belastingdienst',
  'Belastingdienst/EZ',
  'BZK',
  'BZK/FMH',
  'BZK/IKPD',
  'BZK/J&V',
  'CJIB min J&V',
  'CVTE',
  'Defensie',
  'DJI',
  'Europol',
  'EZK',
  'IND',
  'J&V/OCW',
  'KNMI',
  'MinFin',
  'NVWA',
  'OM',
  'Raad van State',
  'Rijkskantoor',
  'RvdK',
  'Rvdr',
  'RWS',
  'Staatsbosbeheer/Rijksdienst Cultureel Erfgoed'
]
export const AssetManagers = [
  'Abdelkarim el Massoudi',
  'Alle de Vries',
  'Annet Baar',
  'Arnoud Onderstal',
  'Carmen Haak',
  'Colette Bosveld',
  'Cristian Niţă',
  'Dennis Blom',
  // 'E.A. Brands',
  'Irma van Ruiten',
  'Jacco van Leeuwen',
  'Jan Otto Claus',
  'Jelle Reinalda',
  'Jeroen Wijers',
  'Joop Hoppers',
  'Joris Spits',
  'Julia Becker',
  'Juliette Eldin',
  'Kim Bezem',
  'Lilian Rahimbaks-Mangroelal',
  'Linda Kneepkens',
  'Lisanne van Heeswijk',
  'Mark Verheijen',
  "Martijn de Haan",
  'Michael Russeler',
  'Michael Bakker',
  'Mohamed Jabouri',
  'Monique Hielkema',
  'Niels Megens',
  'Norbert Izaks',
  'Peter Floor',
  'Peter van Dord',
  'Peter van Riet',
  'Peter van Zon',
  'Ramzy Feyzi',
  'René Buitenhek',
  'René Ligtvoet',
  'Rob Ammerlaan',
  'Sjoukje Bouma',
  'Twan Dijkhuizen',
  // 'V.A. van Beek',
  'Youri Zwanink',
  'Willem van der Veen'
]
export const  ObjectManagers = [
  'A.G.J. van den Berg',
  'Martijn Mulder',
  'Arnold Guijken',
  'Bart de Korte',
  'Bas Mulder',
  'Bas van der Kaa',
  'Bert Vermeulen',
  'Bjorn Hendriks',
  'Carmen Storm',
  'Cindy Gulikers',
  'Claudine Calkoen',
  'Coen Wijen',
  'Coos Stoof',
  'Dirk Franken',
  'Edwin Hagedoorn',
  'Edwin Koree',
  'Eric Weijers',
  'Ewout van Lente',
  'Frank Nijhuis',
  'Frans van As',
  'Gerco van Alfen',
  'Guido Min',
  'Guido van der Rijst',
  'Han Broens',
  'Hans van Boggelen',
  'Herman Wielaert',
  'Huub Bercx',
  'Jennifer Daly',
  'Jeroen Dieben',
  'Jeroen Verheij',
  'Jo-Ann Hoekstra',
  'Jorrit Hoekstra',
  'Leon Brundel',
  'Linda van Kasteren',
  'Madelon van Leusden',
  'Marc Sambeek',
  'Nanja Vlasveld',
  'Nico Laumen',
  'Niek Zandee',
  'Paul Blok',
  'Paul Verkoelen',
  'Peter Darwinkel',
  'Peter Malais',
  'Quirine Cambier van Nooten',
  'Ralf van Rossum',
  'Raoul Hupsel',
  'Raymon Meij',
  'Richard Bisscheroux',
  'Rintje Mulder',
  'Rob Gebuis',
  'Rob Montanus',
  'Rob Vlerken',
  'Roel Kuijer',
  'Ronald Bongers',
  'Ruud van Doorne',
  'Sebastiaan Verschoor',
  'Sven Rodenburg',
  'Vidjai Poeran',
  'Vincent Loeffen',
  'Willemien Kimmel',
  'William Gesthuizen',
  'Wim Beije',
  'Wim Bovy',
]
export const ObjectVisions = [
  'Afstoot verwacht',
  'Afstoot zeker',
  'Flexibel - kort',
  'Flexibel - lang',
  'Niet strategisch',
  'Strategisch',
]
export const ObjectTypes = [
  "Rijkskantoor", 
  "Bijzondere specialty", 
  "Specialty", 
  "Defensie"
]
export const PropertyRights = [
  'Kantoren - Eigendom',
  'Kantoren - Huur',
  'Defensie (geen eigendom)'
]
export const Provinces = [
  'Drenthe',
  'Flevoland',
  'Friesland',
  'Gelderland',
  'Groningen',
  'Limburg',
  'Noord-Brabant',
  'Noord-Holland',
  'Overijssel',
  'Utrecht',
  'Zeeland',
  'Zuid-Holland'
]

export const Regions = [
  'Noord',
  'Noord-West',
  'Oost',
  'Zuid',
  'Zuid-West'
]

export const RPL = [
  { name: 'Aldert Klugkist', id: 'auth0|60112d465238fb006960d979' },
  { name: 'Annemarie Eriks', id: 'auth0|62bea828c1f5b79cdb36897c' },
  { name: 'Claire Hoffman', id: 'auth0|602e24e2a38832007006f30a' },
  { name: 'Esther Beijlsmit', id: 'auth0|602e076ed20a6f006ffd531f' },
  { name: 'Esther Rinsampessij', id: 'auth0|602e074be199770069d2819c' },
  { name: 'Job Verhoef', id: 'auth0|65dc4c300f0e842a400064be' },
  { name: 'Johan Nederveen', id: 'auth0|62bea77f33ef82d54328fbd9' },
  { name: 'Koen Fehrmann', id: 'auth0|60b5ceccea19400033f5098a' },
  { name: 'Kevin Buijing', id: 'auth0|63bed0a7ceb10aafc7bf3c34' }, 
  { name: 'Mark Sodaar', id: 'auth0|62f617c14e9a75601b24ec74' },
  { name: 'Martijn Kuijs', id: 'auth0|602e0717d20a6f006ffd5191' },
  { name: 'Paul Veninga', id: 'auth0|6537c1ac12d0da17fa6ebcf8' },
  { name: 'Simon Datema', id: 'auth0|602e06ff19a8a10071819e1d' },
  { name: 'Stefan Lathouwers', id: 'auth0|602e07341819a10070dca6d6' },
  { name: 'Walter Bruisten', id: 'auth0|602e069281ae2c006ae28695' },
]


