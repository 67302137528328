<script>
import { mapGetters } from 'vuex'

import SingleSelectFilter from './SingleSelectFilter.vue'

import { removeAllActiveFiltersByCategory } from '../utils.js'

export default {
  name: 'TagSelectFilter', 
  mixins: [ SingleSelectFilter ],
  computed: {
    ...mapGetters('tenant', [
      'getTags',
      'getLabelsByTagUuid'
    ]),
    /**
     * Option list bound by template in SingleSelectFilter
     */
    options() {
      return this.availableOptions.map(uuid => {
        return {
          value: uuid,
          text: this.getLabelsByTagUuid[uuid] || 'Onbekend'
        }
      })
    },

    /**
     * Tags that are selectable
     */
    enabledTagUuids() {
      return this.getTags.map(tag => tag.uuid)
    },

    /**
     * Connect selected tags to active filters
     */
    selectedTags() {
      return this.activeFilters
        .filter(filter => filter.category === this.filterConfig.id)
        .map(filter => filter.value)
    },

    /**
     * Tags that enabled and unselected
     */
    availableOptions() {
      return this.enabledTagUuids
        .filter(opt => this.selectedTags.indexOf(opt) === -1)
    }
  },
  methods: {
    setup() {
      // ignore setup from mixin
    },
    /**
     * Go over the active filters
     *  Remove all filters from this category in place
     *  We cannot assign a new array to activeFilters
     */
    resetFilter() {
      removeAllActiveFiltersByCategory({
        activeFilters: this.activeFilters,
        category: this.filterConfig.id
      })
      this.value = ''
    },
    
    /**
     * Handle filter input
     */
    handleInput(value) {
      // Ignore empty values
      if (! value) return 

      // Don't add a tag that is already an active filter
      if (! this.activeFilters.find(
        filter => filter.category === this.filterConfig.id && filter.value === value
      )) {
        this.activeFilters.push({
          category: this.filterConfig.id,
          categoryLabel: this.filterConfig.label,
          label: this.getLabelsByTagUuid[value] || 'Onbekend',
          value: value
        })  
      }

      // de-select the value, as the computed removes the option from the list
      this.value = ''
    },
  }
}
</script>