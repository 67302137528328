<template>
  <b-tab v-if="isRealisationRecord && showTagsTab">

    <template #title>
      <b-icon class="mr-2" icon="tag-fill"></b-icon>
      Tags
    </template>

    <Tags class="mt-3" :compact="true" :record="record" />

    <p>
      Bij het maken van een verbinding tussen een aanvraag en een realisatie proces worden de tags vanuit de aanvraag automatisch gekopieerd naar het realisatie proces.
    </p>
  </b-tab>
</template>

<script>
import { mapGetters } from 'vuex'

import Tags from '@/components/Tags.vue'

import Realisation from '@/models/Realisation'

export default {
  components: {
    Tags,
  },
  props: {
    record: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'getEnabledTags'
    ]),
    isRealisationRecord() {
      return this.record instanceof Realisation
    },
    showTagsTab() {
      return this.getEnabledTags.length || (this.record && this.record.hasTags())
    }
  }
}
</script>

<style>

</style>