<template>
  <MapBoxPopup 
    :coordinates="coordinates" 
    :show="show"
    :offset="[0, -30]" 
    @close="handleClose">
    <div>
      <div v-for="request in visibleRecords" class="d-flex flex-column mb-3" :key="request ? request.uuid : 'none'">
        {{ request.case_ref }} <br/> 
        {{ request.address }} <br />

        <router-link v-if="request.showViewLink" :to="{ name: 'RequestDetail', params: { uuid: request.uuid }}">
          Bekijk de aanvraag details
        </router-link>

        <template v-if="request.showConnectionControls">
          <br/> 
          <a v-if="request.isConnectedToRecord" @click="handleBreakConnection({ uuid: request.uuid }, $event)" href="">
            Verbreek de connectie
          </a>
          <a v-else @click="handleMakeConnection({ uuid: request.uuid }, $event)" href="">
            Verbind aan realisatie
          </a>
        </template>

        <template v-if="request.showProcessLink && request.connectedUuid">
          <br/> 
          <router-link :to="{ name: 'Realisation', params: { uuid: request.connectedUuid }}">
            Bekijk de realisatie details
          </router-link>
        </template>
      </div>

      <b-pagination 
        v-if="requests.length > 1"
        v-model="currentPage"
        :total-rows="requests.length"
        :per-page="1"
        class="justify-content-center m-auto pagination-sm"
        hide-goto-end-buttons />
    </div>
  </MapBoxPopup>
</template>

<script>
import MapBoxMixin from '@/components/common/MapBoxMixin.vue'
import MapBoxPopup from '@/components/common/MapBoxPopup.vue'
import { mapGetters, mapMutations } from 'vuex'

export default {
  components: {
    MapBoxPopup
  },
  mixins: [ MapBoxMixin ],
  props: {
    record: {
      type: Object,
      rquired: true
    }
  },
  data() {
    return {
      layerName: 'requests',
      show: false,
      uuids: [],
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters('requests', [
      'requestByUuid'
    ]),
    ...mapGetters('relations', [
      'realisationUuidByRequestUuid'
    ]),
    ...mapGetters('user', [
      'canAccessRealisationProcesses',
      'canAccessRequests'
    ]),
    requests() {
      return this.uuids
        .map(uuid => this.requestByUuid({ uuid }))
        .map(request => {
          let data = {
            uuid: request.uuid,
            isAccepted: request.status === 3,
            connectedUuid: this.realisationUuidByRequestUuid({ uuid: request.uuid }),
            address: request.summary.address,
            case_ref: request.case_ref,
            coordinates: request.coordinates
          }

          data.isConnected = !! data.connectedUuid
          data.isConnectedToRecord = data.connectedUuid === this.record.uuid

          /**
           * Determine based on user rights, application state,
           * request context & process context which UI segments should be visible
           */
          data.showConnectionControls = this.canAccessRequests 
            && ! request.limited 
            && this.record.ref 
            && ! this.isProcessCompleted 
            && data.isAccepted 
            && (! data.isConnected || data.isConnectedToRecord)
            
          data.showViewLink = this.canAccessRequests 
            && ! request.limited

          data.showProcessLink = this.canAccessRealisationProcesses 
            && data.isAccepted 
            && (data.isConnected && !data.isConnectedToRecord)

          return data
        })
    },
    visibleRecords() {
      return [ this.requests[this.currentPage - 1] || false ]
    },
    coordinates() {
      return this.requests.length !== 0
        ? [this.requests[0].coordinates.lng, this.requests[0].coordinates.lat] 
        : [0,0]
    },
    isProcessCompleted() {
      return !! this.record.status.completed
    }
  },
  watch: {
    /**
     * This shows the popup as soon as the requests are mapped
     */
    requests() {
      this.show = this.requests.length !== 0
    }
  },
  created() {
    this.bind()
  },
  beforeDestroy() {
    this.unbind()
  },
  methods: {
    ...mapMutations('relations', [
      'addConnection',
      'setConnectionChange'
    ]),
    bind() {
      if (! this.map) return 

      // click event
      this.map.on('click', this.layerName, this.handleLayerClickEvent)
      this.map.on('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      // Cursor
      this.map.on('mouseenter', this.layerName, this.showPointer)
      this.map.on('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.on('mouseleave', this.layerName, this.hidePointer)
      this.map.on('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },
    unbind() {
      if (! this.map) return

      this.map.off('click', this.layerName, this.handleLayerClickEvent)
      this.map.off('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      this.map.off('mouseenter', this.layerName, this.showPointer)
      this.map.off('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.off('mouseleave', this.layerName, this.hidePointer)
      this.map.off('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Handle click on request icon or txt
     */
    handleLayerClickEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      let features = e.features
      
      this.$nextTick(function(){
        this.currentPage = 1
        this.uuids = features
          .filter(feature => feature?.properties.uuid)
          .map(feature => feature.properties.uuid)

        if (this.uuids.length) {
          this.show = true
        }
      })
    },

    /**
     * Popup handlers
     */
    handleClose() {
      this.show = false
    },

    handleMakeConnection({ uuid }, e) {
      e.preventDefault()
      this.setConnectionChange({
        type: 'add',
        requestUuid: uuid,
        realisationUuid: this.record.uuid
      })
    },
    handleBreakConnection({ uuid }, e) {
      e.preventDefault()
      this.setConnectionChange({
        type: 'remove',
        requestUuid: uuid,
        realisationUuid: this.record.uuid
      })
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 30px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>