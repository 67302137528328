<template>
  <BlockRepeater 
    class="TypeOfParkingSpace" 
    title=""
    label="Voeg een parkeerplaats toe"
    :path="path" 
    :values="values"
    :inline="true"
    :disabled="disabled"
    :viewer="viewer"
    v-slot="slotProps">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
      :viewer="viewer"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'TypeOfParkingSpace',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'TypeOfParkingSpace'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "ParkingLocation": {
            "title": "Locatie parkeerplaats",
            "type": "string",
            "enumNames": [
                "Binnen",
                "Buiten"
            ],
            "enum": [
                "Inside",
                "Outside"
            ]
        },
        "ParkingAccessibility": {
            "title": "Toegankelijkheid parkeerplaats",
            "type": "string",
            "enumNames": [
                "Openbaar",
                "Achter een slagboom",
                "Op beveiligd terrein"
            ],
            "enum": [
                "Public",
                "Behind a gate",
                "In a secured zone"
            ]
        }
      },
      layout: [{
        "columns": '330px 330px 1fr',
        "fields": [ 'ParkingLocation', 'ParkingAccessibility' ]
      }]
    }
  }
}
</script>