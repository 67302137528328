<template>
  <BlockRepeater 
    class="PaidInvoice" 
    title=""
    label="Voeg een betaling toe"
    :path="path" 
    :values="values"
    :disabled="disabled" 
    :deleted="evaluateDeletedState"
    v-slot="slotProps">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
      :viewer="viewer"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'PaidInvoice',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Party'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PartyName": {
          "type": "string",
          "title": "Leverancier",
          "readonly": true
        },
        "WorkDescription": {
          "type": "string",
          "title": "Beschrijving van de werkzaamheden",
          "readonly": true
        },
        "PaymentCompletedDate": {
          "type": "string",
          "format": "date",
          "title": "Datum dat de betaling is gedaan"
        },
        "deleted_at": {
          type: 'hidden'
        }
      },
      layout: [{
        "columns": '1fr 1fr',
        "fields": ['PartyName', 'PaymentCompletedDate' ]
      }, {
        "columns": '1fr 0px',
        "fields": [ 'WorkDescription', 'deleted_at' ]
      }]
    }
  },
  methods: {
    evaluateDeletedState({ index }) {
      return !! (
        this.values[this.path] && this.values[this.path][index] && this.values[this.path][index].deleted_at
      )
    },
  }
}
</script>