<template>
  <div class="ParkingAndChargePoints">
    <h3>Parkeerplaatsen en Oplaadpunten</h3>

    <!-- inline repeater -->
    <div class="ParkingAndChargePoints__box mt-3">
      <TypeOfParkingSpace 
        :values="values" 
        :disabled="disabled"
        :path="TypeOfParkingSpacePath"
        :viewer="viewer" />
    </div>

    <!-- <h5 class="mt-3">Oplaadpunten van de parkeerplaats(en)</h5> -->
    <FieldSet 
      class="mt-4"
      :label="label"
      :values="internalData"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @input="handleInput"
      :viewer="viewer"
    />
  </div>
</template>
 
<script>

import TypeOfParkingSpace from '@/components/definitions/rvb/TypeOfParkingSpace'
import FieldSet from '@/components/form/FieldSet'

import dot from 'dot-object'
const deepmerge = require('deepmerge')

export default {
  name: 'ParkingAndChargePoints',
  components: { TypeOfParkingSpace, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'ParkingAndChargePoints'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    TypeOfParkingSpacePath() {
      return this.path === '' ? 'TypeOfParkingSpace' : `${this.path}.TypeOfParkingSpace`
    }
  },
  data() {
    return {

      // Copied from Cafienne
      fields: {
        "NumberOfExistingChargePoints": {
            "title": "Aantal bestaande laadpunten",
            "type": "number"
        },
        "NumberOfChargingPointsReplaced": {
            "title": "Aantal laadpunten vervangen",
            "type": "number"
        },
        "ReprogramNumberOfChargingPoints": {
            "title": "Aantal te herprogrammeren",
            "type": "number"
        },
        "ChargePointNeedsGovernmentOffices": {
            "title": "Behoefte Rijkskantoren (4%)",
            "type": "number"
        },
        "ExtraNeedChargePoints": {
            "title": "Extra behoefte laadpunten",
            "type": "number"
        },
        "TotalChargePointsStillToBePlaced": {
            "title": "Totaal nog te plaatsen",
            "type": "number"
        },
        "TotalChargePointsNewSituation": {
            "title": "Totaal laadpunten nieuwe situatie",
            "type": "number"
        }
      },
      layout: [{
        "columns": '1fr 1fr 1fr',
        "fields": [ 'NumberOfExistingChargePoints', 'NumberOfChargingPointsReplaced', 'ReprogramNumberOfChargingPoints' ]
      }, {
        "columns": '1fr 1fr 1fr',
        "fields": [ 'ChargePointNeedsGovernmentOffices', 'ExtraNeedChargePoints', 'TotalChargePointsStillToBePlaced' ]
      }, {
        "columns": '1fr 1fr 1fr',
        "fields": [ 'TotalChargePointsNewSituation' ]
      }],

      internalData: null
    }
  },
  watch: {
    values() {
      this.internalData = this.values
    }
  },
  created() {
    this.internalData = this.values
  },
  methods: {

    /**
     * Apply calculations
     *  Note: `name` includes full path (e.g. <SOMETHING>.ParkingAndChargePoints.NumberOfExistingChargePoints)
     * 
     * TotalChargePointsStillToBePlaced = ChargePointNeedsGovernmentOffices + ExtraNeedChargePoints
     * TotalChargePointsNewSituation = NumberOfExistingChargePoints + ChargePointNeedsGovernmentOffices + ExtraNeedChargePoints
     * or simply put:  TotalChargePointsNewSituation  =   NumberOfExistingChargePoints  + TotalChargePointsStillToBePlaced 
     */
    handleInput({ name, value }) {
      let data = {}
      data[name] = value
      data = dot.object(data)

      this.internalData = deepmerge(this.internalData, data)

      name = this.getFieldNameFromPath({ path: name })

      // TotalChargePointsStillToBePlaced = ChargePointNeedsGovernmentOffices + ExtraNeedChargePoints
      if (['ChargePointNeedsGovernmentOffices', 'ExtraNeedChargePoints'].includes(name)) {
        let ExtraNeedChargePoints = this.getInternalValueByFieldname({ name: 'ExtraNeedChargePoints' })
        let ChargePointNeedsGovernmentOffices = this.getInternalValueByFieldname({ name: 'ChargePointNeedsGovernmentOffices' })

        let TotalChargePointsStillToBePlaced = (parseInt(ExtraNeedChargePoints, 10) || 0) + (parseInt(ChargePointNeedsGovernmentOffices, 10) || 0)
        this.handleInput({ // handling it like human input
          name: this.addPathToFieldname({ name: 'TotalChargePointsStillToBePlaced' }),
          value: TotalChargePointsStillToBePlaced
        })
      }

      // TotalChargePointsNewSituation  =   NumberOfExistingChargePoints  + TotalChargePointsStillToBePlaced 
      if (['NumberOfExistingChargePoints', 'TotalChargePointsStillToBePlaced'].includes(name)) {
        let NumberOfExistingChargePoints = this.getInternalValueByFieldname({ name: 'NumberOfExistingChargePoints' })
        let TotalChargePointsStillToBePlaced = this.getInternalValueByFieldname({ name: 'TotalChargePointsStillToBePlaced' })

        let TotalChargePointsNewSituation = (parseInt(NumberOfExistingChargePoints, 10) || 0) + (parseInt(TotalChargePointsStillToBePlaced, 10) || 0)
        this.handleInput({ // handling it like human input
          name: this.addPathToFieldname({ name: 'TotalChargePointsNewSituation' }),
          value: TotalChargePointsNewSituation
        })
      }
    },
    
    /**************************************************************************
     * Adding methods to take into account the changing `path` prefix of fields names
     */

    /**
     * Add path to field name
     */
    addPathToFieldname({ name }) {
      return `${this.path}.${name}`
    },

    /**
     * Get the field name from the full path
     */
    getFieldNameFromPath({ path }) {
      return path.split('.').reverse()[0]
    },

    /**
     * Get the internal value based on the field name
     */
    getInternalValueByFieldname({ name }) {
      let segments = this.addPathToFieldname({ name }).split('.')
      let value = this.internalData

      segments.forEach(segment => {
        value = Object.prototype.hasOwnProperty.call(value, segment) ? value[segment] : {}
      })

      return value
    }
  }
}
</script>

<style lang="scss">
.ParkingAndChargePoints__box {
  border: 1px solid $gray-500;
  border-radius: 3px;
  padding: $padding-sm;
}
</style>