<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleConfirm">

      <RealisationHeader :record="record" :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step">

        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button :disabled="disabled || (! hasDecision && ! hasPositiveAdvice) || disableNextBtn" variant="light" class="ml-3" size="sm" type="submit"
            @click.prevent.stop="handleConfirm">
            Bevestigen
          </b-button>
        </template> 

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" :disabled="disabled || locked" />
      
      <div class="DetailGrid__map">
        <b-alert :show="Skip">
          De huidige locatie is gekozen op advies van de gemeente.
        </b-alert>
        <h3>Gemeentelijke beoordeling</h3>
        <p>
          <strong :style="AdviceStyle">{{ CPOAdvice }}</strong>
        </p>
        <p v-if="AdviceDetails" class="mb-5">
          Toelichting: {{ AdviceDetails }}
        </p>

        <template v-if="! reloading">
          <p v-if="CPOAdvice === 'Onbekend'">
            De beoordeling van de gemeente is nog niet vastgesteld.
          </p>

          <NoAlternativeDecision 
            v-else-if="! AlternativeLocation && CPOAdvice !== 'Positief'" 
            :values="values" 
            :disabled="locked || disabled" 
            @decision="handleDecision" />

          <AlternativeDecision 
            v-else-if="AlternativeLocation" 
            :values="values" 
            :disabled="locked || disabled" 
            @decision="handleDecision" />

          <b-alert variant="info" class="d-flex justify-content-between" :show="showConfirmationDialog">
            <div v-html="decisionMessage">
            </div>
            <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || locked || disableNextBtn"
              @click.prevent.stop="handleConfirm">
              Bevestigen
            </b-button>
          </b-alert>
        </template>
      </div>
      

      <template v-if="AlternativeLocation">
        <div class="DetailGrid__col1">
          <h3>Eigen locatievoorstel</h3>
          
          <MapLocationViewer 
            :record="record"
            :values="values" />

          <LocationInformation class="mt-4" :location="values" />
          <FileUpload 
            class="mt-4"
            label=""
            doctype="ParkingSpots"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.ParkingSpots || null) : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true" />
        </div>

        <div class="DetailGrid__col2">
          <h3>Alternatief voorstel</h3>
          <MapLocationViewer 
            :record="record"
            :values="AlternativeLocation" />

          <LocationInformation class="mt-4" :location="AlternativeLocation" />
          

        </div>

        <div class="DetailGrid__col3">
          <h4 class="mt-3" v-if="values && values.Docs ? (values.Docs.TopView || []).length : false">
            Boven aanzicht
          </h4>
          <FileUpload 
            class="mt-4"
            label=""
            doctype="TopView"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.TopView || null) : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true" 
            :preview="true"
            :wide="true" />
          <h4 class="mt-3" v-if="values && values.Docs ? (values.Docs.SideView || []).length : null">
            Zij aanzicht
          </h4>
          <FileUpload 
            class="mt-3"
            label=""
            doctype="SideView"  
            :uuid="uuid"
            :files="values && values.Docs ? (values.Docs.SideView || null) : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true"
            :preview="true"
            :wide="true" />
        </div>
        <div class="DetailGrid__col4">
          <h4 class="mt-3" v-if="AlternativeLocation && AlternativeLocation.Docs ? (AlternativeLocation.Docs.TopView || []).length : false">
            Boven aanzicht
          </h4>
          <FileUpload 
            class="mt-4"
            label=""
            doctype="TopView"  
            :uuid="uuid"
            :files="AlternativeLocation && AlternativeLocation.Docs ? (AlternativeLocation.Docs.TopView || null) : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true" 
            :preview="true"
            :wide="true" />
          <h4 class="mt-3" v-if="AlternativeLocation && AlternativeLocation.Docs ? (AlternativeLocation.Docs.SideView || []).length : null">
            Zij aanzicht
          </h4>
          <FileUpload 
            class="mt-3"
            label=""
            doctype="SideView"  
            :uuid="uuid"
            :files="AlternativeLocation && AlternativeLocation.Docs ? (AlternativeLocation.Docs.SideView || null) : null"
            :minFileId="0"
            :reference="record.ref || null"
            :casefull="record.case_ref || null"
            :locked="true"
            :disabled="true"
            :preview="true"
            :wide="true" />
        </div>
      </template>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button :disabled="disabled || (! hasDecision && ! hasPositiveAdvice) || disableNextBtn" variant="light" class="ml-3" size="sm" type="submit"
            @click.prevent.stop="handleConfirm">
            Bevestigen
          </b-button>
        </template> 

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template> 

        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'
import MapLocationViewer from '@/components/map/MapLocationViewer'
import AlternativeDecision from '@/components/definitions/AlternativeDecision'
import NoAlternativeDecision from '@/components/definitions/NoAlternativeDecision'

import LocationInformation from '@/components/realisation/LocationInformation'
import FileUpload from '@/components/FileUpload'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'

export default {
  name: 'RealisationStep4',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, AlternativeDecision, NoAlternativeDecision,
    MapLocationViewer, LocationInformation,
    FileUpload, Comments
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: "3",
      decision: null
    }
  },
  computed: {
    hasPositiveAdvice() {
      return this.values.CPOAdvice.CPOAdvice === 'yes'
    },
    hasDecision() {
      return this.decision !== null
    },
    decisionMessage() {
      switch(this.decision) {
        case 'reject-continue': 
          return 'Ga door naar de <strong>opdrachtbevestiging</strong> (stap 4).'
        case 'accept-own-location':
        case 'reject-own-location':
          return 'Doe zelf een nieuw <strong>locatievoorstel</strong> (Start opnieuw vanaf stap 1)'
        case 'accept-cancel':
        case 'reject-cancel':
          return 'Annuleer het proces'
        case 'accept':
          return 'Het alternatieve voorstel dient aangevuld te worden in stap 1, waarna de nieuwe informatie weer wordt voorgelegd aan de gemeente.'
      }
      return ''
    },
    CPOAdvice() {
      try {
        let valueToLabel = {
          "yes": "Positief",
          "no": "Negatief",
          "alternative": 'Alternatieve locatie',
          "alternative-without": 'Alternatief gewenst, geen voorstel bijgevoegd'
        }
        return valueToLabel[this.values.CPOAdvice.CPOAdvice]
      } catch(e) {
        return 'Onbekend'
      }
    },
    AdviceDetails() {
      try {
        return this.values.CPOAdvice.CPOComments
      } catch(e) {
        return null
      }
    },
    AdviceStyle() {
      try {
        let valueToLabel = {
          "yes": "color: green",
          "no": "color: red",
          "alternative": 'color: orange',
          "alternative-without": 'color: orange'
        }
        return valueToLabel[this.values.CPOAdvice.CPOAdvice]
      } catch(e) {
        return ''
      }
    },
    AlternativeLocation() {
      try {
        return this.values.CPOAdvice.CPOAdvice === 'alternative' && this.values.CPOAdvice.Alternative
      } catch(e) {
        return false
      }
    },
    showConfirmationDialog() {
      return ['alternative', 'no', 'alternative-without'].includes(this.values.CPOAdvice.CPOAdvice) && this.hasDecision && !this.locked
    }
  },
  created() {
    // console.log("step 4")
    // console.log(this.record)
    // console.log(this.values)

    try {
      this.decision = this.values.CPOAdvice.Decision || null
    } catch(e) {
      //
    }
  },
  methods: {
    handleConfirm() {
      // console.log("confirm")
      this.handleSubmitAndNext()
    },
    handleDecision({ value }) {
      // console.log(value)
      this.decision = value
    },
    prepData({ data }) {
      
      if (this.CPOAdvice === 'Positief') {
        data.CPOAdvice = data.CPOAdvice || {}
        data.CPOAdvice.CPOAdvice = 'yes'
        data.CPOAdvice.Decision = ''
      }
    
      return data
    },
    prepStatus() { // { data }
      let status = {
        uuid: this.currentStepData.uuid
      }

      // Going to the next step?
      if (this.goingToNextStep && ! this.locked) {

        let step = this.currentStep + 1

        if (this.CPOAdvice === 'Positief') {
          step = 4
        } else {  
          switch(this.decision) {
            case 'reject-continue': 
              step = 4
              break;
            case 'accept-own-location':
            case 'reject-own-location':
              this.redirectToAfterSubmit = 1
              step = 3
              break;
            case 'accept':
              step = 1
              break;
            case 'reject-cancel':
            case 'accept-cancel':
              step = 3
              break;
          }
        }

        status = {
          uuid: this.getStepDataByNumber({ number: step })?.uuid
        }
      }
      return status
    },
    prepIntention(intention) {
      if (this.goingToNextStep && ! this.locked) {
        let step = null
        switch(this.decision) {
          case 'accept-own-location':
          case 'reject-own-location':
            // TODO: Why not?
            intention.complete = false
            step = 1
            break;
          case 'accept':
            step = 1
            break;
          case 'reject-cancel':
          case 'accept-cancel':
            // This will cancel the process. Step 4 should not be started
            // TODO: Add data requirement to step 4
            intention.complete = false
            break;
        }
        if (step) {
          intention.target = this.getStepDataByNumber({ number: step })?.uuid
        }
      }
      return intention
    },
    isValidData({ data }) {
      if (this.goingToNextStep) {
        console.log(data)
      }

      return true
    }
  },
}
</script>