const COMMENT_STATUS = {
  TODO: 'todo',
  DONE: 'done',
  IGNORE: 'ignore',
}

const COMMENT_STATUSES = Object.values(COMMENT_STATUS)

module.exports = {
  COMMENT_STATUS,
  COMMENT_STATUSES,
}