<template>
  <div class="BuildingAdjustments">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
    />

  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'PowerOption',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Opties voor de energie-aansluiting'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions.PowerPermission'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PowerOption": {
          "title": "Opties voor de energie-aansluiting",
          "type": "string",
          "description": "Bij besluiten welke extra kosten tot gevolg hebben, zoals verzwaring van de installatie, is eerst instemming nodig vanuit het kernteam, of vanuit de centrale klant (specialties). De kosten dienen eerst aan de klant voorgelegd te worden, alvorens er een go/no go komt",
          "enumNames": [
              "Verminder het aantal te plaatsen laadpunten",
              "Geen laadpunten plaatsen"
          ],
          "enum": [
              "ReduceChargePoints",
              "NoChargePoints"
          ]
        }
      },
      layout: [{
        "columns": "1fr 1fr",
        "fields": [ 'PowerOption' ]
      }]
    }
  },
  methods: {

    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name
      })
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name
      })
    }
  }
}
</script>