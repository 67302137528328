<template>
  <div class="GridOperatorApproval">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

// import { required } from 'vuelidate/lib/validators'

export default {
  name: 'AlternativeDecision',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Beoordeling alternatief voorstel'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'CPOAdvice'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "Decision": {
          "type": "string",
          "title": "",
          "enumNames": [
            "Realisatie annuleren",
            "Accepteren",
            "Afwijzen en zelf een alternatieve locatie selecteren"
          ],
          "enum": [
            "reject-cancel",
            'accept',
            'reject-own-location'
          ],
          'format': 'radio'
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'Decision' ]
      }],
      validation: {}
    }
  },
  methods: {
    handleInput({ name, value }) {
      if (name === `${this.path}.Decision`) {
        this.$emit('decision', { value })
      }
    },
  }
}
</script>