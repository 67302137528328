<template>
  <div class="w-100">
    <Feedback :feedback="feedback" :dismissible="true" />

    <b-button 
      v-if="btnRequirementsMet"
      :disabled="busy || ! hasCoordinates || disabled" 
      variant="primary" 
      class="w-100" 
      @click="handleGenerateViews">
      Genereer aanzichten
    </b-button>
  </div>
</template>

<script>
/**
 * TODO: Write a lambda that handles this server side, 
 * TODO: so the file doesn't have to go through the browser?
 * TODO: Q: will timeouts be a factor?
 * 
 * TODO: Lacks error handling
 */
import { getUploadURL, uploadFile } from '@/services/aws.js'

import { getTopViewUrlFromPDOK } from '@/services/pdok.js'
import { getStreetViewStaticURL } from '@/services/streetview.js'
import { mapGetters } from 'vuex'

import Feedback from '@/components/form/Feedback.vue'

import Vue from 'vue'

export default {
  name: 'AutomatedViewsBtn',
  components: { Feedback },
  props: {
    uuid: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {
      busy: false,
      feedback: {
        show: false,
        message: '',
        variant: 'danger'
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAutomateViews'
    ]),
    ...mapGetters('realisation', [
      'recordByUuid'
    ]),
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    record() {
      return this.recordByUuid({ uuid: this.uuid })
    },
    location() {
      return this.record.getLocation()
    },
    hasSideView() {
      return this.location.hasDocOfType({ doctype: 'SideView' })
    },
    hasTopView() {
      return this.location.hasDocOfType({ doctype: 'TopView' })
    },
    hasCoordinates() {
      return this.location.hasCoordinates()
    },
    btnRequirementsMet() {
      return this.canAutomateViews && this.record && (! this.hasSideView || ! this.hasTopView)
    }
  },
  methods: {
    async handleGenerateViews() {
      this.busy = true
      let success = null
      
      const data = {
        step: 'automatedViews',
        version: this.location.version,
        TopView: null,
        SideView: null
      }

      if (! this.hasTopView) {
        success = await this.getAndUploadTopView()

        if (! success) {
          this.feedback = {
            show: true,
            message: 'Het inladen van het bovenaanzicht is mislukt. Probeer het nog eens a.u.b.'
          }
        } else {
          data.TopView = true
        }
      }

      if (! this.hasSideView && success !== false) {
        success = await this.getAndUploadSideView()

        if (! success) {
          this.feedback = {
            show: true,
            message: 'Het inladen van het zijaanzicht is mislukt. Probeer het nog eens a.u.b.'
          }
        } else {
          data.SideView = true
        }
      }

      // Don't continue if an error occurred, or nothing was uploaded to begin with
      if (success !== true) {
        this.busy = false
        return // Nothing to do...
      }

      // Generate file data
      if (data.TopView) {
        data.TopView = this.record.getNextDocTypeId({ doctype: 'TopView' })
      }
      if (data.SideView) {
        data.SideView = this.record.getNextDocTypeId({ doctype: 'SideView' })
      }

      // Update Fauna database
      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/realisation_views', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`
        },
        body: JSON.stringify({
          ref: this.record.ref,
          data
        })
      })
      if (! api.ok) {
        // console.log("error from db call")
        return 
      }
      /**
       * TODO: Note the ad hoc solution
       */
      if (data.TopView) {
        Vue.set(this.location.Docs, 'TopView', [{
          id: data.TopView,
          ext: 'jpeg'
        }])
        this.record.Docs.TopView.push({
          id: data.TopView,
          ext: 'jpeg'
        })
      }
      if (data.SideView) {
        Vue.set(this.location.Docs, 'SideView', [{
          id: data.SideView,
          ext: 'jpeg'
        }])
        this.record.Docs.SideView.push({
          id: data.SideView,
          ext: 'jpeg'
        })
      }

      this.busy = false
    },
    async getAndUploadTopView() {
      try {
        let lat, lng;

        if (this.location.hasChargingpointLocation()) {
          let chargingpoint = this.getChargingPointByUUID({
            uuid: this.location.Location.chargingpointUuid
          })

          lat = chargingpoint.data.address.coordinates.lat
          lng = chargingpoint.data.address.coordinates.lng
        } else {
          if (! this.location.hasCoordinates()) {
            throw new Error("missing coordinates")
          }

          lat = this.location.Location.Latitude
          lng = this.location.Location.Longitude
        }

        let url = getTopViewUrlFromPDOK({ lat, lng })
        await this.uploadView({ doctype: 'TopView', url })

        return true
      } catch(e) {
        // console.log("unable to obtain top view")
        return false
      }
    },
    async getAndUploadSideView() {
      try {
        let lat, lng;

        if (this.location.hasChargingpointLocation()) {
          let chargingpoint = this.getChargingPointByUUID({
            uuid: this.location.Location.chargingpointUuid
          })

          lat = chargingpoint.data.address.coordinates.lat
          lng = chargingpoint.data.address.coordinates.lng
        } else {
          if (! this.location.hasCoordinates()) {
            throw new Error("missing coordinates")
          }
          
          lat = this.location.Location.Latitude
          lng = this.location.Location.Longitude
        }

        let url = getStreetViewStaticURL({ lat, lng })
        await this.uploadView({ doctype: 'SideView', url })

        return true
      } catch(e) {
        // console.log("unable to obtain top view")
        return false
      }
    },

    /**
     * Upload a view from a remote url by doctype 
     */
    async uploadView({ doctype, url }) {
      if (! url) {
        // console.log("FAIL 1")
        return
      }

      let response = await fetch(url)

      if (! response.ok) {
        // console.log("FAIL 2", response)
        return
      }

      const file = await response.blob()

      const token = await this.$auth.getTokenSilently();
      response = await getUploadURL({
        token,
        ref: this.record.ref,
        uuid: this.record.uuid,
        casefull: this.record.getCaseRef(),
        index: this.record.getNextDocTypeId({ doctype }),
        doctype,
        type: 'image/jpeg'
      })

      if (! response.uploadURL) {
        console.log("FAIL 3", response)
        return
      }
      
      const uploadURL = response.uploadURL
      response = await uploadFile({ file, uploadURL })

      if (! response.ok) {
        console.log("FAIL 4", response)
        return
      }
    }
  }
}
</script>

<style>

</style>