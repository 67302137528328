
import RVBCustomerReportingConfig from './configs/RVBCustomerReportingConfig.js'
import RVBProcessConfig from './configs/RVBProcessConfig.js'
import RVBChargingpointCount from './configs/RVBChargingpointCount.js'
import RVBChargingpointsByObjectType from './configs/RVBChargingpointsByObjectType.js'
import RVBChargingpointsByRegion from './configs/RVBChargingpointsByRegion.js'
import RVBChargingpointsByPID from './configs/RVBChargingpointsByPID.js'
import RVBGridImprovementCount from './configs/RVBGridImprovementCount.js'

import RealisationProcesses from './configs/RealisationProcesses.js'
import RequestsTable from './configs/RequestsTable.js'


/**
 * TODO:
 *  - set & get list of suitable table configs (uuids) in tenant config
 *  - implement sorting & filtering on request count
 *  - implement sorting & filtering on related realisation
 *  - abstract filter & mapping functions to named references
 *  - load configs from an external source (e.g. FaunaDB)
 *  - find a way to deal with a mix of model structurs (e.g. from different municipalities)
 *  - account for differences in access at object level, instead of categorical level
 *  - Turn into a modules
 *    - make it possible to register cell, control & filter components
 */

const configurations = {
  "b8457838-bb24-4a90-8d8f-a12c2a02d075": RVBCustomerReportingConfig,
  "10deca9b-d99b-4f0c-a1f7-1db51d58d562": RVBChargingpointCount,
  '67d40fce-cd01-435b-ad2c-6574e1bc4c8c': RVBChargingpointsByObjectType,
  '81daa378-e75a-4a5a-b224-4c48849a8bef': RVBChargingpointsByRegion,
  '191a5278-afe8-4d92-90b4-0dc4554fb5f9': RVBChargingpointsByPID,
  '079c2131-92dd-4371-80e3-7f6fb09cc9da': RVBGridImprovementCount,

  // Realisation Proceses
  '3406eb6e-f42a-4e9d-914a-5a11e308f867': RealisationProcesses,

  // Requests
  "de3dae3b-1531-4d17-85f1-03a1605b3724" : RequestsTable,

  // RVB Cases
  "b04ec143-e712-4788-a74e-7af73b6466c0" : RVBProcessConfig,

  /***
   * GO-RAL spec (same as 3406eb6e-f42a-4e9d-914a-5a11e308f867)
   */
  'ec1e913e-d333-4d0d-bc76-5d4d8b0e3358': RealisationProcesses
}



export const configs = configurations