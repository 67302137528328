

/**
 * A Location is part of Realisation record
 *  One Realisation record can hold multiple Locations
 */
let Location = function({
  data, copy
}) {
  let model = {}

  // short circuit making copies
  if (copy || this.LooksAndQuacksLikeCopy({ data })) {
    model = this.makePlainCopy({ data })
  } else {
    model = this.commonStructure({ data })
  }

  // 
  Object.keys(model).forEach(key => {
    this[key] = model[key]
  })
}

Location.prototype.commonStructure = ({ data }) => {
  // console.log(data)
  data.Location = data.Location || {}

  let address = data?.Object?.Address || {}

  data.Location = {
    StreetName: address.StreetName || '',
    HouseNumber: address.HouseNumber || null,
    HouseNumberSuffix: address.HouseNumberSuffix || '',
    CityName: address.CityName || ''
  }
  
  return data
}


Location.prototype.getAddress = function() {
  try {
    let location = this.Location
    let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
    return (`${location.StreetName || ''} ${number}, ${location.CityName || ''}`).trim().trim(',')
  } catch(e) {
    return false
  }
}


Location.prototype.LocationCopy = function({ version }){
  let oldData = JSON.parse(JSON.stringify(this))

  let data = {
    Location: oldData.Location
  }
  
  data.version = version

  return new Location({ data, copy: true })
}


export default Location;