<template>
  <div class="BrandLogo" :class="`BrandLogo--${img}`" :style="{ width: `${width}px` }"> 
    <img v-if="img === 'rvb'" src="../assets/image/rvb.svg" :width="width" alt="" :style="{ background: color }" />
    <img v-else src="../assets/image/evtools.png" :width="width" alt="" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    context: {
      type: String,
      default: 'login'
    }
  },
  computed: {
    ...mapGetters('tenant', ['getTenant']),
    img() {
      return this.context === 'login' ? 'evtools' : this.getTenant
    },
    width() {

      switch(this.img) {
        case 'rvb':
          return 180
      }

      return 130
    },
    color() {
      switch(this.img) {
        case 'rvb':
          return 'white'
      }

      return 'none'
    }
  }
}
</script>

<style lang="scss">

.BrandLogo--rvb {
  position: relative;
  margin: 0;

  img {
    position: absolute;
    top: -36px;
    left: 2px;

    
    // background: white;
    padding: 0 0.5rem 0.2rem;
    // box-shadow: 2px 5px 19px 0px rgba(0,0,0,0.27);
    // border-radius: 4px;
  }
}
</style>