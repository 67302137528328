<template>
  <div>
    <Form
      ref="form" 
      class="RealisationDetail PageWrapper DetailGrid"    
      @error="handleValidationError" 
      @submit="handleSubmit">

      <RealisationHeader :feedback="feedback" :feedbackMessage="feedbackMessage" :step="currentStep" :recordStep="step" :record="record">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <!-- <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button> -->
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationHeader>
      
      <RealisationSidebar :uuid="uuid" />

      <div v-if="! reloading" class="DetailGrid__map">
        <PlannedRealisation :values="values" :disabled="locked || disabled" />
      </div>

      <RealisationFooter :feedback="feedback" :feedbackMessage="feedbackMessage">
        <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
          @click.prevent.stop="handlePrevious">
          Vorige stap
        </b-button>

        <template v-if="! locked">  
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled">
            Opslaan
          </b-button>
          <b-button 
            v-if="step !== currentStep"
            variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleCancelChange">
            Annuleren
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn" 
            @click.prevent.stop="handleSubmitAndNext">
            Volgende stap
          </b-button>
        </template>

        <template v-else-if="mayEdit">
          <b-button variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartCorrection">
            Corrigeren
          </b-button>
          <b-button v-if="openForChange" variant="outline-light" class="ml-3" size="sm" type="submit" :disabled="disabled"
            @click.prevent.stop="handleStartChange">
            Wijzigen
          </b-button>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || disableNextBtn"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>  
              
        <template v-else>
          <b-button variant="light" class="ml-3" size="sm" type="submit" :disabled="disabled || step <= currentStep"
            @click.prevent.stop="handleNext">
            Volgende stap
          </b-button>
        </template>
      </RealisationFooter>
    </Form>
    <Comments :record="record" />
  </div>
</template>

<script>

import RealisationHeader from '@/components/realisation/RealisationHeader'
import RealisationFooter from '@/components/realisation/RealisationFooter'
import RealisationSidebar from '@/components/realisation/RealisationSidebar'

import Form from '@/components/form/Form'

import Comments from '@/components/Comments'

import BaseRealisationStep from '@/views/BaseRealisationStep'


// AMSTERDAM DEFINITIONS
import PlannedRealisation from '@/components/definitions/amsterdam/PlannedRealisation'

export default {
  name: 'RealisationStep7',
  components: {
    RealisationSidebar, RealisationHeader, RealisationFooter,
    Form, Comments,
    PlannedRealisation
  },
  mixins: [ BaseRealisationStep ],
  data() {
    return {
      currentStep: 7
    }
  },
  created() {
    // console.log("step 7")
    // console.log(this.record)
    // console.log(this.values)
  },
  methods: {
    prepData({ data }) {
      return data
    },
    isValidData({ data }) {
      if (this.goingToNextStep) {
        try {
          if (
            data.PlannedRealisation.PlannedDate === ''
          ) {
            throw new Error("")
          }
        } catch(e) {
          this.disabled = false
          this.feedback = 'danger'
          this.feedbackMessage = 'Het proces kan niet verder zonder een realisatiedatum.'
          return false
        }
      }
      return true
    }
  },
}
</script>