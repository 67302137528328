<template>
  <div>
    <h3 id="table_5" class="mt-3">
      Netverzwaringen
    </h3>
    <TableManager 
      ref="TableManager"
      class="RVBChargingpointCount"
      :loading="loading"
      :sourceRecords="RecordsWithGridUpgrade" 
      category="rvb-customer-reporting"
      uuid="079c2131-92dd-4371-80e3-7f6fb09cc9da" />

  </div>
</template>

<script>

import TableManager from '@/components/Table/TableManager'
import { mapGetters } from 'vuex'

export default {
  name: '',
  components: {
    TableManager
  },

  computed: {
    ...mapGetters('realisation', [
      'records',
      'loading'
    ]),
    RecordsWithGridUpgrade() {
      return this.records.filter(record => {
        return record?.CurrentLocation?.BuildingOptions?.PowerOption === 'GridUpgrade'
      })
    }
  }

}
</script>

<style lang="scss">
.RVBChargingpointCount {
  // .Table__row {
  //   div:first-child {
  //     font-weight: bold;
  //   }
  // }
  .Table__row:last-child {
    font-weight: bold;
  }
}
</style>