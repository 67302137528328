

export default {
  tenantName: 'PARKnCHARGE',

  /**
   * Feature Access checks
   */ 
  access: {
    'requestlist': ['cpo'],
    'requestlocations': ['cpo', 'municipality'],
    'realisationlist': ['cpo', 'municipality'],
    'realisationbtn': ['cpo'],
    'realisationcomment': ['cpo', 'municipality'],
    'canConfirmTrafficDecisions': ['municipality'],
    'canChangeCommentStatus': ['cpo', 'municipality'],
    'canChangeStatus': ['cpo', 'municipality'],
    'automatedViews': ['cpo'],
    'generateStartDoc': ['cpo', 'municipality'],
    'admin': ['admin'],
    'reporting': ['admin'],
    'eventlog': []
  },

  
  /** 
   * Judgement Reasons
   */ 
  reasonOptions: [
    { value: 4, text: 'Eigen parkeergelegenheid', short: 'Eigen parkeergelegenheid', custom: false },
    { value: 5, text: 'Eigen parkeergelegenheid via VvE', short: 'VVE parkeergelegenheid', custom: false },
    { value: 6, text: 'Laadpaal in de buurt', short: 'Laadpaal beschikbaar', custom: true, placeholder: 'Adres aanwezige laadpaal' },
    { value: 7, text: 'Te weinig vraag in de buurt', short: 'Te weinig vraag', custom: false },
    { value: 8, text: 'Alphen aan de Rijn: geen locaties beschikbaar', short: 'Alphen ad Rijn', custom: false },
    // { value: 9, text: 'Amersfoort: geen verwijzing van de gemeente', short: 'Amersfoort', custom: false },
    { value: 10, text: 'Anders', short: 'Anders', custom: true, placeholder: 'Schrijf hier je toelichting naar de aanvrager waarom je hem afwijst. Aanhef en afsluiting worden al toegevoegd.' }
  ],


  /**
   * Monitoring config
   */ 
  monitoring: {
    enabled: false,
    limit: 0.5
  },


  /**
   * search
   */ 
  search: {
    requests: {
      keys: ['summary.name', 'summary.address', 'case_ref', 'ref', 'uuid', 'search.postalcode', 'search.city'],
      placeholder: 'Zoek op referentie, naam, adres of plaats'
    },
    realisations: {
      keys: ['case_ref', 'search.Municipality', 'search.City', 'address', 'status.label', 'values.Location.PlannedRealisation.Contractor', 'values.Location.GridOperator', 'ref', 'uuid'],
      placeholder: 'Zoek op referentie, adres of stap'
    }
  },


  /** 
   * onHold & cancel reasons
   */ 
  onHoldReasons: [{
    text: 'Bezwaar VKB binnengekomen',
    value: 'vkb'
  }, {
    text: 'Overige',
    value: 'other'
  }],

  cancelReasons: [{
    text: 'Bezwaar VKB toegewezen',
    value: 'vkb'
  },{
    text: 'Overige',
    value: 'other'
  }],


  /**
   * Municipality Options
   */
  municipalityOptions: [
    { value: "0358", text: "Aalsmeer", disabled: false },
    { value: "0197", text: "Aalten", disabled: false },
    { value: "0482", text: "Alblasserdam", disabled: false },
    { value: "0484", text: "Alphen aan den Rijn", disabled: false },
    { value: "0307", text: "Amersfoort", disabled: false },
    { value: "0362", text: "Amstelveen", disabled: false },
    { value: "0743", text: "Asten", disabled: false },
    { value: "0758", text: "Breda", disabled: false },
    { value: "0216", text: "Culemborg", disabled: false },
    // { value: "1891", text: "Dantumadiel", disabled: true },
    { value: "0736", text: "De Ronde Venen", disabled: false },
    { value: "0766", text: "Dongen", disabled: false },
    { value: "0505", text: "Dordrecht", disabled: false },
    { value: "0226", text: "Duiven", disabled: false },
    { value: '1979', text: 'Eemsdelta', disabled: false },
    { value: "0772", text: "Eindhoven", disabled: false },
    { value: "1924", text: "Goeree-Overflakkee", disabled: false },
    { value: "0243", text: "Harderwijk", disabled: false },
    { value: "0523", text: "Hardinxveld-Giessendam", disabled: false },
    { value: "0244", text: "Hattem", disabled: false },
    // { value: "0074", text: "Heerenveen", disabled: true },
    { value: "0531", text: "Hendrik-Ido-Ambacht", disabled: false },
    { value: "0252", text: "Heumen", disabled: false },
    { value: "0537", text: "Katwijk", disabled: false },
    // { value: "0080", text: "Leeuwarden", disabled: true },
    { value: "0546", text: "Leiden", disabled: false },
    { value: "0547", text: "Leiderdorp", disabled: false },
    { value: "0809", text: "Loon op Zand", disabled: false },
    { value: "0119", text: "Meppel", disabled: false },
    { value: "1709", text: "Moerdijk", disabled: false },
    { value: "0356", text: "Nieuwegein", disabled: false },
    { value: "0569", text: "Nieuwkoop", disabled: false },
    { value: '0575', text: "Noordwijk", disabled: false },
    // { value: "1970", text: "Noardeast-Fryslân", disabled: true },
    { value: "0579", text: "Oegstgeest", disabled: false },
    { value: "1586", text: "Oost Gelre", disabled: false },
    { value: "1509", text: "Oude IJsselstreek", disabled: false },
    { value: "0590", text: "Papendrecht", disabled: false },
    { value: "0273", text: "Putten", disabled: false },
    { value: "0279", text: "Scherpenzeel", disabled: false },
    { value: "0610", text: "Sliedrecht", disabled: false },
    { value: "0855", text: "Tilburg", disabled: false },
    { value: "0632", text: "Woerden", disabled: false },
    { value: "0297", text: "Zaltbommel", disabled: false },
    { value: "0642", text: "Zwijndrecht", disabled: false },
  ],


  /** 
   * Steps 
   */
  steps: [
    {
      step: 1,
      uuid: '63a4d7f6-f3d1-4030-9637-66386d2f876b',
      component: require('@/views/park-n-charge/RealisationStep1'),
      version: 1,
      label: 'Locatievoorstel',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 2,
      uuid: '7fc0ed9a-f715-472e-9f7a-67152f3eecaa',
      component: require('@/views/park-n-charge/RealisationStep2'),
      version: 1,
      label: 'Gemeente beoordeling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 3,
      uuid: 'd9e54a80-1d1d-4d61-a58d-df4d6a719fcb',
      component: require('@/views/park-n-charge/RealisationStep3'),
      version: 1,
      label: 'Verwerking beoordeling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 4,
      uuid: 'b3fc2e59-25ee-4792-a1b3-27e69b277ee0',
      component: require('@/views/park-n-charge/RealisationStep4'),
      version: 1,
      label: 'Verkeersbesluit',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['municipality']
      }
    },
    {
      step: 5,
      uuid: '252575b0-3ab6-4de3-82af-4cdd83ec6705',
      component: require('@/views/park-n-charge/RealisationStep5'),
      version: 1,
      label: 'Aanvragen netaansluiting',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 6,
      uuid: '5f4ade07-5a0a-471c-bed8-3d6b2316c0b8',
      component: require('@/views/park-n-charge/RealisationStep6'),
      version: 1,
      label: 'Plan realisatie',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 7,
      uuid: '1c146862-f847-4b63-b21c-15593b61bdeb',
      component: require('@/views/park-n-charge/RealisationStep7'),
      version: 1,
      label: 'Realisatie en inbedrijfstelling',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    },
    {
      step: 8,
      uuid: '0c985c06-9a98-445f-93da-047a76d2117f',
      component: require('@/views/park-n-charge/RealisationStep8'),
      version: 1,
      label: 'SAT',
      access: {
        read: ['municipality', 'cpo'],
        edit: ['cpo']
      }
    }
  ]
}


