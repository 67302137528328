<template>
  <div class="PageFooter position-fixed fixed-bottom d-flex justify-content-start align-items-center">

    
    <TenantLogo />

    <span v-if="username" class="flex-grow-1 text-right">
      Hallo {{ username }}, u bent actief als {{ roleName }}<span v-if="tenantName"> in de laadpalen workflow van {{ tenantName }}</span>.
    </span>
    <!-- <a href="#" class="ml-1" @click="logout">
      Uitloggen
    </a> -->

    <!-- <span>Uw sessie verloopt over {{ expireString }}.</span> -->

    <b-badge 
      v-if="afterFiveMinutes && ! busy" 
      class="ml-1" 
      href="#" 
      variant="light"
      @click="handleExtendSession">
      Nu verlengen
    </b-badge>
  </div>
</template>

<script>

import TenantLogo from '@/components/TenantLogo.vue'

import { tenantName } from '@/config'

import { mapGetters, mapMutations } from 'vuex'

import { extendSession, getRemainingSessionLifetime } from '@/auth/session'

export default {
  components: {
    TenantLogo
  },
  data() {
    return {
      busy: false,
      tenantName
    }
  },
  computed: {
    ...mapGetters('user', [
      'roleName',
      'getRemainingTime',
      'getInitialRemainingTime'
    ]),

    username() {
      return this.$auth.user ? this.$auth.user.name : null
    },

    /**
     * 
     */
    expireString() {
      let timeSegments = [
        {
          id: 'weeks',
          seconds: (7 * 86400),
          labels: {
            single: 'week',
            multiple: 'weken'
          }
        },
        {
          id: 'days',
          seconds: 86400,
          labels: {
            single: 'dag',
            multiple: 'dagen'
          }
        },
        {
          id: 'hours',
          seconds: 3600,
          labels: {
            single: 'uur',
            multiple: 'uren'
          }
        },
        {
          id: 'minutes',
          seconds: 60,
          labels: {
            single: 'minuut',
            multiple: 'minuten'
          }
        }
      ]

      /**
       * Only start showing seconds when the countdown is updated each seconds
       */
      if (this.getRemainingTime <= 300) {
        timeSegments.push({
          id: 'seconds',
          seconds: 1,
          labels: {
            single: 'seconde',
            multiple: 'seconden'
          }
        })
      }

      /**
       * Go through the segments, and 
       */
      return timeSegments.reduce(( result, config ) => {
        let count = Math.floor(result.remaining / config.seconds)
        result.remaining = result.remaining % config.seconds

        if (count !== 0 && result.segmentCount < 2) {
          let label = count === 1 ? config.labels.single : config.labels.multiple
          result.label = `${result.label} ${count} ${label}`
          result.segmentCount = result.segmentCount + 1
        }

        result.segments[config.id] = count

        return result
      }, { 
        label: '', 
        remaining: this.getRemainingTime, 
        segments: {},
        segmentCount: 0 // visible segments
      }).label
    },

    /**
     * The session has been started or extended at least 5 minutes ago
     */
    afterFiveMinutes() {
      return this.getRemainingTime < (this.getInitialRemainingTime - 300)
    }
  },
  methods: {
    ...mapMutations('user', [
      'setRemainingTime'
    ]),
    async handleExtendSession() {
      this.busy = true
      await extendSession()
      this.setRemainingTime({
        remainingTime: await getRemainingSessionLifetime()
      })
      this.busy = false
    },

    logout() {
      this.$auth.logout({
        returnTo: window.location.origin
      })
    }
  }
}
</script>

<style lang="scss">
.PageFooter {
  // bottom: 0;
  // position: sticky;
  width: 100%;
  background: $gray-300;
  z-index: 9999;
  border-top: 1px solid $gray-400;
  padding: 3px 16px;
  font-size: 75%;
}
</style>