
// const oldSteps = [{
//   step: 1,
//   uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761',
//   component: require('@/views/rvb/RealisationStep1'),
//   version: 1,
//   label: 'Locatie gegevens',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// },
// {
//   step: 2,
//   uuid: '858f94f3-d616-4129-a7cc-77b34adedc61',
//   component: require('@/views/rvb/RealisationStep2'),
//   version: 1,
//   label: 'Schouw inplannen',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// },
// {
//   step: 3,
//   uuid: 'ad21d4ff-65ac-4fa4-8091-eac8c05ef934',
//   component: require('@/views/rvb/RealisationStep3'),
//   version: 1,
//   label: 'Inventarisatie situatie en aanpassingen',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// },
// {
//   step: 4,
//   uuid: 'a067d05a-54da-4e40-9e3f-3db71a332b54',
//   component: require('@/views/rvb/RealisationStep4'),
//   version: 1,
//   label: 'Offertes',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// },
// {
//   step: 5,
//   uuid: '56573c31-2009-480e-b3a7-9b4977767df8',
//   component: require('@/views/rvb/RealisationStep5'),
//   version: 1,
//   label: 'Realisatie',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// },
// {
//   step: 6,
//   uuid: '9c8a9245-aa31-4b57-9c65-f2ebdc8b10a5',
//   component: require('@/views/rvb/RealisationStep6'),
//   version: 1,
//   label: 'Oplevering en betaling',
//   access: {
//     read: ['rvb'],
//     edit: ['rvb']
//   }
// }]


const spec = {
  uuid: "df0465bb-9d3d-4fa4-9f8c-f46a38e7291d",
  version: 1,
  steps: [
    {
      uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761',
      label: 'Locatie gegevens',
      short: '1',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      requirements: []
    },
    {
      uuid: '858f94f3-d616-4129-a7cc-77b34adedc61',
      label: 'Schouw inplannen',
      short: '2',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
    },


    /**
     * STEP 3 
     */
    {
      uuid: 'ad21d4ff-65ac-4fa4-8091-eac8c05ef934',
      label: 'Inventarisatie situatie en aanpassingen',
      short: '3',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      children: [
        '8f8d15d6-43b5-4370-9e62-95469b72d904',
        'e430f07b-253f-47d7-af14-bc9680b8f3d7',
        'd564ef80-0e65-4991-89b7-e1526e5b7fb7',
        '2edafe45-0dc2-43cc-9e20-98a7e7cf2192',
        '52d10b34-a4a7-493f-8d4d-0e2cb8f0748f',
        '87573a4c-214f-4031-8efa-def519f067de',
        'db596df9-c3b5-4933-8e72-c0d74e75fb4c',
        '3b670829-24d5-4e3a-bbe0-be3c30648ef8',
        '0d667153-2ce1-4052-a348-a3b99d50c167'
      ],
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '858f94f3-d616-4129-a7cc-77b34adedc61' }],
      // Auto complete based on data requirements
      autocomplete: [{
        type: 'data',
        spec: [
          'all',
          // in any case, 3A must be completed
          [
            'completed',
            '8f8d15d6-43b5-4370-9e62-95469b72d904'
          ],

          // PowerOption flows
          [
            'any',
            [
              '!=',
              [
                'get',
                'BuildingOptions.PowerOption'
              ],
              'GridUpgrade'
            ],
            [
              'all',
              [
                '==',
                [
                  'get',
                  'BuildingOptions.PowerPermission.PowerAdjustmentDecision'
                ],
                'NoGo'
              ],
              [
                'completed',
                '52d10b34-a4a7-493f-8d4d-0e2cb8f0748f' // 3E
              ]
            ],
            [
              'all',
              [
                '!=',
                [
                  'get',
                  'BuildingOptions.PowerPermission.PowerAdjustmentDecision'
                ],
                'NoGo'
              ],
              [
                'completed',
                '2edafe45-0dc2-43cc-9e20-98a7e7cf2192' // 3D
              ]
            ]
          ],

          // Fusebox flows
          [
            'any',
            [
              "!",
              [
                "in",
                [
                  'get',
                  'BuildingOptions.FuseBoxOption'
                ],
                [ 'ReplacementNeeded', 'MinorChangeNeeded' ]
              ]
            ],
            [
              'all',
              [
                "==",
                [
                  'get',
                  'BuildingOptions.FuseBoxOption'
                ],
                'MinorChangeNeeded'
              ],
              [
                'completed',
                '3b670829-24d5-4e3a-bbe0-be3c30648ef8'
              ],
            ],
            [
              'all',
              [
                '==',
                [
                  'get',
                  'BuildingOptions.FuseBoxOption'
                ],
                'ReplacementNeeded'
              ],
              [
                'completed',
                '0d667153-2ce1-4052-a348-a3b99d50c167'
              ]
            ]
          ]
        ]
      }] 
    },
    
    {
      uuid: '8f8d15d6-43b5-4370-9e62-95469b72d904',
      label: 'Schouw verslag',
      short: '3-a',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '858f94f3-d616-4129-a7cc-77b34adedc61' }]
    },

    // 3 - 1st branch
    {
      uuid: 'e430f07b-253f-47d7-af14-bc9680b8f3d7',
      label: 'Offerte voor netverzwaring',
      short: '3-b',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [
        { type: "step", uuid: '8f8d15d6-43b5-4370-9e62-95469b72d904' },
        {
          type: 'data',
          spec: [
            '==',
            [
              'get',
              'BuildingOptions.PowerOption'
            ],
            'GridUpgrade'
          ] 
        }
      ]
    },
    {
      uuid: 'd564ef80-0e65-4991-89b7-e1526e5b7fb7',
      label: 'Toestemming voor netverzwaring',
      short: '3-c',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: 'e430f07b-253f-47d7-af14-bc9680b8f3d7' }]
    },
    {
      uuid: '2edafe45-0dc2-43cc-9e20-98a7e7cf2192',
      label: 'Uitvoering van netverzwaring',
      short: '3-d',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: 'd564ef80-0e65-4991-89b7-e1526e5b7fb7' }]
    },

    {
      uuid: '52d10b34-a4a7-493f-8d4d-0e2cb8f0748f',
      label: 'Opties voor de energie-aansluiting',
      short: '3-e',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [
        { type: "step", uuid: '2edafe45-0dc2-43cc-9e20-98a7e7cf2192' },
        {
          type: 'data',
          spec: [
            '==',
            [
              'get',
              'BuildingOptions.PowerPermission.PowerAdjustmentDecision'
            ],
            'NoGo'
          ] 
        }
      ]
    },

    // 3 - 2nd branch
    {
      uuid: '87573a4c-214f-4031-8efa-def519f067de',
      label: 'Offerte voor grote aanpassing hoofdverdeelkast / vervanging',
      short: '3-f',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [
        { type: "step", uuid: '8f8d15d6-43b5-4370-9e62-95469b72d904' },
        {
          type: 'data',
          spec: [
            '==',
            [
              'get',
              'BuildingOptions.FuseBoxOption'
            ],
            'ReplacementNeeded'
          ] 
        }
      ]
    },
    {
      uuid: 'db596df9-c3b5-4933-8e72-c0d74e75fb4c',
      label: 'Toestemming voor grote aanpassing/vervanging hoofdverdeelkast',
      short: '3-g',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '87573a4c-214f-4031-8efa-def519f067de' }]
    },
    {
      uuid: '3b670829-24d5-4e3a-bbe0-be3c30648ef8',
      label: 'Uitvoering van (grote) aanpassing/vervanging hoofdverdeelkast',
      short: '3-h',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [
        {
          type: 'step', uuid: '8f8d15d6-43b5-4370-9e62-95469b72d904'
        },
        {
          type: 'data',
          spec: [
            "any",
            [
              "all",
              [
                'completed',
                '8f8d15d6-43b5-4370-9e62-95469b72d904'
              ],
              [
                "==",
                [
                  'get',
                  'BuildingOptions.FuseBoxOption'
                ],
                "MinorChangeNeeded"
              ]
            ],
            [
              "all",
              [
                'completed',
                'db596df9-c3b5-4933-8e72-c0d74e75fb4c'
              ],
              [
                '==',
                [
                  'get',
                  'BuildingOptions.FuseBoxOption'
                ],
                "ReplacementNeeded"
              ]
            ]


            // 'in',
            // [
            //   'get',
            //   'BuildingOptions.FuseBoxOption'
            // ],
            // ['ReplacementNeeded', 'MinorChangeNeeded']
          ] 
        }
      ]
    },
    {
      uuid: '0d667153-2ce1-4052-a348-a3b99d50c167',
      label: 'Opties voor de hoofdverdeelkast',
      short: '3-i',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [
        { type: "step", uuid: '3b670829-24d5-4e3a-bbe0-be3c30648ef8' },
        {
          type: 'data',
          spec: [
            '==',
            [
              'get',
              'BuildingOptions.FuseBoxOption'
            ],
            'ReplacementNeeded'
          ] 
        }
      ]
    },

    // final step, if applicable 
    // {
    //   uuid: '9a8aec5a-6a6f-4ac5-b326-0c5084642e53',
    //   label: 'Aanpassing op de laadpaal aantallen',
    //   short: '3-j',
    //   access: {
    //     read: ['rvb'],
    //     edit: ['rvb']
    //   },
    //   requirements: [
    //     {
    //       type: 'step', uuid: 'none'
    //     }
    //   ]
    //   // Enable based on data requirements
    //   // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
    //   // requirements: [
    //   //   { type: "step", uuid: '858f94f3-d616-4129-a7cc-77b34adedc61' },
    //   //   {
    //   //     type: 'data',
    //   //     spec: [
    //   //       'any',
    //   //       [
    //   //         '==',
    //   //         [
    //   //           'get',
    //   //           'BuildingOptions.PowerOption'
    //   //         ],
    //   //         'ReduceChargePoints'
    //   //       ],
    //   //       [
    //   //         '==',
    //   //         [
    //   //           'get',
    //   //           'BuildingOptions.NewPowerOption'
    //   //         ],
    //   //         'ReduceChargePoints'
    //   //       ]
    //   //     ] 
    //   //   }
    //   // ]
    // },


    /**
     * Step 4
     */
    // {
    //   uuid: 'a067d05a-54da-4e40-9e3f-3db71a332b54',
    //   label: 'Offertes',
    //   short: '4',
    //   access: {
    //     read: ['rvb'],
    //     edit: ['rvb']
    //   },
    //   children: [
    //     'ee4c4d89-f45d-4d62-866c-ee0f28c40e23',
    //     'b865c39a-7781-4af6-af53-67fda5821d32',
    //     'd92d419d-69c7-45ae-8462-c28d69e7534a'
    //   ],
    //   // Starts when step 3 autocompletes
    //   requirements: [{ type: "step", uuid: 'ad21d4ff-65ac-4fa4-8091-eac8c05ef934' }],
    //   // Autocomplete based on last child
    //   autocomplete: [{ type: "step", uuid: 'd92d419d-69c7-45ae-8462-c28d69e7534a' }]
    // },
    
    {
      uuid: 'ee4c4d89-f45d-4d62-866c-ee0f28c40e23',
      label: 'Offertes aanvragen',
      short: '4',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      // Enable based on data requirements
      requirements: [{ type: "step", uuid: 'ad21d4ff-65ac-4fa4-8091-eac8c05ef934' }]
    },
    {
      uuid: 'b865c39a-7781-4af6-af53-67fda5821d32',
      label: 'Offertes ontvangen',
      short: '5',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      // Enable based on data requirements
      requirements: [{ type: "step", uuid: 'ee4c4d89-f45d-4d62-866c-ee0f28c40e23' }]
    },
    {
      uuid: 'd92d419d-69c7-45ae-8462-c28d69e7534a',
      label: 'Offertes goedkeuren',
      short: '6',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      // Enable based on data requirements
      requirements: [{ type: "step", uuid: 'b865c39a-7781-4af6-af53-67fda5821d32' }]
    },

    /**
     * Step 5
     */
    // {
    //   uuid: '56573c31-2009-480e-b3a7-9b4977767df8',
    //   label: 'Realisatie',
    //   short: '5',
    //   access: {
    //     read: ['rvb'],
    //     edit: ['rvb']
    //   },
    //   children: [
    //     '8257e278-2891-475b-ab2e-2963f8712d3a',
    //     '5be227f4-6717-4805-823c-73f3f265ef40'
    //   ],
    //   requirements: [{ type: "step", uuid: 'a067d05a-54da-4e40-9e3f-3db71a332b54' }],
    //   // Autocomplete based on last child
    //   autocomplete: [{ type: "step", uuid: '5be227f4-6717-4805-823c-73f3f265ef40' }] 
    // },
    {
      uuid: '8257e278-2891-475b-ab2e-2963f8712d3a',
      label: 'Orders plaatsen',
      short: '7',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: 'd92d419d-69c7-45ae-8462-c28d69e7534a' }],
    },
    {
      uuid: '5be227f4-6717-4805-823c-73f3f265ef40',
      label: 'Planning',
      short: '8',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '8257e278-2891-475b-ab2e-2963f8712d3a' }]
    },

    /**
     * Step 6
     */
    // {
    //   uuid: '9c8a9245-aa31-4b57-9c65-f2ebdc8b10a5',
    //   label: 'Oplevering en betaling',
    //   short: '6',
    //   access: {
    //     read: ['rvb'],
    //     edit: ['rvb']
    //   },
    //   final: true,
    //   children: [
    //     '7ba9dfd8-a9bc-4a70-a262-46da42992a4f',
    //     'f4e0bb5d-0317-43ea-a534-1b16df038763',
    //     '93645c6c-f832-4457-b7fd-2f59938fbfdd'
    //   ],
    //   // Starts when step 5 autocompletes
    //   requirements: [{ type: "step", uuid: '56573c31-2009-480e-b3a7-9b4977767df8' }],
    //    // Autocomplete based on last child
    //   autocomplete: [{ type: "step", uuid: '93645c6c-f832-4457-b7fd-2f59938fbfdd' }]
    // },

    { uuid: '7ba9dfd8-a9bc-4a70-a262-46da42992a4f',
      label: 'Proces Verbaal van Oplevering (PVO)',
      short: '9',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '5be227f4-6717-4805-823c-73f3f265ef40' }]
    },
    { uuid: 'f4e0bb5d-0317-43ea-a534-1b16df038763',
      label: 'Archiveren van opleveringsdocumenten',
      short: '10',
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: '7ba9dfd8-a9bc-4a70-a262-46da42992a4f' }]
    },
    { uuid: '93645c6c-f832-4457-b7fd-2f59938fbfdd',
      label: 'Betalen facturen',
      short: '11',
      final: true,
      access: {
        read: ['rvb'],
        edit: ['rvb']
      },
      // requirements: [{ type: "step", uuid: '63a4d7f6-f3d1-4030-9637-66386d2f8761' }]
      requirements: [{ type: "step", uuid: 'f4e0bb5d-0317-43ea-a534-1b16df038763' }]
    },
  ]
}

/**
 * Workflow configuration defaults
 *  This does not generate a functional workflow without tenant specific input
 * 
 * @type {object}
 */
 export default {
  tenantName: 'Rijksvastgoedbedrijf',

  /**
   * Feature Access checks
   * 
   * @type {Object}
   */ 
  access: {
    'requestlist': [],
    'requestlocations': [],
    'realisationlist': ['rvb', 'customer', 'caseviewer', 'caseworker', 'admin'],
    'realisationbtn': ['admin'],
    'realisationcomment': ['rvb', 'customers', 'caseworker', 'caseviewer'],
    'canConfirmTrafficDecisions': [],
    'canChangeCommentStatus': ['rvb', 'caseworker'],
    'canModerateComments': ['admin'],
    'canChangeStatus': ['rvb', 'caseworker'],
    'automatedViews': [],
    'generateStartDoc': [],
    'admin': ['admin'],
    'reporting': ['customer', 'caseviewer', 'caseworker', 'admin'],
    'manuals': ['customer', 'caseviewer', 'caseworker', 'admin'],
    'eventlog': [],
    'customerreports': ['customer', 'caseworker', 'admin'],
    'canAccessDebugger': ['developer'],
    'export': ['admin', 'caseworker']
  },

  realisationProcessStatusTranslations: {
    completed: 'Afgerond',
    cancelled: 'Geannuleerd',
    onhold: 'Gepauzeerd',
  },

  
  /** 
   * Judgement Reasons
   * 
   * @type {Object[]}
   */ 
  reasonOptions: [],


  /**
   * Monitoring config
   * 
   * @type {Object}
   */ 
  monitoring: {
    enabled: false,
    limit: 0.5
  },


  /**
   * search
   * 
   * @type {Object}
   */ 
  search: {
    requests: {
      keys: [],
      placeholder: ''
    },
    realisations: {
      keys: [],
      placeholder: ''
    }
  },


  /** 
   * onHold & cancel reasons
   * 
   * @type {Object[]}
   */ 
  onHoldReasons: [
    {
      value: 'Netverzwaring',
      text: 'Netverzwaring'
    },
    {
      value: 'In gesprek met verhuurder',
      text: 'In gesprek met verhuurder'
    },
    {
      value: 'In gesprek met klant',
      text: 'In gesprek met klant'
    },
    {
      value: 'Geen behoeftestelling meer',
      text: 'Geen behoeftestelling meer'
    },
    {
      value: 'other',
      text: 'Overig namelijk ...'
    }
  ],

  /**
   * @type {Object[]}
   */
  cancelReasons: [{
    value: 'Geen behoeftestelling meer',
    text: 'Geen behoeftestelling meer'
  }, {
    value: 'Op afstoot',
    text: 'Op afstoot'
  }, {
    value: 'Wordt voortgezet door consortium',
    text: 'Wordt voortgezet door consortium'
  }, {
    value: 'Wordt voortgezet door verhuurder',
    text: 'Wordt voortgezet door verhuurder'
  }, {
    value: 'Wordt voortgezet door de beheerder',
    text: 'Wordt voortgezet door de beheerder'
  }, {
    value: 'automated-grid',
    text: 'De stroom capaciteit kan niet uitgebreid worden',
  }, {
    value: 'automated-fusebox',
    text: 'Hoofdverdeelkast kan niet aangepast of vervangen worden'
  }, {
    value: 'other',
    text: 'Overig namelijk …'
  }],


  /**
   * Municipality Options
   * 
   * @type {Object[]}
   */
  municipalityOptions: [],


  /** 
   * Steps 
   * 
   * @type {Object[]}
   */
  steps: spec.steps,

  stepComponents: {
    '63a4d7f6-f3d1-4030-9637-66386d2f8761': require('@/views/rvb/RealisationStep1'),
    '858f94f3-d616-4129-a7cc-77b34adedc61': require('@/views/rvb/RealisationStep2'),
    'ad21d4ff-65ac-4fa4-8091-eac8c05ef934': require('@/views/rvb/RealisationStep3'),
    // 'a067d05a-54da-4e40-9e3f-3db71a332b54': require('@/views/rvb/RealisationStep4'),
    // '56573c31-2009-480e-b3a7-9b4977767df8': require('@/views/rvb/RealisationStep5'),
    // '9c8a9245-aa31-4b57-9c65-f2ebdc8b10a5': require('@/views/rvb/RealisationStep6'),
  
    // 3
    '8f8d15d6-43b5-4370-9e62-95469b72d904': require('@/views/rvb/RealisationStep3A'),
    'e430f07b-253f-47d7-af14-bc9680b8f3d7': require('@/views/rvb/RealisationStep3B'),
    'd564ef80-0e65-4991-89b7-e1526e5b7fb7': require('@/views/rvb/RealisationStep3C'),
    '2edafe45-0dc2-43cc-9e20-98a7e7cf2192': require('@/views/rvb/RealisationStep3D'),
    '52d10b34-a4a7-493f-8d4d-0e2cb8f0748f': require('@/views/rvb/RealisationStep3E'),
    '87573a4c-214f-4031-8efa-def519f067de': require('@/views/rvb/RealisationStep3F'),
    'db596df9-c3b5-4933-8e72-c0d74e75fb4c': require('@/views/rvb/RealisationStep3G'),
    '3b670829-24d5-4e3a-bbe0-be3c30648ef8': require('@/views/rvb/RealisationStep3H'),
    '0d667153-2ce1-4052-a348-a3b99d50c167': require('@/views/rvb/RealisationStep3I'),
    '9a8aec5a-6a6f-4ac5-b326-0c5084642e53': require('@/views/rvb/RealisationStep3J'),
  
    // 4
    'ee4c4d89-f45d-4d62-866c-ee0f28c40e23': require('@/views/rvb/RealisationStep4'),
    'b865c39a-7781-4af6-af53-67fda5821d32': require('@/views/rvb/RealisationStep5'),
    'd92d419d-69c7-45ae-8462-c28d69e7534a': require('@/views/rvb/RealisationStep6'),
  
    // 5
    '8257e278-2891-475b-ab2e-2963f8712d3a': require('@/views/rvb/RealisationStep7'),
    '5be227f4-6717-4805-823c-73f3f265ef40': require('@/views/rvb/RealisationStep8'),
  
    // 6
    '7ba9dfd8-a9bc-4a70-a262-46da42992a4f': require('@/views/rvb/RealisationStep9'),
    'f4e0bb5d-0317-43ea-a534-1b16df038763': require('@/views/rvb/RealisationStep10'),
    '93645c6c-f832-4457-b7fd-2f59938fbfdd': require('@/views/rvb/RealisationStep11')
  },


  /**
   * Admin default menu
   * 
   * @type {Object[]}
   */
  AdminMenu: [
    {
      type: 'segment',
      label: 'Configuratie',
      items: [{
        type: 'link',
        label: 'Beschikbare tags',
        to: {
          name: 'TagsConfig'
        }
      }, 
      {
        type: 'link',
        label: 'PIDs',
        to: {
          name: 'PidsConfig'
        }
      }]
    }
  ],
  /**
   * Reporting default menu
   * 
   * @type {Object[]}
   */
  ReportingMenu: [
    { 
      type: 'link',
      label: 'Voor klanten',
      to: {
        name: 'RVBCustomers'
      },
      securityCheck: 'customerreports'
    },
    {
      type: 'segment',
      label: 'Laadpunten',
      items: [ {
        type: 'link',
        label: 'Aantal laadpunten',
        to: {
          name: 'RVBChargingpointCount'
        }
      }, {
        type: 'link',
        label: 'Laadpunten per stelsel',
        to: {
          name: 'RVBChargingpointsByObjectType'
        }
      }, {
        type: 'link',
        label: 'Laadpunten per regio',
        to: {
          name: 'RVBChargingpointsByRegion'
        }
      }, {
        type: 'link',
        label: 'Laadpunten per PID',
        to: {
          name: 'RVBChargingpointsByPID'
        }
      }]
    }, {
      type: 'segment',
      label: 'Proces informatie',
      items: [
      // {
      //   type: 'link',
      //   label: 'Status overzicht',
      //   to: {
      //     name: 'RealisationProcessStatus'
      //   }
      // }, 
      {
        type: 'link',
        label: 'Aantal netverzwaringen',
        to: {
          name: 'RVBGridImprovementCount'
        }
      }]
    }
  ],


  /**
   * Manuals menu
   * 
   * @type {Object[]}
   */
  ManualsMenu: [
    {
      type: 'segment',
      label: 'Workflow handleiding',
      items: [
        { 
          type: 'link',
          label: 'De overzichtspagina',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-realisatieprocessen-overzicht' }
          },
          securityCheck: 'manuals'
        },
        { 
          type: 'link',
          label: 'Doorlopen van stappen',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-de-stappen-van-het-realisatieproces-doorlopen' }
          },
          securityCheck: 'manuals'
        },
        { 
          type: 'link',
          label: 'Opmerkingen plaatsen',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-opmerkingen-plaatsen' }
          },
          securityCheck: 'manuals'
        },
        { 
          type: 'link',
          label: 'Corrigeren en wijzigen',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-corrigeren-en-wijzigen' }
          },
          securityCheck: 'manuals'
        },
        { 
          type: 'link',
          label: 'PID aanpassen',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-pid-nummer-van-een-aanvraag-aanpassen' }
          },
          securityCheck: 'manuals'
        },
        { 
          type: 'link',
          label: 'Rapportage',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-rapportage' }
          },
          securityCheck: 'reporting'
        },
      ],
    },
    {
      type: 'segment',
      label: 'Voor beheerders',
      items: [
        { 
          type: 'link',
          label: 'Nieuwe PID',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-nieuwe-pid-nummers-aanmaken' }
          },
          securityCheck: 'admin'
        },
        { 
          type: 'link',
          label: 'Nieuw realisatieproces',
          to: {
            name: 'ManualPage',
            params: { slug: 'rvb-nieuw-realisatieproces-aanmaken' }
          },
          securityCheck: 'realisationbtn'
        },
      ],
    },
  ],
}
