
/**
 * Amsterdam Realisation
 */
 let Realisation = function ({
  ref, data
}) {

  data = this.commonStructure({ ref, data })
  data.tenant = 'amsterdam'

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Realisation.prototype.generateMunicipalityData = () => {
  return '0363'
}

Realisation.prototype.generateAddressData = ({ model }) => {
  const CurrentLocation = model.CurrentLocation

  if (CurrentLocation.Location && CurrentLocation.Location.StreetName) {
    return `${CurrentLocation.Location.StreetName} ${CurrentLocation.Location.HouseNumber} ${CurrentLocation.Location.HouseNumberSuffix}`.replace('  ', ' ')
  }
  return ''
}

Realisation.prototype.generateSearchData = () => {
  return {
    City: "Amsterdam",
    Municipality: "amsterdam"
  }
}


export default Realisation;