import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// models
import Realisation from './models/Realisation'
import Location from './models/Location'
import Request from './models/Request'

// Polyfill Fetch
import 'whatwg-fetch'

// Polyfill Buffer
if (! window.Buffer) {
  window.Buffer = require('buffer').Buffer
}

// AlertPlugin, ButtonPlugin, IconsPlugin, InputGroupPlugin, FormInputPlugin, FormSelectPlugin, ListGroupPlugin, NavPlugin, PaginationPlugin
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'

import './assets/sass/bootstrap.scss'

import './assets/sass/index.scss'

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

// Auth0 - TODO: Move plugin code to npm module
import { Auth0Plugin } from "./auth";
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH_DOMAIN,
  clientId: process.env.VUE_APP_AUTH_CLIENTID,
  audience: process.env.VUE_APP_AUTH_AUDIENCE,
  onRedirectCallback: appState => {
    router.push(
      appState && appState.targetUrl
        ? appState.targetUrl
        : window.location.pathname
    )
  }
})

/**
 * Init Geocoder
 */

import { initGoogleAPILoader } from '@/services/geocoder'
initGoogleAPILoader()

import VueScrollTo from 'vue-scrollto'
Vue.use(VueScrollTo)

const instance = new Vue({
  router,
  store,
  render: h => h(App),
})

// bind auth0 Plugin to the store, so we can retrieve there the token as well
instance.$store.$auth = instance.$auth

// bind store to models
Realisation.prototype.$store = instance.$store
Location.prototype.$store = instance.$store
Request.prototype.$store = instance.$store

instance.$mount('#app')
