/**
 * Import Dependency
 */

/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  chargingpoints: [],
  chargingpointCache: {}
}

const getters = {
  /**
   * Chargingpoints
   */
  chargingpoints: (state) => state.chargingpoints,

  getChargingPointByUUID: (state) => ({ uuid }) => {
    if (! uuid) return null

    let match = state.chargingpoints.find(charger => {
      return charger.data.uuid === uuid
    })
    return match ? match : null
  },

  
}
const actions = {

}
const mutations = {
  /**
   * Chargingpoints
   */
  setChargingPoints(state, { chargingpoints }) {
    state.chargingpoints = chargingpoints

    // TODO: Replace & use cache
    // state.chargingpointCache = chargingpoints.reduce(( result, point) => {
    //   let uuid = point.data.uuid 
    //   result[uuid] = {
    //     address: ''
    //   }
    //   return result
    // }, {})
  },

  /**
   * Replace all chargingpoints for a municipality by municipality code
   *  If none are set, it simply adds the additional chargingpoints to the collection
   */
  replaceChargingPointsByCode(state, { chargingpoints, code }) {
    state.chargingpoints = (state.chargingpoints || [])
      .filter(location => location.data.code !== code)
      .concat(chargingpoints)


    
  },

  replaceLocation(state, { location }) {
    let recordIndex = state.chargingpoints.findIndex(current => current.data.uuid === location.data.uuid)
    
    if (recordIndex !== -1) {
      state.chargingpoints.splice(recordIndex, 1, location)
    }
  },
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
