<template>
  <div id="app" v-if="isAuthenticated && loaded">
    <PageNav />

    <Overlay class="MainContent">
      <div class="mx-auto mt-5" style="width: 325px" v-if="isGuest">
        <h4>U heeft geen toegang</h4>
        <p>
          Uw gebruikersaccount heeft geen rechten toegewezen gekregen in de Workflow tool.
        </p>
        <p>
          Onze support afdeling kan u verder helpen indien dit onterecht is.
        </p>
        <p>
          <a href="mailto:support@evtools.nl">
            support@evtools.nl
          </a>
        </p>
      </div>
      <router-view v-else id="scroll-container" />
    </Overlay>

    <PageFooter />

    <!-- <SessionModal /> -->
    
  </div>
  <div id="app" v-else class="LoadingScreen">
    <div class="MainContent">
      <div class="pt-4">
        <div class="m-auto" style="width: 160px">
          <EVToolsLogo class="" />
        </div>
        <b-card 
          class="m-auto" 
          style="width: 275px" 
          title="EVtools Workflow"
          :sub-title="subTitle">

          <div v-if="userRolesAreAvailable" class="LoadingScreen__Status mt-3">
            <div class="d-flex justify-content-between">
              <span>Configuratie...</span> 
              <span class="done" v-if="loadingStatus.config">Done</span>
              <b-spinner variant="secondary" small v-else />
            </div>
            <div class="d-flex justify-content-between">
              <span>Aanvragen...</span> 
              <span class="done" v-if="loadingStatus.requests">Done</span>
              <b-spinner variant="secondary" small v-else />
            </div>
            <div class="d-flex justify-content-between">
              <span>Realisatie processen...</span> 
              <span class="done" v-if="loadingStatus.workflows">Done</span>
              <b-spinner variant="secondary" small v-else />
            </div>
            <div class="d-flex justify-content-between">
              <span>Laadpaal datasets... </span>
              <span v-if="this.loadingStatus.municipalities.target !== 0">
                <span>{{ loadingStatus.municipalities.current }} / {{ loadingStatus.municipalities.target }}</span>
              </span>
              <b-spinner variant="secondary" small v-else />
            </div>
          </div>

          <div v-if="missingMunicipalities.length">
            <p class="pt-4">
              Het laden van {{ loadingStatus.municipalities.error }} laadpaal dataset(s) is mislukt.
            </p>
            <b-button v-if="! loading" variant="primary" @click="handleRetryMunicipalities">
              Probeer deze opnieuw
            </b-button>
          </div>

          <template #footer>
            <div style="font-size: 0.9rem;">
              <b-icon icon="envelope" font-scale="0.9" /> <a class="card-link" href="mailto:support@evtools.nl">support@evtools.nl</a>
            </div>
          </template>
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';

import EVToolsLogo from '@/components/EVToolsLogo.vue'
import PageFooter from "@/components/PageFooter.vue";
import PageNav from "@/components/PageNav.vue";
import Overlay from '@/components/Overlay.vue';
// import SessionModal from '@/components/SessionModal.vue'

import { staticGeocode } from '@/services/geocoder'
import { municipalityOptions } from '@/config'


export default {
  name: "App",
  components: {
    EVToolsLogo,
    PageNav, PageFooter, Overlay
    // SessionModal
  },
  data() {
    return {
      loading: false,
      loadingStatus: {
        config: false,
        requests: false,
        workflows: false,
        municipalities: {
          error: 0,
          target: 0,
          current: 0
        }
      },
      missingMunicipalities: []
    }
  },
  computed: {
    ...mapGetters(['loaded']),
    ...mapGetters('requests', [
      'requestsWithoutCoordinates',
      'requestByUuid'
    ]),
    ...mapGetters('realisation', {
      'realisationprocesses' : 'records'
    }),
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRealisationProcesses',
      'isGuest'
    ]),
    ...mapGetters('tenant', ['getTenant']),
    isAuthenticated() {
      // console.log(this.$auth)
      return this.$auth.isAuthenticated && this.$auth.user
    },
    userRolesAreAvailable() {
      return this.isAuthenticated && this.$auth.user[`${process.env.VUE_APP_AUTH_NAMESPACE}roles`]
    },
    subTitle() {
      return this.userRolesAreAvailable 
        ? 'De datasets worden ingeladen'
        : 'Authorisatie controle'
    }
  },
  watch: {
    userRolesAreAvailable() {
      if (this.userRolesAreAvailable && ! this.loaded) {
        this.init()
      }
    },

    loaded() {
      // Trigger Geocoder for requests without coordinates
      if (this.loaded) {
        this.runGeocoder()

        this.updateAddressData()
      }
    }
  },
  methods: {
    ...mapActions('tenant', [
      'loadConfig',
      'loadWorkflowSpecs'
    ]),
    ...mapActions('realisation', { 
      'loadRealisations': 'loadRecords'
    }),
    ...mapActions('requests', [
      'loadRequests'
    ]),
    ...mapMutations([
      'setLoadedState'
    ]),
    ...mapMutations('requests',[
      'updateRequest'
    ]),
    ...mapMutations('relations', [
      'addConnection'
    ]),
    ...mapMutations('user', [
      'setRoles'
    ]),
    ...mapMutations('chargingpoints', [
      'replaceChargingPointsByCode'
    ]),
    init() {
      if (this.loading || this.loaded) return

      this.loading = true
      this.loadRoles()
      this.$nextTick(this.loadData)
    },
    loadRoles() {
      let roles = this.$auth.user[`${process.env.VUE_APP_AUTH_NAMESPACE}roles`] || {}

      // console.log(roles)

      // Older users have only one set of roles, new users have roles per tenant
      if (Array.isArray(roles)) {
        roles = roles.find(role => role.tenant === process.env.VUE_APP_TENANT)
      }

      if (! roles) {
        roles = {
          tenant: process.env.VUE_APP_TENANT,
          admin: [],
          cpo: [],
          municipality: []
        }
      }

      this.setRoles({
        roles
      })
    },
    loadData: async function() {
      const token = await this.$auth.getTokenSilently();
      
      // We need the tenant config & workflow specs while processing the other data
      await this.loadConfig({ token })
      this.setPageTitle()

      await this.loadWorkflowSpecs({ token})
      this.loadingStatus.config = true

      // We can get the other data simultaneously  
      await Promise.all([
        this.loadRequests({ token }).then(() => {
          this.loadingStatus.requests = true
        }), 
        this.loadRealisations({ token }).then(() => {
          this.loadingStatus.workflows = true
        })
      ])
      this.matchRequestToRealisations()

      // This can be done in the background
      await this.loadChargingPoints()

      if (this.missingMunicipalities.length === 0) {
        this.setLoadedState({ loaded: true })
      }
      this.loading = false
    },
    /**
     * Go through the realisation process objects and connect the request objects
     */
    matchRequestToRealisations() {
      if (! this.canAccessRequests && ! this.canAccessRealisationProcesses) return 
      // if (! this.isMunicipality) return 

      (this.realisationprocesses || []).forEach(record => {
        (record.requestUuids || []).forEach(uuid => {
          this.addConnection({ 
            requestUuid: uuid,  
            realisationUuid: record.uuid
          })
        })
      })
    },

    /**
     * Geocode requests
     *  TODO: Temporary solution while Request form doesn't have a Geocoder.
     */
    runGeocoder: async function() {
      if (! this.canAccessRequests) return 

      for (let i = 0; i < this.requestsWithoutCoordinates.length; i++) {
        let request = this.requestsWithoutCoordinates[i]
        let coordinates = await staticGeocode({ components: request.address })
        
        let iteration = coordinates.iteration
        coordinates = coordinates.result
        
        if (coordinates || coordinates === null) {
          if (coordinates === null) {
            coordinates = {
                lat: null,
                lng: null,
                nomatch: true,
                iteration
              }
          } else {
            coordinates = Object.assign({ nomatch: false, iteration }, coordinates)
          }
          const payload = {
            ref: request.ref,
            data: {
              coordinates
            }
          }
          const token = await this.$auth.getTokenSilently();
          const api = await fetch('/api/requestcoordinates', {
            method: 'POST',
            headers: {
              Authorization: `Bearer ${token}`
            },
            body: JSON.stringify(payload)
          })
          if (api.ok) {
            this.updateRequest({
              ref: payload.ref,
              data: {
                coordinates
              }
            })
          }
        }
      }
    },

    /**
     * Load chargingpoints for all active municipalities
     *  One by one, but all at once
     */
    async loadChargingPoints() {
      let codes = municipalityOptions
        .filter(muni => muni.disabled === false)
        .map(muni => muni.value)

      this.loadingStatus.municipalities.target = codes.length

      await Promise.all(
        codes.map(code => {
          return this.loadChargingPointsByCode({ code })
            .then(() => {
              this.loadingStatus.municipalities.current++
            })
            .catch(() => {
              this.loadingStatus.municipalities.error++
              this.missingMunicipalities.push(code)
            })
        })
      )
    },

    /**
     * 
     */
    async loadChargingPointsByCode({ code }) {
      const token = await this.$auth.getTokenSilently();
      const api = await fetch('/api/chargingpoints', {
        method: "POST",
        headers: {
          authorization: 'Bearer ' + token
        },
        body: JSON.stringify({
          codes: [ code ]
        })
      });
      if (! api.ok) {
        throw new Error("failed")
      }
      const response = await api.json()

      if (response.data && response.data.chargingpoints) {
        this.replaceChargingPointsByCode({ code, chargingpoints: response.data.chargingpoints })
      }
      return response
    },
    async handleRetryMunicipalities() {
      this.loading = true
      let failed = []
      await Promise.all(
        this.missingMunicipalities.map(code => {
          return this.loadChargingPointsByCode({ code })
            .then(() => {
              this.loadingStatus.municipalities.current++
              this.loadingStatus.municipalities.error--
            })
            .catch(() => {
              failed.push(code)
            })
        })
      )
      this.missingMunicipalities = failed

      if (this.missingMunicipalities.length === 0) {
        this.setLoadedState({ loaded: true })
      }
      this.loading = false
    },
    updateAddressData() {
      this.realisationprocesses.forEach(record => {
        record.address = record.generateAddressData({ model: record })
      })
    },

    /**
     * Set the page title (seen in browser tabs)
     */
    setPageTitle() {
      let title = null
      switch(this.getTenant) {
        case 'rvb': 
          title = 'RVB - Realisatieportaal'
          break
      }
      
      if (title) {
        document.title = title
      }
    }
    
  }
};
</script>

<style lang="scss">
html, body {
  // height: 100%;
  margin: 0;
  padding: 0;
}
html {
  overflow-y: scroll;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  color: #2c3e50;
  padding: 0;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100%;
  height: 100%;
  max-height: 100%;
  overflow: hidden;
}
.MainContent {
  // overflow-y: scroll;
  min-height: 100vh;
  padding-top: 52px; // 52px offset for the header
  padding-bottom: 25px; // 25px offset for footer
}
.LoadingScreen {
  background: #041E42;

  .card {
    box-shadow: 0 0 40px 4px #111118;
  }

  &__Status .done {
    color: #00B46B;
  }
}
</style>
