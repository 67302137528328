<template>
  <div class="ChargingSituation">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @blur="handleBlur"
      @input="handleInput"
    />
    <Parkingspots 
      :values="values" 
      :path="IdentifiersPath" 
      :disabled="disabled" />
  </div>
</template>

<script>
import Parkingspots from '@/components/definitions/Parkingspots'
import FieldSet from '@/components/form/FieldSet'

import { required, helpers } from 'vuelidate/lib/validators'
import { isNumeric } from '@/services/validation'


export default {
  components: { FieldSet, Parkingspots },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'ParkingSpots'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      fields: {
        "Type": {
            "type": "string",
            "title": "Laadsituatie",
            "enumNames": [
                "Laadpaal",
                "Laadcluster"
            ],
            "enum": [
                "station",
                "hub"
            ]
        },
        "NumberOfChargingStations": {
            "type": "number",
            "title": "Aantal laadpalen"
        },
        "NumberOfParkingSpots": {
            "type": "number",
            "title": "Aantal parkeerplaatsen"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'Type' ]
      }, {
        "columns": '1fr 1fr',
        "fields": [ 'NumberOfChargingStations', 'NumberOfParkingSpots' ]
      },
      //  {
      //   "columns": '1fr',
      //   "fields": [  ]
      // }
      ],
      validation: {
        "Type": {
          required,
          validoption: (value) => !helpers.req(value) || ['station', 'hub'].includes(value)
        },
        "NumberOfParkingSpots": {
          required, 
          isNumeric
        }, 
        "NumberOfChargingStations": {
          required, 
          isNumeric,
          // If the situation is a hub, then enforce a minimum of 3 ChargingStations
          // TODO: Figure out a way to refresh validation, without 'manual' intervention
          // minimum: (value) => {
          //   if ((this.internalData['ParkingSpots.Type'] || this.values.ParkingSpots.Type) === 'hub') {
          //     return parseInt(value, 10) >= 3
          //   }
          //   return true
          // }
        }
      },
      internalData: {}
    }
  },
  computed: {
    IdentifiersPath() {
      return this.path === '' ? 'Identifiers' : `${this.path}.Identifiers`
    }
  },
  methods: {
    handleInput({ name, value }) {
      // console.log("input", name, value)
      this.internalData[name] = value

      this.$emit('input', { name, value })
    },
    handleBlur({ name }) {
      this.$emit('blur', { name })
    },
  }
}
</script>

<style>

</style>