<template>
  <div class="QuotationRequest">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isUrl } from '@/services/validation'

export default {
  name: 'QuotationRequest',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Progress'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "SiteReportLink": {
            "type": "string",
            "format": "url",
            "title": "Link naar het schouwverslag",
            "readonly": true
        },
        "EmailSentDate": {
            "type": "string",
            "format": "date",
            "title": "Datum van offerteverzoek",
            "description": "Datum dat de e-mail is verzonden aan de leveranciers die een offerte moeten uitbrengen"
        }
      },
      layout: [{
        "columns": '1fr 1fr',
        "fields": [ 'EmailSentDate' ]
      }, {
        "columns": '1fr',
        "fields": [ 'SiteReportLink' ]
      }],
      validation: {
        SiteReportLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  }
}
</script>