<template>
  <div class="BlockRepeaterItem" :class="{ inline, border, divider, compact, permanent, deleted }">
    <div class="BlockRepeaterItem__body">

      <slot v-bind:id="id" />

    </div>
    <footer v-if="!viewer" class="BlockRepeaterItem__footer">
      <!-- <b-button variant="primary" size="sm" class="mr-2">Opslaan</b-button> -->
      <b-button variant="warning" :size=" inline ? '' : 'sm'" @click="deleteItem" :disabled="disabled || permanent">
        <span v-if="!inline">Verwijderen</span>
        <b-icon icon="trash" />
      </b-button>
    </footer>
  </div>
</template>

<script>
export default {
  name: "BlockRepeaterItem",
  props: {
    id: {
      type: Number,
      required: true
    },
    inline: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    divider: {
      type: Boolean,
      default: false
    },
    compact: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    permanent: {
      type: Boolean,
      default: false
    },
    deleted: {
      type: Boolean,
      default: false
    },
    /**
     * Viewer (no UI)
     */
    viewer: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    deleteItem(event) {
      if (this.permanent) return false

      event.preventDefault();
      this.$emit('delete')
    }
  }
};
</script>

<style lang="scss">
.BlockRepeaterItem {
  border-radius: 3px;
  border: 1px solid $gray-400;
  display: block;
  margin-bottom: $padding;
  max-width: 100%;

  &__body {
    padding: $padding;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    @include sidePad();
    padding-top: $padding-sm;
    padding-bottom: $padding-sm;
    border-top: 1px solid $gray-400;

    .disabled {
      cursor: not-allowed;
    }
  }

  &.inline {
    border: none;
    margin: 0;
    display: flex;
    align-items: flex-end;
    
    .BlockRepeaterItem__body {
      padding: 0;
    }

    .BlockRepeaterItem__footer {
      padding: 0;
      padding-bottom: $padding-sm;
      padding-left: $padding-sm;
      border: none;
    }
  }
  &.border.inline {
    border-radius: 3px;
    border: 1px solid $gray-400;

    .BlockRepeaterItem__body {
      padding: $padding-sm;
    }
    .BlockRepeaterItem__footer {
      padding-bottom: $padding-sm;
      padding-right: $padding-sm;
    }
  }
  &.inline.divider:last-child {
    border-top: 1px solid $gray-400;
    padding-top: $padding-sm;
  }
  &.compact {
    .FormField input, .FormField select {
      min-height: auto !important;
    }
  }

  &.deleted {
    display: none !important;
  }

  // &.permanent {
  //   .BlockRepeaterItem__footer {
  //     display: none;
  //   }
  // }
}
</style>