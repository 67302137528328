<template>
  <div>
    <span v-if="relatedRealisationUuid">
      {{ relatedRealisationCaseRef }}
    </span>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  props: {
    value: {
      type: [String, Number],
      default: 0
    }
  },
  computed: {
    ...mapGetters('realisation',[
      'recordByUuid'
    ]),
    ...mapGetters('relations', [
      'realisationUuidByRequestUuid'
    ]),
    relatedRealisationUuid() {
      return this.realisationUuidByRequestUuid({ uuid: this.value })
    },
    relatedRealisationCaseRef() {
      return this.recordByUuid({ uuid: this.relatedRealisationUuid }).case_ref
    }
  }
}
</script>

<style>

</style>