<template>
  <div class="CPOAdvice">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      @input="handleInput"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CPOAdvice',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'CPO Advies'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'CPOAdvice'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "CPOAdvice": {
            "type": "string",
            "title": "",
            "enumNames": [
              "Positief",
              // "Negatief",
              "Alternatieve locatie"
            ],
            "enum": [
              'yes',
              // 'no',
              'alternative'
            ],
            'format': 'radio'
        },
        "Contractor": {
          "type": "string",
          "title": "Uitvoerder"
        },
        "CPOComments": {
            "type": "string",
            "title": "Toelichting",
            "format": "textarea"
        }
      },
      layout: [{
        "columns": '1fr',
        "fields": [ 'CPOAdvice' ]
      },{
        "columns": '1fr',
        "fields": [ 'CPOComments' ]
      }, {
        "columns": "1fr",
        "fields": [ 'Contractor' ]
      }],
      validation: {
        CPOAdvice: {
          required
        }
      }
    }
  },
  methods: {
    handleInput({ name, value }) {
      if (name === `${this.path}.CPOAdvice`) {
        this.$emit('advice', { value })
      }
    },
  }
}
</script>