<template>
  <div class="UpdatedAt">
    {{ value.date }} - {{ value.time }}
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
.UpdatedAt {
  display: flex;
  // justify-content: space-between;
  width: 100%;
}
</style>