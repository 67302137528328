/**
 * Import Dependency
 */
import { navigationConfig } from "@/config"

/**
 * Does the active user have access to a feature?
 * 
 * @param {*} param0 
 * @returns 
 */
const securityCheck = ({ state, attribute }) => {
  let accepted = navigationConfig[attribute] || []

  if (! Array.isArray(accepted) || accepted.length === 0) {
    return false
  }

  return accepted.some(role => state.roles.includes(role))
}

/**
 * Does any user have access to a feature?
 * 
 * @param {*} param0 
 * @returns 
 */
const someoneHasAccess = ({ attribute }) => {
  let accepted = navigationConfig[attribute] || []
  return (Array.isArray(accepted) && accepted.length !== 0)
}



/**
 * Import API
 */

/**
 * Declare Variable
 */
const state = {
  
  roles: [],

  roleDetails: {},


  /**
   * Remaining time on idToken in seconds
   */
  remainingTime: null,

  /**
   * Remaining time on a fresh idToken.
   */
  initialRemainingTime: null
}

const getters = {

  // isAdmin: state => !! state.isAdmin,
  // isCPO: state => !! state.isCPO,
  // isMunicipality: state => !! state.isMunicipality,
  // isRVB: state => !! state.isRVB,
  // isCustomer: state => !! state.isCustomer,
  // isCaseviewer: state => !! state.isCaseviewer,
  // isCaseworker: state => !! state.isCaseworker,

  hasRole: state => ({ role }) => state.roles.includes(role),
  
  /**
   * A user is a guest when a user has no particular rights at all, or only the role "guest".
   */
  isGuest: state => state.roles.length === 0 || (state.roles.length === 1 && state.roles[0] === 'guest'),

  roleDetails: state => state.roleDetails,
  roleDetailsByRole: state => ({ role }) => state.roleDetails[role] || [],

  roleName: state => {

    let names = []

    if (state.roles.includes('municipality')) {
      names.push('gemeente')
    }
    if (state.roles.includes('cpo')) {
      names.push('CPO')
    }
    if (state.roles.includes('admin')) {
      names.push('beheerder')
    }

    if (state.roles.includes('rvb')) {
      names.push('RVB medewerker')
    }

    // RVB
    if (state.roles.includes('customer')) {
      names.push('klant')
    }
    if (state.roles.includes('caseworker')) {
      names.push('medewerker')
    }
    if (state.roles.includes('caseviewer')) {
      names.push('bezoeker')
    }


    // Doesn't actually exist, but we need a fallback that implies no rights are provided
    if (names.length === 0) {
      return 'bezoeker'
    }

    if (names.length === 1) {
      return names[0]
    }

    if (names.length === 2) {
      return names.join(' en ')
    }

    let last = names.pop()
    return `${names.join(', ')} en ${last}`
  },

  
  canAccessRequests: state => securityCheck({ state, attribute: 'requestlist' }),
  canAccessRequestLocations: state => securityCheck({ state, attribute: 'requestlocations' }),
  canAccessRealisationProcesses: state => securityCheck({ state, attribute: 'realisationlist' }),
  canStartRealisationProcess: state => securityCheck({ state, attribute: 'realisationbtn' }),
  canChangeStatus: state => securityCheck({ state, attribute: 'canChangeStatus' }),
  canChangeCommentStatus: state => securityCheck({ state, attribute: 'canChangeCommentStatus' }),
  canModerateComments: state => securityCheck({ state, attribute: 'canModerateComments' }),
  canAutomateViews: state => securityCheck({ state, attribute: 'automatedViews' }),
  canGenerateStartDoc: state => securityCheck({ state, attribute: 'generateStartDoc' }),
  canAccessConfig: state => securityCheck({ state, attribute: 'admin' }),
  canAccessReporting: state => securityCheck({ state, attribute: 'reporting' }),
  canAccessManuals: state => securityCheck({ state, attribute: 'manuals' }),
  canAccessEventLog: state => securityCheck({ state, attribute: 'eventlog' }),
  canConfirmTrafficDecisions: state => securityCheck({ state, attribute: 'canConfirmTrafficDecisions' }),

  securityCheck: state => ({ attribute }) => securityCheck({ state, attribute }),
  someoneHasAccess: () => ({ attribute }) => someoneHasAccess({ attribute }),
  canAccessExport: state => securityCheck({ state, attribute: 'export' }),

  /**
   * Remaining time on idToken in seconds
   */
  getRemainingTime: state => state.remainingTime,
  belowSessionWarningTreshold: state => state.remainingTime < 300, // 5 minutes
  getInitialRemainingTime: state => state.initialRemainingTime
}
const actions = {

}
const mutations = {
  setRoles(state, { roles }) {
    // console.log("roles", roles)

    state.roles = Object
      .keys(roles)
      .filter(role => (Array.isArray(roles[role]) && roles[role].length !== 0) || roles[role] === true)

    // Raw role data
    state.roleDetails = roles
  },
  /**
   * Remaining time on idToken in seconds
   */
  setRemainingTime(state, { remainingTime }) {
    if (! remainingTime && remainingTime !== 0) return 

    state.remainingTime = remainingTime

    if (state.initialRemainingTime === null) {
      state.initialRemainingTime = remainingTime
    }
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
