
import { commonFilterWrapper } from '../utils'

export default {
  
  /**
   * Config reference
   * @type {string}
   */
  uuid: 'b8457838-bb24-4a90-8d8f-a12c2a02d075',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'rvb-customer-reporting',

  /**
   * Access requirements
   */
  access: {
    requirements: [],
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RVBControls',


  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 100,

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: [
          "Customer"
        ],
        columns: '150px',
        area: `"Customer"`
      },
      header: { // copied to row
        properties: [
          "PIDNumber", "StreetName", "RVBRegion", "RegionalProjectLeader", "ObjectManager", "AssetManager",
          "NumberOfExistingChargePoints", "ChargePointNeedsGovernmentOffices", "ExtraNeedChargePoints",
          "PowerOption", "FuseBoxOption", "AllPartiesSignedDate"
        ],
        columns: '175px 4fr 3fr 400px',
        area: '"PIDNumber RegionalProjectLeader NumberOfExistingChargePoints AllPartiesSignedDate" '
          + ' "RVBRegion ObjectManager ChargePointNeedsGovernmentOffices PowerOption" ' 
          + ' "StreetName AssetManager ExtraNeedChargePoints FuseBoxOption" '
          // 
        // area: '"PIDNumber StreetName RVBRegion" "RegionalProjectLeader ObjectManager AssetManager" "NumberOfExistingChargePoints ChargePointNeedsGovernmentOffices"  "PowerOption FuseBoxOption AllPartiesSignedDate"'
      },
    }
  },


  headerLabels: {
    PIDNumber: 'PID',
    StreetName: 'Straatnaam',
    RVBRegion: 'Regio',

    RegionalProjectLeader: 'RPL',
    ObjectManager: 'Object Manager',
    AssetManager: 'Asset Manager',

    NumberOfExistingChargePoints: 'Bestaande laadpunten',
    ChargePointNeedsGovernmentOffices: '4% behoefte',
    ExtraNeedChargePoints: 'Extra behoefte',

    PowerOption: 'Conclusie over de capaciteit voor de laadpunten',
    FuseBoxOption: 'Opties voor de hoofdverdeelkast',
    AllPartiesSignedDate: 'Datum van PVO ondertekening'
  },
  cellComponents: {
    PIDNumber: null,
    StreetName: null,
    RVBRegion: null,

    RegionalProjectLeader: null,
    ObjectManager: null,
    AssetManager: null,

    NumberOfExistingChargePoints: null,
    ChargePointNeedsGovernmentOffices: null,
    ExtraNeedChargePoints: null,

    PowerOption: null,
    FuseBoxOption: null,
    AllPartiesSignedDate: null
  },


  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'string',
      sortBy: 'PIDNumber',
      direction: 'up'
    },
    PIDNumber: {
      type: 'string',
      sortBy: "PIDNumber"
    }
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {
    

    return Object.assign(record, {
      /** 
      * Filter params
      */ 
      filteredOut: false,
      filterReason: null
    }, {
      categories: {
        all: true,
        actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

        gepauzeerd: record.status.onhold === true && record.status.cancelled !== true,
        afgerond: record.status.completed === true,
        geannulleerd: record.status.cancelled === true
      },
    })
  },


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    Customer: {
      cost: 1,
      id: 'Customer',
      label: 'Klant',
      position: '',
      componentName: 'RVBCustomerFilter', 
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.Customer === value
    },

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
     category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          layout: 'default'
        }, {
          text: 'Actief',
          value: 'actief'
        }, {
          text: 'Gepauzeerd',
          value: 'gepauzeerd'
        }, {
          text: 'Afgerond',
          value: 'afgerond'
        }, {
          text: 'Geannuleerd',
          value: 'geannulleerd'
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },

}