<template>
  <div style="display:none"></div>
</template>

<script>

import MapBoxMixin from '@/components/common/MapBoxMixin'
import {mapActions, mapGetters, mapMutations} from 'vuex'

import { monitoringConfig } from '@/config'

export default {
  name: 'LayerMonitoring',
  mixins: [ MapBoxMixin ],
  data() {
    return {
      /**
       * Name of layer to which the event handlers are bound
       */
      'chargingpointsLayerName': 'chargingpoints',

      /**
       * Whether the event handlers are bound
       */
      bound: false,
      
      /**
       * Usage above this limit shows up red
       *  Usage between 85% and 100% of limit shows orange
       */
      limit: monitoringConfig.limit || 0.5
    }
  },
  computed: {
    ...mapGetters('monitoring', [
        'getAllLocationdata',
        'getSelectedChargerUUID',
        'getSelectedLocationUUIDs',
    ]),
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),

    getMonitoringGeoJSON(){
      return {
        "type": "FeatureCollection",
        "features": (this.getAllLocationdata || []).map(point => {
          let aggregated = point.aggregated
          let properties = {}
          
          if (aggregated) {
            // Map occupancy data, while maintaining object key association
            properties = Object.keys(aggregated).reverse().reduce((props, key) => {
              props[key] = aggregated[key].occupancy_rate

              // set the last key as dominant usage figure
              props.usage = aggregated[key].occupancy_rate
              return props
            }, {})
          } else {
            properties.nodata = true
          }

          return {
            "type": "Feature",
            "properties": Object.assign(properties, {
              "uuid": point.uuid,
            }),
            "geometry": {
              "type": "Point",
              "coordinates": point.coordinates
            }
          }
        })
      }
    },

    // /**
    //  * TODO: Use to show dropdown of year / month options
    //  */
    // getUniqueYearMonthKeys() {
    //   let unique = {};

    //   (this.getAllLocationdata || []).forEach(point => {
    //     Object.keys(point.aggregated).forEach(key => {
    //       unique[key] = true
    //     })
    //   })

    //   return Object.keys(unique)
    // },

    // /**
    //  * All month / year combinations available in the current dataset
    //  * TODO: Use to show dropdown of year / month options
    //  */
    // yearMonthList() {
    //   let uniqueYMkeys = this.getUniqueYearMonthKeys

    //   uniqueYMkeys.reduce(( monthsByYear, key ) => {
    //     let splitKey = key.split('_')
    //     let year = splitKey[0]
    //     let month = splitKey[1]

    //     monthsByYear[year] = monthsByYear[year] || {}
    //     monthsByYear[year][month] = key

    //     return monthsByYear
    //   }, {})

    //   return null
    // }
  },
  watch: {
    loaded(ready) {
      this.bind()
      
      if (ready) {
        this.addLayer()
      }
    },

    /**
     * When the charginpoints list changes we reload the layer
     */
    getAllLocationdata() {
      this.updateLayerData()
    }
  },
  created() {
    if (this.loaded) {
      this.bind()
      this.addLayer()
    }
  },
  beforeDestroy() {
    this.unbind()
  },
  methods: {
    ...mapActions('monitoring', [
      'addOrRemoveSelectedCharger',
    ]),
    ...mapMutations('monitoring', [
      'setSelectedChargerUUID',
    ]),

    /********************************************************************************
     * Events Binding
     */

    bind() {
      this.unbind()

      if (this.bound) return
      this.bound = true

      this.map.on('click', this.chargingpointsLayerName, this.handleClickMarkerEvent)
      this.map.on('contextmenu', this.chargingpointsLayerName, this.handleContext)
      this.map.on('mouseenter', this.chargingpointsLayerName, this.showPointer)
      this.map.on('mouseleave', this.chargingpointsLayerName, this.hidePointer)
    },
    unbind() {
      if (! this.bound) return
      
      if (this.map) {
        this.map.off('click', this.chargingpointsLayerName, this.handleClickMarkerEvent)
        this.map.off('contextmenu', this.chargingpointsLayerName, this.handleContext)
        this.map.off('mouseenter', this.chargingpointsLayerName, this.showPointer)
        this.map.off('mouseleave', this.chargingpointsLayerName, this.hidePointer)
      }

      this.bound = false
    },

    /********************************************************************************
     * The events
     */

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Emit a click event upon clicking a charger icon
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      if (e.originalEvent.ctrlKey || e.originalEvent.button == 2) {
        this.handleContext(e)
        return
      }

      if (e._defaultPrevented) return

      let {uuid} = e.features[0].properties

      if (uuid === this.getSelectedChargerUUID) {
        this.setSelectedChargerUUID({ uuid: null })
      } else if (uuid) {
        this.setSelectedChargerUUID({ uuid })
      }
    },

    /**
     * CTRL + click or right click
     */
    handleContext(e) {
      if ( ! (e.originalEvent.ctrlKey || e.originalEvent.button == 2)) return

      if (e._defaultPrevented) return

      let {uuid} = e.features[0].properties

      if (
        this.getSelectedChargerUUID &&
        this.getSelectedLocationUUIDs.length === 0
      ) {
        this.addOrRemoveSelectedCharger({uuid: this.getSelectedChargerUUID})
      }

      this.addOrRemoveSelectedCharger({uuid})
    },

    /********************************************************************************
     * Monitoring Indicator Layer 
     */

    /**
     * Update the dataset as more monitoring data becomes available
     */
    updateLayerData() {
      let source = this.map.getSource('chargingpoints-monitoring')
      if (source) {
        source.setData(this.getMonitoringGeoJSON)
      }
    },

    /**
     * Load the chargingpoint layer
     */
    addLayer() {

      if (! this.map) return

      // Clean up first if we must
      let source = this.map.getSource('chargingpoints-monitoring')
      if (source) {
        if (this.map.getLayer('chargingpoints-monitoring')) {
          this.map.removeLayer('chargingpoints-monitoring')
        }
        this.map.removeSource('chargingpoints-monitoring')
      }
      

      // Add the generated source 
      this.map.addSource('chargingpoints-monitoring', {
        type: 'geojson',
        data: this.getMonitoringGeoJSON
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ["chargingpoints", 'realisation-processes', 'realisation-processes-text', 'requests', 'requests-text', 'locationpin']
      
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        "id": "chargingpoints-monitoring",
        "type": "circle",
        "source": "chargingpoints-monitoring",
        "minzoom": 10,
        "layout": {
          "circle-sort-key": 1
        },
        "paint": {
          "circle-opacity": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.2,
            1
          ],
          "circle-translate": [10, -10],
          "circle-radius": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            2,
            18,
            11
          ],
          "circle-color": [
            "case",
            [
              "==", ["get", "nodata"], true
            ],
            "rgb(140, 140, 140)",
            [
              "<", ["get", "usage"], (this.limit * 0.85)
            ],
            "rgb(9, 145, 9)", // green
            [
              "<", ["get", "usage"], this.limit
            ],
            "rgb(201, 119, 43)", // orange
            "hsl(0, 88%, 38%)" // red
          ]
        }
      }, positionBelow) 
    }
  }
}
</script>