<template>
  <div class="Energy">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

export default {
  name: 'Energy',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Energie'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Energy'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "EANCode": {
            "title": "EAN code",
            "type": "string"
        },
        "TypeOfNetConnection": {
            "title": "Type netaansluiting",
            "type": "string",
            "enumNames": [
                "1 x 25 A",
                "1 x 35 A",
                "1 x 40 A",
                "3 x 25 A",
                "3 x 35 A",
                "3 x 40 A",
                "3 x 50 A",
                "3 x 63 A",
                "3 x 80 A",
                "3 x 100 A",
                "3 x 125 A",
                "3 x 160 A",
                "3 x 250 A",
                "3 x 400 A",
                "3 x 500 A",
                "3 x 630 A",
                "160 kVA",
                "250 kVA",
                "400 kVA",
                "630 kVA",
                "1750 kVA",
                "2000 kVA"
            ],
            "enum": [
                "1 x 25 A",
                "1 x 35 A",
                "1 x 40 A",
                "3 x 25 A",
                "3 x 35 A",
                "3 x 40 A",
                "3 x 50 A",
                "3 x 63 A",
                "3 x 80 A",
                "3 x 100 A",
                "3 x 125 A",
                "3 x 160 A",
                "3 x 250 A",
                "3 x 400 A",
                "3 x 500 A",
                "3 x 630 A",
                "160 kVA",
                "250 kVA",
                "400 kVA",
                "630 kVA",
                "1750 kVA",
                "2000 kVA"
            ]
        },
        "PowerLimit": {
            "title": "Afgezekerd op",
            "type": "string",
            "enumNames": [
                "1 x 25 A",
                "1 x 35 A",
                "1 x 40 A",
                "3 x 25 A",
                "3 x 35 A",
                "3 x 40 A",
                "3 x 50 A",
                "3 x 63 A",
                "3 x 80 A",
                "3 x 100 A",
                "3 x 125 A",
                "3 x 160 A",
                "3 x 250 A",
                "3 x 400 A",
                "3 x 500 A",
                "3 x 630 A",
                "160 kVA",
                "250 kVA",
                "400 kVA",
                "630 kVA",
                "1750 kVA",
                "2000 kVA"
            ],
            "enum": [
                "1 x 25 A",
                "1 x 35 A",
                "1 x 40 A",
                "3 x 25 A",
                "3 x 35 A",
                "3 x 40 A",
                "3 x 50 A",
                "3 x 63 A",
                "3 x 80 A",
                "3 x 100 A",
                "3 x 125 A",
                "3 x 160 A",
                "3 x 250 A",
                "3 x 400 A",
                "3 x 500 A",
                "3 x 630 A",
                "160 kVA",
                "250 kVA",
                "400 kVA",
                "630 kVA",
                "1750 kVA",
                "2000 kVA"
            ]
        },
        "ConnectionPowerValue": {
            "title": "Aansluitwaarde kW",
            "type": "string"
        },
        "NumberOfTransformers": {
            "title": "Aantal trafo's",
            "type": "integer"
        },
        "TransformerOwnership": {
            "title": "Trafo eigendom of huur",
            "type": "string",
            "enumNames": [
                "Huur",
                "Eigendom"
            ],
            "enum": [
                "Rent",
                "Property"
            ]
        },
        "TransformerCapacityInkVA": {
            "title": "Capaciteit trafo in kVA",
            "type": "string"
        },
        "MaximumTechnicalPowerInkW": {
            "title": "Max. technisch vermogen in kW",
            "type": "string"
        },
        "ContractedPower": {
            "title": "Gecontracteerd vermogen in kW",
            "type": "string"
        },
        "ActualPeakLoad": {
            "title": "Piekbelasting (2018 - 2019)",
            "type": "string",
            "description": "Werkelijke piekbelasting op de aansluiting over 2018 - 2019 (gemeten op trafo)"
        },
        "CapacitiyAvailablePerChargepoint": {
            "title": "Beschikbare capaciteit per punt",
            "type": "string",
            "description": "De norm is 22 kW per laadpunt. In overleg met de klant kan er afgeweken worden tot een minimum van 3.7 kW per laadpunt"
        }
      },
      layout: [{
        "columns": '33% 33% 33%',
        "fields": [ 'EANCode' ]
      }, {
        "columns": '33% 33% 33%',
        "fields": [ 'TypeOfNetConnection', 'PowerLimit', 'ConnectionPowerValue' ]
      }, {
        "columns": '33% 33% 33%',
        "fields": [ 'NumberOfTransformers', 'TransformerOwnership', 'TransformerCapacityInkVA' ]
      }, {
        "columns": '33% 33% 33%',
        "fields": [ 'ContractedPower', 'MaximumTechnicalPowerInkW', 'ActualPeakLoad' ]
      }, {
        "columns": '33% 33% 33%',
        "fields": [ 'CapacitiyAvailablePerChargepoint' ]
      }]
    }
  }
}
</script>