<template>
  <div>
    <h4>Adres</h4>
    {{ address }}
    <br /><br/>
    {{ Latitude }} <br/> {{ Longitude }}

    <h4 class="mt-3">Laadsituatie</h4>
    <table>
      <tr>
        <td class="pr-4">Aantal laadpalen</td>
        <td>{{ ParkingSpots.NumberOfChargingStations }}</td>
      </tr>
      <tr>
        <td class="pr-4">Aantal parkeerplaatsen</td>
        <td>{{ ParkingSpots.NumberOfParkingSpots }}</td>
      </tr>
      <tr>
        <td class="pr-4">Laadsituatie</td>
        <td>{{ ParkingSpots.Type === 'station' ? 'Laadpaal' : 'Laadcluster' }}</td>
      </tr>
      <tr>
        <td class="pr-4">CPO</td>
        <td>{{ location.ChargePointOperator }}</td>
      </tr>
      <tr>
        <td class="pr-4">Toelichting</td>
        <td>{{ location.Remarks }}</td>
      </tr>
      <tr>
        <td class="pr-4">Parkeerplaats IDs</td>
        <td>{{ Identifiers }}</td>
      </tr>
    </table>
  </div>
</template>

<script>

import { isObject } from '@/services/validation'

import { formatPostalCode } from '@/helpers/string'

import { mapGetters } from 'vuex'

export default {
  name: 'LocationInformation',
  props: {
    location: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('chargingpoints', [
      'getChargingPointByUUID'
    ]),
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    address() {
      try {
        let location = this.location.Location

        if (this.isCurrentTenant({ tenant: 'go-ral'})) {
          location = this.getChargingPointByUUID({
            uuid: location.chargingpointUuid
          })
          location = location.data.address
          let number = (`${location.number || ''} ${location.suffix || ''}`).trim()
          return (`${location.street || ''} ${number}, ${formatPostalCode(location.postalcode || '')} ${location.city || ''}`).trim().trim(',')
        } else {
          let number = (`${location.HouseNumber || ''} ${location.HouseNumberSuffix || ''}`).trim()
          return (`${location.StreetName || ''} ${number}, ${formatPostalCode(location.PostalCode || '')} ${location.CityName || ''}`).trim().trim(',')
        }
      } catch(e) {
        return false
      }
      // return `${location.StreetName} ${location.HouseNumber} ${location.HouseNumberSuffix}, ${formatPostalCode(location.PostalCode)} ${location.CityName}`
    },
    Latitude() {
      try {
        let location = this.location.Location

        if (this.isCurrentTenant({ tenant: 'go-ral'})) {
          location = this.getChargingPointByUUID({
            uuid: location.chargingpointUuid
          })
          location = location.data.coordinates[1]
          return `Latitude: ${location.toFixed(7)}`
        }

        return `Latitude: ${location.Latitude.toFixed(7)}`
      } catch(e) {
        return ''
      }
    },
    Longitude() {
      try {
        let location = this.location.Location

        if (this.isCurrentTenant({ tenant: 'go-ral'})) {
          location = this.getChargingPointByUUID({
            uuid: location.chargingpointUuid
          })
          location = location.data.coordinates[0]
          return `Longitude: ${location.toFixed(7)}`
        }

        return `Longitude: ${location.Longitude.toFixed(7)}`
      } catch(e) {
        return ''
      }
    },
    ParkingSpots() {
      return this.location.ParkingSpots
    },
    Identifiers() {
      if (
        ! this.ParkingSpots.Identifiers || 
        ! Array.isArray(this.ParkingSpots.Identifiers) ||
        this.ParkingSpots.Identifiers.length === 0
      ) {
        return ''
      }

      if (isObject(this.ParkingSpots.Identifiers[0])) {
        return this.ParkingSpots.Identifiers.map(item => item.Identifier).join(', ')
      }
      return this.ParkingSpots.Identifiers.join(', ')
    }
  }
}
</script>