<template>
  <MapBoxPopup 
    :coordinates="coordinates" 
    :show="show"
    :offset="[0, -30]" 
    @close="handleClose">
    <div>
      <div v-for="record in visibleRecords" class="d-flex flex-column mb-3" :key="record ? record.uuid : 'none'">
      
        {{ record.case_ref }} <br/> 
        {{ record.address }} <br />

        <router-link v-if="record.uuid" :to="{ name: 'Realisation', params: { uuid: record.uuid }}">
          Bekijk de realisatie details
        </router-link>
      </div>

      <b-pagination 
        v-if="records.length > 1"
        v-model="currentPage"
        :total-rows="records.length"
        :per-page="1"
        class="justify-content-center m-auto pagination-sm"
        hide-goto-end-buttons />
    </div>
  </MapBoxPopup>
</template>

<script>
import MapBoxPopup from '@/components/common/MapBoxPopup.vue'
import MapBoxMixin from '@/components/common/MapBoxMixin.vue'
import { mapGetters } from 'vuex'

export default {
  components: {
    MapBoxPopup
  },
  mixins: [ MapBoxMixin ],
  data() {
    return {
      layerName: 'realisation-processes',
      show: false,
      uuids: [],
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters('realisation', ['recordByUuid']),
    records() {
      return this.uuids
        .map(uuid => this.recordByUuid({ uuid })) 
    },
    visibleRecords() {
      return [ this.records[this.currentPage - 1] || false ]
    },  
    coordinates() {
      return this.records.length 
        ? [
          this.records[0].values.Location.Location.Longitude, 
          this.records[0].values.Location.Location.Latitude
        ] 
        : [0, 0]
    }
  },
  watch: {
    records() {
      this.show = this.records.length !== 0
    }
  },
  created() {
    this.bind()
  },
  beforeDestroy() {
    this.unbind()
  },
  methods: {
    bind() {
      if (! this.map) return

      // click event
      this.map.on('click', this.layerName, this.handleLayerClickEvent)
      this.map.on('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      // Cursor
      this.map.on('mouseenter', this.layerName, this.showPointer)
      this.map.on('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.on('mouseleave', this.layerName, this.hidePointer)
      this.map.on('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },
    unbind() {
      if (! this.map) return

      this.map.off('click', this.layerName, this.handleLayerClickEvent)
      this.map.off('click', `${this.layerName}-text`, this.handleLayerClickEvent)

      this.map.off('mouseenter', this.layerName, this.showPointer)
      this.map.off('mouseenter', `${this.layerName}-text`, this.showPointer)
      this.map.off('mouseleave', this.layerName, this.hidePointer)
      this.map.off('mouseleave', `${this.layerName}-text`, this.hidePointer)
    },

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Handle click on request icon or txt
     */
    handleLayerClickEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      let features = e.features

      this.$nextTick(function(){
        this.currentPage = 1
        this.uuids = features
          .filter(feature => feature?.properties.uuid)
          .map(feature => feature.properties.uuid)

        if (this.uuids.length) {
          this.show = true
        }
      })
    },

    /**
     * Popup handlers
     */
    handleClose() {
      this.show = false
      // this.uuids = []
    }
  }
}
</script>

<style lang="scss">
.mapboxgl-popup-content {
  padding: 10px 30px 10px 10px !important;

  p {
    margin: 0;
    user-select: auto;
  }
}
</style>