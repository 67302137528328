
import { stepsByUuid, stepNumbersByEditor } from '@/config'
import { commonFilterWrapper } from '../utils'
const now = Date.now()


export default {
  /**
   * Config reference
   * @type {string}
   */
  uuid: '3406eb6e-f42a-4e9d-914a-5a11e308f867',

  /**
   * Use to group table configurations
   *  Configs for realisation index should not be used for the statistics dashboard
   */
  category: 'realisation',

  /**
   * Access requirements
   */
  access: {
    /**
     * Required access checks. Must pass all
     */
    requirements: [],

    // TODO: Maybe specicify exclude list? If you have one config, exclude this one
    supersededBy: []
  },

  /**
   * 
   */
  controlsComponentName: 'RealisationControls',


  // Minimal width of the table
  minWidth: '920px',
  maxWidth: '1900px',
  pageSize: 10,

  /**
   * CSS Grid config
   * 
   * @type {object}
   */ 
  layouts: {
    default: {
      id: 'default',
      label: 'Standaard',
      filters: {
        filters: ['reference', 'address', 'step', 'tags', 'vkb'],
        columns: '150px 2fr 240px 1fr 130px',
        area: `"reference address step tags vkb" `
      },
      header: { // copied to row
        properties: ['reference', 'address', 'step', 'requestCount', 'tags', 'updated_at', 'vkb'],
        columns: '150px 4fr minmax(240px, 4fr) 110px 130px 150px 180px',
        area: '"reference address step requestCount tags updated_at vkb"'
      },
    }
  },


  headerLabels: {
    reference: 'Referentie',
    address: 'Adres',
    step: 'Stap',
    requestCount: 'Aanvragen',
    tags: 'Tags',
    updated_at: 'Gewijzigd op',
    vkb: 'VKB status'
  },
  cellComponents: {
    reference: null,
    address: null,
    step: 'Step',
    requestCount: 'RequestCount',
    tags: 'Tags',
    updated_at: 'UpdatedAt',
    vkb: null
  },


  /**
   * sorting options
   *  Note that a header may be sorted by a different field than itself
   *  An example is the referencenr used to sort the reference column numerically
   */
  sorting: {
    default: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    reference: {
      type: 'number',
      sortBy: 'reference_nr'
    },
    address: {
      type: 'string',
      sortBy: 'address'
    },
    step: {
      type: 'string',
      sortBy: 'step'
    },
    cpo: {
      type: 'string',
      sortBy: 'cpo'
    },
    tags: {
      type: 'number',
      sortBy: 'tagcount'
    },
    updated_at: {
      type: 'number',
      sortBy: 'updated_at_nr'
    },
    vkb: {
      type: 'number',
      sortBy: 'vkb_sorting'
    }
  },


  /**
   * Take a record and convert it to the data we use for displaying / sorting / filtering records in this table
   * @type {Function}
   */
  dataMapper: (record) => {
    // console.log(record)

    let cpo = ''
    let Location = record.Location || record.values.Location || null
    if (Location) {
      // Netbeheerder / Aannemer
      let GridOperator = Location.GridOperator
      let Contractor = Location.PlannedRealisation ? Location.PlannedRealisation.Contractor : ''
      cpo = `${GridOperator || '-'} / ${Contractor || '-'}`
    }

    // TODO: Step logic is to be replaced 

    const step = stepsByUuid[record.status.uuid] || {}
    // if (! step) {
    //   console.log(record)
    // }
    const stepNumber = step.step || step.short
    const stepLabel = step.label

    /**
     * VKB / TrafficDecision
     */
    /**
       * VKB / TrafficDecision
       */
     let vkb = 'Onbekende status'
     let vkb_sorting = 9999
     if (record.status.vkb) {
       vkb = 'Onherroepelijk'
       vkb_sorting = 1
     }
     else if (Location) {

       // Location
       let TrafficDecisionDetails = Location.getTrafficDecisionDetails()
       if (! TrafficDecisionDetails.PublicationDate) {
         vkb = 'Nog te publiceren'
         vkb_sorting = 100
       }
       else if (TrafficDecisionDetails.ConfirmedDate) {
         vkb = 'Onherroepelijk'
         vkb_sorting = 1
       } 
       else if (TrafficDecisionDetails.DefinitiveDate) {
         // 2022-06-09
         // const [ year, month, day ] = TrafficDecisionDetails.DefinitiveDate.split('-')
         const date = (new Date(TrafficDecisionDetails.DefinitiveDate)).getTime()

         // Already passed
         if (date < now) {
           vkb = 'Verlopen'
           vkb_sorting = 2
         } else {
           const diffInMs = date - now
           const diffInDays = diffInMs / (1000 * 60 * 60 * 24)
           
           // console.log("diffInDays", diffInDays)

           if (diffInDays < 2) {
             vkb = 'Nog max 2 dagen'
             vkb_sorting = 3
           }
           else if (diffInDays < 7) {
             vkb = 'Nog max 7 dagen'
             vkb_sorting = 4

           } else {
             vkb = 'Nog meer dan 7 dagen',
             vkb_sorting = 5
           }
         }
       }
     }

    
    return {
      reference: record.case_ref,
      // used for sorting the reference column
      reference_nr: record.raw.case.counter, 

      address: record.address,
      municipality: record.MunicipalityCode || record.Municipality || 'Onbekend',

      tags: Array.isArray(record.Tags) ? record.Tags : [],
      tagcount: Array.isArray(record.Tags) ? record.Tags.length : 0,
      
      step: `${stepNumber} ${record.status.completed ? 'Afgerond' : stepLabel}`,

      // uuid is passed to render component to obtain relation count
      requestCount: record.uuid, 

      cpo,
      updated_at: record.updated_at_short,
      updated_at_nr: record.raw.updated_at,

      // Turns row into a link
      route: {
        name: `Realisation.Step${stepNumber}`,
        params: {
          uuid: record.uuid
        }
      },

      /**
       * Note: using dutch category names because these are also used as slugs
       */
      categories: {
        all: true,
        actief: record.status.completed !== true && record.status.cancelled !== true && record.status.onhold !== true,

        gemeente: !record.status.completed && !record.status.cancelled && (stepNumbersByEditor['municipality'] || []).includes(parseInt(record.status.step)),
        cpo: !record.status.completed && !record.status.cancelled && (stepNumbersByEditor['cpo'] || []).includes(parseInt(record.status.step)),
        
        onhold: record.status.onhold === true && record.status.cancelled !== true,
        afgerond: record.status.completed === true,
        geannuleerd: record.status.cancelled === true
      },

      /** 
       * Filter params
       */ 
      filteredOut: false,
      filterReason: null,


      /**
       * Traffic Decision
       */
      vkb,
      vkb_sorting
    }
  },


  /************************************************************************** 
   * Filter configuration
   * 
   * @type {object[]}
   */ 
  filters: {
    reference:{
      cost: 2,
      id: 'reference',
      label: 'Referentie',
      position: '',
      componentName: 'SingleStringFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.reference.toLowerCase().includes(value)
    }, 
    
    step: {
      cost: 1,
      id: 'step',
      label: 'Stap',
      position: '',
      componentName: 'StepFilter', 
      hidden: false,
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.step === value
    },

    address: {
      cost: 20,
      id: 'address',
      label: 'Adres',
      position: '',
      componentName: 'SingleStringFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value.toLowerCase(),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.address.toLowerCase().includes(value)
    },
    municipality: {
      cost: 5,
      id: 'municipality',
      label: 'Gemeente',
      position: '',
      componentName: null,
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.municipality === value
    },

    tags: {
      cost: 50,
      id: 'tags',
      label: 'Tags',
      position: '',
      componentName: 'TagSelectFilter',
      hidden: false,
      prep: ({ filter }) => {
        return {
          value: filter.filters.map(filter => filter.value),
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => value.every(tag => (record.tags || []).includes(tag))
    }, 

    vkb: {
      cost: 5,
      id: 'vkb',
      label: 'VKB status',
      position: '',
      componentName: 'VKBSelectFilter',
      hidden: false, 
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.vkb === value

    },

    /**
     * Note: The 'cost' is high so that we can 'undo' this filter for the counter per category
     */
    category: {
      cost: 9999,
      id: 'category',
      label: 'Category',
      position: '',
      componentName: null, 
      hidden: true,
      options: [{
          text: 'Alles',
          value: 'all',
          reset: true,
          route: {
            name: 'RealisationList'
          }
        }, {
          text: 'Actief',
          value: 'actief',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'actief' }
          }
        }, {
          text: 'Gemeente',
          value: 'gemeente',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'gemeente' }
          }
        }, {
          text: 'CPO',
          value: 'cpo',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'cpo' }
          }
        }, {
          text: 'On hold',
          value: 'onhold',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'onhold' }
          }
        }, {
          text: 'Afgerond',
          value: 'afgerond',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'afgerond' }
          }
        }, {
          text: 'Geannuleerd',
          value: 'geannuleerd',
          route: {
            name: 'RealisationListFiltered',
            params: { filter: 'geannuleerd' }
          }
        }
      ],
      prep: ({ filter }) => {
        return {
          id: filter.id,
          value: filter.filters[0].value,
          callback: commonFilterWrapper({ callback: filter.callback })
        }
      },
      callback: ({ record, value }) => record.categories[value] === true
    }
  },
}