<template>
  <div class="TenantLogo" :style="{ width: `${width}px` }">
    <img v-if="img === 'go-ral'" src="../assets/image/go-ral.png" :width="width" alt="" :style="{ background: color }" />
    <img v-else-if="img === 'park-n-charge'" src="../assets/image/Logo_desktop.png" :width="width" alt="" :style="{ background: color }" />
    <img v-else-if="img === 'amsterdam'" src="../assets/image/Logo desktop.svg" :width="width" alt="" :style="{ background: color }" />
    <img v-else-if="img === 'opcharge'" src="../assets/image/opcharge.svg" :width="width" alt="" :style="{ background: color }" />
    <img v-else-if="img === 'rvb'" src="../assets/image/evtools.png" :width="width" alt="" :style="{ background: color }" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('tenant', ['getTenant']),
    img() {
      return this.getTenant
    },
    width() {
      switch(this.img) {
        case 'amsterdam':
        case 'park-n-charge': 
          return 60

        case 'opcharge':
        case 'go-ral':
          return 160

        case 'rvb':
          return 130
      }

      return 0
    },
    color() {
      switch(this.img) {
        case 'rvb':
          return '#041E42'
      }

      return 'white'
    }
  }
}
</script>

<style lang="scss">

.TenantLogo {
  position: relative;
  margin: 0 16px 0 0;

  img {
    position: absolute;
    left: 0;
    bottom: 0;

    // background: white;
    padding: 0.5rem;
    box-shadow: 2px 5px 19px 0px rgba(0,0,0,0.27);
    border-radius: 4px;
  }
}
</style>