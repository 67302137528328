<template>
  <div class="Row" :style="style">
    <template v-for="(field,index) in fields">
      <Instruction 
        v-if="field.props.type === 'instruction'" 
        v-bind="field.props"
        :key="`field_${index}`" />
      <FieldValue 
        v-else-if="viewer || field.readonly || field.viewer"
        v-bind="field.props"
        :name="name({ name: field.name })"
        :key="`field_${index}`" />
      <FormField
        v-else
        v-bind="field.props"
        :name="name({ name: field.name })"
        :key="`field_${index}`"
        :size="size" 
        @blur="handleBlur({ name: field.name })"
        @input="value => handleInput({ name: field.name, value })" />
    </template>
  </div>
</template>

<script>

import Instruction from '@/components/form/Instruction'
import FormField from '@/components/form/FormField'
import FieldValue from '@/components/form/FieldValue'

export default {
  name: 'Row',
  components: { FormField, FieldValue, Instruction },
  props: {
    /**
     * The field specification
     */
    fields: {
      type: Array,
      required: true
    },
    /**
     * CSS Grid "grid-template-columns" value
     */
    columns: {
      type: String,
      required: true
    },
    /**
     * 
     */
    path: {
      type: String,
      default: ''
    },

    /**
     * Viewer Mode
     */
    viewer: {
      type: Boolean,
      default: false
    },

    /**
     * Bootstrap sizes (sm, lg or empty for regular)
     */
    size: {
      type: String,
      default: 'sm'
    }
  },
  computed: {
    style() {
      return {
        'grid-template-columns': this.columns
      }
    }
  },
  methods: {
    name({ name }) {
      return this.path === '' ? name : `${this.path}.${name}`
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name: this.name({ name }) // add path info
      })
    },
    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name: this.name({ name }) // add path info
      })
    }
  }
}
</script>

<style>
.Row {
  display: grid;
  column-gap: 0.5rem;
}
</style>