<script>
export default {
  name: 'MapBoxLayerMixin',
  inject: {
    map: {
      default: null
    },
    mapbox: {
      default: null
    },
    loaded: {
      default: false
    }
  },
}
</script>
