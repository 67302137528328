/**
 * Import Dependency
 */
import Vue from 'vue'
import { unique } from '@/helpers/array'

/**
 * Import API
 */

/**
 * Declare Variable
 */
 const state = {
  // one => many
  realisationToRequests: {},
  // one => one
  requestToRealisation: {},

  changes: []
}

const getters = {
  changeCount: state => state.changes.length,

  requestUuidsByRealisationUuid: state => ({ uuid }) => {
    let stored = (state.realisationToRequests[uuid] || []).slice()

    stored = unique({ arr: stored })
    
    // Cycle through the additions / removals
    let changes = state.changes.filter(change => change.realisationUuid === uuid)
    changes = unique({ arr: changes })

    if (changes.length !== 0) {
      changes.forEach(change => {
        
        if (change.type === 'add') {
          stored.push(change.requestUuid)
        } else {
          let index = stored.indexOf(change.requestUuid)
          if (index !== -1) {
            stored.splice(index, 1)
          }
        }
      })
    }

    return unique({ arr: stored })
  },
  
  realisationUuidByRequestUuid: state => ({ uuid }) => {
    let stored = state.requestToRealisation[uuid] || null

    // The last change is all that matters
    let changes = state.changes.filter(change => change.requestUuid === uuid)
    if (changes.length !== 0) {
      let lastChange = changes.reverse()[0]
      return (lastChange.type === 'add') ?  lastChange.realisationUuid : null
    }

    return stored
  }
}
const actions = {

}
const mutations = {
  resetRelations(state ) {
    Vue.set(state, "requestToRealisation", {})
    Vue.set(state, "realisationToRequests", {})
    Vue.set(state, "changes", [])
  },
  addConnection(state, { requestUuid, realisationUuid }) {
    // request => realisation
    Vue.set(state.requestToRealisation, requestUuid, realisationUuid)

    // realisation => requests
    let arr = state.realisationToRequests[realisationUuid] || []
    arr.push(requestUuid)
    Vue.set(state.realisationToRequests, realisationUuid, arr)
  },
  setConnectionChange(state, { type, requestUuid, realisationUuid }) {
    state.changes.push({
      type, requestUuid, realisationUuid
    })
  },
  clearChanges(state) {
    state.changes = []
  },

  finalizeChanges(state) {
    state.changes.forEach(change => {
      let arr = [], index = null

      if (change.type === 'add') {
        // request => realisation
        Vue.set(state.requestToRealisation, change.requestUuid, change.realisationUuid)

        // realisation => requests
        arr = unique({ arr: state.realisationToRequests[change.realisationUuid] || [] })
        arr.push(change.requestUuid)
        Vue.set(state.realisationToRequests, change.realisationUuid, arr)

      } else {
        // request => realisation
        Vue.set(state.requestToRealisation, change.requestUuid, null)

        // realisation => requests
        arr = unique({ arr: state.realisationToRequests[change.realisationUuid] || [] })
        index = arr.indexOf(change.requestUuid)

        if (index !== -1) {
          arr.splice(index, 1)
        }

        Vue.set(state.realisationToRequests, change.realisationUuid, arr)
      }
    })
  }
}

/**
 * Export
 */
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
