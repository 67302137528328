<template>
  <div class="Object">
    <!-- <Address 
      :values="values"
      :disabled="disabled" 
      :path="AddressPath"
      :viewer="viewer" /> -->
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
    />
  </div>
</template>

<script>
// import Address from '@/components/definitions/Address'

import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { AssetManagers, ObjectManagers, ObjectVisions, PropertyRights } from '@/config'

export default {
  name: 'Object',
  components: { 
    // Address, 
    FieldSet 
  },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Object'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    AddressPath() {
      return this.path === '' ? 'Address' : `${this.path}.Address`
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "AssetManager": {
          "title": "Asset Manager",
          "type": "string",
          // Addition to Cafienne
          "enumNames": [''].concat(AssetManagers),
          "enum": [''].concat(AssetManagers)
        },
        "ObjectManager": {
          "title": "Object Manager",
          "type": "string",
          // Addition to Cafienne
          "enumNames": [''].concat(ObjectManagers),
          "enum": [''].concat(ObjectManagers)
        },
        "PropertyRight": {
          "title": "Eigendomsrecht",
          "type": "string",
          // Addition to Cafienne
          "enumNames": [''].concat(PropertyRights),
          "enum": [''].concat(PropertyRights)
        },
        "ObjectVision": {
          "title": "Objectvisie",
          "type": "string",
          // Addition to Cafienne
          "enumNames": [''].concat(ObjectVisions),
          "enum": [''].concat(ObjectVisions)
        },
      },
      layout: [{
        "columns": '1fr 1fr 1fr',
        "fields": ['AssetManager', 'ObjectManager' ]
      }, {
        "columns": '1fr 1fr 1fr',
        "fields": [ 'PropertyRight', 'ObjectVision' ]
      }],
      validation: {
        "PropertyRight": {
          validOption: (value) => !helpers.req(value) || ([''].concat(PropertyRights)).includes(value)
        }
      }
    }
  }
}
</script>