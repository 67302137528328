<template>
<div>
  <h5>Adresgegevens:</h5>
  <table class="table table-sm mb-4">
    <tr>
      <th scope="row">Straat</th>
      <td colspan="2">
        {{ address.street }}
      </td>
    </tr>
    <tr>
      <th scope="row">Huisnummer</th>
      <td colspan="2">
        {{ address.streetNumber }} {{ address.streetNumberSuffix }}
      </td>
    </tr>
    <tr>
      <th scope="row">Postcode</th>
      <td>{{ address.postalCode }}</td>
      <td>
        <router-link :to="{ name: 'RequestList', query: { zoeken: search.postalcode } }">
          <b-badge variant="dark">
            {{ requestCountByPostalcode({ postalcode: search.postalcode }) }}
            <b-icon class="ml-2" icon="search"></b-icon>
          </b-badge>  
        </router-link>
      </td>
    </tr>
    <tr>
      <th scope="row">Plaats</th>
      <td>
        {{ address.city }}
      </td>
      <td>
        <router-link :to="{ name: 'RequestList', query: { zoeken: search.city } }">
          <b-badge variant="dark">
            {{ requestCountByCity({ city: search.city }) }}
            <b-icon class="ml-2" icon="search"></b-icon>
          </b-badge>  
        </router-link>
      </td>
    </tr>
  </table>

  <h5>Opmerkingen en overige informatie</h5>
  <table class="table table-sm mb-4">
    <tr>
      <th scope="row">Locatie</th>
      <td>{{ additional ? additional.location : '-' }}</td>
    </tr>
    <tr>
      <th scope="row">Opmerkingen</th>
      <td>{{ remark ? remark : '-' }}</td>
    </tr>
  </table>

  <h5>Contactgegevens:</h5>
  <table class="table table-sm mb-4">
    <tr>
      <th scope="row">Naam</th>
      <td>{{ contact.name || '-' }}</td>
    </tr>
    <tr>
      <th scope="row">Telefoonnummer</th>
      <td>{{ contact.phone || '-' }}</td>
    </tr>
    <tr>
      <th scope="row">Emailadres</th>
      <td>{{ contact.email || '-' }}</td>
    </tr>
  </table>
</div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('requests', [      
      'requestCountByNumberplate',
      'requestCountByPostalcode',
      'requestCountByCity',
    ]),
    search() {
      return this.request.search
    },
    address() {
      return this.request.address
    },
    additional() {
      return this.request.additional
    },
    remark() {
      return this.request.raw.baseData.remarks || ''
    },
    contact() {
      return this.request.contact
    }
  }
}
</script>

<style>

</style>