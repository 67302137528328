
// This script was based on https://gitlab.com/journeyworks/ev-consult/evmaps-python/-/blob/master/locatievoorstellen/py3_locatievoorstellen.py
// wms_string = "%s?bbox=%s,%s,%s,%s&styles=&format=image/jpeg&service=WMS&version=2.0&request=GetMap&crs=EPSG:28992&transparent=true&width=%s&height=%s&layers=%s" % (pdok_url, minx, miny, maxx, maxy, width, height, pdok_layer)

import { fromWGS84toRD } from '@/helpers/coordinates'

const pdok_layer = 'Actueel_orthoHR'
const baseURL = 'https://service.pdok.nl/hwh/luchtfotorgb/wms/v1_0'
const resolutionFactor = 0.025

// A4 is 210mm x 297mm - subtract left and right margin from A4 size
const width = 2100 - (2 * 254) // 1592
const height = 1000


export const getTopViewUrlFromPDOK = ({ lat, lng }) => {

  // Convert coordinates to PDOK preferred format
  const coordinates = fromWGS84toRD({ lat, lng })

  let X = coordinates[0]
  let Y = coordinates[1]

  // normalize to resolution
  X = Math.round(X / resolutionFactor) * resolutionFactor
  Y = Math.round(Y / resolutionFactor) * resolutionFactor

  // determine bounding box 
  const minx = X - (0.5 * width * resolutionFactor)
  const miny = Y - (0.5 * height * resolutionFactor)
  const maxx = X + (0.5 * width * resolutionFactor)
  const maxy = Y + (0.5 * height * resolutionFactor)

  const queryStringSegments = {
    bbox: `${minx},${miny},${maxx},${maxy}`,
    styles: '',
    format: 'image/jpeg',
    service: 'WMS',
    version: '2.0',
    request: 'GetMap',
    crs: 'EPSG:28992', // 
    transparent:  "true",
    width,
    height,
    layers: pdok_layer
  }
  const queryString = Object.keys(queryStringSegments)
    .reduce((str, key) => `${str}&${key}=${queryStringSegments[key]}`, '?')
    .replace('?&', '?')
  
  const url = `${baseURL}${queryString}`

  // console.log(url)

  return url
}





