<template>
  <div class="Address">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :validation="validation"
      :layout="layout"
      :disabled="disabled"
      :path="path"
    />
  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { required, helpers } from 'vuelidate/lib/validators'
import { unicodeString } from '@/services/validation'

import { municipalityOptions } from '@/config'

import { mapGetters } from 'vuex'

export default {
  name: 'Address',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    hasRef: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      // Copied from Cafienne
      validation: {
        "ProcessType": {
          required
        },
        "Municipality": {
          required
        },
        "GridOperator": {
          required,
          unicodeString: (value) => !helpers.req(value) || unicodeString(value)
        },
        "ChargePointOperator": {
          required,
          unicodeString: (value) => !helpers.req(value) || unicodeString(value)
        }
      }
    }
  },
  computed: {
    ...mapGetters('tenant', [
      'isCurrentTenant'
    ]),
    /**
     * Disable if set and stored (record has a DB ref)
     */
    disableMunicipality() {
      return this.values.Municipality !== null && this.hasRef
    },
    disableProcessType() {
      return this.values.ProcessType !== null && this.hasRef
    },
    layout() {
      let layout = []
      // if (this.isCurrentTenant({ tenant: 'go-ral' })) {
      //   layout.push({
      //     "columns": '1fr',
      //     "fields": [ 'ProcessType' ]
      //   })
      // }

      return layout.concat([{
        "columns": '1fr',
        "fields": [ 'Municipality' ]
      },{
        "columns": '1fr',
        "fields": [ 'ChargePointOperator' ]
      }, {
        "columns": '1fr',
        "fields": [ 'GridOperator' ]
      }, {
        "columns": '1fr',
        "fields": [ 'Remarks' ]
      }])
    },
    fields() {
      let fields = {
        "Municipality": {
          "type": "string",
          "title": "Behandelende gemeente",
          "disabled": this.disableMunicipality,
          "options": municipalityOptions
        },
        "ChargePointOperator": {
            "type": "string",
            "title": "CPO",
            "disabled": true
        },
        "GridOperator": {
          "type": "string",
          "title": "Netbeheerder"
        },
        "Remarks": {
            "type": "string",
            "title": "Toelichting",
            "format": "textarea"
        }
      }

      if (this.isCurrentTenant({ tenant: 'go-ral' })) {
        fields['ProcessType'] = {
          "type": "string",
          "title": "Type realisatie proces",
          "disabled": this.disableProcessType,
          "options": [{
            value: 'citizen-request',
            text: 'Aanvraag e-rijder'
          }, {
            value: 'municipality-request',
            text: 'Aanvraag gemeente'
          }, {
            value: 'data-driven',
            text: 'Datagedreven'
          }, {
            value: 'proactive',
            text: 'Proactief'
          }]
        }
      }

      return fields
    }
  }
}
</script>