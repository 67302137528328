

import { municipalityOptions } from '@/config'

/**
 * Realisation
 */
let Realisation = function ({
  ref, data
}) {

  data = this.commonStructure({ ref, data })
  data.tenant = 'park-n-charge'

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}

Realisation.prototype.generateMunicipalityData = ({ data }) => {
  return data.Municipality || null
}

Realisation.prototype.generateAddressData = ({ model }) => {
  const CurrentLocation = model.CurrentLocation

  if (CurrentLocation.Location && CurrentLocation.Location.StreetName) {
    return `${CurrentLocation.Location.StreetName} ${CurrentLocation.Location.HouseNumber} ${CurrentLocation.Location.HouseNumberSuffix} ${ CurrentLocation.Location.CityName }`.replace('  ', ' ')
  }
  return ''
}

Realisation.prototype.generateSearchData = ({ model, data }) => {

  let municipalityName = ''
  if (data.Municipality) {
    let municipalityOption = municipalityOptions.find(option => option.value === data.Municipality)
    if (municipalityOption) {
      municipalityName = municipalityOption.text
    }
  }

  return {
    City: model.CurrentLocation ? model.CurrentLocation.Location.CityName: '',
    Municipality: municipalityName
  }
}


export default Realisation;