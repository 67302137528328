<script>
import SingleSelectFilter from './SingleSelectFilter.vue'

import { mapGetters } from 'vuex'

export default {
  name: 'TablefieldFilter', 
  mixins: [ SingleSelectFilter ],
  computed: {
    ...mapGetters('tenant', [
      'getWorkflowSpecByName'
    ]),
    steps() {
      return this.getWorkflowSpecByName({ name: 'realisation' })?.steps || []
    },
    options() {
      let options = this.steps.map(step => {
        return {
          value: `${step.step || step.short} ${step.label}`,
          text: `${step.step || step.short} ${step.label}`
        }
      })
      options.push({
        value: `${options.length} Afgerond`,
        text: `${options.length} Afgerond`
      })
      return options
    }
  }
}
</script>