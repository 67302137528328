<template>
  <a 
    class="mb-4 mr-2"
    @click="handleDownload">{{ 
      file.name || `${request.case_ref}-${type}-${file.id}` 
    }}</a>
</template>

<script>
import { saveAs } from 'file-saver';

import { isObject } from '@/services/validation'

export default {
  props: {
    type: {
      type: String,
      required: true
    },
    file: {
      type: [Object, Number],
      required: true
    },
    request: {
      type: Object,
      required: true
    }
  },
  methods: {
    handleDownload: async function() {
      try {

        let index = this.file
        let ext = ''

        if (isObject(this.file)) {
          index = this.file.id
          ext = this.file.ext || ''
        }

        const token = await this.$auth.getTokenSilently();
        const api = await fetch(`${process.env.VUE_APP_AWS_ENDPOINT}/url`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`
          },
          body: JSON.stringify({
            ref: this.request.ref,
            uuid: this.request.uuid,
            casefull: this.request.case_ref,
            index,
            doctype: this.type,
            ext
          })
        })
        if (! api.ok) {
          throw new Error("server error")
        } 
        const response = await api.json()
        if (! response.downloadURL) {
          throw new Error("missing url")
        }
        // Chrome, FF, Edge
        if (window.browser && window.browser.downloads) {
          window.browser.downloads.download({
            url : response.downloadURL
          })
        } else { // Safari, etc.
          const res = await fetch(response.downloadURL)
          const blob = await res.blob()
          saveAs(blob, response.filename)
        }
      } catch(e) {
        // console.log(e)
        this.$bvModal.show('file-error')
      }
    }
  }
}
</script>