


export default function({ ref, data, }) {
  const contact = data.contact
  contact.postalCode = contact.postalcode

  let address = `${contact.street} ${contact.streetnumber} ${contact.streetsuffix || ''}, ${this.formatPostalCode(contact.postalCode)}`
  if (contact.city.toLowerCase().trim() !== 'amsterdam') {
    address = `${address}, ${contact.city}`
  }
  
  let common = this.commonStructure({ ref, data })

  data = Object.assign(common, {
    tenant: 'amsterdam',
    contact: contact,
    address: {
      street: contact.street,
      streetNumber: contact.streetnumber,
      streetNumberSuffix: contact.streetsuffix || '',
      postclCode: contact.postalCode,
      city: contact.city
    },
    vehicle: data.vehicle,

    softrequirements: data.softrequirements,

    summary: Object.assign(common.summary, {
      name: `${contact.initials}. ${contact.surname}`,
      address,
    }),

    search: {
      postalcode: data.contact.postalcode.replace(' ', '').trim(),
      numberplate: data.vehicle.numberplate.replace(/-/g, '')
    },

    coordinates: {
      nomatch: data.coordinates ? data.coordinates.nomatch : false,
      lat: data.coordinates ? data.coordinates.lat : null,
      lng: data.coordinates ? data.coordinates.lng : null
    }
  })

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}
