<template>
  <BlockRepeater 
    class="PVO" 
    title=""
    label="Voeg een PVO toe"
    :path="path" 
    :values="values"
    :disabled="disabled" 
    :deleted="evaluateDeletedState"
    v-slot="slotProps">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="`${path}.${slotProps.id}`"
      :viewer="viewer"
    />
  </BlockRepeater>
</template>

<script>
import BlockRepeater from '@/components/form/BlockRepeater'
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isUrl } from '@/services/validation'

export default {
  name: 'PVO',
  components: { BlockRepeater, FieldSet },
  props: {
    label: {
      type: String,
      default: ''
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'Party'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "PartyName": {
            "type": "string",
            "title": "Leverancier",
            "readonly": true
        },
        "WorkDescription": {
            "type": "string",
            "title": "Beschrijving van de werkzaamheden",
            "readonly": true
        },
        "DateOfDeliveringPVO": {
            "type": "string",
            "format": "date",
            "title": "Datum van PVO oplevering"
        },
        "LinkToPVO": {
            "type": "string",
            "format": "url",
            "title": "Link naar het Proces Verbaal van Oplevering"
        },
        "deleted_at": {
          type: 'hidden'
        }
      },
      layout: [{
        "columns": '1fr 1fr',
        "fields": ['PartyName', 'DateOfDeliveringPVO' ]
      }, {
        "columns": '1fr',
        "fields": [ 'LinkToPVO' ]
      }, {
        "columns": '1fr, 0px',
        "fields": [ 'WorkDescription', 'deleted_at' ]
      }],
      validation: {
        LinkToPVO: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  },
  methods: {
    evaluateDeletedState({ index }) {
      return !! (
        this.values[this.path] && this.values[this.path][index] && this.values[this.path][index].deleted_at
      )
    },
  }
}
</script>