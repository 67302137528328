export const signalColorCodes = {
    "tedruk": "#FF4E4E",
    "semidruk": "#FF9900",
    "nietdruk": "#689BC7",
}

export const monochromeColors = ['#689AC5', '#3B547B']

export const labelColor = "#7F7F7F"

export const animationDuration = 250

export const thresholdConfiguration = {
    type: "steppedArea",
    color: signalColorCodes["tedruk"],
    areaOpacity: 0,
    enableInteractivity: true
}
