



/**
 * RVB does not use Requests at this moment
 */
export default function ({
  ref, data
}) {
  data = this.commonStructure({ ref, data })

  // 
  Object.keys(data).forEach(key => {
    this[key] = data[key]
  })
}


