<template>
  <div style="display:none"></div>
</template>

<script>
/* eslint-disable */

import MapBoxMixin from '@/components/common/MapBoxMixin'
import { mapGetters } from 'vuex'

/**
 * This layer shows the realisation process locations
 */
export default {
  name: 'LayerRealisationProcesses',
  mixins: [ MapBoxMixin ],
  props: {

    /**
     * The selected realisation process
     *  Used on the LocationSelection page
     */
    realisationUuid: {
      type: String,
      default: null
    },

    /**
     * Starting from this step, the location has been validated and actual realisation is started
     */
    realisationFromStep: {
      type: Number,
      default: 6
    },

    /**
     * The uuid of the realisation process being looked at
     *  This realisation process is plotted on the map with a pin already
     */
    activeUuid: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      /**
       * Icon counter. We're loading 2 icons (see requiredIcons)
       */
      iconCounter: 0,
      requiredIcons: [
        'suggestion',
        'in-progress'
      ],
      layerName: 'realisation-processes',

      delayedRepaint: null
    }
  },
  computed: {
    ...mapGetters('realisation', { 'realisationProcesses': 'records' }),

    /**
     * Track whether all required icons are loaded
     */
    iconsLoaded() {
      return this.iconCounter >= this.requiredIcons.length
    },

    shownProcesses() {
      return this.realisationProcesses.filter(process => {
        // Only show processes which have a status, and are not completed or cancelled
        return this.activeUuid !== process.uuid && process.status && process.status.completed !== true && process.status.cancelled !== true
      })
    }
  },
  watch: {
    /**
     * Trigger the loading the icons when mapbox is ready
     *  When switching styles mapbox 'unloads', and we reset the icon counter as we will need to reload them
     */
    loaded(ready) {
      if (ready === false) {
        this.iconCounter = 0
      } 
      if (ready) {
        this.init()
      }
    },

    /**
     * When the icons are loaded we can move on to adding the layer
     */
    iconsLoaded(ready) {
      if (ready) {
        this.addLayer()
      }
    }
  },
  created() {
    if (this.loaded) {
      this.init()
    }
  },
  beforeDestroy() {
    if (this.map) {
      // this.map.off('click', this.layerName, this.handleClickMarkerEvent)
      // this.map.off('mouseenter', this.layerName, this.showPointer)
      // this.map.off('mouseleave', this.layerName, this.hidePointer)
    }
    this.clearLayer()
  },
  methods: {
    
    /********************************************************************************
     * Setup
     */

    init() {
      // Load the layer icons
      this.loadIcons()

      // // click event
      // this.map.on('click', this.layerName, this.handleClickMarkerEvent)

      // // Cursor
      // this.map.on('mouseenter', this.layerName, this.showPointer)
      // this.map.on('mouseleave', this.layerName, this.hidePointer)
    },

    /**
     * Make the marker icons available to MapBox
     *  This must be done before adding the layer
     */
    loadIcons() {
      this.requiredIcons.forEach(name => {
        this.map.loadImage(
          require(`@/assets/image/legend/chargingpoint-${name}.png`),
          (err, image) => {
            if (err) throw err;

            if (! this.map.hasImage(`chargingpoint-${name}`)) {
              this.map.addImage(`chargingpoint-${name}`, image);
            }
            this.iconCounter++
        })
      })
    },

    /********************************************************************************
     * The events
     */

    /**
     * Mouse hover effects
     */
    showPointer() {
      this.map.getCanvas().style.cursor = 'pointer'
    },
    hidePointer() {
      this.map.getCanvas().style.cursor = ''
    },

    /**
     * Emit a click event upon clicking a request icon
     */
    handleClickMarkerEvent(e) {
      if (! e.features.length) return;

      // Cancel other map events
      e.preventDefault()
      e.originalEvent.stopPropagation()

      this.$emit('click', { uuid: e.features[0].properties.uuid })
    },


    /********************************************************************************
     * The Layer
     */

    /**
     * Translate request requests into GeoJSON
     */
    generateGeoJson() {
      let processes = Array.isArray(this.shownProcesses) ? this.shownProcesses : []

      return {
        "type": "FeatureCollection",
        "features": processes.map(process => {
          let icon = ''

          if (process.status.step < (this.realisationFromStep || 6)) {
            icon = 'suggestion'
          } else {
            icon = 'in-progress'
          }

          let coordinates = {
            lat: process.values.Location.Location.Latitude,
            lng: process.values.Location.Location.Longitude
          }

          return {
            "type": "Feature",
            "properties": {
              "uuid": process.uuid,
              "icon": `chargingpoint-${icon}`,
              "reference": process.case_ref,
              "size": 0.05
            },
            "geometry": {
              "type": "Point",
              "coordinates": [coordinates.lng, coordinates.lat]
            }
          }
        })
      }
    },

    updateLayerData() {
      if (! this.loaded) return
      let source = this.map.getSource(this.layerName)

      if (! source) return
      source.setData(this.generateGeoJson())
    },

    /**
     * Load the request layer
     */
    addLayer() {
      if (! this.map) return

      this.clearLayer()

      // Add the generated source 
      this.map.addSource(this.layerName, {
        type: 'geojson',
        data: this.generateGeoJson()
      })

      let currentLayers = this.map.getStyle().layers.map(layer => layer.id)
      let positionBelow = ['requests', 'requests-text', 'locationpin']
      positionBelow = positionBelow.reduce((result, layer) => {
        return result ? result : (currentLayers.includes(layer) ? layer : null)
      }, null)

      /**
       * The marker icon layer
       */
      this.map.addLayer({ 
        "id": this.layerName,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 10,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          "icon-allow-overlap": true,
          "icon-image": ['get', 'icon'], 
          "icon-size": [
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            ['get', 'size'],
            18,
            0.2
          ],
          "icon-offset": [0, -125]
        },
        "paint": {
          // "icon-color": "",
          "icon-opacity": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            10,
            0,
            10.2,
            1
          ]
        }
      }, positionBelow) // , this.getSymbolLayerId

      /**
       * Add the ID next to definitive charging points
       */
      this.map.addLayer({ 
        "id": `${this.layerName}-text`,
        "type": "symbol",
        "source": this.layerName,
        "minzoom": 14,
        "layout": {
          "symbol-sort-key": 1,
          "symbol-placement": "point",
          "symbol-z-order": "source",
          // 'text-allow-overlap': true,
          'text-field': ['get', 'reference'],
          'text-font': [
            'Open Sans Semibold',
            'Arial Unicode MS Bold'
          ],
          "text-size": 11,
          "text-radial-offset": [ // transition based on zoom
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            1,
            18.2,
            2
          ],
          'text-variable-anchor': [ "top-right", "bottom-right", "bottom-left", "top-left", "right", "left", "top", "bottom" ],
          "text-justify": 'auto'
        },
        "paint": {
          "text-opacity": [
            "interpolate",
            ["linear"],
            ["zoom"],
            14,
            0,
            14.2,
            0.8
          ]
        }
      }, positionBelow)
    },

    /**
     * Clean up the layer
     */
    clearLayer() {
      if (! this.map) return 

      // Clean up first if we must
      let source = this.map.getSource(this.layerName)
      if (source) {
        if (this.map.getLayer(this.layerName)) {
          this.map.removeLayer(this.layerName)
        }
        if (this.map.getLayer(`${this.layerName}-text`)) {
          this.map.removeLayer(`${this.layerName}-text`)
        }
        
        this.map.removeSource(this.layerName)
      }
    }
  }
}
</script>

