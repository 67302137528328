<template>
  <div class="FuseBoxPermission">
    <FieldSet 
      :label="label"
      :values="values"
      :fields="fields"
      :layout="layout"
      :validation="validation"
      :disabled="disabled"
      :path="path"
      :viewer="viewer"
      @blur="handleBlur"
      @input="handleInput"
    />

  </div>
</template>

<script>
import FieldSet from '@/components/form/FieldSet'

import { helpers } from 'vuelidate/lib/validators'
import { isUrl } from '@/services/validation'

export default {
  name: 'FuseBoxPermission',
  components: { FieldSet },
  props: {
    label: {
      type: String,
      default: 'Toestemming voor (grote) aanpassing/vervanging hoofdverdeelkast'
    },
    values: {
      type: Object,
      default: function() {
        return {}
      }
    },
    path: {
      type: String,
      default: 'BuildingOptions.FuseBoxPermission'
    },
    columns: {
      type: String,
      default: '1fr 1fr 1fr 1fr 1fr'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    viewer: {
      type: Boolean,
      default: false
    },
    completed: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      // Copied from Cafienne
      fields: {
        "FuseBoxPermissionAskedDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de Go/No Go voor het aanpassen van de hoofdverdeelkast is aangevraagd"
            "title": "Datum van Go/No Go aanvraag"
        },
        "FuseBoxPermissionReceivedDate": {
            "type": "string",
            "format": "date",
            // "title": "Datum dat de uitkomst van de Go/No Go beslissing voor het aanpassen van de hoofdverdeelkast is ontvangen"
            "title": "Datum van beslissing"
        },
        "FuseBoxContractorQuotationLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar de offerte voor een grote aanpassing hoofdverdeelkast / vervanging"
            "title": "Link naar offerte",
            "readonly": true
        },
        "Amount": {
            "type": "string",
            // "title": "Kosten van een grote aanpassing hoofdverdeelkast / vervanging"
            "title": "Kosten in €",
            "readonly": true
        },
        "FuseBoxAdjustmentDecision": {
            "type": "string",
            // "title": "Uitkomst Go/No Go beslissing voor het aanpassen van de hoofdverdeelkast",
            "title": "Beslissing",
            "enumNames": [
                "Go",
                "No Go"
            ],
            "enum": [
                "Go",
                "NoGo"
            ]
        },
        "FuseBoxAdjustmentDecisionLink": {
            "type": "string",
            "format": "url",
            // "title": "Link naar Go/No Go document voor netverzwaring"
            "title": "Link naar het document over de beslissing"
        }
      },
      layout: [{
        "columns": "1fr 1fr",
        "fields": [ 'FuseBoxPermissionAskedDate', 'FuseBoxPermissionReceivedDate' ]
      },{
        "columns": "1fr 1fr",
        "fields": [ 'Amount', 'FuseBoxContractorQuotationLink' ]
      },{
        "columns": "1fr 1fr",
        "fields": [ 'FuseBoxAdjustmentDecision', 'FuseBoxAdjustmentDecisionLink' ]
      }],
      validation: {
        FuseBoxContractorQuotationLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        },
        FuseBoxAdjustmentDecisionLink: {
          isUrl: (value) => !helpers.req(value) || isUrl(value)
        }
      }
    }
  },
  watch: {
    completed() {
      if (this.completed) {
        this.lockEvaluationOptions()
      }
    }
  },
  created() {
    if (this.completed) {
      this.lockEvaluationOptions()
    }
  },
  methods: {
    lockEvaluationOptions() {
      this.fields.FuseBoxAdjustmentDecision.disabled = true
    },

    handleInput({ name, value }) {
      this.$emit('input', {
        value, 
        name
      })
    },
    handleBlur({ name }) {
      this.$emit('blur', {
        name
      })
    }
  }
}
</script>