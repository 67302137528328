<template>
  <component v-if="infoComponent !== null" :is="infoComponent" :request="request" />
  <div v-else>
    Unknown tenant 
  </div>
</template>

<script>

import AMInformation from '@/components/request/amsterdam/Information.vue'
import PNCInformation from '@/components/request/park-n-charge/Information.vue'
import GORALInformation from '@/components/request/go-ral/Information.vue'
import OPCInformation from '@/components/request/opcharge/Information.vue'

export default {
  components: {
    PNCInformation,
    GORALInformation,
    OPCInformation,
    AMInformation
  },
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    infoComponent() {
      switch(process.env.VUE_APP_TENANT) {
        case 'park-n-charge':
          return 'PNCInformation'
        case 'go-ral': 
          return 'GORALInformation'
        case 'opcharge':
          return 'OPCInformation'
        case 'amsterdam': 
          return 'AMInformation'
      }

      return null
    }
  }
}
</script>

<style>

</style>