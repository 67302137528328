<template>
  <div class="RealisationControls">

    <CategoryMenuFilter 
      :filterConfig="filterConfig" />
    
    <div class="RealisationControls__search">

    </div>

  </div>
</template>

<script>

import CategoryMenuFilter from '../filters/CategoryMenuFilter.vue'
import TableState from '../mixins/TableState.vue'

/**
 * This component implements the permament controls of the realisation index
 */
export default {
  name: 'RealisationControls',
  mixins: [ TableState ],
  components: {
    CategoryMenuFilter
  },
  computed: {
    filterConfig() {
      return this.config.filters.category || {}
    },
  },
}
</script>

<style lang="scss">
.RealisationControls {
  padding-left: 8px;
}

</style>