<template>
  <div v-if="record.ref && (canAccessRequestLocations || canAccessRequests)">
    <h5>Verbonden aanvragen</h5>
    <ul class="RealisationDetail__RequestList">
      <li 
        v-for="request in connectedRequests" 
        :key="request.uuid">
        {{ request.case_ref }}: {{ request.summary.address }} 
        <b-icon 
          v-if="canAccessRequests && ! completed && ! request.limited"
          class="ml-2" 
          icon="x-circle-fill" 
          @click="handleBreakConnection({ uuid: request.uuid })" />
      </li>
    </ul>
  </div>
</template>

<script>

import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'RelatedRequests',
  props: {
    record: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'canAccessRequests',
      'canAccessRequestLocations'
    ]),
    ...mapGetters('requests', [
      'requestByUuid'
    ]),
    ...mapGetters('relations', [
      'requestUuidsByRealisationUuid'
    ]),
    /**
     * The related requests UUIDS
     */
    requestUuids() {
      return this.requestUuidsByRealisationUuid({ uuid: this.record.uuid })
    },
    
    /**
     * The related request objects
     */
    connectedRequests() {
      return this.requestUuids.slice().map(uuid => {
        return this.requestByUuid({ uuid })
      })
    },
    completed() {
      try {
        return !! this.record.status.completed
      } catch(e) {
        return false
      }
    },
  },
  methods: {
    ...mapMutations('relations', [
      'setConnectionChange'
    ]),
    handleBreakConnection({ uuid }) {
      this.setConnectionChange({
        type: 'remove',
        requestUuid: uuid,
        realisationUuid: this.record.uuid
      })
    },
  }
}
</script>

<style>

</style>