<template>
  <div>
    <h3 class="mt-3">Kenmerken:</h3>
    <table class="table table-striped table-sm mb-4">
      <tbody>
        <tr v-for="(val, key) in summary" :key="key">
          <th scope="row">{{ key }}</th>
          <td v-if="key === 'Status'">
            <span
              class="badge bagde-pill"
              :class="'badge-' + status.variant"
            >
              <b-icon :icon="status.icon"></b-icon>
              {{ val }}
            </span>
            <b-icon
              v-if="hasJudgement && ! hasRealisationProcess && isAllowedToEditJudgement"
              icon="pencil-square"
              class="ml-1 start-editing-judgement"
              @click="resetJudgement"
            />
          </td>
          <td v-else-if="key === 'Adres'">
            {{ val }}
            <br />
            <a target="_blank" :href='`https://www.google.com/maps/search/?api=1&amp;query=${encodeURIComponent(val)}`'>
              Open adres in Google Maps <b-icon icon="box-arrow-up-right"></b-icon>
            </a>
          </td>
          <td v-else>{{ val }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'
export default {
  name: 'SummaryTable',
  props: {
    request: {
      type: Object,
      required: true
    }
  },
  computed: {
    ...mapGetters('user', ['hasRole']),
    ...mapGetters('requests', ['statusOptionById']),
    ...mapGetters('relations', ['realisationUuidByRequestUuid']),
    status() {
      return this.statusOptionById({ id: this.request.status })
    },
    hasJudgement() {
      return this.status.id !== 2
    },
    isRejected() {
      return this.status.id === 4
    },
    hasRealisationProcess() {
      return this.realisationUuidByRequestUuid({ uuid: this.request.uuid })
    },
    isAllowedToEditJudgement() {
      return this.hasRole({role: 'admin'})
    },
    summary() {
      const summary = {
        Referentie: this.request.case_ref,
        Status: this.status.name,
        'Ingezonden op': this.request.submitted_at,
      }

      if (this.hasJudgement) {
        summary['Beoordeeld op'] = this.request.processed_at
      }

      if (this.isRejected) {
        summary.Reden = this.request.judgement.reasonLabel

        if (this.request.judgement.reason === 3) {
          summary.Toelichting = this.request.judgement.remarks
        }
      }

      return Object.assign(summary, {
        Aanvrager: this.request.summary.name,
        Adres: this.request.summary.address
      })
    },
  },
  methods: {
    ...mapMutations('requests', ['updateRequest']),
    resetJudgement() {
      this.updateRequest({
        ref: this.request.ref,
        data: {
          editJudgement: true
        }
      })
    },
  }
}
</script>

<style lang="scss">
.start-editing-judgement {
  &:hover {
    cursor: pointer;
  }
}
</style>